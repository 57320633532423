import {
    Home,
    About,
    UserProfile,
    Survey,
    CreateSurvey,
    EditSurvey,
    ViewSurvey,
    ViewSession,
    LoadingRedirect,
    Debug
} from "views";

export default [
    {
        path: "/",
        viewComponent: LoadingRedirect,
    },
    {
        path: "/home",
        view: "home",
        viewComponent: Home,
    },
    {
        path: "/about",
        view: "about",
        viewComponent: About,
    },
    {
        path: "/profile",
        view: "profile",
        viewComponent: UserProfile,
    },
    {
        path: "/create",
        view: "create-survey",
        viewComponent: CreateSurvey,
    },
    {
        path: "/new-survey",
        view: "create-survey",
        viewComponent: CreateSurvey,
    },
    {
        path: "/copy/:surveyId",
        view: "duplicate-survey",
        viewComponent: LoadingRedirect,
    },
    {
        path: "/edit/:surveyId",
        view: "edit-survey",
        viewComponent: EditSurvey,
    },
    {
        path: "/view/:surveyId",
        view: "view-survey",
        viewComponent: ViewSurvey,
    },
    {
        path: "/view/:surveyId/response/:sessionId",
        view: "view-survey-session",
        viewComponent: ViewSession,
    },
    {
        path: "/surveys/:surveyId",
        view: "take-survey",
        // NOTE: 'public' is a resevered word.
        isPublic: true,
        viewComponent: Survey,
    },
    {
        path: "/surveys/:surveyId/:fragmentId",
        view: "take-survey-at-fragment",
        // NOTE: 'public' is a resevered word.
        isPublic: true,
        viewComponent: Survey,
    },
    {
        path: "/callback",
        view: "callback",
        isPublic: true,
        viewComponent: LoadingRedirect,
    },
    {
        path: "/login",
        view: "login",
        isPublic: true,
        viewComponent: LoadingRedirect,
    },
    {
        path: "/register",
        view: "register",
        isPublic: true,
        viewComponent: LoadingRedirect,
    },
    {
        path: "/debug",
        view: "debug",
        isPublic: true,
        viewComponent: Debug
    }
]
    .map(
        // Precompile a "match" regular expression to check to matches.
        ({ path, ...rest }) => ({
            path,
            match: new RegExp(
                `^${path.replace(
                    /\/:(?:\w+)\??/g,
                    (match, param) =>
                        // NOTE: And explicit set of accepted pathname characters could be good. (i.e. /[\w\d%_-]+/) But, I think this is better for now.
                        `(/[^/]*)${match.endsWith("?") ? "?" : ""}`
                )}$`
            ),
            ...rest,
        })
    )
    // Make sure all the routes are accessible by sorting them in reverse path order.
    .sort(
        ({ path: a }, { path: b }) =>
            a.split(":")[0] < b.split(":")[0] ? 1 : -1
    );
