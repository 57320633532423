import React from "react";
import { SurveyLexLogo, SurveyLexNavLink } from "components";
import UserMenu from "components/UserMenu/UserMenu";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import types from "store/types";
import "./SurveyLexUserNav.css";

const SurveyLexNavLinks = connect(({ CONFIG, view }) => ({
    faqLink: CONFIG.APPLICATION__FAQ_LINK,
    contactLink: CONFIG.APPLICATION__CONTACT_LINK,
    view,
}))(({ dispatch, view, faqLink, contactLink }) => {
    const navigateTo = link => () =>
        dispatch({
            type: types.requests.NAVIGATION,
            payload: link,
        });
    return (
        <div className="links">
            <SurveyLexNavLink
                text="My Surveys"
                active={view === "home"}
                onClick={navigateTo({ view: "home" })}
            />
            <SurveyLexNavLink
                text="FAQ"
                onClick={navigateTo({ location: faqLink })}
            />
            <SurveyLexNavLink
                text="Contact Us"
                active={view === "about"}
                onClick={navigateTo({ location: contactLink })}
            />
        </div>
    );
});

const UserMenuWithProfile = connect(
    ({ user }) => ({ profile: user.profile }),
    undefined,
    (stateProps, { dispatch }, ownProps) => {
        // Let the state know we require the user profile.
        dispatch({ type: types.requests.USER_PROFILE });
        return { ...stateProps, dispatch, ...ownProps };
    }
)(({ profile = {}, ...props }) => {
    return (
        <UserMenu
            name={`${profile.givenName || ""} ${profile.familyName || ""}`}
            picture={profile.picture}
            {...props}
        />
    );
});

/**
 * A main navbar for the SurveyLex application.
 */
const SurveyLexUserNav = connect()(({ dispatch }) => {
    return (
        <div className="slx-nav">
            <SurveyLexLogo
                size="xsmall"
                onClick={() =>
                    dispatch({
                        type: types.requests.NAVIGATION,
                        payload: { view: "home" },
                    })
                }
            />
            <SurveyLexNavLinks />
            <div className="pull-right">
                <UserMenuWithProfile>
                    <MenuItem
                        onClick={() =>
                            dispatch({
                                type: types.requests.NAVIGATION,
                                payload: { view: "profile" },
                            })
                        }
                    >
                        User Profile
                    </MenuItem>
                    <MenuItem
                        onClick={() => dispatch({ type: types.user.EXITED })}
                    >
                        Logout
                    </MenuItem>
                </UserMenuWithProfile>
            </div>
        </div>
    );
});

export default SurveyLexUserNav;
