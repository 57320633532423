import ReactGA from "react-ga";
import types from "store/types";

ReactGA.initialize(process.env.REACT_APP__GA__USER_TOKEN);

export default ({ dispatch, getState }) => next => action => {
    // We shouldn't fail on analytics...
    try {
        switch (action.type) {
            case types.requests.ANALYTICS_EVENT: {
                // TODO: We should have a more universal format for this.
                ReactGA.event(action.payload);
                next(action);
                break;
            }
            case types.requests.NAVIGATION: {
                if (
                    action.payload.location &&
                    /^http/.test(action.payload.location)
                ) {
                    ReactGA.outboundLink({
                        label: action.payload.location,
                    });
                }
                next(action);
                break;
            }
            case types.responsePlayback.PLAYED:
            case types.responsePlayback.PAUSED:
            case types.responsePlayback.STOPPED:
            case types.responsePlayback.RECEIVED_PLAYBACK:
            case types.responsePlayback.END_OF_PLAYBACK:
                {
                    next(action);
                    const {
                        responsePlayback: { playback = {} } = {},
                    } = getState();
                    ReactGA.event({
                        category: "Response Playback",
                        action: action.type,
                        label: playback.id,
                    });
                }
                break;
            case types.user.AUTHENTICATED:
                next(action);
                ReactGA.set({
                    userId: getState().user.userId,
                });
                ReactGA.event({
                    category: "User",
                    action: action.type,
                });
                break;
            case types.user.EXITED: {
                ReactGA.event({
                    category: "User",
                    action: action.type,
                });
                next(action);
                ReactGA.set({
                    userId: undefined,
                });
                break;
            }
            case types.session.INITIALIZED:
            case types.session.UPDATED:
                {
                    next(action);
                    const {
                        session: { sessionId, surveyId },
                    } = getState();
                    ReactGA.set({
                        sessionId,
                        surveyId,
                    });
                    ReactGA.event({
                        category: "User",
                        action: action.type,
                        nonInteraction: true,
                    });
                }
                break;
            case types.responses.COMPLETED:
                next(action);
                ReactGA.event({
                    category: "Survey Taker",
                    action: action.type,
                });
                break;
            case types.requests.DOWNLOAD_ARCHIVE:
            case types.session.ARCHIVED:
                next(action);
                ReactGA.event({
                    category: "Survey Results",
                    action: action.type,
                });
                break;
            case types.surveys.SEARCHED:
            case types.surveys.DELETED:
            case types.surveys.ARCHIVED:
            case types.surveys.PUBLISHED:
            case types.surveys.VIEWED:
                next(action);
                ReactGA.event({
                    category: "Survey Manager",
                    action: action.type,
                });
                break;
            case types.recorder.FAILED:
                next(action);
                ReactGA.event({
                    category: "Recorder",
                    action: action.type,
                    label: action.payload.reason,
                    nonInteraction: true,
                });
                break;
            case types.navigation.ARRIVED:
                {
                    next(action);
                    const pathname = action.payload.location.pathname;
                    ReactGA.set({ title: getState().view, page: pathname });
                    ReactGA.pageview(pathname);
                }
                break;
            case types.surveyCreator.CREATED_FRAGMENT:
                next(action);
                ReactGA.event({
                    category: "Editor",
                    label: action.payload.type,
                    action: action.type,
                });
                break;
            case types.surveyCreator.APPLIED_TEMPLATE:
                next(action);
                ReactGA.event({
                    category: "Editor",
                    label: action.payload.surveyId || action.payload.surveyUrl,
                    action: action.type,
                });
                break;
            case types.surveyCreator.DELETED_FRAGMENT:
                ReactGA.event({
                    category: "Editor",
                    label: getState().surveyCreator.survey.fragments[
                        action.payload
                    ].type,
                    action: action.type,
                });
                next(action);
                break;
            case types.surveyCreator.MOVED_FRAGMENT:
            case types.surveyCreator.UNDO:
            case types.surveyCreator.REDO:
            case types.surveyCreator.SAVED: {
                next(action);
                ReactGA.event({
                    category: "Editor",
                    action: action.type,
                });
                break;
            }
            default:
                next(action);
                break;
        }
    } catch (e) {
        // console.log(e);
        next(action);
        dispatch({ type: types.application.ERRORED, payload: e, error: true });
    }
};
