import React from "react";
import { ConsentForm, EditorPane } from "components";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import "./EditorFragmentConsent.css";
import connectFragmentDataToValue from "enhancers/connectFragmentDataToValue";

const ConsentTitleTextField = compose(
    withProps({
        placeholder: "Consent Title",
    }),
    connectFragmentDataToValue("title")
)(TextField);

const ConsentConfirmTextField = compose(
    connect(({ surveyCreator: { survey: { fragments } } }, { fragmentIndex }) => ({
        isLastConsent:
            fragmentIndex < fragments.length - 1
                ? fragments[fragmentIndex + 1].type !== "consent"
                : true,
    }))
)(
    () => "I Accept"
    // ({ isLastConsent }) => "I Accept"
    // isLastConsent ? "Start Survey" : "Agree and Continue"
);
const ConsentPromptTextField = compose(connectFragmentDataToValue("prompt"))(
    TextField
);
const ConsentTextField = compose(connectFragmentDataToValue("consentText"))(
    TextField
);

const EditorFragmentConsent = compose(
    withProps(({ fragmentIndex }) => ({
        children: (
            <div className="editor-consent-screen__container">
                <ConsentTitleTextField
                    fragmentIndex={fragmentIndex}
                    className="editor-consent-screen__title"
                />
                <ConsentForm
                    reject={() => {}}
                    confirmText={<ConsentConfirmTextField fragmentIndex={fragmentIndex} />}
                    prompt={
                        <ConsentPromptTextField
                            className="editor-consent-screen__prompt"
                            placeholder="Consent Prompt"
                            fullWidth
                            multiline
                            fragmentIndex={fragmentIndex}
                        />
                    }
                    disabled={true}
                >
                    <ConsentTextField
                        placeholder="Consent Language"
                        variant="outlined"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={15}
                        fragmentIndex={fragmentIndex}
                    />
                </ConsentForm>
            </div>
        ),
    }))
)(EditorPane);

export default EditorFragmentConsent;
