import React from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import types from "store/types";
import "./CompletionRate.css";

const CompletionRate = compose(
    connect(({ sessionsBySurveyId, completedBySessionId }, { surveyId }) => ({
        sessionsById: sessionsBySurveyId[surveyId],
        completedBySessionId,
    })),
    withProps(({ completedBySessionId, sessionsById }) => {
        const sessionIds = Object.keys(sessionsById);
        return {
            totalCompletions: sessionIds.filter(
                sessionId => completedBySessionId[sessionId]
            ).length,
            totalViews: sessionIds.length,
        };
    }),
    withProps(({ totalCompletions, totalViews }) => ({
        rateCompletion: `${
            totalViews ? Math.floor((totalCompletions / totalViews) * 100) : 0
        }%`,
    }))
)(({ dispatch, surveyId, totalCompletions = 0, rateCompletion, ...rest }) => {
    dispatch({
        type: types.requests.RESPONSES,
        // Let's retrieve all the surveys from a user.
        payload: {
            surveyId,
        },
    });
    return (
        <div className="completion-rate">
            <div className="completion-rate__totals">
                {totalCompletions} Completions
            </div>
            <div className="completion-rate__rate">
                Completion Rate ({rateCompletion})
            </div>
        </div>
    );
});

export default CompletionRate;
