import types from "store/types";
import { FileUploadService } from "services";

const fileUploader = new FileUploadService();

const uploadFileToUser = blob => (dispatch, getState) => {
    const state = getState();
    const token = state.user.session.token;
    const userId = state.user.userId;

    // Blob is a FormData.
    blob.append("userId", userId);

    fileUploader.uploadImageToUser(blob, token, userId).then(res => {
        if (res.status === 200 && res.data.imageUrl) {

            dispatch({
                type: types.user.UPDATED_PROFILE,
                payload: {
                    ...state.user.profile,
                    uploadedImages: state.user.profile.uploadedImages.concat(res.data.imageUrl)
                },
            });

            // Update the localStorage version of the user
            // localStorage.setItem('user', JSON.stringify(getState().user));
        }
        else {
            // TODO: If there is a 500 error (e.g. something with GCP itself), Object isn't currently handling this properly. Need to update that and/or better handle it here
            // This also errors if the Storage service container is broken
            console.log("Error...");
            console.log(res);
        }
    })
    .catch(err => {
        // TODO: handle error...
        console.log(err);
    })
};

const deleteImageFromUser = imageUrl => (dispatch, getState) => {
    const state = getState();
    const token = state.user.session.token;
    const userId = state.user.userId;

    fileUploader.deleteImageFromUser(imageUrl, userId, token).then(res => {
        if (res.status === 200) {

            const uploadedImages = state.user.profile.uploadedImages;
            const index = uploadedImages.indexOf(imageUrl);
            
            // If we couldn't find the image index, drop out.
            if (index === -1) return 

            dispatch({
                type: types.user.UPDATED_PROFILE,
                payload: {
                    ...state.user.profile,
                    uploadedImages: uploadedImages.slice(0,index).concat(uploadedImages.slice(index+1))
                },
            });
            // localStorage.setItem('user', JSON.stringify(getState().user));
        }
        else {
            // Handle error...
            console.log("Error...");
            console.log(res);
        }
    })
    .catch(err => {
        // TODO: handle error...
        console.log(err);
    })
}

export default ({ getState, dispatch }) => next => action => {
    switch (action.type) {
        case types.fileUpload.UPLOAD_IMAGE_TO_USER:
            const { blob } = action.payload;
            dispatch(uploadFileToUser(blob));
            break;
        case types.fileUpload.DELETE_IMAGE_FROM_USER:
            const { imageUrl } = action.payload;
            dispatch(deleteImageFromUser(imageUrl));
            break;
        default:
    }
    next(action);
};
