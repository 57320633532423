import React from "react";
import { Logo } from "components";
import { Typography } from "@mui/material";
import "./Top.css";

/**
 * A bar at the top of the page.
 */
const Top = ({ title, backgroundImage, logoUrl, context }) => {
    return (
        <div
            className="top"
            style={{
                backgroundImage: backgroundImage
                    ? "url(" + backgroundImage + ")"
                    : undefined,
            }}
        >
            { logoUrl ? <Logo className="top__logo" size="auto" source={logoUrl} /> : <div className="top__logo" /> }
            <Typography className="top__title" variant="h4">
                {title}
            </Typography>
            <div className="top__context">{context}</div>
        </div>
    );
};

Top.defaultProps = {
    backgroundImage: "",
    secondaryLogo: "",
};

export default Top;
