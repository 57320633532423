import React from "react";
import showdown from "showdown";
import showdownOptions from "util/showdownOptions";
import DOMPurify, { sanitize } from "dompurify";
import { compose, withProps } from "recompose";
import rewrite from "util/customMarkdownHtmlRewrite";
import "./Showdown.css";

// https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const Showdown = compose(
    withProps(({ options = {} }) => ({
        converter: new showdown.Converter({ ...showdownOptions, ...options }),
    })),
    withProps(({ converter, children }) => ({
        html: sanitize(converter.makeHtml(children)),
    })),
    withProps(({ html, embed = true }) => ({
        html: embed ? rewrite(html) : html,
    }))
)(({ className, html }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: html }}
            className={className ? `${className} showdown` : "showdown"}
        />
    );
});

export default Showdown;
