export default ({
    CLEARED,
    SET,
    UNSET
}) => (state = {}, action) => {
    switch (action.type) {
        case SET:
            return {
                ...state,
                ...action.payload
            }
        case UNSET:
            return action.payload.reduce((state, unset) => {
                delete state[unset];
                return state
            }, { ...state});
        case CLEARED:
            return {};
        default:
            return state;
    }
}