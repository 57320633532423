import types from "store/types";
import { v1 as UUID } from "uuid";
import { isKeyHotkey } from "is-hotkey";
import surveyReducer from "store/reducers/survey";
import { memoize } from "lodash";
import axios from "axios";
import debounce from "util/debounce";
import { combine, promote } from "redux-intervention";

const defaultSurvey = surveyReducer({})(undefined, {});

const undo = (dispatch) => {
    dispatch({ type: types.surveyCreator.UNDO });
};
const redo = (dispatch) => {
    dispatch({ type: types.surveyCreator.REDO });
};
const save = (dispatch) => {
    dispatch({ type: types.surveyCreator.SAVED });
};

// Key bindings for editor commands.
// TODO: Consider registering KEY_BINDINGS to the state.
const KEY_BINDINGS = {
    "mod+z": undo,
    "alt+backspace": undo,
    "mod+shift+z": redo,
    "mod+y": redo,
    "mod+s": save,
};

const dispatchKeyCommands = (event) => (dispatch, getState) => {
    const keyCommand = Object.entries(KEY_BINDINGS).find(
        // Checking if the key is one of the hotkeys.
        ([hotkey]) => isKeyHotkey(hotkey, event)
    );
    if (keyCommand) {
        // Calling that hotkey command if it is one of the hotkeys.
        event.preventDefault();
        const [, command] = keyCommand;
        return dispatch(command);
    }
};

const loadSurveyCreatorSurvey = (survey) => ({
    type: types.surveyCreator.LOADED_SURVEY,
    payload: survey,
});

const loadSurveyCreatorSurveyBySurveyId =
    (surveyId) => (dispatch, getState) => {
        const {
            surveyBySurveyId: { [surveyId]: survey },
        } = getState();
        return dispatch(loadSurveyCreatorSurvey(survey));
    };

const newSurveyFromSurvey =
    ({ title = "", fragments = [] }) =>
    (dispatch, getState) => ({
        createdDate: new Date(),
        title,
        surveyId: UUID(),
        fragments: fragments.map(
            ({ fragmentId, meta: { lineage } = {}, ...fragment }) => ({
                ...fragment,
                fragmentId: UUID(),
                // Inheritance metadata for tracking survey fragments.
                meta: {
                    ...(fragment.meta || {}),
                    lineage: lineage || fragmentId,
                    parent: fragmentId,
                },
            })
        ),
        creator: getState().user.userId,
    });

const clearSurveyCreatorHistory = () => ({
    type: types.surveyCreator.CLEARED_SURVEY_HISTORY,
});

const syncEditNavigation = (dispatch, getState) => {
    const {
        surveyCreator: {
            survey: { surveyId },
        },
        view,
    } = getState();

    // If we're already viewing the editor.
    if (view === "edit-survey") return;
    // If this is the current survey.
    if (survey.surveyId !== getState().surveyCreator.survey.surveyId) return;
    // If we're in the `create-survey` view.
    if (getState().view === "create-survey") {
        return dispatch({
            type: types.requests.NAVIGATION,
            payload: {
                view: "edit-survey",
                params: { surveyId },
            },
        });
    }
};

const setSurvey = (survey) => async (dispatch, getState) => {
    // "Load" the survey.
    await dispatch(loadSurveyCreatorSurvey(survey));
    // Clear out the history.
    return dispatch(clearSurveyCreatorHistory());
};

const retrievePublicSurvey = memoize((surveyId) =>
    debounce(
        (dispatch, getState) => {
            const {
                surveyBySurveyId: { [surveyId]: survey },
                CONFIG: { NLX__API_ENDPOINT__OBJECT: OBJECT_ENDPOINT },
            } = getState();

            if (survey instanceof Object && survey.surveyId === surveyId)
                return Promise.resolve();

            const endpoint = OBJECT_ENDPOINT + "/surveys/taker/" + surveyId;
            return axios.get(endpoint).then((res) => {
                if (res.data) {
                    return dispatch({
                        type: types.surveys.LOADED,
                        payload: [res.data],
                    });
                }
            });
        },
        // Update at max once a minute.
        60 * 1000,
        { leading: true }
    )
);

const retrievePublicSurveyFromUrl = memoize((surveyUrl) =>
    debounce(
        async (dispatch, getState) => {
            const { data: survey } = await axios.get(surveyUrl);
            await dispatch({
                type: types.surveys.LOADED,
                payload: [survey],
            });
            return survey;
        },
        // Update at max once a minute.
        60 * 1000,
        { leading: true }
    )
);

const setFragmentId = (store) => (next) => (action) =>
    // Ensure that a created fragment has a fragmentId.
    next({
        ...action,
        payload: {
            fragmentId: UUID(),
            ...action.payload,
        },
    });

const scrollToBottom = () => {
    // Scroll to the bottom when a fragment is created.
    setTimeout(() => {
        window.scrollTo(
            0,
            document.body.scrollHeight -
                document.getElementsByClassName("Footer")[0].scrollHeight -
                window.innerHeight
        );
    }, 0);
};

const setSurveyCreatorFromNavigation = async (dispatch, getState) => {
    const state = getState();
    if (state.view === "edit-survey") {
        const {
            navigation: {
                params: { surveyId },
            },
        } = getState();
        await dispatch({
            type: types.requests.SURVEY,
            payload: { survey: { surveyId } },
        });
        await dispatch(loadSurveyCreatorSurveyBySurveyId(surveyId));
        return dispatch(clearSurveyCreatorHistory());
    }
    if (state.view === "create-survey") {
        const surveyId = UUID();
        const state = getState();
        await dispatch(
            loadSurveyCreatorSurvey({
                ...defaultSurvey,
                surveyId,
                creator: state.user.userId,
            })
        );
        return dispatch(clearSurveyCreatorHistory());
    }
    if (state.view === "duplicate-survey") {
        const surveyId = { navigation: { params: { surveyId } } };
        await dispatch({
            type: types.requests.SURVEY,
            payload: { survey: { surveyId } },
        });
        const {
            surveys: { [surveyId]: survey },
        } = getState();
        if (survey) {
            const copiedSurvey = await dispatch(newSurveyFromSurvey(survey));
            await dispatch(setSurvey(copiedSurvey));
            return dispatch(clearSurveyCreatorHistory());
        }
    }
};

const applySurveyTemplate = (survey) => async (dispatch, getState) => {
    const copiedSurvey = await dispatch(newSurveyFromSurvey(survey));
    const stateSurvey = getState().surveyCreator.survey;
    return dispatch({
        type: types.surveyCreator.UPDATED_SURVEY,
        payload: {
            ...stateSurvey,
            fragments: [...stateSurvey.fragments, ...copiedSurvey.fragments],
        },
    });
};

const appendTemplate =
    ({ payload: { surveyId, surveyUrl, survey } = {} }) =>
    async (dispatch, getState) =>
        dispatch(
            applySurveyTemplate(
                survey instanceof Object
                    ? // Apply provided survey.
                      survey
                    : typeof surveyId === "string" &&
                      getState().surveyBySurveyId[surveyId] instanceof Object
                    ? // Apply survey from state.
                      getState().surveyBySurveyId[surveyId]
                    : typeof surveyId === "string"
                    ? // Apply survey from state after load via surveyId.
                      // TODO: "Request" public survey instead of retrieving.
                      (await dispatch(retrievePublicSurvey(surveyId))) &&
                      getState().surveyBySurveyId[surveyId]
                    : typeof surveyUrl === "string"
                    ? // Apply survey from state after load via url.
                      await dispatch(retrievePublicSurveyFromUrl(surveyUrl))
                    : undefined
            )
        );

const saveSurveyCreator =
    ({ getState, dispatch }) =>
    (next) =>
    (action) => {
        {
            const {
                surveyCreator: { survey },
                CONFIG: { NLX__API_ENDPOINT__OBJECT },
                user: {
                    session: { token },
                },
            } = getState();

            const {
                surveyId,
                creator,
                title,
                archived,
                published,
                fragments,
                logoUrl,
                interfaceSize,
            } = survey;

            const endpoint = `${NLX__API_ENDPOINT__OBJECT}/surveys`;

            axios
                .post(
                    endpoint,
                    {
                        surveyId,
                        creator,
                        title,
                        archived,
                        published,
                        fragments,
                        logoUrl,
                        interfaceSize,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then((response) => {
                    // Saved successfully.
                    // TODO: Send a notification when this is successful.
                    // TODO: Add retry logic here.
                    // TODO: Add navigation prevention for external links while this is running.
                })
                .catch((err) => {
                    console.error("There was a problem saving the survey.");
                    console.error(err);
                });

            // Resolve the saved state.
            next(action);

            // Load the survey state into the application.
            dispatch({
                type: types.surveys.LOADED,
                payload: [survey],
            });
        }
    };

// Redux Middleware ~ store => next => action => next(action)
export default combine(
    [setFragmentId, types.surveyCreator.CREATED_FRAGMENT],
    {
        [types.surveyCreator.SAVED]: saveSurveyCreator,
        [types.surveyCreator.CREATED_FRAGMENT]: promote(() => scrollToBottom),
        [types.surveyCreator.APPLIED_TEMPLATE]: promote(appendTemplate),
        [types.browserEvent("onkeydown")]: promote((action) =>
            dispatchKeyCommands(action.payload)
        ),
    },
    [
        promote(() => syncEditNavigation),
        types.requests.SAVED,
        types.navigation.ARRIVED,
        types.requests.SURVEY_CREATOR,
    ],
    [
        promote(() => setSurveyCreatorFromNavigation),
        types.navigation.ARRIVED,
        types.requests.SURVEY_CREATOR,
    ],
);
