import React from "react";
import { SurveyLexUserNav, SurveyLexWelcomeNav } from "components";
import { withAuth } from "enhancers";
import "./styles.css";

const SurveyLexHeaderBar = ({
  isAuthenticated,
}) => {
  if (isAuthenticated) {
    // When we are authenticated, use the user's information to fill out their profile.
    return <SurveyLexUserNav />;
  } else {
    // If we aren't authenticated, just show a login link.
    return <SurveyLexWelcomeNav />;
  }
};

export default withAuth(SurveyLexHeaderBar);
