import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import continuous from "enhancers/continuous";
import { compose, withProps, setPropTypes } from "recompose";
import FullscreenLayout from "layouts/FullscreenLayout";
import filterDOMProps from "util/filterDOMProps";
import types from "store/types";

const AudioInfo = compose(
    connect(
        ({ recorder: { context, recorder, input } }) => ({
            context,
            recorder,
            input,
        }),
        (dispatch, { context }) => {
            console.log(context)
            dispatch({ type: types.requests.RECORDER });
            return { dispatch };
        }
    )
)(({ context, recorder, input }) =>
    context instanceof Object ? (
        <div>Connected</div>
    ) : (
        <div>Not Connected</div>
    )
);

const AudioCanvas = compose(
    setPropTypes({
        draw: PropTypes.func,
    }),
    // We'll need a canvas down the line.
    withProps({
        canvas: (() => {
            const canvas = document.createElement("canvas");
            canvas.classList.add("analyser__canvas");
            return canvas;
        })(),
    }),
    connect(
        ({ recorder: { context, recorder, input } }) => ({
            audioContext: context,
            recorder,
            input,
        }),
        (dispatch) => {
            dispatch({ type: types.requests.RECORDER });
            return { dispatch };
        }
    ),
    // We don't want to create these on every render.
    withProps(({ audioContext, recorder, input }) => {
        if (audioContext && recorder && input) {
            const analyser = new AnalyserNode(audioContext, {
                fftSize: 2048,
                smoothingTimeConstant: 0.015,
            });
            input.connect(analyser);
            return analyser
                ? {
                      analyser,
                      frequencyBins: new Uint8Array(analyser.frequencyBinCount),
                  }
                : {};
        }
    }),
    // Set the canvas as a child.
    withProps(({ canvas, children, ...props }) => ({
        canvas,
        children: (
            <div
                {...filterDOMProps(props)}
                style={{ height: "100%", width: "100%" }}
                ref={(ref) => {
                    if (ref) {
                        try {
                            ref.replaceChild(canvas, canvas);
                        } catch (e) {
                            ref.appendChild(canvas);
                        }
                    }
                }}
            />
        ),
    })),
    // Make sure the canvas fits it's container.
    withProps(({ canvas }) => {
        if (canvas.parentElement) {
            let {
                width,
                height,
            } = canvas.parentElement.getBoundingClientRect();
            // This assumes high DPI. We could add the actual DPI value in here.
            if (canvas.height !== height * 2) canvas.height = height * 2;
            if (canvas.width !== width * 2) canvas.width = width * 2;
        }
        return {};
    }),
    // Everything after this will be rendered in an animation loop.
    continuous(),
    // Fill the values with the analyser.
    withProps(({ analyser, frequencyBins }) => {
        if (analyser) {
            window.analyser = analyser;
            analyser.getByteFrequencyData(frequencyBins);
            // analyser.getByteTimeDomainData(values);
            return { frequencyBins };
        } else {
            return {};
        }
    }),
    // Draw something onto the canvas
    withProps(({ draw, ...props }) => {
        if (draw instanceof Function) {
            draw(props);
        }
        return {};
    })
)(({ children }) => children);

const spectrogram = () => {
    let sweep = 0;
    return ({ frequencyBins = [], canvas }) => {
        const context = canvas.getContext("2d");
        const iMax = frequencyBins.length;
        for (let i = 0; i < iMax; i++) {
            let y = i * 2;
            let magnitude = 256 - frequencyBins[i];
            context.fillStyle = `rgb(${magnitude}, ${magnitude}, ${magnitude})`;
            context.fillRect(sweep * 2, canvas.height - y, 2, 2);
        }
        sweep = (sweep + 1) % (canvas.width / 2);
    };
};

export default () => (
    <FullscreenLayout>
        <div style={{ position: "absolute", zIndex: 1 }}>
            Debug Audio
            <AudioInfo />
        </div>
        <div style={{ position: "relative", zIndex: 0 }}>
            <AudioCanvas draw={spectrogram()} />
        </div>
    </FullscreenLayout>
);

/**
 * TODO: Request recorder with specific user media settings.
 * Display and change the devices.
 * Display statics, current sample rate, current analyzer stats.
 * Change the visualization display.
 * */
