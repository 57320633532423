import React, { Component } from "react";

import { CreateSurveyButton } from "components";

import { withUserSurveys, withAuth } from "enhancers";
import { compose } from "recompose";
import { connect } from "react-redux";
import "./Home.css";

import { SurveySearchBar, SurveySummaries } from "components";

// import Splash from "views/HomeSplash/HomeSplash";
import LoadingRedirect from "views/LoadingRedirect/LoadingRedirect";
import types from "store/types";

import UserAppLayout from "layouts/UserAppLayout";

class UserHome extends Component {
    handleView = surveyId => {
        this.props.dispatch({
            type: types.requests.NAVIGATION,
            payload: {
                view: "view-survey",
                params: {
                    surveyId: surveyId,
                },
            },
        });
    };

    handleEdit = surveyId => {
        this.props.dispatch({
            type: types.requests.NAVIGATION,
            payload: {
                view: "edit-survey",
                params: {
                    surveyId: surveyId,
                },
            },
        });
    };

    handleDelete = surveyId => {
        this.props.dispatch({
            type: types.modals.REQUEST_CONFIRMATION,
            payload: {
                modalProps: {
                    surveyId: surveyId,
                    text: "Are you sure you would like to delete this survey?",
                    header: "Confirm Survey Deletion",
                    handleConfirm: () => {
                        this.props.dispatch({
                            type: types.surveys.DELETED,
                            payload: surveyId,
                        });
                    },
                },
            },
        });
    };

    handleArchive = surveyId => {
        this.props.dispatch({
            type: types.modals.REQUEST_CONFIRMATION,
            payload: {
                modalProps: {
                    surveyId: surveyId,
                    text: "Are you sure you would like to archive this survey?",
                    header: "Confirm Survey Archiving",
                    handleConfirm: () => {
                        this.props.dispatch({
                            type: types.surveys.ARCHIVED,
                            payload: surveyId,
                        });
                    },
                },
            },
        });
    };

    render() {
        return (
            <div className="user-home">
                <div className="home-actions">
                    <SurveySearchBar />
                    <CreateSurveyButton />
                </div>
                <SurveySummaries
                    surveys={this.props.surveys}
                    onView={this.handleView}
                    onArchive={this.handleArchive}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete}
                />
            </div>
        );
    }
}

// Let's fulfill the UserHome contract.
// We can use recomposes `compose` to make this look cleaner.
//const UserHomeWithUserSurveys = withUser(withUserSurveys(UserHome));
const UserHomeWithUserSurveys = compose(
    connect(),
    withAuth,
    withUserSurveys
)(UserHome);

// Notice that this callback, login, and the value isAuthenticated are both meaningful values that a Home screen needs to perform as a Home screen, with no extra fluff.
// These values decide what the component is, because this is the contract the component is creating with the rest of the application. So if the values are all over the place, then your component will have a crazy contract to fulfill.
const Home = props => {
    const { isAuthenticated } = props;

    return isAuthenticated ? (
        <UserAppLayout>
            <div className="home-view">
                <UserHomeWithUserSurveys {...props} />
            </div>
            <div className="home-view tutorial-video">
                <iframe
                    title="Tutorial"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/JV820uceEKY"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </UserAppLayout>
    ) : (
        <LoadingRedirect />
    ); //<Splash />;
};

export default withAuth(Home);
