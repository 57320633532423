import { connect } from "react-redux";
import types from "store/types";

const withAuth = connect(
    ({ user: { profile = {}, session: { token, expiresAt } = {} } = {} }) => ({
        isAuthenticated: Date.now() < expiresAt && token,
        token,
        profile,
    }),
    dispatch => ({
        login: () => dispatch({ type: types.requests.AUTHENTICATION }),
        logout: () => dispatch({ type: types.user.EXITED }),
    })
);

export default withAuth;
