import React from "react";
import { CSSBaseline } from "components";
import "./FullscreenLayout.css";

// This layout give 100% of the room to the 'app-content' section.
const FullscreenLayout = ({ children, style }) => (
    <div style={style} className="app-container">
        <CSSBaseline />
        <div className="app-content app-content__fullscreen">{children}</div>
    </div>
);
export default FullscreenLayout;
