import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UserAppLayout from "layouts/UserAppLayout";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import "./UserProfile.css";

const UserProfile = compose(
    connect(({ user }) => ({ profile: user.profile })),
    withProps(({ profile = {} }) => ({
        name: profile.name,
    }))
)(({ name }) => {
    return (
        <UserAppLayout>
            <Card className="UserProfile">
                <Grid container>
                    <Grid item xs={6} className="item">
                        <Typography variant="h6" gutterBottom>
                            {name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Free Tier Account
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </UserAppLayout>
    );
});

export default UserProfile;
