import React from "react";
import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import UserAppLayout from "layouts/UserAppLayout";
import types from "store/types";
import { BreadCrumbsNavbar, ResponseSummary } from "components";
import { Typography, Button } from "@mui/material";
import moment from "moment";
import "./ViewSession.css";

const ViewPreviousSessionButton = compose(
    connect(({ sessionsBySurveyId }, { sessionId, surveyId }) => ({
        sessionsById: sessionsBySurveyId[surveyId],
    })),
    withProps(({ sessionsById = {}, sessionId }) => {
        const sessions = Object.values(sessionsById).sort(
            ({ sessionId: a }, { sessionId: b }) =>
                a.split("-")[1] < b.split("-")[1] ? -1 : 1
        );
        const index = sessions.findIndex(
            ({ sessionId: id }) => sessionId === id
        );
        return {
            nextSession: index > 0 ? sessions[index - 1] : null,
        };
    }),
    mapProps(
        ({
            dispatch,
            nextSession,
            sessionId,
            disabled = false,
            surveyId,
            sessionsById,
            ...rest
        }) => ({
            disabled: disabled || nextSession === null,
            onClick: () => {
                dispatch({
                    type: types.requests.NAVIGATION,
                    payload: {
                        view: "view-survey-session",
                        params: {
                            sessionId:
                                nextSession !== null &&
                                typeof nextSession.sessionId === "string"
                                    ? nextSession.sessionId
                                    : sessionId,
                            surveyId,
                        },
                    },
                });
            },
            ...rest,
        })
    )
)(Button);
const ViewNextSessionButton = compose(
    connect(({ sessionsBySurveyId }, { sessionId, surveyId }) => ({
        sessionsById: sessionsBySurveyId[surveyId],
    })),
    withProps(({ sessionsById = {}, sessionId }) => {
        const sessions = Object.values(sessionsById).sort(
            ({ sessionId: a }, { sessionId: b }) =>
                a.split("-")[1] < b.split("-")[1] ? -1 : 1
        );
        const index = sessions.findIndex(
            ({ sessionId: id }) => sessionId === id
        );
        return {
            nextSession:
                index + 1 && index + 1 < sessions.length
                    ? sessions[index + 1]
                    : null,
        };
    }),
    mapProps(
        ({
            dispatch,
            nextSession,
            sessionId,
            disabled = false,
            surveyId,
            sessionsById,
            ...rest
        }) => ({
            disabled: disabled || nextSession === null,
            onClick: () => {
                dispatch({
                    type: types.requests.NAVIGATION,
                    payload: {
                        view: "view-survey-session",
                        params: {
                            sessionId:
                                nextSession !== null &&
                                typeof nextSession.sessionId === "string"
                                    ? nextSession.sessionId
                                    : sessionId,
                            surveyId,
                        },
                    },
                });
            },
            ...rest,
        })
    )
)(Button);

const ViewSession = compose(
    connect(
        ({
            navigation: {
                match: { params: { surveyId, sessionId } = {} } = {},
            } = {},
        }) => ({
            surveyId,
            sessionId,
        })
    ),
    connect(
        (
            { sessionsById, surveys, sessionsBySurveyId, responsesBySessionId },
            { sessionId, surveyId }
        ) => ({
            responsesBySessionId,
            survey: surveys[surveyId],
            sessionsById: sessionsBySurveyId[surveyId],
            session: sessionsById[sessionId],
        })
    ),
    withProps(({ sessionId, responsesBySessionId = {} }) => {
        return {
            responses: Object.values(responsesBySessionId[sessionId] || {}),
        };
    }),
    withProps(({ dispatch }) => ({
        onArchiveClick: (sessionId) => {
            dispatch({
                type: types.modals.REQUEST_CONFIRMATION,
                payload: {
                    modalProps: {
                        text: "Are you sure you would like to archive this session? It will no longer show up in your Summary view, but the data will not be deleted.",
                        header: "Confirm Session Archiving",
                        handleConfirm: () => {
                            dispatch({
                                type: types.session.ARCHIVED,
                                payload: {
                                    sessionId,
                                },
                            });
                        },
                    },
                },
            });
        },
    }))
)(
    ({
        dispatch,
        session,
        onArchiveClick,
        survey = {},
        surveyId,
        sessionId,
        responses = [],
    }) => {
        dispatch({ type: types.requests.SESSIONS, payload: { sessionId } });
        dispatch({
            type: types.requests.SURVEY,
            payload: { survey: { surveyId } },
        });
        dispatch({
            type: types.requests.RESPONSES,
            payload: { surveyId },
        });
        return (
            <UserAppLayout>
                <div className="view-session">
                    <div className="view-session-header">
                        <div className="view-session-header__title">
                            <Typography variant="h5">
                                {survey.title || "Untitled Survey"}
                            </Typography>
                        </div>
                        <Button
                            disabled={session && session.archived}
                            color="primary"
                            variant="contained"
                            className="view-survey__download-results"
                            onClick={() => onArchiveClick(sessionId)}
                        >
                            {session && session.archived
                                ? "Session Archived"
                                : "Archive Session"}
                        </Button>
                    </div>
                    <div className="view-session-header view-session-header__breadcrumbs">
                        <BreadCrumbsNavbar
                            trail={[
                                {
                                    title: "My Surveys",
                                    location: {
                                        view: "home",
                                    },
                                },
                                {
                                    title: "Survey Overview",
                                    location: {
                                        view: "view-survey",
                                        params: { surveyId },
                                    },
                                },
                                {
                                    title: "Session Details",
                                },
                            ]}
                        />
                    </div>
                    <div className="view-session-header view-session-header__session-navigation">
                        <Typography
                            variant="h6"
                            className="view-session-header__title"
                        >
                            {session && session.startTime
                                ? moment(session.startTime).format(
                                      "MMMM Do YYYY, h:mm A"
                                  )
                                : ""}
                        </Typography>
                        <div>
                            <ViewPreviousSessionButton
                                sessionId={sessionId}
                                surveyId={surveyId}
                            >
                                Previous Session
                            </ViewPreviousSessionButton>
                            <ViewNextSessionButton
                                sessionId={sessionId}
                                surveyId={surveyId}
                            >
                                Next Session
                            </ViewNextSessionButton>
                        </div>
                    </div>
                    <div className="view-session-fragments">
                        {responses.map(({ responseId }, key) => (
                            <ResponseSummary
                                key={key}
                                responseId={responseId}
                            />
                        ))}
                    </div>
                </div>
            </UserAppLayout>
        );
    }
);

export default ViewSession;
