import React from "react";
import { compose, withHandlers, withState } from "recompose";
import { Menu, IconButton } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./CardMenu.css";

const CardMenu = compose(
    withState("open", "setOpen", false),
    withHandlers({
        onOpen: ({ setOpen }) => (event) => {
            setOpen(true);
        },
        onClose: ({ setOpen }) => (event) => {
            setOpen(false);
        },
    })
)(({ children, open, onOpen, onClose, menuAnchor }) => {
    return (
        <div>
            <IconButton ref={menuAnchor} onClick={onOpen}>
                <KeyboardArrowDown />
            </IconButton>
            <Menu
                id="card-menu"
                anchorEl={menuAnchor.current}
                open={open}
                onClose={onClose}
            >
                {children}
            </Menu>
        </div>
    );
});

export default CardMenu;
