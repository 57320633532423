import React from "react";
import { TextField } from "@mui/material";
import { compose, withProps } from "recompose";
import { EditorPane } from "components";
import "./EditorFragmentInfo.css";
import connectFragmentDataToValue from "enhancers/connectFragmentDataToValue";

const InfoTitleTextField = compose(connectFragmentDataToValue("title"))(
    TextField
);
const InfoContentTextField = compose(connectFragmentDataToValue("content"))(
    TextField
);

const EditorFragmentInfo = compose(
    withProps(({ fragmentIndex }) => ({
        children: (
            <div className="editor-fragment-info">
                <div className="editor-fragment-info__title">
                    <InfoTitleTextField
                        fragmentIndex={fragmentIndex}
                        className="editor-fragment-info__title"
                        placeholder="Title"
                        fullWidth
                    />
                </div>
                <InfoContentTextField
                    fragmentIndex={fragmentIndex}
                    className="editor-fragment-info__prompt"
                    placeholder="Content (Supports Markdown)"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={15}
                />
            </div>
        ),
    }))
)(EditorPane);

export default EditorFragmentInfo;
