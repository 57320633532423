import React from "react";
import { Bar as BarChart } from "react-chartjs-2";
import moment from "moment";
import "./SurveyResultChartSummary.css";

/*
const data = {
	labels: ["January", "February", "March", "April", "May", "June", "July"],
	datasets: [
		{
			label: "My First dataset",
			fillColor: "rgba(220,220,220,0.5)",
			strokeColor: "rgba(220,220,220,0.8)",
			highlightFill: "rgba(220,220,220,0.75)",
			highlightStroke: "rgba(220,220,220,1)",
			data: [65, 59, 80, 81, 56, 55, 40]
		},
	]
};
*/

const simplePeriods = (start, unit, length) =>
  length
    ? [
        {
          getLabel: format => moment(start).format(format),
          includes: time => 
            moment(time).isBetween(start, moment(start).add(1, unit))
        },
        ...simplePeriods(moment(start).add(1, unit), unit, length - 1)
      ]
    : [];

const SurveyResultChartSummary = ({ responses, options }) => {
  const bins = simplePeriods(
    moment()
      .startOf(options.period)
      .add(-(options.length - 1), options.period),
    options.period,
    options.length
  );
  const data = bins.reduce(
    (chartDataset, bin) => {
      chartDataset.labels.push([bin.getLabel(options.labelFormat)]);
      chartDataset.datasets[0].data.push([
        responses.filter(({ createdDate }) => bin.includes(createdDate)).length
      ]);
      return chartDataset;
    },
    {
      labels: [],
      datasets: [
        {
          ...options.style,
          label: "Survey Results Summary",
          data: []
        }
      ]
    }
  );
  return (
    <div className="surveyresultchartsummary">
      <BarChart data={data} options={options} height={200} width={320} />
    </div>
  );
};

SurveyResultChartSummary.defaultProps = {
  responses: [],
  options: {
    legend: {
      display: false
    },
    period: "month",
    length: 6,
    labelFormat: "MMM",
    style: {
      fillColor: "rgba(250,200,65,0.9)",
      strokeColor: "rgba(220,220,220,0.9)",
      highlightFill: "rgba(250,200,65,0.9)",
      highlightStroke: "rgba(220,220,220,0.9)"
    }
  }
};

export default SurveyResultChartSummary;
