import { compose } from "recompose";
import { connect } from "react-redux";
import withPromise from "enhancers/withPromise";
import types from "store/types";

const withPreload = compose(
    connect(),
    withPromise("preloaded", ({ dispatch, fragmentId }) =>
        dispatch({
            type: types.requests.PRELOAD_FRAGMENT,
            payload: { fragmentId },
        })
            .then(() => true)
            .catch(() => true)
    )
);

export default withPreload;
