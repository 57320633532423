import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { LinearProgress } from "@mui/material";
import types from "store/types";
import "./DownloadProgress.css";

const DownloadProgress = compose(
    connect(
        ({ downloadsById }, { downloadId }) => downloadsById[downloadId] || {}
    ),
    withProps(({ dispatch, downloadId, downloadStatus, completed }) => {
        if (["COMPLETED", "FAILED"].includes(downloadStatus)) return {};
        if (downloadId) {
            dispatch({
                type: types.requests.BUILD_PROGRESS,
                payload: { downloadId },
            });
        }
        return {};
    })
)(({ includedFiles, expectedFiles, downloadStatus }) => (
    <div className="download-progress">
        <LinearProgress
            variant={
                ["COMPLETED", "FAILED", "PROCESSING"].includes(downloadStatus)
                    ? "determinate"
                    : "indeterminate"
            }
            value={
                downloadStatus === "PROCESSING"
                    ? includedFiles && expectedFiles
                        ? (includedFiles / expectedFiles) * 100
                        : 0
                    : downloadStatus === "FAILED" ||
                      downloadStatus === "COMPLETED"
                    ? 100
                    : undefined
            }
            color={downloadStatus === "FAILED" ? "secondary" : "primary"}
        />
    </div>
));

export default DownloadProgress;
