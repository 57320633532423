const popoverReducer = ({
    OPEN_DEVICE_INFO_POPOVER,
    CLOSE_DEVICE_INFO_POPOVER,
}) => (state = null, action = {}) => {
    switch (action.type) {
        case OPEN_DEVICE_INFO_POPOVER:{
            if (!(action.payload instanceof Object)) {
                return state;
            }
            const { open, anchorEl, userAgent } = action.payload;
            return { ...state, open, anchorEl, userAgent }
        }
        case CLOSE_DEVICE_INFO_POPOVER: {
            if (!(action.payload instanceof Object)) {
                return state;
            }
            const { open, anchorEl } = action.payload;
            return { ...state, open, anchorEl }
        }
        default:
            return state;
    }
};

export default popoverReducer;