import moment from "moment";

/**
 * Creates an array of a given length with start and stop times for 
 * a given unit of time, from a given starting time.
 * 
 * @param {Integer|Date|moment} start - A start time in unix epoch, javascript date or moment object.
 * @param {string} unit - See moment.js units.
 * @param {Integer} length - A desired length of time.
 * @returns {Object[{ getLabel, includes, start, end }]} Returns an Array of objects with a start and end javascript Date object, an includes function, and a getLabel function.
 */

const createTimeBins = (start, unit, length) =>
    length
        ? [
              {
                  getLabel: format => moment(start).format(format),
                  includes: time =>
                      moment(time).isBetween(start, moment(start).add(1, unit)),
                  start: moment(start).toDate(),
                  end: moment(start).add(1, unit).toDate(),
              },
              ...createTimeBins(moment(start).add(1, unit), unit, length - 1),
          ]
        : [];

export default createTimeBins
