import types from "store/types";
import axios from "axios";
import { memoize } from "lodash";
import debounce from "util/debounce";
import retryWithBackoff from "util/retryWithBackoff";
import { v1 as UUID } from "uuid";
// import { retrievePageRange } from "store/middlewares/pageRange";
// import tus from "tus-js-client";

const FRAGMENT_TYPES = {
    CONSENT: "CONSENT",
    AUDIO_STANDARD: "AUDIO_STANDARD",
    VIDEO_STANDARD: "VIDEO_STANDARD",
    TRANSITION_INSTRUCTOR_BOSTON_NAMING_TEST:
        "TRANSITION_INSTRUCTOR_BOSTON_NAMING_TEST",
    AUDIO_BOSTON_NAMING_TEST: "AUDIO_BOSTON_NAMING_TEST",
    TEXT_SURVEYJS: "TEXT_SURVEYJS",
    UPLOAD: "UPLOAD",
    DEANONYMIZATION: "DEANONYMIZATION",
};

// TODO: It might be better to flip this around.
const audioTypes = [
    FRAGMENT_TYPES.AUDIO_STANDARD,
    FRAGMENT_TYPES.AUDIO_BOSTON_NAMING_TEST,
];

const submitResponse = (response) => (dispatch, getState) => {
    const {
        CONFIG: { NLX__API_ENDPOINT__RESPONSE: RESPONSE_ENDPOINT },
    } = getState();
    const uploadResponse = () => axios.post(RESPONSE_ENDPOINT, response);
    return retryWithBackoff(uploadResponse, 100, 8000).then((response) => {
        return dispatch({
            type: types.session.ADDED_RESPONSE,
            payload: {
                response: response.data.result.responseId,
            },
        });
    });
};

const submitSample = (blob, responseId) => (dispatch, getState) => {
    const {
        recorder: { recordings },
        user: { userId },
        surveyTaker: { surveyId, sessionId },
        CONFIG: {
            NLX__API_ENDPOINT__SAMPLE_COLLECTOR: SAMPLE_COLLECTOR_ENDPOINT,
        },
    } = getState();
    const createdDate = new Date();
    const formData = new FormData();
    // Requires a temp file name just to get this POST to work with Collector. This file name is not used anywhere else.
    formData.append("sample", blob, "temp-file.wav");
    // TODO: I think there's a way to get this from the blob, but I'm not sure.
    const recording = recordings.find(({ blob: _blob }) => blob === _blob);
    formData.append("length", recording.duration);
    formData.append("surveyId", surveyId);
    formData.append("sessionId", sessionId);
    formData.append("responseId", responseId);
    formData.append("startTime", recording.startTime);
    formData.append("stopTime", recording.stopTime);
    formData.append("createdDate", createdDate);
    if (userId) {
        formData.append("userId", userId);
    }

    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    // const uploadSampleResumable = () => {
    //     const callbackRedirect = () => {
    //         let callback = () => {};
    //         return [
    //             newCallback => {
    //                 callback = newCallback;
    //             },
    //             (...args) => callback(...args),
    //         ];
    //     };
    //     const [setErrorCallback, errorCallbackRedirect] = callbackRedirect();
    //     const [
    //         setSuccessCallback,
    //         successCallbackRedirect,
    //     ] = callbackRedirect();

    //     const tusUploader = new tus.Upload(blob, {
    //         endpoint: SAMPLE_COLLECTOR_ENDPOINT,
    //         resume: true,
    //         metadata: {
    //             filetype: "wav",
    //             length: recording.duration,
    //             surveyId,
    //             sessionId,
    //             responseId,
    //             createdDate,
    //             userId,
    //         },
    //         retryDelays: null,
    //         onError: errorCallbackRedirect,
    //         onSuccess: successCallbackRedirect,
    //         onProgress: function(bytesUploaded, bytesTotal) {
    //             var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
    //                 2
    //             );
    //             console.log(bytesUploaded, bytesTotal, percentage + "%");
    //         },
    //     });

    //     const upload = () =>
    //         retryWithBackoff(
    //             () =>
    //                 new Promise((resolve, reject) => {
    //                     setErrorCallback(reject);
    //                     setSuccessCallback(resolve);
    //                     tusUploader.start();
    //                 }),
    //             100,
    //             8000
    //         );

    //     return upload;
    // };

    const uploadSample = () =>
        axios.post(SAMPLE_COLLECTOR_ENDPOINT, formData, config);

    return uploadSample()
        .catch(() => {
            // TODO: Think about moving this upload retry logic to the `types.error.AUDIO_SAMPLE_UPLOAD` channel instead.
            dispatch({
                type: types.error.AUDIO_SAMPLE_UPLOAD,
                payload: {
                    blob,
                    length: recording.duration,
                    surveyId,
                    sessionId,
                    responseId,
                    createdDate,
                    userId,
                },
            });
            return retryWithBackoff(uploadSample, 100, 8000);
        })
        .then((response) => {
            dispatch({
                type: types.session.ADDED_SAMPLE,
                payload: {
                    ...response.data,
                },
            });
            dispatch({
                type: types.samples.LOADED,
                payload: [response.data],
            });
            return response.data;
        });
};

const retrieveResponse = memoize((responseId) =>
    debounce(
        (dispatch, getState) => {
            const {
                user: {
                    session: { token },
                },
                CONFIG: { NLX__API_ENDPOINT__RESPONSE: RESPONSE_ENDPOINT },
            } = getState();
            return axios
                .get(RESPONSE_ENDPOINT + "/" + responseId, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    dispatch({
                        type: types.responses.LOADED,
                        payload: [response.data],
                    });
                });
        },
        60 * 1000,
        { leading: true }
    )
);

// const retrieveResponsesBySurveyId = (
//     surveyId,
//     { fromDate = new Date(), toDate = new Date("2015"), limit = 1000 } = {}
// ) => (dispatch, getState) => {
//     const state = getState();
//     const {
//         user: {
//             session: { token },
//         },
//         CONFIG: { NLX__API_ENDPOINT__OBJECT },
//         pageRange,
//     } = state;
//     const endpoint = `${NLX__API_ENDPOINT__OBJECT}/responses/s/${surveyId}`;
//     const getResponses = ({ fromDate, toDate }) =>
//         axios.get(endpoint, {
//             headers: { Authorization: `Bearer ${token}` },
//             params: {
//                 fromDate: fromDate.toISOString(),
//                 toDate: toDate.toISOString(),
//                 limit,
//             },
//         });
//     return dispatch(
//         retrievePageRange(
//             pageRange[endpoint],
//             getResponses,
//             // Assume that we retrieved all the responses in the range if the server returned less than our limit.
//             (response) =>
//                 response.data.length
//                     ? new Date(
//                           response.data[response.data.length - 1].createdDate
//                       )
//                     : toDate
//         )({ fromDate, toDate, endpoint })
//     ).then((response) => {
//         dispatch({
//             type: types.responses.LOADED,
//             payload: response.data,
//         });
//     });
// };

const retrieveResponsesByFragmentId = memoize((surveyId) =>
    debounce(
        (dispatch, getState) => {
            const {
                user: {
                    session: { token },
                },
                CONFIG: { NLX__API_ENDPOINT__RESPONSE: RESPONSE_ENDPOINT },
            } = getState();
            return axios
                .get(RESPONSE_ENDPOINT + "/fragment/" + surveyId, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    return dispatch({
                        type: types.responses.LOADED,
                        payload: response.data,
                    });
                });
        },
        60 * 1000,
        { leading: true }
    )
);

const retrieveSampleBySampleId = ({ sampleId, withFeatures = false }) => async (
    dispatch,
    getState
) => {
    const {
        user: {
            session: { token },
        },
        CONFIG: { NLX__API_ENDPOINT__SAMPLE: SAMPLE_ENDPOINT },
    } = getState();
    const { data } = axios
        .get(SAMPLE_ENDPOINT + "/" + sampleId, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                withFeatures,
            },
        })
    await dispatch({
        type: types.samples.LOADED,
        payload: [data],
    });
    return data;
};

export default ({ dispatch, getState }) => (next) => async (action) => {
    switch (action.type) {
        case types.requests.SAMPLE:
        case types.requests.SAMPLES:
            {
                if (!action.payload) return;
                const { sampleId, surveyId } = action.payload;
                if (sampleId) {
                    await dispatch(retrieveSampleBySampleId(action.payload));
                }
                if (surveyId) {
                    await dispatch({
                        type: types.requests.SESSIONS,
                        payload: { surveyId },
                    });
                }
                return next(action);
            }
            break;
        case types.requests.RESPONSES:
            {
                if (!action.payload) return next(action);
                const { surveyId, responseId, fragmentId } = action.payload;
                if (surveyId) {
                    // Retrieve the responses by surveyId from the sessions for now.
                    await dispatch({
                        type: types.requests.SESSIONS,
                        payload: { surveyId },
                    });
                    // dispatch(
                    //     retrieveResponsesBySurveyId(action.payload.surveyId)
                    // );
                }
                if (responseId) {
                    await dispatch(
                        retrieveResponse(action.payload.responseId)
                    );
                }
                if (fragmentId) {
                    await dispatch(
                        retrieveResponsesByFragmentId(action.payload.fragmentId)
                    );
                }
                return next(action);
            }
            break;
        case types.responses.SENT:
            dispatch({
                type: types.requests.PREVENT_NAVIGATION,
                payload: `UPLOADING_REPONSE_${action.payload.responseId}`,
            });
            next(action);
            break;
        case types.responses.DELIVERED:
            next(action);
            dispatch({
                type: types.requests.UNPREVENT_NAVIGATION,
                payload: `UPLOADING_REPONSE_${action.payload.responseId}`,
            });
            break;
        case types.responses.COMPLETED:
            {
                const {
                    surveyTaker: {
                        sessionId,
                        surveyId,
                        survey,
                        fragmentIndex: surveyTakerFragmentIndex,
                    } = {},
                    user: { userId } = {},
                } = getState();
                const {
                    payload: {
                        fragment: {
                            fragmentId,
                            type: fragmentType,
                            data: fragmentData,
                        } = survey.fragments[surveyTakerFragmentIndex],
                        submission: { skipped = false } = {},
                    } = {},
                } = action;
                // Build the response object.
                const responseId = UUID();
                const response = {
                    data: action.payload.submission,
                    responseId,
                    fragmentId,
                    fragmentType,
                    fragmentData,
                    sessionId,
                    surveyId,
                    userId,
                };
                next(action);
                let responseUpload = () => {
                    if (skipped) return dispatch(submitResponse(response));
                    if (audioTypes.includes(action.payload.fragment.type)) {
                        const blob = action.payload.blob;
                        // Upload the sample first, and set the sampleId on the response.
                        return dispatch(submitSample(blob, responseId)).then(
                            ({ sampleId }) => {
                                // Remove the blob from the submission.
                                response.data.sampleId = sampleId;
                                return dispatch(submitResponse(response));
                            }
                        );
                    } else {
                        return dispatch(submitResponse(response));
                    }
                };
                dispatch({
                    type: types.responses.SENT,
                    payload: { responseId },
                });
                responseUpload()
                    .then(() => {
                        dispatch({
                            type: types.responses.DELIVERED,
                            payload: { responseId },
                        });
                    })
                    .catch(() => {
                        // TODO: This seams like the wrong thing to do here.
                        dispatch({
                            type: types.responses.DELIVERED,
                            payload: { responseId },
                        });
                    });
            }
            break;
        default: {
            next(action);
            break;
        }
    }
};
