import types from "store/types";
import { history } from "services";
import routes from "routes";
import { promote, combine } from "redux-intervention";

// location, new window/same, if same, replace/push
// external link => new window
// if command/ctrl is down => new window
// TODO: Instead of only getting locations programmatically, render locations and options to the state, and use those for the links in the page.

const setLocationFromView = ({ view, params = {} }) => {
    if (typeof view !== "string") return null;
    let route = routes.find(({ view: _view }) => _view === view);
    if (!route) {
        console.warn("Route for view not found.", { view, params }, routes);
    }
    // Fill in the path params.
    const location = route.path.replace(/:(\w+)\??/g, (match, param) => {
        const replace = params[param];
        if (replace === undefined && !match.endsWith("?")) {
            console.warn(
                "Missing required route param.",
                { view, params },
                routes
            );
        }
        return replace === undefined ? "" : replace;
    });
    return {
        location,
    };
};

const setQueryString = ({ location, query }) => {
    if (!query) return null;

    const queryPairs = Object.entries(query).map(
        ([key, value]) => `${key}=${value}`
    );

    // This doesn't do hash location well...
    if (queryPairs.length) {
        if (!/\?.*$/gi.test(location)) location += "?";
        if (/\?$/gi.test(location)) {
            location = location + queryPairs.join("&");
        } else {
            location = [location, ...queryPairs].join("&");
        }
    }
    return { location };
};

const resetScroll = promote(() => () => window.scrollTo(0, 0));

const redirectRootToHomeView = promote((action) => (dispatch) => {
    // Redirect '/' to the "home" view.
    // NOTE: This would loop if "home" is at location "/".
    if (action.payload.location.pathname === "/") {
        return dispatch({
            type: types.requests.NAVIGATION,
            payload: {
                view: "home",
            },
        });
    }
});

export default combine(
    {
        [types.requests.NAVIGATION]:
            ({ dispatch }) =>
            (next) =>
            (action) => {
                next(action);
                // Use the location or build it from a view name and params object.
                let navigation;
                if (action.payload.location) {
                    navigation = action.payload.location;
                    if (action.payload.query) {
                        const queryPairs = Object.entries(
                            action.payload.query
                        ).map(([key, value]) => `${key}=${value}`);
                        if (queryPairs.length) {
                            if (!/\?.*$/gi.test(navigation)) navigation += "?";
                            if (/\?$/gi.test(navigation)) {
                                navigation = navigation + queryPairs.join("&");
                            } else {
                                navigation = [navigation, ...queryPairs].join(
                                    "&"
                                );
                            }
                        }
                    }
                } else if (typeof action.payload.view === "string") {
                    let params = action.payload.params || {};
                    let route = routes.find(
                        ({ view }) => view === action.payload.view
                    );
                    if (!route) {
                        console.warn(
                            "Route for view not found.",
                            action.payload,
                            routes
                        );
                    }
                    // Fill in the path params.
                    navigation = route.path.replace(
                        /:(\w+)\??/g,
                        (match, param) => {
                            const replace = params[param];
                            if (replace === undefined && !match.endsWith("?")) {
                                console.warn(
                                    "Missing required route param.",
                                    param
                                );
                            }
                            return replace === undefined ? "" : replace;
                        }
                    );
                } else {
                    console.warn("Unknown navigation.", action.payload);
                }

                if (/^https?:\/\//i.test(navigation)) {
                    window.location = navigation;
                } else if (action.payload.replace) {
                    history.replace(navigation);
                } else {
                    history.push(navigation);
                }
            },
    },
    {
        [types.navigation.ARRIVED]: redirectRootToHomeView,
    },
    {
        [types.navigation.ARRIVED]: resetScroll,
    }
);
