import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { ExpandMore } from '@mui/icons-material'
import './UserMenu.css'

/**
 * A menu option for the logged in user
 */
class UserMenu extends React.Component {
  // All this fuss is for the anchorEl of the menu.
  constructor(props) {
    super(props);
    this.menuAnchor = React.createRef();
  }

  state = {
    isOpen: false,
  }

  handleClick = event => {
    this.setState({ isOpen: true })
  }

  handleClose = (key) => {
    if (key) this.props.onClick(key)
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen } = this.state
    const { name, picture, children } = this.props

    return (
      <div className="user-menu__container">
        <div className="user-menu" onClick={this.handleClick}>
        <Typography variant="h6" className="user-menu__full-name">{name}</Typography>
        <div className="user-menu__avatar">
          <Avatar
            alt={name}
            src={picture}
          />
        </div>
        <button className="expand-more" ref={this.menuAnchor}>
          <ExpandMore onClick={this.handleClick} />
        </button>
        </div>
        <div className="menu-container">
          <Menu
            id="user-menu"
            anchorEl={this.menuAnchor.current}
            open={isOpen}
            onClose={this.handleClose}
            TransitionComponent={Fade}
          >
            {
              /* The inside of the menu. */
              children
            }
          </Menu>
        </div>
      </div>
    )
  }
}

UserMenu.propType = {
  name: PropTypes.string,
  picture: PropTypes.string,
  children: PropTypes.node
  /*
  givenName: PropTypes.string,
  familyName: PropTypes.string,
  picture: PropTypes.string,
  onClick: PropTypes.func,
  */
};

UserMenu.defaultProps = {
  givenName: 'Bob',
  familyName: 'Dole',
  picture: 'https://placehold.it/128x128.png',
  onClick: (key) => {},
}

export default UserMenu
