import React from "react";
import { connect } from "react-redux";
import { compose, withProps, withPropsOnChange } from "recompose";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AudioVisualization, CountdownTimer, Showdown } from "components";
import types from "store/types";
import { Typography, Snackbar, SnackbarContent } from "@mui/material";
import "./SurveyFragmentAudioStandard.css";
import AudioErrorDialog from "components/AudioErrorDialog/AudioErrorDialog";
import { isNumber } from "lodash";
import { DEFAULT } from "util/visualizations";
import withPreload from "enhancers/withPreload";

const withCanWeHearYou = connect(({ recorder: { success } }) => ({
    weCanNow: success === 2 || success === 3,
    weCant: !success || success === 1,
}));

const WeCantHearYouToast = compose(
    withCanWeHearYou,
    withProps(({ weCant }) => ({
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        open: weCant,
        children: (
            <SnackbarContent
                className="we-cant-hear-you we-cant-hear-you__muted"
                message="Is your mic muted? (We can't hear you!)"
            />
        ),
        // TODO: onClose could take you to a help or audio test page.
    }))
)(({ open, anchorOrigin, children }) => {
    return (
        <Snackbar open={open} anchorOrigin={anchorOrigin} children={children} />
    );
});

const WeCanHearYouNowToast = compose(
    withCanWeHearYou,
    withProps(({ weCanNow }) => ({
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        open: weCanNow,
        children: (
            <SnackbarContent
                className="we-cant-hear-you we-cant-hear-you__heard"
                message="We can hear you!"
            />
        ),
    }))
)(({ open, anchorOrigin, children }) => {
    return (
        <Snackbar open={open} anchorOrigin={anchorOrigin} children={children} />
    );
});

const completeAudioFragment = (fragment) => (dispatch, getState) => {
    const {
        recorder: { recordings },
    } = getState();
    const lastRecording = recordings[recordings.length - 1];
    dispatch({
        type: types.responses.COMPLETED,
        payload: {
            // surveyId, sessionId
            fragment,
            submission: {},
            blob: lastRecording.blob,
        },
    });
    return dispatch({ type: types.recorder.RESET });
};

const retryAudioFragment = (fragment) => (dispatch, getState) =>
    dispatch({ type: types.recorder.RETRIED, payload: { fragment } });

const skipAudioFragment = (fragment) => (dispatch) => {
    dispatch({
        type: types.responses.COMPLETED,
        payload: {
            fragment,
            submission: {
                skipped: true,
            },
        },
    });
    return dispatch({ type: types.recorder.RESET });
};

const displaySkipConfirmationModal = (fragment) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.modals.REQUEST_CONFIRMATION,
            payload: {
                modalType: "REQUEST_CONFIRMATION",
                modalProps: {
                    text: "Are you sure you want to skip this question?",
                    header: "Confirm Skip Question",
                    firstButtonText: "Cancel",
                    secondButtonText: "Skip",
                    handleConfirm: () => {
                        dispatch(skipAudioFragment(fragment)).then(resolve);
                    },
                    handleCancel: () => {},
                },
            },
        });
    });

const Playback = compose(
    connect(({ recorder: { recordings = [] } }) => ({
        recordings,
    })),
    withPropsOnChange(["recordings"], ({ recordings }) => ({
        src: recordings.length
            ? URL.createObjectURL(recordings[recordings.length - 1].blob)
            : "",
    }))
)(({ src }) => (
    // TODO: We should probably create our own playback button here to help with brand consistency.
    <audio controls controlsList="nodownload" type="audio/wav" src={src} />
));

const displaySubmitConfirmationModal = (fragment) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: types.modals.REQUEST_CONFIRMATION,
            payload: {
                modalProps: {
                    text: (
                        <div className="audio-confirmation-modal__text-container">
                            <div className="audio-confirmation-modal__playback">
                                <Playback />
                            </div>
                            <div className="audio-confirmation-modal__text">
                                Are you sure you would like to submit this audio
                                sample?
                            </div>
                        </div>
                    ),
                    header: "Confirm Sample Submission",
                    className: "audio-confirmation-modal",
                    firstButtonText: "Retry",
                    secondButtonText: "Submit",
                    handleConfirm: () => {
                        dispatch(completeAudioFragment(fragment)).then(resolve);
                    },
                    handleCancel: () => {
                        dispatch(retryAudioFragment(fragment)).then(reject);
                    },
                    disableBackdropClick: true,
                    disableEscapeKeyDown: true,
                },
            },
        });
    });

// TODO: We need to enforce skipping on the backend if we want
const AudioScreenSkipButton = ({ canSkip, onClick, children }) => (
    <div
        className={`survey-audio-screen__skip survey-audio-screen__skip--${
            canSkip ? "skippable" : "required"
        }`}
        onClick={canSkip ? onClick : undefined}
    >
        {children}
    </div>
);

const PreloadedFragmentMarkdown = withPreload(({ preloaded, children }) =>
    preloaded ? <Showdown>{children}</Showdown> : <Showdown># ... </Showdown>
);

const SurveyFragmentAudioStandard = compose(
    withCanWeHearYou,
    connect(({ recorder: { state, startTime, error } }) => ({
        recorderState: state,
        startTime,
        isError: error instanceof Object,
    })),
    withProps(({ fragment: { fragmentId } }) => ({ fragmentId })),
    // Rename deprecated autoStop to autoComplete.
    withProps(({ autoStop, autoComplete }) => ({
        autoComplete: autoComplete !== undefined ? autoComplete : autoStop,
    })),
    // With modal confirmation guarding onComplete.
    withProps(
        ({
            fragment = { data: {} },
            dispatch,
            confirmComplete = true,
            confirmSkip = true,
            onComplete,
        }) => ({
            onSkip: confirmSkip
                ? (...args) =>
                      dispatch(
                          displaySkipConfirmationModal(fragment)
                      ).then(() => onComplete(...args))
                : (...args) =>
                      dispatch(skipAudioFragment(fragment)).then(() =>
                          onComplete(...args)
                      ),
            onComplete: confirmComplete
                ? (...args) =>
                      dispatch(
                          displaySubmitConfirmationModal(fragment)
                      ).then(() => onComplete(...args))
                : (...args) =>
                      dispatch(completeAudioFragment(fragment)).then(() =>
                          onComplete(...args)
                      ),
        })
    ),
    withProps(({ onComplete, earlyStop = true, autoComplete = true, confirmComplete = true}) => ({
        onStopRecording:
            (autoStopped = false) =>
            (dispatch) => {
                if (autoStopped || earlyStop) {
                    dispatch({
                        type: types.recorder.STOPPED,
                        payload: { autostop: autoStopped },
                    }).then(() => {
                        if (!confirmComplete && !autoComplete) return;
                        onComplete();
                    });
                }
        },
    })),
    withProps(
        ({
            onStopRecording,
            maxLength = 60,
            countdown = 0,
            fragmentId,
            fragment,
        }) => ({
            onStartRecording: (dispatch) => {
                dispatch({ type: types.requests.RECORDER })
                    .then(() =>
                        dispatch({
                            type: types.requests.PRELOAD_FRAGMENT,
                            payload: { fragmentId, fragment },
                        })
                    )
                    .then(() =>
                dispatch({
                    type: types.recorder.SET_AUTOSTART,
                    payload: setTimeout(() => {
                        dispatch({
                            type: types.recorder.STARTED,
                        });
                            dispatch({
                                type: types.recorder.SET_AUTOSTOP,
                                payload: setTimeout(
                                        () => dispatch(onStopRecording(true)),
                                    maxLength * 1000
                                ),
                            });
                    }, countdown * 1000),
                        })
                    );
        },
        })
    ),
    // If autoStart is true, start the recorder immediately.
    withPropsOnChange(
        ["fragmentId", "weCant"],
        ({ weCant, onStartRecording, autoStart = false, dispatch }) => {
            if (weCant === false && autoStart === true) {
                dispatch(onStartRecording);
            }
            return {};
        }
    ),
    withProps(({ maxLength }) => ({
        seconds:
            (isNumber(maxLength) && maxLength) ||
            (typeof maxLength === "string" && parseInt(maxLength)) ||
            60,
    })),
    withProps(({ recorderState, autoComplete = true, confirmComplete = true }) => ({
        waitingToContinue:
            recorderState === "stopped" && !autoComplete && !confirmComplete,
        waitingToConfirm:
            recorderState === "stopped" && confirmComplete,
    }))
)(
    ({
        fragment,
        isRequired,
        visualization = DEFAULT,
        recorderState,
        startTime,
        dispatch,
        prompt,
        seconds,
        earlyStop = true,
        confirmComplete = true,
        autoComplete = true,
        autoStart = false,
        waitingToContinue,
        waitingToConfirm,
        onStartRecording,
        onStopRecording,
        onSkip,
        onComplete,
        isError,
        weCant,
    }) => (
        <div className="survey-screen survey-audio-screen">
            {isError ? <AudioErrorDialog /> : null}
            <WeCantHearYouToast />
            <WeCanHearYouNowToast />
            <div className="survey-audio-screen__container">
                <div className="survey-audio-screen__prompt">
                    <PreloadedFragmentMarkdown fragmentId={fragment.fragmentId}>
                        {prompt}
                    </PreloadedFragmentMarkdown>
                </div>
                <div className="survey-audio-screen__control">
                    <AudioScreenSkipButton
                        fragment={fragment}
                        canSkip={
                            !waitingToContinue &&
                            recorderState !== "recording" &&
                            recorderState !== "stopped" &&
                            isRequired !== true
                        }
                        onClick={onSkip}
                    >
                        <Typography variant="subtitle1">Skip</Typography>
                    </AudioScreenSkipButton>
                    <button
                        disabled={
                            weCant ||
                            (recorderState === "recording" && !earlyStop) || waitingToConfirm
                        }
                        className={`survey-audio-screen__button survey-audio-screen__button--${recorderState} ${
                            waitingToContinue
                                ? "survey-audio-screen__button--waiting"
                                : ""
                        }`}
                        onClick={() => {
                            switch (true) {
                                case weCant:
                                    return;
                                case waitingToContinue:
                                    onComplete();
                                    return;
                                case recorderState === "recording":
                                    dispatch(onStopRecording());
                                    return;
                                default:
                                    dispatch(onStartRecording);
                            }
                        }}
                    >
                        <Typography
                            className="survey-audio-screen__button-text"
                            variant="h5"
                        >
                            {(() => {
                                switch (true) {
                                    case weCant:
                                    case waitingToConfirm:
                                        return <MicOffIcon />;
                                    case waitingToContinue:
                                        return <ArrowForwardIcon />;
                                    case recorderState === "recording":
                                        return <StopIcon />;
                                    default:
                                        return <MicIcon />;
                                }
                            })()}
                        </Typography>
                    </button>
                    <Typography variant="h5">
                        {waitingToContinue ? null : (
                        <CountdownTimer
                            startTime={
                                recorderState === "recording"
                                    ? startTime
                                    : undefined
                            }
                                seconds={seconds + 0.99}
                            format="mm:ss"
                        />
                        )}
                    </Typography>
                </div>
            </div>
            <AudioVisualization {...visualization} />
        </div>
    )
);

export default SurveyFragmentAudioStandard;
