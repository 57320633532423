import React from "react";
import PropTypes from "prop-types";
import { createStore, applyMiddleware } from "redux";
import { Provider, connect } from "react-redux";
import { withProps } from "recompose";
import thunk from "redux-thunk";
import logger, { createLogger } from "redux-logger";
import { memoize } from "lodash";

import { nlxSurveyAudioReducer } from "store/reducers";
import { promiseNext } from "redux-intervention";

import actionTypes from "store/types";

const baseStore = createStore(nlxSurveyAudioReducer);

const DisplayProps = props => (
    <pre
        style={{
            border: "1px solid black",
            maxHeight: "25vh",
            overflow: "auto",
        }}
    >
        <code>{JSON.stringify(props, undefined, 2)}</code>
    </pre>
);

const DisplayStore = connect(state => state)(DisplayProps);
const DisplayActions = withProps({ actionTypes })(DisplayProps);

/**
 * metaTimestamp middlware
 */
// If the meta doesn't have a timestamp, set the timestamp to now.
const metaTimestamp = () => next => (action = {}) =>
    next(
        action.meta
            ? action.meta.timestamp
                ? action
                : { ...action, meta: { ...action.meta, timestamp: Date.now() } }
            : { ...action, meta: { timestamp: Date.now() } }
    );

const SurveyLexProvider = ({ children, middlewares }) => {
    // NOTE: The thunk middleware isn't optional in this setup.
    const store = applyMiddleware(thunk, promiseNext(...middlewares))(
        () => baseStore
    )();
    window.STORE = store;
    window.STORE.types = actionTypes;
    // Tell the store that the application has been initialized.
    store.dispatch({ type: actionTypes.application.INITIALIZED });
    return <Provider store={store}>{children}</Provider>;
};

const memoMatchConstantToType = memoize(constant => {
    for (let [key, type] of Object.entries(actionTypes)) {
        if (type === constant) {
            return [key];
        }
        if (type instanceof Object) {
            const match = matchConstantToType(constant, type);
            if (match) {
                return [key].concat(match);
            }
        }
    }
    return false;
});
const matchConstantToType = (constant, types) => {
    for (let [key, type] of Object.entries(types)) {
        if (type === constant) {
            return [key];
        }
        if (type instanceof Object) {
            const match = matchConstantToType(constant, type);
            if (match) {
                return [key].concat(match);
            }
        }
    }
    return false;
};
const memStringNumber = memoize(
    str =>
        (Array.from(str).reduce((n, c) => {
            return (c.charCodeAt(0) + n) % 53;
        }, 0) +
            1) /
        53
);

SurveyLexProvider.defaultProps = {
    children: (
        <div>
            Store
            <DisplayStore />
            Action Types
            <DisplayActions />
        </div>
    ),
    middlewares: [
        metaTimestamp,
        createLogger({
            collapsed: (getState, action) => {
                return true;
            },
            diff: true,
            diffPredicate: (getState, action) => {
                const setting = getState().CONFIG.DEBUG__LOGGER_DIFF;
                if (
                    setting !== "NO" &&
                    (setting === "YES" ||
                        setting === action.type ||
                        setting ===
                            memoMatchConstantToType(action.type, actionTypes).join(
                                "."
                            ))
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            titleFormatter: (action, time, took) => {
                if (action.type === undefined) {
                    return ["%c!!! UNDEFINED ACTION TYPE !!!", "color: red"];
                }
                const type = memoMatchConstantToType(action.type, actionTypes);
                return [
                    `%c${time.split(".")[0]} %c${
                        type ? `types.${type.join("%c.")}` : action.type
                    } %cΔ ${Math.floor(took * 100) / 100}ms`,
                    "color: #666",
                    ...(type || []).map(
                        str =>
                            `color: hsla(${memStringNumber(str) *
                                360},50%,70%, 1)`
                    ),
                    // Highlight anything over 10ms and cap out intensity at 20ms.
                    `color: hsla(${(305 + (1 - Math.min(20, took) / 20) * 190) %
                        360},90%,50%, 0.7)`,
                ];
            },
            logger: [
                "log",
                "info",
                "warn",
                "error",
                "group",
                "groupEnd",
                "groupCollapsed",
            ].reduce(
                (logger, logLevel) => ({
                    ...logger,
                    [logLevel]: (...args) => {
                        if (args.length === 1 && Array.isArray(args[0])) {
                            console[logLevel].apply(null, args[0]);
                        } else {
                            console[logLevel].apply(null, args);
                        }
                    },
                }),
                {}
            ),
        }),
    ],
};

SurveyLexProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    middlewares: PropTypes.arrayOf(PropTypes.func),
};

SurveyLexProvider.baseStore = baseStore;
SurveyLexProvider.defaultMiddlewares = {
    metaTimestamp,
    logger,
};

export default SurveyLexProvider;
