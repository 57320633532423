import React from "react";
import { connect } from "react-redux";
import types from "store/types";

// NOTE: Import these directly to avoid import loops.
import ConfirmResultDialog from "components/ConfirmResultDialog/ConfirmResultDialog";
import ConfirmPromptDialog from "components/ConfirmPromptDialog/ConfirmPromptDialog";
import ImageSelectorDialog from "components/ImageSelectorDialog/ImageSelectorDialog";
import SkipAudioDialog from "components/SkipAudioDialog/SkipAudioDialog";

const MODAL_COMPONENTS = {
    SURVEY_SUCCESS: ConfirmResultDialog,
    REQUEST_CONFIRMATION: ConfirmPromptDialog,
    SELECT_IMAGE: ImageSelectorDialog,
    SKIP_AUDIO: SkipAudioDialog,
};

export default connect(
    ({ modals: { modalType, modalProps } }) => ({
        Modal: MODAL_COMPONENTS[modalType],
        ...modalProps,
    }),
    (dispatch) => ({
        closeModal: () => dispatch({ type: types.modals.CLOSE_MODAL }),
    })
)(({ Modal, closeModal, ...props }) => {
    if (!Modal) return null;
    return <Modal closeModal={closeModal} {...props} />;
});
