const initProgress = { lengthComputable: false, loaded: 0, total: 0 };

const progressReducer = ({ INITIALIZE, PROGRESS, COMPLETE }) => (
    state = initProgress,
    action
) => {
    switch (action.type) {
        case INITIALIZE:
            return initProgress;
        case PROGRESS:
            const { lengthComputable, loaded, total } = action.payload;
            return { ...state, lengthComputable, loaded, total };
        case COMPLETE:
            return {
                loaded: state.loaded,
                lengthComputable: true,
                total: state.loaded,
            };
        default:
            return state;
    }
};

export default progressReducer;