import React from 'react'
import { connect } from "react-redux";
import {
    KeyboardArrowRight,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import types from 'store/types';
import './BreadCrumbsNavbar.css'

const BreadCrumb = connect()(({ dispatch, title, location }) => (
    <div
        className="breadcrumb"
        onClick={() => {
            if (location)
                dispatch({
                    type: types.requests.NAVIGATION,
                    payload: location,
                });
        }}
    >
        <Typography variant="overline">{title}</Typography>
    </div>
));

const BreadCrumbsNavbar = ({ trail = [] }) => (
    <div className="navbar__breadcrumbs">
        {trail
            .map((crumb, i) => <BreadCrumb {...crumb} key={i} />)
            .reduce((joined, rendered, i) => (
                <React.Fragment>
                    {joined}
                    { joined ? <div className="breadcrumb__connector">
                        <KeyboardArrowRight />
                    </div> : null }
                    {rendered}
                </React.Fragment>
            ), null)}
    </div>
);


export default BreadCrumbsNavbar
