import { connect } from "react-redux";
import types from 'store/types'

// We can add global state to all components wrapped by this HoC super easily by placing something right here.
// If we want to subscribe to changes to the global state we'd need to set that up.

// Basically, you only want to ever pass callbacks that will be directly attached to DOM and data that will be used to build the DOM.
// This way we can inject any component with actions and data that we want and aren't tied down by the component's internal logic.
// When we need more state logic, wrap that state logic in an HoC so we can change the location and nature of that state logic in the future.
// This ties us down to a component props contract instead of a logic-based contract.

const withUserSurveys = connect(
  ({ user, surveys }) => ({
    user: user,
    surveys: surveys
  }),
  undefined,
  (stateProps, { dispatch }, props) => {
    dispatch({
      type: types.requests.SURVEYS,
      // Let's retrieve all the surveys from a user.
      payload: {
        surveys: true
      }
    });
    return {
      ...props,
      ...stateProps,
      surveys: Object.values(stateProps.surveys),
      surveysById: stateProps.surveys,
    };
  }
);

export default withUserSurveys;
