import SearchBar from 'components/SearchBar/SearchBar'
import './SurveySearchBar.css'

import { connect } from 'react-redux'
import actionTypes from 'store/types'

const SurveySearchBar = connect(({surveySearch: {text}}) => ({
  search: text,
  placeholder: "Search surveys..."
}), dispatch => ({
  onSearch: search => dispatch({type: actionTypes.surveys.SEARCHED, payload: {text: search}})
}))(SearchBar)

export default SurveySearchBar
