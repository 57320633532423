import types from "store/types";
import Recorder from "lib/recorder";
import { get, once } from "lodash";
import UAParser from "ua-parser-js";
import mergeBranch from "util/mergeBranch";

const parser = new UAParser();
const userAgent = parser.getResult();

// if v1 > v2, returns 1, if v2 > v1, returns -1, if equal, returns 0
function compareVersion(v1, v2) {
    if (typeof v1 !== "string") return false;
    if (typeof v2 !== "string") return false;
    v1 = v1.split(".");
    v2 = v2.split(".");
    const k = Math.min(v1.length, v2.length);
    for (let i = 0; i < k; ++i) {
        v1[i] = parseInt(v1[i], 10);
        v2[i] = parseInt(v2[i], 10);
        if (v1[i] > v2[i]) return 1;
        if (v1[i] < v2[i]) return -1;
    }
    return v1.length === v2.length ? 0 : v1.length < v2.length ? -1 : 1;
}

// Older browsers might not implement mediaDevices at all, so we set an empty object first
if (window.navigator.mediaDevices === undefined) {
    window.navigator.mediaDevices = {};
}

// Some browsers partially implement mediaDevices. We can't just assign an object
// with getUserMedia as it would overwrite existing properties.
// Here, we will just add the getUserMedia property if it's missing.
if (window.navigator.mediaDevices.getUserMedia === undefined) {
    window.navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia =
            window.navigator.webkitGetUserMedia ||
            window.navigator.mozGetUserMedia;

        // handling various cases where this doesn't exist or isn't available
        if (!getUserMedia) {
            let errorMessage =
                "getUserMedia is not implemented in this browser";
            let name = "Error";
            const model = get(userAgent, "device.model");
            const browser = get(userAgent, "browser.name");
            const os = get(userAgent, "os.name");
            const osVersion = get(userAgent, "os.version");

            if (model === "iPhone" && browser === "WebKit") {
                errorMessage =
                    "Looks like you're using an 'in-app' browser such as Facebook Messenger. These browsers have limited functions and won't allow access to your microphone.";
                name = "iPhoneWebViewError";
            } else if (model === "iPhone" && browser === "Chrome") {
                errorMessage =
                    "Looks like you're using iPhone's version of Chrome. Unfortunately, Chrome on iPhone does not allow access to the microphone and you will be unable to take this survey in this browser.";
                name = "BrowserSpecificError";
            } else if (os === "Android") {
                // TODO: make this more specific for Android?
                errorMessage =
                    "Looks like you're using an 'in-app' browser such as Facebook Messenger. These browsers have limited functions and won't allow access to your microphone.";
                name = "BrowserSpecificError";
            } else if (osVersion) {
                // if osVersion is less than 11.0, will return 1, which we want to send an error
                if (compareVersion("11.0", osVersion) > 0) {
                    errorMessage =
                        "Looks like you're using an 'in-app' browser such as Facebook Messenger. These browsers have limited functions and won't allow access to your microphone.";
                    name = "iOSUpgradeError";
                }
            }
            const error = {
                message: errorMessage,
                name: name,
            };
            return Promise.reject(error);
        }

        // Otherwise, wrap the call to the old window.navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
        });
    };
}

// Normalize browser differences.
window.AudioContext = window.AudioContext || window.webkitAudioContext;

const checkUserMedia = once((dispatch) => {
    if (!window.navigator.mediaDevices.getUserMedia) {
        dispatch({
            type: types.recorder.FAILED,
            payload: {
                unsupported: true,
                reason: "`getUserMedia` is not implemented.",
            },
        });
    }
    if (!window.AudioContext) {
        dispatch({
            type: types.recorder.FAILED,
            payload: {
                unsupported: true,
                reason: "`AudioContext` is not implemented.",
            },
        });
    }
});

const audioHeartBeat = (dispatch, getState) => {
    const { analyser } = getState().recorder;
    if (analyser) {
        const values = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(values);
        dispatch({
            type: types.recorder.ACTIVITY,
            payload: {
                level: values.reduce((sum, v) => v + sum, 0),
            },
        });
        setTimeout(() => {
            dispatch(audioHeartBeat);
        }, 1000);
    }
};

/**
 * Records audio and keeps state.
 */
export default ({ dispatch, getState }) => {
    // Initialize the recorder with unsupported.
    return (next) => (action) => {
        checkUserMedia(dispatch);
        switch (action.type) {
            case types.requests.RECORDER: {
                const { recorder } = getState();
                // If we don't already have a recorder...
                if (!recorder.recorder) {
                    // Attempt to create an audio recorder.
                    dispatch({
                        type: types.permission.ASKED,
                        payload: "RECORDER",
                    });

                    return window.navigator.mediaDevices
                        .getUserMedia({
                            video: false,
                            audio: true,
                            // {
                            //     autoGainControl: false,
                            //     channelCount: 2,
                            //     echoCancellation: false,
                            //     noiseSuppression: false,
                            //     sampleRate: { ideal: 44100, max: 48000 },
                            //     sampleSize: 16,
                            // },
                        })
                        .then((stream) => {
                            dispatch({
                                type: types.permission.RECEIVED,
                                payload: {
                                    for: "RECORDER",
                                    permission: true,
                                },
                            });
                            const context = new window.AudioContext();
                            const analyser = context.createAnalyser();
                            const inputPoint = context.createGain();
                            const zeroGain = context.createGain();
                            zeroGain.gain.value = 0.0;
                            const audioInput = context.createMediaStreamSource(
                                stream
                            );
                            const recorder = new Recorder(inputPoint);
                            audioInput.connect(inputPoint);
                            analyser.fftSize = 2048;
                            inputPoint.connect(analyser);
                            inputPoint.connect(zeroGain);
                            zeroGain.connect(context.destination);
                            return dispatch({
                                type: types.recorder.CREATED,
                                payload: {
                                    input: inputPoint,
                                    recorder,
                                    analyser,
                                    context,
                                },
                            }).then(() => {
                                dispatch(audioHeartBeat);
                            });
                        })
                        .catch((error) => {
                            dispatch({
                                type: types.permission.RECEIVED,
                                payload: {
                                    for: "RECORDER",
                                    permission: false,
                                },
                            });
                            let payload = error;
                            if (window.location.protocol === "http:") {
                                payload = {
                                    message:
                                        "mediaDevices.getUserMedia is not accessible on non-secure (non-HTTPS) endpoints.",
                                    name: "HTTPSError",
                                };
                            }
                            dispatch({
                                type: types.recorder.FAILED,
                                payload: payload,
                                error: true,
                                meta: {
                                    action,
                                },
                            });
                            console.error(error);
                        })
                        .then(() => {
                            next(action);
                        });
                } else {
                    return next(action);
                }
                // break;
            }
            // NOTE: These states should be made available in the reducer.
            case types.recorder.STARTED:
                {
                    // NOTE: Don't clear to make this a pause.
                    const {
                        recorder: { recorder, autostop, autostart },
                    } = getState();
                    // Clear any existing autostop.
                    if (autostop !== undefined) {
                        dispatch({ type: types.recorder.CANCELED_AUTOSTOP });
                    }
                    if (autostart !== undefined) {
                        dispatch({ type: types.recorder.CANCELED_AUTOSTART });
                    }
                    // NOTE: Wait for the user's audible 'click' to stop before recording.
                    setTimeout(() => {
                        if (recorder) {
                            recorder.clear();
                            recorder.record();
                        }
                        next(
                            mergeBranch(action)({
                                payload: {
                                    startTime: new Date(),
                                },
                            })
                        );
                    }, 100);

                    /**
                     * Autostopping example...
                     *
                     *  dispatch({
                     *      type: types.recorder.SET_AUTOSTOP,
                     *      payload: setTimeout(() => {
                     *          dispatch({ type: types.recorder.STOPPED, payload: { autostop: true } })
                     *      }, 60000)
                     *  })
                     *
                     */
                }
                break;
            case types.recorder.STOPPED:
                {
                    const {
                        recorder: {
                            autostop,
                            autostart,
                            context,
                            recorder,
                            startTime,
                        },
                    } = getState();
                    // Clear any existing autostop.
                    if (autostop !== undefined) {
                        dispatch({ type: types.recorder.CANCELED_AUTOSTOP });
                    }
                    if (autostart !== undefined) {
                        dispatch({ type: types.recorder.CANCELED_AUTOSTART });
                    }
                    if (recorder) {
                        recorder.stop();
                        const stopTime = new Date();
                        recorder.getBuffers(([buffL]) => {
                            const duration = buffL.length / context.sampleRate;
                            const exporter = action.payload
                                ? action.payload.mono
                                    ? recorder.exportMonoWAV
                                    : recorder.exportWAV
                                : // Stereo by default.
                                  recorder.exportWAV;
                            exporter((blob) => {
                                dispatch({
                                    type: types.recorder.RECORDED,
                                    payload: {
                                        blob,
                                        startTime,
                                        stopTime,
                                        duration,
                                    },
                                }).then(() => {
                                    next(
                                        mergeBranch(action)({
                                            payload: { stopTime },
                                        })
                                    );
                                });
                            });
                        });
                    }
                }
                break;
            default: {
                next(action);
                break;
            }
        }
    };
};
