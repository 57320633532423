import { withProps } from "recompose";
import { Bar as Chart } from "react-chartjs-2";
import chartOptions from "util/chartJsOptions";
import "./TextResponsesBarChart.css";
import { flowRight } from "lodash";
import { intoObjectKey } from "store/reducers/helpers";
import mergeBranch from "util/mergeBranch";

const TextResponsesBarChart = withProps(({ response, responses }) => {
    const responseCountsByData = responses.reduce(
        // Count responses.
        flowRight(
            // Get response or responses from response.
            reducer => (state, { data } = {}) =>
                typeof data === "string"
                    ? reducer(state, data)
                    : Array.isArray(data)
                    ? data.reduce(reducer, state)
                    : reducer(state, data),
            intoObjectKey((state, response) => response)
        )((count = 0) => count + 1),
        // Init with 0 for all values.
        (
            response.fragmentData.rateValues || response.fragmentData.choices
        ).reduce(
            intoObjectKey((state, choice) =>
                choice instanceof Object ? choice.label : choice
            )(() => 0),
            {}
        )
    );

    const isInResponse = data =>
        Array.isArray(response.data)
            ? response.data.includes(data)
            : response.data === data;

    return {
        data: {
            datasets: [
                {
                    data: Object.values(responseCountsByData),
                    backgroundColor: Object.keys(responseCountsByData).map(
                        data => (isInResponse(data) ? "#3F71F7" : "#d5d5d5")
                    ),
                },
            ],
            labels: Object.keys(responseCountsByData),
        },
        options: mergeBranch(chartOptions)({
            plugins: {
                labels: false,
                //{
                //     render: ({ label }) =>
                //         label.length > 10
                //             ? label.slice(0, 7).trim() + " ..."
                //             : label,
                //     fontColor: Object.keys(responseCountsByData).map(data =>
                //         response.data === data ? "#fff" : "#888"
                //     ),
                //     fontStyle: "bold",
                //     textMargin: -20 // ({ value }) => value ? -20 : 2,
                // },
            },
            scales: {
                y: { ticks: { precision: 0 } }
            },
        }),
    };
})(Chart);

export default TextResponsesBarChart;
