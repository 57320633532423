import types from "store/types";
import { v1 as UUID } from "uuid";
import axios from "axios";
import { combine, promote } from "redux-intervention";
import debounce from "util/debounce";

const retrieveUser = debounce(async (dispatch, getState) => {
    await dispatch({ type: types.requests.AUTHENTICATION });
    const {
        CONFIG: { NLX__API_ENDPOINT__OBJECT: OBJECT_ENDPOINT },
        user: { profile: { email, name } = {}, session: { token } = {} } = {},
    } = getState();
    let response = await axios.get(OBJECT_ENDPOINT + "/users/" + email, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if (!response.data) {
        console.info("User not found... attempting to create a new one.");
        // Propagate the user from external services.
        const createUserResponse = await axios.post(
            OBJECT_ENDPOINT + "/users/",
            { email, name, uuid: UUID() },
            { headers: { Authorization: `Bearer ${token}` } }
        )
        return dispatch({
            type: types.user.LOADED_PROFILE,
            payload: createUserResponse.data.result,
        });
    }
    return dispatch({
        type: types.user.LOADED_PROFILE,
        payload: response.data,
    });
}, 60 * 60 * 1000);


const retrieveUserAuth0 = debounce(async (dispatch, getState) => {
    await dispatch({ type: types.requests.AUTH0LOCK });
    const {
        auth0lock,
        user: { session: { accessToken } = {} } = {},
    } = getState();
    if (!accessToken) return null;
    if (!auth0lock) return null;
    return new Promise((resolve, reject) => {
        auth0lock.getUserInfo(accessToken, (error, userInfo) => {
            if (userInfo && !error) {
                return resolve(
                    dispatch({
                        type: types.user.LOADED_PROFILE,
                        payload: userInfo,
                    })
                );
            }
            return reject(error);
        });
    });
}, 60 * 60 * 1000);

// const resolveAndReload = () => next => async action => {
//     // Wait for things to finish.
//     await next(action);
//     // We could also do a set timeout.
//     window.location.reload();
// };

export default combine({
    [types.user.AUTHENTICATED]: promote(() => dispatch => {
        dispatch(retrieveUserAuth0);
        dispatch(retrieveUser);
    }),
    [types.requests.USER_PROFILE]: ({ dispatch }) => next => action =>
        dispatch(retrieveUser).then(() => next(action)),
});
