import types from "store/types";
import showdown from "showdown";
import { sanitize } from "dompurify";
import { combine } from "redux-intervention";
import getSurveyFragmentMarkdown from "util/getSurveyFragmentMarkdown";

const preloadImage = (src) =>
    new Promise((resolve, reject) => {
        const img = document.createElement("img");
        img.addEventListener("load", () => {
            resolve(img);
        });
        img.addEventListener("error", () => {
            resolve(img);
        });
        img.setAttribute("src", src);
    });

const preloadAudio = (src) =>
    new Promise((resolve, reject) => {
        const audio = document.createElement("audio");
        audio.addEventListener("canplaythrough", () => {
            resolve(audio);
        });
        audio.addEventListener("error", () => {
            resolve(audio);
        });
        audio.preload = "auto";
        audio.currentTime = 0;
        audio.src = src;
        audio.load();
    });

const findAll = (expression) =>
    function* (text) {
        // Ensure that it's global.
        const compiledExpression = new RegExp(
            expression,
            expression.flags.replace("g", "") + "g"
        );
        let found = compiledExpression.exec(text);
        let stop = 10;
        while (found) {
            stop -= 1;
            if (stop < 1) {
                break;
            }
            yield found;
            found = compiledExpression.exec(text);
        }
    };

const preloadMarkdownMedia = (markdown) => {
    const converter = new showdown.Converter();
    const html = sanitize(converter.makeHtml(markdown));
    const anchorTagExpression =
        /<a[^>]*href="([^"]+)"[^>]*(?:\/>|>(.*?)(?=<\/a>)<\/a>)/g;
    const imageTagExpression = /<img[^>]*src="([^"]+)"[^>]*\/?>/g;
    const imageSources = Array.from(findAll(imageTagExpression)(html)).map(
        ([, src]) => src
    );
    const audioSources = Array.from(findAll(anchorTagExpression)(html))
        .map(([, href]) => href)
        .filter((href) => /\.(wav|mp3|ogg)$/i.test(href));

    return Promise.all(
        [].concat(
            imageSources.map(preloadImage),
            audioSources.map(preloadAudio)
        )
    );
};

const preloadFragmentsMiddleware = combine({
    // Hook into the SurveyTaker.
    [types.surveyTaker.SET_FRAGMENT]:
        ({ dispatch, getState }) =>
        (next) =>
        (action) => {
            next(action);

            const {
                survey: { fragments },
                fragmentIndex,
            } = getState().surveyTaker;

            fragments
                .slice(fragmentIndex, fragmentIndex + 7)
                .forEach((fragment) => {
                    setTimeout(() => {
                        dispatch({
                            type: types.requests.PRELOAD_FRAGMENT,
                            payload: {
                                fragmentId: fragment.fragmentId,
                                fragment,
                            },
                        });
                    });
                });
        },

    [types.requests.PRELOAD_FRAGMENT]:
        ({ dispatch, getState }) =>
        (next) =>
        async (action) => {
            const {
                preloadByFragmentId,
                survey: { fragments },
            } = getState().surveyTaker;

            const { fragment: _fragment } = action.payload;
            const { fragmentId } = _fragment || action.payload;

            if (_fragment === undefined && fragmentId === undefined) {
                console.warn(
                    "Attempting to preload fragment with no fragment or fragmentId."
                );
                return next(action);
            }

            if (preloadByFragmentId[fragmentId] === undefined) {
                const fragment =
                    _fragment ||
                    fragments.find((o) => o.fragmentId === fragmentId) ||
                    {};
                await dispatch({
                    type: types.surveyTaker.PRELOADED_FRAGMENT,
                    payload: {
                        fragmentId,
                        preload: preloadMarkdownMedia(
                            []
                                .concat(getSurveyFragmentMarkdown(fragment))
                                .join("\n")
                        ),
                    },
                });
            }
            await getState().surveyTaker.preloadByFragmentId[fragmentId];
            next(action);
        },
});

export default preloadFragmentsMiddleware;
