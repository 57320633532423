import React from "react";
import PropTypes from "prop-types";
import { compose, setDisplayName, setPropTypes, withProps } from "recompose";
import { connect } from "react-redux";
import { DownloadProgress } from "components";
import { Button } from "@mui/material";
import types from "store/types";
import "./DownloadBuilderAvailableArchives.css";

const Archive = compose(
    setDisplayName("Archive"),
    setPropTypes({
        downloadId: PropTypes.string,
        downloadName: PropTypes.string,
        downloadStatus: PropTypes.string,
    }),
    connect(),
    withProps(({ dispatch, downloadId, downloadName }) => ({
        onDownload: () =>
            dispatch({
                type: types.requests.DOWNLOAD_ARCHIVE,
                payload: { downloadId, downloadName },
            }),
    }))
)(
    ({
        downloadId,
        downloadStatus,
        downloadName,
        isAvailable,
        onDownload,
    }) => (
        <React.Fragment>
            <div className="archive__container">
                <Button
                    className="archive__download-button"
                    disabled={!isAvailable}
                    onClick={onDownload}
                >
                    {isAvailable
                        ? "Download"
                        : downloadStatus !== "FAILED"
                        ? `${downloadStatus}...`
                        : downloadStatus}
                </Button>
                <div className="archive__name">{downloadName}</div>
            </div>
            <DownloadProgress downloadId={downloadId} hideCompleted />
        </React.Fragment>
    )
);

const DownloadBuilderAvailableArchives = compose(
    connect(({ downloadsBySurveyId }, { surveyId }) => ({
        downloadsById: downloadsBySurveyId[surveyId],
    })),
    withProps(({ downloadsById = {} }) => ({
        // TODO: Re-order these according to a timestamp.
        downloads: Object.values(downloadsById).reverse(),
    }))
)(({ downloads }) => (
    <div className="archives-available">
        {downloads.map((download, i) => (
            <Archive key={i} {...download} />
        ))}
        {downloads.length === 0 ? (
            <div>Archives are available here once created.</div>
        ) : null}
    </div>
));

export default DownloadBuilderAvailableArchives;
