import React from "react";
// import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";
import routes from "./routes";
import middleware from "store/middlewares";
import { history } from "services";
import { DispatchArrived, Intercom } from "components";
import SurveyLexProvider from "components/SurveyLexProvider/SurveyLexProvider";
import { Router, useRoutes } from "react-router-dom";
import { CategoryScale, LinearScale, PointElement, LineElement, BarElement, Chart } from "chart.js";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(BarElement);

import "./index.css";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";

const defaultMiddleware =
    process.env.NODE_ENV === "development"
        ? SurveyLexProvider.defaultProps.middlewares
        : [SurveyLexProvider.defaultMiddlewares.metaTimestamp];

const reduxMiddleware = middleware.concat(defaultMiddleware);

const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    fontSize: "1rem",
                    lineHeight: 1.22,
                    letterSpacing: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#3F71F7",
        },
    },
});

function SurveyLexRoutes() {
    let element = useRoutes(
        routes.map(({ viewComponent: ViewComponent, path }) => ({
            path,
            element: <ViewComponent />,
        }))
    );
    return element;
}

function HistoryRouter({ basename, children, history }) {
    const [state, setState] = React.useState({
      action: history.action,
      location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
      <Router
        basename={basename}
        children={children}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  };

const SurveyLex = (
    <SurveyLexProvider middlewares={reduxMiddleware}>
        <HistoryRouter history={history}>
            <React.Fragment>
                <Intercom />
                <DispatchArrived />
                <MuiThemeProvider theme={theme}>
                    <SurveyLexRoutes />
                </MuiThemeProvider>
            </React.Fragment>
        </HistoryRouter>
    </SurveyLexProvider>
);

ReactDOM.render(SurveyLex, document.getElementById("root"));
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(SurveyLex);

unregister();
