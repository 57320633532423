import React from "react";
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import connectFragmentDataToValue from "enhancers/connectFragmentDataToValue";
import withValidation from "enhancers/withValidation";
import { AudioFragmentFlowChart, EditorPane } from "components";
import { DEFAULT, NONE } from "util/visualizations";
import "./styles.css";

const PromptField = compose(
    withProps({
        placeholder: "Audio Prompt",
        multiline: true,
    }),
    connectFragmentDataToValue("prompt")
)(TextField);

const BooleanCheckBoxField = (field, _default) =>
    compose(
        connectFragmentDataToValue(field, "onChange", v => v, _default),
        withProps(({ value, onChange }) => ({
            value: field,
            checked: value,
            onChange: () => {
                onChange(!value);
            },
        }))
    )(Checkbox);

const AutoStartCheckbox = BooleanCheckBoxField("autoStart", false);
const AutoStopCheckbox = BooleanCheckBoxField("autoStop", true);
const EarlyStopCheckbox = BooleanCheckBoxField("earlyStop", true);
const ConfirmCompleteCheckbox = BooleanCheckBoxField("confirmComplete", true);

const VisualizationCheckbox = compose(
    connectFragmentDataToValue("visualization", "onChange", v => v),
    withProps(({ value }) => ({
        value: value instanceof Object ? value : DEFAULT,
    })),
    withProps(({ value, onChange }) => ({
        value: "visualization",
        checked: value instanceof Object && value.type === DEFAULT.type,
        onChange: ({ target: { checked } }) =>
            onChange(checked ? DEFAULT : NONE),
    }))
)(Checkbox);

const MaxLengthField = compose(
    connectFragmentDataToValue("maxLength", "onChange", v => v),
    withValidation(
        value => {
            const ranged = Math.min(180, Math.max(1, value));
            return ranged === value ? true : "Between 1 and 180 seconds.";
        },
        {
            normalize: parseFloat,
            coerce: value =>
                typeof value === "string"
                    ? value.replace(/[^0-9e.]/, "")
                    : value,
        },
        60
    ),
    withProps(({ onChange, onRevert }) => ({
        onChange: ({ target: { value } = {} } = {}) => onChange(value),
        onBlur: onRevert,
    })),
    mapProps(({ onRevert, valid, errorMessage, ...props }) => props)
)(TextField);

const IsRequiredCheckbox = BooleanCheckBoxField("isRequired", false);

const EditorAudioFragmentFlowChart = compose(
    connect(
        ({ surveyCreator: { survey: { fragments } = {} } = {} }, { fragmentIndex }) => ({
            data: fragments[fragmentIndex] && fragments[fragmentIndex].data,
        })
    ),
    withProps(
        ({
            data: {
                autoStart = false,
                autoStop = true,
                earlyStop = true,
                confirmComplete = true,
            } = {},
        }) => ({ autoStart, autoStop, earlyStop, confirmComplete })
    )
)(AudioFragmentFlowChart);

const EditorFragmentAudioStandard = compose(
    withProps(({ fragmentIndex }) => ({
        advancedOptions: (
            <FormGroup className="editor-audio-standard-screen__form-group">
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={<IsRequiredCheckbox fragmentIndex={fragmentIndex} />}
                    label="Required"
                />
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={<VisualizationCheckbox fragmentIndex={fragmentIndex} />}
                    label="Show Visualization"
                />
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={
                        <AutoStartCheckbox fragmentIndex={fragmentIndex} />
                    }
                    label="Start Timed Recording Automatically"
                />
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={<AutoStopCheckbox fragmentIndex={fragmentIndex} />}
                    label="Continue Automatically After Recording"
                />
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={
                        <EarlyStopCheckbox fragmentIndex={fragmentIndex} />
                    }
                    label="Can Stop the Recording Early"
                />
                <FormControlLabel
                    className="editor-audio-standard-screen__require-label"
                    control={<ConfirmCompleteCheckbox fragmentIndex={fragmentIndex} />}
                    label="Confirm Response & Allow Restart"
                />
            </FormGroup>
        ),
        children: (
            <React.Fragment>
                <div className="editor-audio-standard-screen__container">
                    <FormGroup className="editor-audio-standard-screen__form-group">
                        <PromptField
                            className="editor-audio-standard-screen__prompt"
                            variant="outlined"
                            multiline
                            rows={3}
                            fragmentIndex={fragmentIndex}
                        />
                    </FormGroup>
                    <div className="editor-audio-standard-screen__flowchart">
                        <EditorAudioFragmentFlowChart fragmentIndex={fragmentIndex} />
                    </div>
                    <div className="editor-audio-standard-screen__recorder">
                        <button className="editor-audio-standard-screen__record-button">
                            Start
                        </button>
                        <MaxLengthField
                            label="Max Length (sec)"
                            variant="outlined"
                            fragmentIndex={fragmentIndex}
                        />
                    </div>
                </div>
            </React.Fragment>
        ),
    }))
)(EditorPane);
export default EditorFragmentAudioStandard;
