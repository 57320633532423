import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { Card, CardContent } from "@mui/material";
import "./SurveyStatsCard.css";

const twoSig = number => Math.round(number * 100) / 100;

const SurveyStatsCard = compose(
    connect(
        (
            {
                surveys,
                sessionsBySurveyId,
                responsesBySessionId,
                completedBySessionId,
            },
            { surveyId }
        ) => ({
            survey: surveys[surveyId],
            sessionsById: sessionsBySurveyId[surveyId],
            responsesBySessionId,
            completedBySessionId,
        })
    ),
    withProps(({ survey }) => ({
        surveyAge: survey ? +new Date() - new Date(survey.createdDate) : 0,
    })),
    withProps(
        ({ responsesBySessionId, sessionsById, completedBySessionId }) => {
            const sessionIds = Object.keys(sessionsById);
            return {
                totalCompletions: sessionIds.filter(
                    sessionId => completedBySessionId[sessionId]
                ).length,
                totalEngagement: sessionIds.filter(sessionId => {
                    if (!responsesBySessionId[sessionId]) return false;
                    for (let response in responsesBySessionId[sessionId]) {
                        return true;
                    }
                    return false;
                }).length,
                totalViews: sessionIds.length,
            };
        }
    ),
    withProps(({ totalCompletions, totalViews }) => ({
        rateCompletion: `${
            totalViews ? Math.floor((totalCompletions / totalViews) * 100) : 0
        }%`,
    })),
    withProps(({ totalCompletions, surveyAge }) => ({
        rateDailyCompletion: `${twoSig(
            totalCompletions / Math.ceil(surveyAge / (1000 * 60 * 60 * 24))
        )}`,
    }))
)(
    ({
        className,
        totalCompletions = 0,
        totalViews = 0,
        totalEngagement = 0,
        rateCompletion = "0%",
        rateDailyCompletion = 0,
    }) => {
        return (
            <Card className={className}>
                <CardContent>
                    <div className="card-block">
                        <div className="card-block__title">
                            {totalCompletions}
                        </div>
                        <div className="card-block__subtitle">
                            Total Completions
                        </div>
                    </div>
                    <div className="card-pairs-table">
                        {Object.entries({
                            "Total Views": totalViews,
                            "Total Engagement": totalEngagement,
                            "Completion Rate": rateCompletion,
                            "Completions/day": rateDailyCompletion,
                        }).map(([key, value]) => (
                            <div key={key} className="card-pairs-table__pair">
                                <div className="card-pairs-table__key">
                                    {key}:
                                </div>
                                <div className="card-pairs-table__value">
                                    {value}
                                </div>
                            </div>
                        ))}
                    </div>
                </CardContent>
            </Card>
        );
    }
);

SurveyStatsCard.propTypes = {
    surveyId: PropTypes.string,
};

export default SurveyStatsCard;
