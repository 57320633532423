import React from "react";
import PropTypes from "prop-types";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
    compose,
    mapProps,
    withProps,
    withStateHandlers,
    branch,
    setDisplayName,
    setPropTypes,
} from "recompose";
import {
    Accordion as MuiExpansionPanel,
    AccordionSummary as ExpansionPanelSummary,
    AccordionDetails as ExpansionPanelDetails,
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

import "./ExpansionPanel.css";

const ExpansionPanel = compose(
    withStyles({
        root: {
            borderTop: "1px solid rgba(0, 0, 0, .125)",
            boxShadow:
                "0px 2px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
            "&:not(:last-child)": {
                borderBottom: 0,
            },
            "&:before": {
                display: "none",
            },
            "&$expanded": {
                margin: 0,
            },
        },
        expanded: {},
    }),
    setPropTypes({
        title: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
            .isRequired,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
            .isRequired,
    }),
    setDisplayName("ExpansionPanel"),
    // If expanded and onChange are not provided, create an internal state.
    branch(
        ({ expanded, onChange }) =>
            expanded === undefined || onChange === undefined,
        withStateHandlers(({ expanded = false }) => ({ expanded }), {
            onChange: ({ expanded }, { onChange }) => () => {
                if (onChange instanceof Function) onChange(!expanded);
                return { expanded: !expanded };
            },
        })
    ),
    withProps(({ title, children, expanded }) => ({
        children: [
            <ExpansionPanelSummary
                className="expansion-panel-summary"
                expandIcon={<ExpandMoreIcon className="expansion-panel-icon" />}
            >
                {title}
            </ExpansionPanelSummary>,
            <ExpansionPanelDetails className="expansion-panel-details">
                {children}
            </ExpansionPanelDetails>,
        ],
    })),
    mapProps(({ title, setExpanded, ...props }) => props)
)(MuiExpansionPanel);

export default ExpansionPanel;
