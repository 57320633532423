import { combine, promote } from "redux-intervention";
import types from "store/types";
import UAParser from "ua-parser-js";

const userAgent = new UAParser().getResult();

// Set the useragent on application initialization.
export default combine({
    [types.application.INITIALIZED]: promote((action, next) => dispatch => {
        dispatch({
            type: types.application.SET_USERAGENT,
            payload: userAgent,
        });
        // Set the user agent before completing application initialization.
        next(action);
    }),
});
