import React from "react";
import { SurveyLexLogo, SurveyLexNavLink } from "components";
import { connect } from 'react-redux'
import types from 'store/types';
import "./SurveyLexWelcomeNav.css";

/**
 * A main navbar for the SurveyLex application
 */
const SurveyLexWelcomeNav = ({ dispatch }) => {
  return (
    <div className="slx-nav slx-nav__welcome">
      <SurveyLexLogo size="small" />
      <div className="expanding-gap" />
      <div className="links">
        <SurveyLexNavLink
          text="About"
          active={false}
          onClick={() =>
            dispatch({
              type: types.requests.NAVIGATION,
              payload: {
                view: 'about'
              }
            })
          }
        />
        <SurveyLexNavLink
          text="Try It Now!"
          active={true}
          onClick={() =>
            dispatch({
              type: types.requests.AUTHENTICATION,
            })
          }
        />
      </div>
    </div>
  );
};

export default connect()(SurveyLexWelcomeNav);
