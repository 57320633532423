const actionTypes = {
    browserEvent: event => `BROWSER/${event.toUpperCase().replace(/^ON/, "")}`,
    surveys: {
        CREATED: "CREATED_SURVEY",
        UPDATED: "UPDATED_SURVEY",
        DELETED: "DELETED_SURVEY",
        ARCHIVED: "ARCHIVED_SURVEY",
        PUBLISHED: "PUBLISHED_SURVEY",
        VIEWED: "VIEWED_SURVEY",
        LOADED: "LOADED_SURVEY",
        CLEARED_SEARCH: "CLEARED_SURVEYS_SEARCH",
        SEARCHED: "SEARCHED_SURVEYS",
    },
    responses: {
        SENT: "SENT_RESPONSE",
        DELIVERED: "DELIVERED_RESPONSE",
        LOADED: "LOADED_RESPONSES",
        COMPLETED: "COMPLETED_RESPONSE",
    },
    questions: {
        CREATED: "CREATED_SURVEY_QUESTION",
        UPDATED: "UPDATED_SURVEY_QUESTION",
        DELETED: "DELETED_SURVEY_QUESTION",
    },
    samples: {
        LOADED: "LOADED_SAMPLES",
        CREATED: "CREATED_SAMPLE",
        UPDATED: "UPDATED_SAMPLE",
        DELETED: "DELETED_SAMPLE",
    },
    responsePlayback: {
        LOADED: "LOADED_RESPONSE_PLAYBACK",
        PLAYED: "PLAYED_RESPONSE_PLAYBACK",
        PAUSED: "PAUSED_RESPONSE_PLAYBACK",
        STOPPED: "STOPPED_RESPONSE_PLAYBACK",
        REQUESTED_PLAYBACK: "REQUESTED_RESPONSE_PLAYBACK",
        RECEIVED_PLAYBACK: "RECEIVED_RESPONSE_PLAYBACK",
        PLAYBACK_BUFFERED: "BUFFERED_RESPONSE_PLAYBACK",
        END_OF_PLAYBACK: "END_OF_RESPONSE_PLAYBACK",
    },
    auth: {
        CREATED_LOCK: "CREATED_AUTH_LOCK",
        FLUSHED: "FLUSHED_AUTH_CREDENTIALS_FROM_STORAGE",
        SET: "SET_AUTH_CREDENTIALS_IN_STORAGE"
    },
    user: {
        AUTHENTICATED: "AUTHENTICATED_USER",
        LOADED_PROFILE: "LOADED_USER_PROFILE",
        UPDATED_PROFILE: "UPDATED_USER_PROFILE",
        EXITED: "EXITED_USER",
    },
    session: {
        LOADED: "LOADED_SESSIONS",
        RECEIVED: "RECEIVED_SESSION",
        INITIALIZED: "INITIALIZED_SESSION",
        CLEARED: "CLEARED_SESSION",
        UPDATED: "UPDATED_SESSION",
        ADDED_SAMPLE: "ADDED_SAMPLE_TO_SESSION",
        ADDED_RESPONSE: "ADDED_RESPONSE_TO_SESSION",
        ARCHIVED: "ARCHIVED_SESSION",
        SENT: "SENT",
        DELIVERED: "DELIVERED_SESSION"
    },
    error: {
        UPLOAD: "UPLOAD_FAILURE",
        RESPONSE_UPLOAD: "RESPONSE_UPLOAD_FAILURE",
        AUDIO_SAMPLE_UPLOAD: "AUDIO_SAMPLE_UPLOAD_FAILURE"
    },
    requests: {
        PRELOAD_FRAGMENT: "REQUESTED_PRELOAD_FRAGMENT",
        USER_PROFILE: "REQUESTED_USER_PROFILE",
        AUTHENTICATION: "REQUESTED_AUTHENTICATION",
        SURVEY: "REQUESTED_SURVEY",
        SURVEYS: "REQUESTED_SURVEYS",
        PLAYBACK: "REQUESTED_RESPONSE_PLAYBACK",
        NAVIGATION: "REQUESTED_NAVIGATION",
        PREVENT_NAVIGATION: "REQUESTED_PREVENT_NAVIGATION",
        UNPREVENT_NAVIGATION: "REQUESTED_UNPREVENT_NAVIGATION",
        SURVEY_CREATOR: "REQUESTED_SURVEY_CREATOR_INITIALIZATION",
        SESSION: "REQUESTED_SESSION",
        SESSIONS: "REQUESTED_SESSIONS",
        SAMPLE: "REQUESTED_SAMPLE",
        SAMPLES: "REQUESTED_SAMPLES",
        RECORDER: "REQUESTED_RECORDER",
        SURVEY_TAKER: "REQUESTED_SURVEY_TAKER",
        RESPONSES: "REQUESTED_RESPONSES",
        DOWNLOAD_ARCHIVE: "REQUESTED_ARCHIVE_DOWNLOAD",
        SURVEY_COMPLETION: "SURVEY_COMPLETION",
        FILE_SURVEY_TEXT_SUMMARY: "REQUESTED_SURVEY_TEXT_SUMMARY",
        FILE_SAMPLE: "REQUESTED_SAMPLE_FILE",
        FILE_SURVEY_RESPONSES: "REQUESTED_SURVEY_SUMMARY_FILE",
        FILE_SURVEY_MODELS: "REQUESTED_SURVEY_MODELS_FILE",
        FILE_FEATURIZATION: "REQUESTED_FEATURIZATION_FILE",
        DOWNLOADS: "REQUESTED_DOWNLOADS",
        BUILD_DOWNLOAD: "REQUESTED_DOWNLOAD_BUILD",
        BUILD_PROGRESS: "REQUESTED_DOWNLOAD_PROGRESS",
        ANALYTICS_EVENT: "REQUESTED_ANALYTICS_EVENT",
        AUTH0LOCK: "REQUESTED_AUTH0LOCK"
    },
    modals: {
        SURVEY_SUCCESS: "SURVEY_SUCCESS_MODAL",
        REQUEST_CONFIRMATION: "REQUEST_CONFIRMATION_MODAL",
        SELECT_IMAGE: "SELECT_IMAGE_MODAL",
        CLOSE_MODAL: "CLOSE_MODAL",
    },
    navigation: {
        ARRIVED: "ARRIVED",
    },
    application: {
        INITIALIZED: "INITIALIZED_APPLICATION",
        ERRORED: "APPLICATION_ERRORED_GRACEFULLY",
        SET_ENTRY_QUERY: "SET_APPLICATION_ENTRY_QUERY",
        SET_USERAGENT: "SET_APPLICATION_USERAGENT",
    },
    surveyTaker: {
        INITIALIZED: "INITIALIZED_SURVEY_TAKER",
        LOADED: "SET_SURVEY_TAKER_SURVEY",
        SET_SESSION: "SET_SURVEY_TAKER_SESSION",
        SET_FRAGMENT: "SET_SURVEY_TAKER_FRAGMENT",
        COMPLETED_FRAGMENT: "COMPLETED_SURVEY_TAKER_FRAGMENT",
        SESSION_PROGRESS: "LOADED_SESSION_PROGRESS",
        // STARTED_FRAGMENT_PRELOAD: "STARTED_FRAGMENT_PRELOAD",
        PRELOADED_FRAGMENT: "PRELOADED_FRAGMENT"
    },
    surveyCreator: {
        CLOSED: "CLOSED_SURVEY_CREATOR",
        INITIALIZED: "INITIALIZED_SURVEY_CREATOR",
        CREATED_FRAGMENT: "CREATED_SURVEY_FRAGMENT",
        UPDATED_FRAGMENT: "UPDATED_SURVEY_FRAGMENT",
        DELETED_FRAGMENT: "DELETED_SURVEY_FRAGMENT",
        MOVED_FRAGMENT: "MOVED_SURVEY_FRAGMENT",
        LOADED_SURVEY: "LOADED_SURVEY_CREATOR_SURVEY",
        UPDATED_SURVEY: "UPDATED_SURVEY_CREATOR_SURVEY",
        UNDO: "UNDO_SURVEY_CREATOR",
        REDO: "REDO_SURVEY_CREATOR",
        CLEARED_SURVEY_HISTORY: "CLEARED_SURVEY_CREATOR_SURVEY_HISTORY",
        RESET_SURVEY: "RESET_SURVEY_CREATOR_SURVE",
        SAVED: "SAVED_SURVEY_CREATOR_SURVEY",
        APPLIED_TEMPLATE: "APPLIED_SURVEY_TEMPLATE"
    },
    recorder: {
        STARTED: "STARTED_RECORDING",
        STOPPED: "STOPPED_RECORDING",
        CREATED: "CREATED_RECORDER",
        RESET: "RESET_RECORDER",
        RETRIED: "RETRIED_RECORDER",
        FAILED: "RECORDER_FAILED",
        RECORDED: "RECORDED_AUDIO_BLOB",
        SET_AUTOSTART: "SET_RECORDING_AUTOSTART",
        CANCELED_AUTOSTART: "CANCELED_RECORDING_AUTOSTART",
        SET_AUTOSTOP: "SET_RECORDING_AUTOSTOP",
        CANCELED_AUTOSTOP: "CANCELED_RECORDING_AUTOSTOP",
        ACTIVITY: "REPORTED_RECORDER_ACTIVITY",
    },
    fileUpload: {
        UPLOAD_IMAGE_TO_USER: "UPLOAD_IMAGE_TO_USER",
        DELETE_IMAGE_FROM_USER: "DELETE_IMAGE_FROM_USER",
    },
    permission: {
        ASKED: "ASKED_PERMISSION",
        RECEIVED: "RECEIVED_PERMISSION",
    },
    downloadStatus: {
        REQUESTED: "REQUESTED_DOWNLOAD_STATUS",
        PROGRESS: "RECIEVED_DOWNLOAD_STATUS_PROGRESS",
        FINALIZED: "FINALIZED_DOWNLOAD_STATUS",
    },
    downloadBuilder: {
        INITIALIZED: "INITIALIZED_DOWNLOAD_BUILDER",
        UPDATED_SELECTION: "UPDATED_DOWNLOAD_SELECTION",
        UPDATED_STAGE: "UPDATED_DOWNLOAD_BUILDER_STAGE",
        SET_NAME: "SET_DOWNLOAD_NAME",
        SET_FILES: "SET_DOWNLOAD_FILES",
        SET_OPTION: "SET_DOWNLOAD_OPTION",
        DESELECTED_FILE: "DESELECTED_DOWNLOAD_FILE",
        SELECTED_FILE: "SELECTED_DOWNLOAD_FILE",
        QUEUING: "QUEUING_DOWNLOAD",
        QUEUED: "QUEUED_DOWNLOAD"
    },
    files: {
        ADDED: "ADDED_FILE",
        UPDATED: "UPDATED_FILE",
    },
    archive: {
        INITIALIZED: "INITIALIZED_ARCHIVE",
        ADDED_FILE: "ADDED_ARCHIVE_FILE",
    },
    configuration: {
        SET: "SET_CONFIGURATION",
        UNSET: "UNSET_CONFIGURATION",
        CLEARED: "CLEARED_CONFIGURATION",
        REQUESTED: "REQUESTED_CONFIGURATION",
    },
    reports: {
        INITIALIZED: "INITIALIZED_REPORT",
        LOADED: "LOADED_REPORT",
        UPDATED: "UPDATED_REPORT",
    },
    popover: {
        OPEN_DEVICE_INFO_POPOVER: "OPEN_DEVICE_INFO_POPOVER",
        CLOSE_DEVICE_INFO_POPOVER: "CLOSE_DEVICE_INFO_POPOVER",
    },
    pageRange: {
        REQUESTED: "REQUESTED_PAGE_RANGE",
        RETRIEVED: "RETREIVED_PAGE_RANGE"
    }
};

export const fragmentTypes = {};
export default actionTypes;
