import React from "react";
import { connect } from "react-redux";
import { compose, mapProps } from "recompose";
import { ExpansionPanel } from "components";
import {
    Button,
    Card as MuiCard,
    CardContent,
    IconButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
    ArrowDownBoldCircle,
    ArrowUpBoldCircle,
    CloseCircle,
} from "mdi-material-ui";
import types from "store/types";
import "./EditorPane.css";

const RemovePaneButton = compose(
    connect(undefined, (dispatch, { fragmentIndex }) => ({
        onClick: () => {
            dispatch({
                type: types.surveyCreator.DELETED_FRAGMENT,
                payload: fragmentIndex,
            });
        },
    })),
    mapProps(({ dispatch, fragmentIndex, ...props }) => props)
)(IconButton);

const MoveForwardButton = compose(
    connect(undefined, (dispatch, { fragmentIndex }) => ({
        onClick: () => {
            dispatch({
                type: types.surveyCreator.MOVED_FRAGMENT,
                payload: {
                    id: fragmentIndex,
                    movement: -1,
                },
            });
        },
    })),
    mapProps(({ dispatch, fragmentIndex, ...props }) => props)
)(Button);

const MoveBackwardButton = compose(
    connect(undefined, (dispatch, { fragmentIndex }) => ({
        onClick: () => {
            dispatch({
                type: types.surveyCreator.MOVED_FRAGMENT,
                payload: {
                    id: fragmentIndex,
                    movement: 1,
                },
            });
        },
    })),
    mapProps(({ dispatch, fragmentIndex, ...props }) => props)
)(Button);

const EditorPane = ({
    fragmentIndex,
    header,
    buttons = null,
    children,
    isFirst,
    isLast,
    advancedOptions,
    Card = advancedOptions
        ? withStyles({
              root: {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
              },
          })(MuiCard)
        : MuiCard,
}) => (
    <React.Fragment>
        <Card className="editor-pane__screen">
            <div className="editor-pane__controls">
                <div className="editor-pane__controls-left">
                    <MoveForwardButton
                        className="editor-pane__control-button"
                        disabled={isFirst}
                        fragmentIndex={fragmentIndex}
                    >
                        <ArrowUpBoldCircle />
                        <span className="editor-pane__text">Move Up</span>
                    </MoveForwardButton>
                    <MoveBackwardButton
                        className="editor-pane__control-button"
                        disabled={isLast}
                        fragmentIndex={fragmentIndex}
                    >
                        <ArrowDownBoldCircle />
                        <span className="editor-pane__text">Move Down</span>
                    </MoveBackwardButton>
                    {buttons}
                </div>
                <div className="editor-pane__controls-right">
                    <RemovePaneButton fragmentIndex={fragmentIndex}>
                        <CloseCircle />
                    </RemovePaneButton>
                </div>
            </div>
            <div>{header}</div>
            <CardContent className="editor-pane__screen-container">
                {children}
            </CardContent>
        </Card>
        {advancedOptions ? (
            <ExpansionPanel
                className="editor-audio-standard-screen__advanced-options"
                title="Advanced Options"
            >
                {advancedOptions}
            </ExpansionPanel>
        ) : null}
    </React.Fragment>
);

export default EditorPane;
