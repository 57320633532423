import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import PlayButton from "components/PlayButton/PlayButton";
import "./SurveyResultResponse.css";

import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import types from "store/types";

// TODO: Move this compose/connect function to enhancers.
const PlaybackControlButton = compose(
    connect(
        state => {
            const {
                playback,
                state: playbackState,
                startTime,
            } = state.responsePlayback;
            return {
                activePlayback: playback.id,
                playbackState,
                startTime,
            };
        },
        dispatch => ({
            onPlay: (playbackTime, playback) => () => {
                if (playbackTime === 0) {
                    dispatch({
                        type: types.responsePlayback.REQUESTED_PLAYBACK,
                        payload: playback,
                    });
                }
                dispatch({
                    type: types.responsePlayback.PLAYED,
                    payload: { playbackTime },
                    meta: { timestamp: Date.now() },
                });
            },
            onPause: () =>
                dispatch({
                    type: types.responsePlayback.PAUSED,
                    meta: { timestamp: Date.now() },
                }),
        }),
        (stateProps, dispatchProps, parentProps) => {
            const props = { ...parentProps, ...stateProps };
            const playbackId = parentProps.playbackId;
            // If this is even the current playback.
            if (stateProps.activePlayback === playbackId) {
                switch (stateProps.playbackState) {
                    case "playing":
                    case "buffering":
                        props.onPlayPause = dispatchProps.onPause;
                        props.state = "pause";
                        break;
                    case "paused":
                    case "stopped":
                    case "complete":
                        props.onPlayPause = dispatchProps.onPlay(
                            Date.now() - stateProps.startTime
                        );
                        props.state = "play";
                        break;
                    default:
                        props.onPlayPause = dispatchProps.onPlay(0, {
                            id: playbackId,
                        });
                        props.state = "play";
                }
                // If this isn't the current playback.
            } else {
                props.onPlayPause = dispatchProps.onPlay(0, { id: playbackId });
                props.state = "play";
            }

            return props;
        }
    ),
    withHandlers({
        onClick: ({ onPlayPause }) => onPlayPause,
    })
)(PlayButton);

const SurveyResultResponse = connect(
    ({ samplesById }, { sampleId }) => samplesById[sampleId]
)(({ sampleId, createdDate, transcription, fullView }) => (
    <div className={`survey-result-response ${fullView ? "fullview" : ''}`}>
        <Typography className="survey-result-response__play-button">
            <PlaybackControlButton playbackId={sampleId} />
        </Typography>
        {/* {fullView && ( */}
            <Typography className="survey-result-response__transcription">
                {transcription}
            </Typography>
        {/* )} */}
        {/* <Typography className="survey-result-response__timestamp">
            {createdDate
                ? moment(createdDate).format("ddd MMM DD, YYYY [at] H:mm A z")
                : ""}
        </Typography> */}
    </div>
));

SurveyResultResponse.propTypes = {
    sampleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    createdDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
        PropTypes.number,
    ]),
    transcription: PropTypes.string,
    fullView: PropTypes.bool,
};

SurveyResultResponse.defaultProps = {
    responses: [],
    fullView: false,
};

export { PlaybackControlButton };
export default SurveyResultResponse;
