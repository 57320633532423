import React from "react";
// import RIntercom from "react-intercom";
import { connect } from "react-redux";

// intercom component
const Intercom = connect(
    ({ user: { userId, profile: { name, email } = {} } = {}, view }) => ({
        name,
        email,
        userId,
        view,
    })
)((props) => {
    const { name, email, userId, view } = props;

    // Don't show in the survey taker.
    if (["take-survey"].includes(view)) return null;

    // setup the user
    const intercomUser = {
        user_id: userId,
        email,
        name,
    };

    // return <RIntercom appID="ve6a6w7h" {...intercomUser} />;
    return <div></div>
});

export default Intercom;
