import surveyReducer from "./survey";
import {
    PastPresentFutureReducer,
    intoObject,
    intoObjectKey,
    actionTypesReducerReducer,
    pass,
} from "store/reducers/helpers";
import reduceReducers from "reduce-reducers";

const surveyCreatorReducer = ({
    CLOSED,
    INITIALIZED,
    UNDO,
    REDO,
    CLEARED_SURVEY_HISTORY,
    RESET_SURVEY,
    SAVED,
    ...actionTypes
}) => {
    return reduceReducers(
        intoObjectKey({
            survey: PastPresentFutureReducer({
                UNDO: UNDO,
                REDO: REDO,
                CLEAR: CLEARED_SURVEY_HISTORY,
                RESET: RESET_SURVEY,
            })(
                surveyReducer({
                    UPDATED: actionTypes.UPDATED_SURVEY,
                    LOADED: actionTypes.LOADED_SURVEY,
                    CREATED_FRAGMENT: actionTypes.CREATED_FRAGMENT,
                    UPDATED_FRAGMENT: actionTypes.UPDATED_FRAGMENT,
                    DELETED_FRAGMENT: actionTypes.DELETED_FRAGMENT,
                    MOVED_FRAGMENT: actionTypes.MOVED_FRAGMENT,
                })
            ),
        })(pass),
        actionTypesReducerReducer({
            [SAVED]: intoObject(() => "saveSurvey")(
                (state) => state.survey.present
            ),
        })(pass)
    );
};

export default surveyCreatorReducer;
