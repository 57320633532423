import React from "react";
import PropTypes from "prop-types";
import filterDOMProps from "util/filterDOMProps";
import "./styles.css";
import { Button } from "@mui/material";
import { withProps, compose, setDisplayName, setPropTypes } from "recompose";

const ClipboardLink = compose(
    setDisplayName("ClipboardLink"),
    setPropTypes({
        className: PropTypes.string,
        children: PropTypes.string,
        copyInputRef: PropTypes.shape({
            current: PropTypes.node,
        }),
    }),
    withProps(({ copyInputRef }) => ({
        copyInputRef: copyInputRef || React.createRef(),
        isMobile: (() => {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
        })()
    })),
    withProps(({ copyInputRef, isMobile }) => ({
        onCopy: () => {
            copyInputRef.current.focus();
        },
        onFocus: event => {
            event.preventDefault();
            event.target.setSelectionRange(0, event.target.value.length);
            const success = document.execCommand("copy");
            if (success) {
                const copyToAnimate = copyInputRef.current.cloneNode(true);
                event.target.parentElement.appendChild(copyToAnimate);
                copyToAnimate.classList.add("clipboard-link--success");
                setTimeout(
                    () => {
                        copyToAnimate.remove();
                    },
                    // The amount of time needed to complete the animation.
                    // NOTE: There may be a more robust way of doing this.
                    // https://developer.mozilla.org/en-US/docs/Web/API/Document/animationend_event
                    800
                );
            }
        },
        isMobileDeviceClass: () => {
            if (isMobile) return "clipboard-link__is-mobile";
            return "";
        },
    }))
)(
    ({
        copyInputRef,
        children,
        className,
        onCopy,
        onFocus,
        showCopy = true,
        showClipboard,
        success,
        isMobile,
        isMobileDeviceClass,
        ...props
    }) => (
        <div
            className={[`clipboard-link ${isMobileDeviceClass()}`, className].filter(_ => _).join(" ")}
            onClick={onCopy}
        >
            {showCopy ? (
                <Button
                    className="clipboard-link__button clipboard-link__copy"
                    aria-label="Copy to clipboard."
                    onClick={onCopy}
                    variant="text"
                    color="primary"
                >
                    {isMobile ? (
                        "Copy Link"
                    ) : (
                        "Copy"
                    )
                    }
                </Button>
            ) : null}
            <div className="clipboard-link__text-container">
                <input
                    className="clipboard-link__text"
                    ref={copyInputRef}
                    onFocus={onFocus}
                    type="text"
                    data-autoselect
                    value={children}
                    aria-label="Copy this to your clipboard."
                    readOnly
                    {...filterDOMProps(props)}
                />
            </div>
            {showClipboard ? (
                <Button
                    className="clipboard-link__button clipboard-link__clipboard"
                    value={children}
                    aria-label="Copy to clipboard."
                    tabIndex="0"
                    variant="text"
                    color="primary"
                >
                    <svg viewBox="0 0 14 16" version="1.1" aria-hidden="true">
                        <path
                            fillRule="evenodd"
                            d="M2 13h4v1H2v-1zm5-6H2v1h5V7zm2 3V8l-3 3 3 3v-2h5v-2H9zM4.5 9H2v1h2.5V9zM2 12h2.5v-1H2v1zm9 1h1v2c-.02.28-.11.52-.3.7-.19.18-.42.28-.7.3H1c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h3c0-1.11.89-2 2-2 1.11 0 2 .89 2 2h3c.55 0 1 .45 1 1v5h-1V6H1v9h10v-2zM2 5h8c0-.55-.45-1-1-1H8c-.55 0-1-.45-1-1s-.45-1-1-1-1 .45-1 1-.45 1-1 1H3c-.55 0-1 .45-1 1z"
                        />
                    </svg>
                </Button>
            ) : null}
        </div>
    )
);

export default ClipboardLink;
