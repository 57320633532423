import React from 'react'
import PropTypes from 'prop-types'

export default options => Component =>
  class Continuous extends React.Component {

    static propTypes = {
      continue: PropTypes.func,
      limiter: PropTypes.number
    }

    static defaultProps = {
      continue: () => true,
      limiter: 0,
      ...options
    }

    constructor (props) {
      super(props)
      this._isHalted = true
      this._isMounted = false
      this._last_update = Date.now()
    }

    componentDidMount () {
      this._isMounted = true
      requestAnimationFrame(this.update.bind(this))
    }

    componentDidUpdate () {
      if (this._isHalted && this.props.continue(this.props)) {
        requestAnimationFrame(this.update.bind(this))
      }
    }

    componentWillUnmount () {
      this._isMounted = false
    }

    update () {
      if (this._isMounted) {
        if (this.props.continue(this.props)) {
          this._isHalted = false
          const now = Date.now()
          if (this._last_update + this.props.limiter < now) {
            this.forceUpdate()
            this._last_update = now
          }
          setTimeout(
            () => requestAnimationFrame(this.update.bind(this)),
            this.props.limiter - (now - this._last_update)
          )
        } else {
          this._isHalted = true
          this.forceUpdate() // "Final" update.
        }
      }
    }

    render () {
      return <Component {...this.props} />
    }
  }
