import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";
import viewReducer from "./view";
import popoverReducer from "./popover";
import surveyReducer from "./survey";
import surveyCreatorReducer from "./surveyCreator";
import configurationReducer from "./configuration";
import sessionReducer from "./session";
import surveyTakerReducer from "./surveyTaker";
import requestStatus from "./requestStatus";
import progress from "./progress";
import types from "store/types";
import sessionCompletionReducer from "./sessionCompletion";
import pageRange from "./pageRange";
import navigation from "./navigation";
import { flowRight } from "lodash";
import {
    actionTypesReducerReducer,
    actionTypeFilter,
    intoObjectKey,
    intoObject,
    actionsReducerReducer,
    acceptReducerReducer,
    mergeReducerReducer,
    simpleLoadingReducer,
    simpleTwoKeyLoadingReducer,
    simpleUpdatingReducer,
    initialState,
    payload,
    pass,
} from "./helpers";

// SET => actionTypesReducerReducer({[SET]: payload()})(pass)
const setReducer = (SET) => (state = {}, action) => {
    switch (action.type) {
        case SET:
            return action.payload;
        default:
            return state;
    }
};

const surveysReducer = (actionTypes) => {
    const { LOADED, UPDATED, CREATED, ARCHIVED, DELETED } = actionTypes;
    return (state = {}, action = {}) => {
        if (action.error) {
            return state;
        }
        switch (action.type) {
            case LOADED:
                return action.payload.reduce(
                    (surveysById, survey) => {
                        surveysById[survey.surveyId] = surveyReducer(
                            actionTypes
                        )(survey, {
                            ...action,
                            payload: survey,
                        });
                        return surveysById;
                    },
                    { ...state }
                );
            case UPDATED:
            case CREATED:
                return {
                    ...state,
                    [action.payload.surveyId]: surveyReducer(actionTypes)(
                        state[action.payload.surveyId],
                        action
                    ),
                };
            case ARCHIVED:
                return {
                    ...state,
                    [action.payload]: {
                        ...state[action.payload],
                        archived: true,
                    },
                    //surveyReducer(actionTypes)(state[action.payload.surveyId], action)
                };
            case DELETED:
                const nextState = { ...state };
                delete nextState[action.payload];
                return nextState;
            default:
                return state;
        }
    };
};

// const filesReducer = ({ ADDED, UPDATED }) => (state={}, action={}) {
//     switch(action.type) {
//         case ADDED:
//              return state
//         case UPDATED:
//         default:
//             return state;
//     }
// }

const playbackReducer = ({
    PLAYED,
    PAUSED,
    STOPPED,
    REQUESTED_PLAYBACK,
    RECEIVED_PLAYBACK,
    PLAYBACK_BUFFERED,
    END_OF_PLAYBACK,
}) =>
    intoObjectKey({
        playback(state = {}, action = {}) {
            switch (action.type) {
                case REQUESTED_PLAYBACK:
                    return action.payload;
                default:
                    return state;
            }
        },
        state(state = null, action = {}) {
            switch (action.type) {
                case PLAYED:
                    return "playing";
                case PAUSED:
                    return "paused";
                case STOPPED:
                    return "stopped";
                case END_OF_PLAYBACK:
                    return "complete";
                default:
                    return state;
            }
        },
        // When did we click play last?
        startTime(state = null, action = {}) {
            switch (action.type) {
                case PLAYED:
                case RECEIVED_PLAYBACK:
                    return action.meta.timestamp;
                case PAUSED:
                case STOPPED:
                case END_OF_PLAYBACK:
                    return null;
                default:
                    return state;
            }
        },
        // What playback time did we click play from?
        origin(state = null, action = {}) {
            switch (action.type) {
                case PLAYED:
                    return action.payload.playbackTime;
                case PAUSED:
                case STOPPED:
                case END_OF_PLAYBACK:
                    return null;
                default:
                    return state;
            }
        },
        // Where is the buffer?
        buffer(state = null, action = {}) {
            switch (action.type) {
                case PLAYBACK_BUFFERED:
                    return action.payload.playbackTime;
                default:
                    return state;
            }
        },
        // We could keep a state here to calculate the time spent buffering.
    })(pass);

const userReducer = ({
    AUTHENTICATED,
    LOADED_PROFILE,
    UPDATED_PROFILE,
    EXITED,
}) =>
    flowRight(
        initialState({}),
        actionTypesReducerReducer({
            [AUTHENTICATED]: payload(pass),
            [LOADED_PROFILE]: intoObject({
                userId: (state, action) => action.payload.uuid || state.userId,
                // Simple merge if the same user.
                profile: (state, action) =>
                    !action.payload.uuid || state.userId === action.payload.uuid
                        ? { ...state.profile, ...action.payload }
                        : action.payload,
            })(pass),
            [UPDATED_PROFILE]: intoObjectKey({
                profile: payload(pass),
            })(pass),
            [EXITED]: () => ({}),
        })
    )(pass);

const auth0lockReducer = ({ CREATED }) => (state = null, action) =>
    action.type === CREATED ? action.payload : state;

const samplesByIdReducer = ({ LOADED }) => (state = [], action = {}) => {
    switch (action.type) {
        case LOADED:
            return flowRight(
                actionsReducerReducer((state, { payload = [], ...action }) =>
                    Array.isArray(payload)
                        ? payload.map((payload) => ({ ...action, payload }))
                        : [{ ...action, payload }]
                ),
                intoObjectKey(payload("sampleId")),
                mergeReducerReducer()
            )((state, action) => action.payload || state)(state, action);
        default:
            return state;
    }
};
const samplesBySurveyIdReducer = simpleTwoKeyLoadingReducer(
    "surveyId",
    "sampleId"
);
const samplesBySessionIdReducer = simpleTwoKeyLoadingReducer(
    "sessionId",
    "sampleId"
);
const samplesByResponseIdReducer = simpleTwoKeyLoadingReducer(
    "responseId",
    "sampleId"
);

const responsesByFragmentIdReducer = simpleTwoKeyLoadingReducer(
    "fragmentId",
    "responseId"
);
const responsesBySessionIdReducer = simpleTwoKeyLoadingReducer(
    "sessionId",
    "responseId"
);
const responsesBySurveyIdReducer = simpleTwoKeyLoadingReducer(
    "surveyId",
    "responseId"
);

const dummyReducer = (state = null, action = {}) => state;

const surveySearchReducer = ({ SEARCHED, CLEARED } = {}) =>
    combineReducers({
        text: (state = "", action = {}) => {
            switch (action.type) {
                case SEARCHED:
                    return action.payload.text;
                case CLEARED:
                    return "";
                default:
                    return state;
            }
        },
        terms: (state = [], action = {}) => {
            switch (action.type) {
                case SEARCHED:
                    const text = action.payload.text;
                    return text ? text.trim().split(/\s+/g) : [];
                case CLEARED:
                    return [];
                default:
                    return state;
            }
        },
    });

const modalReducer = (actionTypes) => (state = {}, action = {}) => {
    if (action.error) {
        return state;
    }
    switch (action.type) {
        case actionTypes.SURVEY_SUCCESS:
            return {
                ...state,
                modalType: "SURVEY_SUCCESS",
                modalProps: {
                    open: true,
                    ...action.payload.modalProps,
                },
            };
        case actionTypes.CLOSE_MODAL:
            return {
                ...state,
                modalType: null,
                modalProps: {},
            };
        case actionTypes.REQUEST_CONFIRMATION:
            return {
                ...state,
                modalType: "REQUEST_CONFIRMATION",
                modalProps: {
                    open: true,
                    ...action.payload.modalProps,
                },
            };
        case actionTypes.SELECT_IMAGE:
            return {
                ...state,
                modalType: "SELECT_IMAGE",
                modalProps: {
                    open: true,
                    ...action.payload.modalProps,
                },
            };
        default:
            return state;
    }
};

const refReducer = ({ ARRIVED }) => (state = null, action = {}) => {
    switch (action.type) {
        case ARRIVED:
            const refSearch =
                typeof action.payload.location.search === "string" &&
                action.payload.location.search.match(/[?&]ref=([^&]+)/i);
            return refSearch ? refSearch[1] : state;
        default:
            return state;
    }
};

const activityReducer = ({ REPORTED }) =>
    flowRight(
        actionTypesReducerReducer([REPORTED]),
        intoObjectKey({
            level: payload("level"),
            activityTimestamp: (state, { meta: { timestamp } = {} } = {}) =>
                timestamp,
            success: (state = 0, { payload: { level } }) =>
                level > 500 ? state + 1 : state,
        })
    )(pass);

const timeoutReducer = (timeoutField) => ({ SET, CLEARED }) => (
    state = {},
    action = {}
) => {
    switch (action.type) {
        case SET:
            return { ...state, [timeoutField]: action.payload };
        case CLEARED:
            clearTimeout(state[timeoutField]);
            return { ...state, [timeoutField]: undefined };
        default:
            return state;
    }
};
/**
    TODO: Implement more informative recorder states.

    - Can’t hear the microphone.
    - Heard something from the microphone.


    - Recording
    - Not Recording


    - Recorded
    - No Recording Found


    - Before Timer
    - During Timer
    - After Timer


    - Repeat
    - Initial
 */
const recorderReducer = ({
    STARTED,
    STOPPED,
    CREATED,
    FAILED,
    RECORDED,
    RESET,
    RETRIED,
}) => (state = {}, action = {}) => {
    switch (action.type) {
        case CREATED:
            return { ...state, error: undefined, ...action.payload };
        case RESET:
            return {
                ...state,
                recordings: [],
                state: "initial",
                startTime: undefined,
                stopTime: undefined,
            };
        case RETRIED:
            return {
                ...state,
                state: "ready",
                startTime: undefined,
                stopTime: undefined,
            };
        case RECORDED:
            return {
                ...state,
                recordings: (state.recordings || []).concat(action.payload),
            };
        case STARTED:
            return state.recorder
                ? {
                      ...state,
                      state: "recording",
                      startTime:
                          action.payload.startTime || action.meta.timestamp,
                  }
                : state;
        case STOPPED:
            return state.recorder
                ? {
                      ...state,
                      state: "stopped",
                      stopTime:
                          action.payload.stopTime || action.meta.timestamp,
                  }
                : state;
        case FAILED:
            if (action.error) {
                return { ...state, error: action.payload };
            }
            return action.payload && action.payload.unsupported
                ? { ...state, unsupported: true }
                : state;
        default:
            return state;
    }
};

const downloadBuilderReducer = actionTypesReducerReducer(
    // Basically filter out other actions, so we don't have anything with a surveyId initializing this.
    Object.values(types.downloadBuilder)
)(
    intoObjectKey({
        selected: flowRight(
            initialState({}),
            actionTypesReducerReducer({
                [types.downloadBuilder.UPDATED_SELECTION]: payload("selection"),
            })
        )(pass),
        downloadName: flowRight(
            initialState(""),
            actionTypesReducerReducer({
                [types.downloadBuilder.SET_NAME]: payload("downloadName"),
            })
        )(pass),
        files: flowRight(
            initialState([]),
            actionTypesReducerReducer({
                [types.downloadBuilder.SET_FILES]: payload("files"),
            })
        )(pass),
        ignore: flowRight(
            initialState({}),
            actionTypesReducerReducer({
                [types.downloadBuilder.DESELECTED_FILE]: intoObjectKey(
                    payload("filename")
                )(() => true),
                [types.downloadBuilder.SELECTED_FILE]: intoObjectKey(
                    payload("filename")
                )(() => null),
            })
        )(pass),
        build: flowRight(
            initialState({}),
            actionTypesReducerReducer({
                [types.downloadBuilder.QUEUING]: payload(),
                [types.downloadBuilder.QUEUED]: payload(),
                // [types.reports.UPDATED]: mergeReducerReducer()(payload()),
            })
        )(pass),
        options: flowRight(
            initialState({}),
            actionTypesReducerReducer({
                [types.downloadBuilder.SET_OPTION]: intoObjectKey(
                    payload("option")
                )(payload("value")),
            })
        )(pass),
    })(pass)
);

const preventingNavigationReducer = ({ SET, UNSET, CLEAR }) => (
    state = new Set(),
    action
) => {
    switch (action.type) {
        case SET:
            return new Set(state).add(action.payload);
        case UNSET:
            const _state = new Set(state);
            _state.delete(action.payload);
            return _state;
        case CLEAR:
            return new Set();
        default:
            return state;
    }
};

const acceptSessionReducer = acceptReducerReducer(
    (result) => result instanceof Object && !result.archived,
    () => null
);

export const nlxSurveyAudioReducer = reduceReducers(
    (state = {}, action = {}) => {
        switch (action.type) {
            case "HOT_STATE":
                return action.payload;
            default:
                return state;
        }
    },
    combineReducers({
        CONFIG: configurationReducer(types.configuration),
        preventingNavigation: preventingNavigationReducer({
            SET: types.requests.PREVENT_NAVIGATION,
            UNSET: types.requests.UNPREVENT_NAVIGATION,
        }),
        pageRange,
        // zips: intoObjectKey(payload("archiveId"))(
        //     archiveReducer(types.archive)
        // ),
        downloadsBySurveyId: flowRight(
            initialState({}),
            actionTypesReducerReducer([
                types.reports.INITIALIZED,
                types.reports.LOADED,
                types.reports.UPDATED,
            ]),
            actionsReducerReducer(),
            intoObjectKey(payload("surveyId")),
            intoObjectKey(payload("downloadId"))
        )(
            // Just shallow merge the payload in.
            (state, action) => ({ ...state, ...action.payload })
        ),
        downloadsById: flowRight(
            initialState({}),
            actionTypesReducerReducer([
                types.reports.INITIALIZED,
                types.reports.LOADED,
                types.reports.UPDATED,
            ]),
            actionsReducerReducer(),
            intoObjectKey(payload("downloadId"))
        )(
            // Just shallow merge the payload in.
            (state, action) => ({ ...state, ...action.payload })
        ),
        responsePlayback: reduceReducers(
            playbackReducer(types.responsePlayback),
            intoObject({
                source(state, action = {}) {
                    switch (action.type) {
                        case types.responsePlayback.REQUESTED_PLAYBACK:
                            return null;
                        case types.responsePlayback.LOADED:
                            return state.playback.id ===
                                action.payload.playbackId
                                ? action.payload.source
                                : null;
                        default:
                            return state.source;
                    }
                },
            })(pass)
        ),
        surveyCreator: initialState({})(pass),
        surveyBySurveyId: initialState({})(pass),
        surveys: surveysReducer(types.surveys),
        responses: dummyReducer,
        responsesByFragmentId: responsesByFragmentIdReducer(types.responses),
        responsesBySurveyId: responsesBySurveyIdReducer(types.responses),
        responsesBySessionId: responsesBySessionIdReducer(types.responses),
        responseBySessionIdByFragmentId: flowRight(
            initialState({}),
            actionTypesReducerReducer(Object.values(types.responses)),
            actionsReducerReducer(),
            intoObjectKey(payload("sessionId")),
            intoObjectKey(payload("fragmentId"))
        )(payload()),
        responsesById: simpleLoadingReducer("responseId")(types.responses),
        sessionsBySurveyId: flowRight(
            actionsReducerReducer(),
            intoObjectKey(payload("surveyId")),
            intoObjectKey(payload("sessionId")),
            acceptSessionReducer
        )(sessionReducer(types.session)),
        sessionsById: flowRight(
            actionsReducerReducer(),
            intoObjectKey(payload("sessionId")),
            acceptSessionReducer
        )(sessionReducer(types.session)),
        filesBySampleId: reduceReducers(
            simpleLoadingReducer("sampleId")({
                LOADED: types.files.ADDED,
            }),
            simpleUpdatingReducer("sampleId")(types.files)
        ),
        filesByFilename: reduceReducers(
            simpleLoadingReducer("filename")({
                LOADED: types.files.ADDED,
            }),
            simpleUpdatingReducer("filename")(types.files)
        ),
        user: userReducer(types.user),
        session: sessionReducer({
            ...types.session,
            LOADED: undefined,
            ARCHIVED: undefined,
        }),
        samplesById: samplesByIdReducer(types.samples),
        samplesBySurveyId: samplesBySurveyIdReducer(types.samples),
        samplesBySessionId: samplesBySessionIdReducer(types.samples),
        samplesByResponseId: samplesByResponseIdReducer(types.samples),
        surveySearch: surveySearchReducer({
            SEARCHED: types.surveys.SEARCHED,
            CLEARED: types.surveys.CLEARED_SEARCH,
        }),
        downloadBuilderBySurveyId: intoObjectKey(payload("surveyId"))(
            downloadBuilderReducer
        ),
        surveyTaker: surveyTakerReducer({
            ...types.surveyTaker,
            SENT_RESPONSE: types.responses.SENT,
            DELIVERED_RESPONSE: types.responses.DELIVERED,
        }),
        modals: modalReducer(types.modals),
        view: viewReducer({
            ARRIVED: types.navigation.ARRIVED,
        }),
        ref: refReducer({
            ARRIVED: types.navigation.ARRIVED,
        }),
        navigation: navigation({ ARRIVED: types.navigation.ARRIVED }),
        history: combineReducers({
            surveyCreator: surveyCreatorReducer(types.surveyCreator),
        }),
        recorder: reduceReducers(
            recorderReducer(types.recorder),
            timeoutReducer("autostart")({
                SET: types.recorder.SET_AUTOSTART,
                CLEARED: types.recorder.CANCELED_AUTOSTART,
            }),
            timeoutReducer("autostop")({
                SET: types.recorder.SET_AUTOSTOP,
                CLEARED: types.recorder.CANCELED_AUTOSTOP,
            }),
            activityReducer({ REPORTED: types.recorder.ACTIVITY })
        ),
        downloadRequestsByDownloadId: intoObjectKey(payload("downloadId"))(
            combineReducers({
                status: requestStatus({
                    REQUESTED: types.downloadStatus.REQUESTED,
                    ACKNOWLEDGED: types.downloadStatus.PROGRESS,
                    FULFILLED: types.downloadStatus.FINALIZED,
                    FAILED: types.downloadStatus.FINALIZED,
                }),
                progress: progress({
                    INITIALIZE: types.downloadStatus.REQUESTED,
                    PROGRESS: types.downloadStatus.PROGRESS,
                    COMPLETE: types.downloadStatus.FINALIZED,
                }),
            })
        ),
        popover: popoverReducer({
            OPEN_DEVICE_INFO_POPOVER: types.popover.OPEN_DEVICE_INFO_POPOVER,
            CLOSE_DEVICE_INFO_POPOVER: types.popover.CLOSE_DEVICE_INFO_POPOVER,
        }),
        auth0lock: auth0lockReducer({ CREATED: types.auth.CREATED_LOCK }),
        // (state, action = {}) => {
        //    return { surveyCreator: surveyCreatorReducer(types.surveyCreator)(state, action) }
        // }
        entryQuery: setReducer(types.application.SET_ENTRY_QUERY),
        userAgent: setReducer(types.application.SET_USERAGENT),
        // TODO: We're creating some unnecessary work here. We might want to use intoObjectKey with an object instead of using combineReducers. It does have the nice side effect of requiring the entire base level of the state to be in one place. Something to think about.
        completedBySessionId: initialState([])(pass),
    }),
    // NOTE: These reducers are basically just selectors.
    (state, action) => {
        return flowRight(
            intoObjectKey({
                // TODO: Deprecate `state.surveys` and replace it with this.
                surveyBySurveyId: () => state.surveys,
                surveyCreator: flowRight(
                    initialState({}),
                    intoObjectKey({
                        saveSurvey: () =>
                            state.history.surveyCreator.saveSurvey,
                        // Set surveyCreator to always be the 'present' value of the surveyCreator history.
                        survey: () =>
                            state.history.surveyCreator.survey.present,
                    })
                )(pass),
                completedBySessionId: flowRight(
                    initialState({}),
                    actionTypeFilter(
                        Object.values({
                            ...types.session,
                            ...types.surveys,
                            ...types.responses,
                        })
                    ),
                    actionsReducerReducer(),
                    intoObjectKey(payload("sessionId"))
                )(sessionCompletionReducer(state)),
            })
        )(pass)(state, action);
    }
);
