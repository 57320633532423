import React from 'react'
import PropTypes from "prop-types";
import './ModelSummary.css'

// const modelToImage = model => ({}[model] || "blank");
const modelToName = model =>
    ({
        accent: "Accent",
        age: "Age",
        audioquality: "Audio Quality",
        fatigue: "Fatigue",
        gender: "Gender",
        sentiment: "Sentiment",
        stress: "Stress",
    }[model] || model);
const modelToTranslatedValue = model => {
    const capitalize = word => {
        const firstLetter = word.slice(0, 1);
        return firstLetter.toUpperCase() + word.slice(1);
    };
    const translateValue = {
        accent: {
            us: "English (US)",
            england: "English (UK)",
            indian: "English (Indian)",
            australia: "English (Australian)",
            canada: "English (Canadian)",
        },
        audioquality: quality =>
            `${capitalize(quality.replace(/quality/i, ""))} Quality`,
        age: capitalize,
        fatigue: capitalize,
        gender: capitalize,
        sentiment: capitalize,
        stress: capitalize,
    }[model];
    return value =>
        translateValue instanceof Function
            ? translateValue(value)
            : translateValue instanceof Object
            ? translateValue[value]
            : typeof translateValue === "string"
            ? translateValue
            : "";
};

const ModelSummary = ({ model, value }) => (
    <React.Fragment>
        {/* <div>{modelToImage(model)}</div> */}
        <div className="model-summary__name">{modelToName(model)}</div>
        <div className="model-summary__value">
            {modelToTranslatedValue(model)(value)}
        </div>
        {/* <div>{value}</div> */}
    </React.Fragment>
);

ModelSummary.propTypes = {
    model: PropTypes.string,
    value: PropTypes.string,
};

export default ModelSummary
