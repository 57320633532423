import React from "react";
import { compose, mapProps } from "recompose";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import types from "store/types";
import "./EditorTemplateList.css";

const AddTemplateButton = compose(
    connect(({ surveyCreator: { survey = {} } }) => ({ survey })),
    mapProps(
        ({
            dispatch,
            fragmentIndex,
            title,
            surveyId,
            surveyUrl,
            survey,
            description,
            onClick,
        }) => ({
            className: "add-template-button",
            children: title,
            onClick: (event) => {
                if (onClick instanceof Function) onClick(event);
                // TODO: Apply the template at a position and replace a template component.
                const fragmentId = survey.fragments[fragmentIndex].fragmentId;
                dispatch({
                    type: types.surveyCreator.APPLIED_TEMPLATE,
                    payload: {
                        surveyId,
                        surveyUrl,
                    },
                }).then(() => {
                    const fragmentIndex = survey.fragments.findIndex(
                        ({ fragmentId: id }) => fragmentId === id
                    );
                    dispatch({
                        type: types.surveyCreator.DELETED_FRAGMENT,
                        payload: fragmentIndex,
                    });
                });
            },
        })
    )
)(Button);

const defaultTemplateList = [
    {
        title: "Clinical research",
        description:
            "For clinical researchers looking to add voice to their IRBs to build vocal biomarkers.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/fcd511e0-5569-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Customer reviews",
        description:
            "For customer service managers looking for new ways to measure the way customers feel.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/79fa6840-5c00-11e9-9370-8382f4dce8b4",
    },
    {
        title: "Focus groups",
        description:
            "For market researchers looking to reach thousands of focus group participants.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/f9e7dd00-5569-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Job interviews",
        description:
            "For recruiters looking to place quality talent in days as opposed to months.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/071fa700-556a-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Political surveys",
        description:
            "For political analysts looking to collect feedback from voters on a range of issues.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/f5f482c0-5569-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Speech research",
        description:
            "For speech scientists looking to collect massive labeled datasets.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/fcd511e0-5569-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Birthday messages",
        description:
            "For friends and relatives looking to curate and assemble messages around birthdays.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/0058ba60-556a-11e9-980f-7fb5d2fe95b4",
    },
    // {
    //     title: "Course reviews",
    //     description:
    //         "For faculty members looking to collect actionable feedback from students.",
    //     surveyUrl:
    //         "https://api.neurolex.ai/1.0/object/surveys/taker/79fa6840-5c00-11e9-9370-8382f4dce8b4",
    // },
    {
        title: "Event experiences",
        description:
            "For event planners looking for new and engaging event experiences.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/0058ba60-556a-11e9-980f-7fb5d2fe95b4",
    },
    {
        title: "Startup screening",
        description:
            "For investors looking to see if companies fit within their investment thesis.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/55775660-622f-11e9-b823-e1e5a4e35fdc",
    },
    {
        title: "Journalism",
        description:
            "For journalists looking for innovative ways to engage with their readers.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/3d3d7c30-6231-11e9-b213-bff79649d2ca",
    },
    {
        title: "Website forms",
        description:
            "For web developers looking to create new contact form experiences.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/57899ac0-13f2-11e9-80bc-05bec494a183",
    },
    {
        title: "Music testing",
        description:
            "For radio stations looking to test and release new music tracks.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/5f108ef0-5d23-11e9-8d7e-bb5f7e5229ff",
    },
    {
        title: "Sports experiences",
        description:
            "For professional sports franchises looking for new fan engagement experiences.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/10c2b240-6233-11e9-9dc5-ab60e77f206c",
    },
    {
        title: "Gratitude journaling",
        description:
            "For individuals looking to keep spoken gratitude journals to be more cheerful.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/bee40100-5a39-11e9-8a4f-ff9e7549e3c7",
    },
    {
        title: "Customer discovery",
        description:
            "For startups and product teams looking to document customer discovery interviews.",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/844c3ab0-6553-11e9-8b68-3fbfc50764f8",
    },
    {
        title: "Voiceome",
        description: "",
        surveyUrl:
            "https://api.neurolex.ai/1.0/object/surveys/taker/c741cba0-acca-11e9-aeb9-2b1c6d8db2a2",
    },
];
const EditorTemplateList = compose(
    connect(({ templateList }) => ({
        templateList: templateList || defaultTemplateList,
    }))
)(({ templateList, fragmentIndex }) => (
    <div className="editor-template-list">
        {templateList.map(({ title, description, surveyId, surveyUrl }) => (
            <React.Fragment key={title}>
                <AddTemplateButton
                    className="editor-template-list-button"
                    title={title}
                    description={description}
                    fragmentIndex={fragmentIndex}
                    surveyId={surveyId}
                    surveyUrl={surveyUrl}
                />
            </React.Fragment>
        ))}
    </div>
));

export default EditorTemplateList;
