import {
    type,
    initialState,
    payload,
    compose,
    pass,
} from "redux-composable-reducers";
export default ({ ARRIVED }) =>
    initialState({
        location: {},
        match: { params: {}, pattern: {} },
        params: {},
        route: {},
    })(
        type({
            [ARRIVED]: payload()
        })
    );
