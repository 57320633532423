import { combine, promote } from "redux-intervention";
import types from "store/types";
import { once } from "lodash";

const queryStringToJSON = (string) => {
    if (typeof string !== "string") return {};
    if (string === "") return {};
    const result = {};
    // slice(1) takes off '?' from beginning
    const pairs = string.slice(1).split("&");

    pairs.forEach((pair) => {
        pair = pair.split("=");
        result[pair[0]] = decodeURIComponent(pair[1] || "");
    });

    return result;
};

// The first time we land on the page after load, set that as the querystring.
export default combine({
    [types.navigation.ARRIVED]: promote((action, next) =>
        once((dispatch, getState) => {
            dispatch({
                type: types.application.SET_ENTRY_QUERY,
                payload: action.payload.location.search
                    ? queryStringToJSON(action.payload.location.search)
                    : {},
            });
            next(action);
        })
    ),
});
