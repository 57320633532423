import { withProps } from "recompose";
import { Pie as PieChart } from "react-chartjs-2";
import chartOptions from "util/chartJsOptions";
import './TextResponsesPieChart.css'

const TextResponsesPieChart = withProps(({ response, responses }) => {
    const responseCountsByData = responses.reduce(
        (counts, { data } = {}) =>
            data
                ? {
                      ...counts,
                      [data]: counts[data] ? counts[data] + 1 : 1,
                  }
                : counts,
        {}
    );
    return {
        data: {
            datasets: [
                {
                    data: Object.values(responseCountsByData),
                    backgroundColor: Object.keys(responseCountsByData).map(
                        data => (response.data === data ? "#3F71F7" : "#d5d5d5")
                    ),
                },
            ],
            labels: Object.keys(responseCountsByData),
        },
        options: {
            ...chartOptions,
            plugins: {
                labels: {
                    render: ({ label }) =>
                        label.length > 10
                            ? label.slice(0, 7).trim() + " ..."
                            : label,
                    fontColor: Object.keys(responseCountsByData).map(data =>
                        response.data === data ? "#fff" : "#888"
                    ),
                    fontStyle: "bold",
                },
            },
            scales: {},
        },
    };
})(PieChart);


export default TextResponsesPieChart
