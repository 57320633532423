import React from "react";
import { ConsentForm } from "components";
import "./SurveyFragmentConsent.css";
import { Showdown } from "components";
import showdownOptions from "util/showdownOptions";

import { connect } from "react-redux";
import { compose, withProps, withState } from "recompose";
import types from "store/types";

const withChecked = withState(
    "checked",
    "setChecked",
    ({ optInOut }) => optInOut === "out"
);
const ConsentFormWithChecked = withChecked(ConsentForm);

const SurveyFragmentConsent = compose(
    connect(),
    withProps(({ dispatch, onComplete, fragment }) => ({
        onComplete: (...args) => {
            dispatch({
                type: types.responses.COMPLETED,
                payload: {
                    fragment,
                    submission: {
                        consent: true,
                    },
                },
            });
            onComplete(...args);
        },
    }))
)(({ title, prompt, consentText, optInOut, onComplete }) => {
    return (
        <div className="survey-screen survey-consent-screen">
            <div className="survey-consent-screen__container">
                <div className="survey-consent-screen__header">{<Showdown options={showdownOptions}>{title}</Showdown>}</div>
                <ConsentFormWithChecked
                    optInOut={optInOut || "in"}
                    rejectText={"Back"}
                    confirmText={"Continue"}
                    confirm={onComplete}
                    prompt={<Showdown options={showdownOptions}>{prompt}</Showdown>}
                >
                    <div className="survey-consent-screen__description">
                        <Showdown options={showdownOptions}>{consentText}</Showdown>
                    </div>
                </ConsentFormWithChecked>
            </div>
        </div>
    );
});

export default SurveyFragmentConsent;
