import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withMobileDialog from "enhancers/withMobileDialog";

class ConfirmResultDialog extends React.Component {

  handleClose = () => {
  	if (this.props.closeModal) this.props.closeModal();
  };

  render() {
    const { header, text, ...rest } = this.props;

    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          {...rest}
        >
          <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmResultDialog.propTypes = {
  header: PropTypes.node,
  text: PropTypes.node,
  closeModal: PropTypes.func,
  ...Dialog.propTypes
};

export default withMobileDialog()(ConfirmResultDialog);
