// import routes from "routes";
// TODO: There's probably a better way to do this, but we don't want to import all of the components in the reducers, generally.
const routes = [
    {
        path: "/",
    },
    {
        path: "/home",
        view: "home",
    },
    {
        path: "/about",
        view: "about",
    },
    {
        path: "/profile",
        view: "profile",
    },
    {
        path: "/create",
        view: "create-survey",
    },
    {
        path: "/new-survey",
        view: "create-survey",
    },
    {
        path: "/copy/:surveyId",
        view: "duplicate-survey",
    },
    {
        path: "/edit/:surveyId",
        view: "edit-survey",
    },
    {
        path: "/view/:surveyId",
        view: "view-survey",
    },
    {
        path: "/view/:surveyId/response/:sessionId",
        view: "view-survey-session",
    },
    {
        path: "/surveys/:surveyId/:fragmentId?",
        view: "take-survey",
        // NOTE: 'public' is a resevered word.
        isPublic: true,
    },
    {
        path: "/callback",
        view: "callback",
        isPublic: true,
    },
    {
        path: "/login",
        view: "login",
        isPublic: true,
    },
    {
        path: "/register",
        view: "register",
        isPublic: true,
    },
]
    .map(
        // Precompile a "match" regular expression to check to matches.
        ({ path, ...rest }) => ({
            path,
            match: new RegExp(
                `^${path.replace(
                    /\/:(?:\w+)\??/g,
                    (match, param) =>
                        // NOTE: And explicit set of accepted pathname characters could be good. (i.e. /[\w\d%_-]+/) But, I think this is better for now.
                        `(/[^/]*)${match.endsWith("?") ? "?" : ""}`
                )}$`
            ),
            ...rest,
        })
    )
    // Make sure all the routes are accessible by sorting them in reverse path order.
    .sort(
        ({ path: a }, { path: b }) =>
            a.split(":")[0] < b.split(":")[0] ? 1 : -1
    );


const routesWithMatch = routes.map(({ path, ...rest }) => ({
  path,
  match: new RegExp(`^${path.replace(/:\w+/g, "\\w+").replace(/:\w+\??/g, "\\w*")}$`),
  ...rest
}));

const viewReducer = ({ ARRIVED }) => (state = null, action = {}) => {
  switch (action.type) {
    case ARRIVED:
      const pathname = action.payload.location.pathname;
      const route = routesWithMatch.find(({ match }) => match.test(pathname));
      // Return null if there are no matching routes or the route doesn't have a view associated with it.
      return route ? route.view ? route.view : null : null;
    default:
      return state;
  }
};

export default viewReducer;
