import PropTypes from "prop-types";
import { compose, withStateHandlers, setPropTypes, mapProps } from "recompose";

/**
 * @function withValidation.cleanup
 * 
 * @returns function - Higher-Order Component
 * 
 * @description
 * Removes all `props` that `withValidation` provides except `value` and `onChange`.
 */
export const cleanup = mapProps(
    ({ onRevert, errorMessage, valid, ...props }) => props
);

/**
 * @function withValidation
 *
 * @argument function validate - A function returning a value for errorMessage, or true, given a (coerced and normalized, if provided) value.
 * @argument [object] options
 * @argument [function] options.normalize - Clean a value before validating or committing.
 * @argument [function] options.coerce - Coerce a given value on input.
 * @argument [function] options.isNull - Determine whether defaultValue should be used in place of value on mount.
 * @argument [function|*] defaultValue - A default value to use instead of the given value on mount if the given value is `NaN`, `undefined`, `null` or `""`. Manually when this is used by setting the `isNull` function in options. If a function is provided it will be called with `props`.
 *
 * @returns function - Higher-Order Component
 *
 * @description
 *  `withValidation` creates a component enhancer that requires `value` and `onChange` props. A local state is created and all changes are kept, but `onChange` is only called with states that pass validation. `onChange`, `onRevert`, `value`, `valid`, `error`, and `errorMessage` are provided to the component.
 * 
 */

export default Object.assign((
    validate,
    {
        normalize,
        coerce,
        isNull = value => [undefined, null, "", NaN].includes(value),
    } = {},
    defaultValue
) => {
    const getValidation = value => {
        if (validate instanceof Function) {
            const result = validate(value);
            return {
                error: result !== true ? true : undefined,
                valid: result === true,
                errorMessage: result !== true ? result : undefined,
            };
        }
        return { error: false, valid: true, errorMessage: null };
    };
    const getDefaultOrValue = (value, props) =>
        defaultValue !== undefined &&
        isNull instanceof Function &&
        isNull(value)
            ? defaultValue instanceof Function
                ? defaultValue(props)
                : defaultValue
            : value;
    const getNormalValue = value =>
        normalize instanceof Function ? normalize(value) : value;
    const getCoercedValue = value =>
        coerce instanceof Function ? coerce(value) : value;
    const validationState = value => {
        const coercedValue = getCoercedValue(value);
        const normalValue = getNormalValue(coercedValue);
        const validation = getValidation(normalValue);
        return {
            ...validation,
            normalValue,
            value: coercedValue,
        };
    };
    const cleanAndPassThrough = (state = {}, props = {}) => {
        // Don't pollute the props.
        delete state.normalValue;
        // Allow passing through of error and errorMessage.
        if (state.error === undefined) {
            state.error = props.error;
        }
        if (state.errorMessage === undefined) {
            state.errorMessage = props.errorMessage;
        }
        return state;
    };
    return compose(
        setPropTypes({
            value: PropTypes.any.isRequired,
            onChange: PropTypes.func.isRequired,
            onRevert: PropTypes.func,
        }),
        withStateHandlers(
            props => {
                const { value } = props;
                const validation = validationState(
                    getDefaultOrValue(value, props)
                );
                // If the value is 'null' overwrite the default value.
                return cleanAndPassThrough(validation, props);
            },
            {
                onChange: (state, props) => value => {
                    const { onChange } = props;
                    const validation = validationState(value);
                    if (validation.valid) {
                        // Commit the validated change.
                        onChange(validation.value);
                    }
                    return cleanAndPassThrough(validation, props);
                },
                onRevert: (state, props) => () => {
                    const { onRevert, value } = props;
                    const validation = validationState(
                        getDefaultOrValue(value, props)
                    );
                    // Revert shouldn't ever be setting the component to a value that wasn't already committed.
                    if (onRevert instanceof Function)
                        onRevert(validation.normalValue);
                    validation.value = validation.normalValue;
                    return cleanAndPassThrough(validation, props);
                },
            }
        )
    );
}, { cleanup });

