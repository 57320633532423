import axios from "axios";
import surveyJsResponseToTextResponses from "util/surveyJsResponseToTextResponses";
import types from "store/types";
import { groupBy } from "lodash";
import moment from "moment";

// TODO: This could be a HUGE collection and corresponding output CSV, so this function should have a streaming option that requires headers at some point.
const CSVFromCollection = (_headers, delimiter = ";") => collection => {
    // If headers aren't defined, derive the headers.
    const headers = Array.isArray(_headers)
        ? _headers
        : Array.from(
              collection.reduce(
                  (headerSet, obj) =>
                      Object.keys(obj).reduce(
                          (set, key) => set.add(key),
                          headerSet
                      ),
                  new Set()
              )
          );
    return [headers]
        .concat(
            collection.map(obj =>
                headers.map(header =>
                    obj[header] === undefined || obj[header] === null
                        ? ""
                        : `${obj[header]}`
                )
            )
        )
        .map(arr =>
            arr
                .map(s =>
                    s
                        // Remove any instances of the delimiter.
                        .replace(delimiter, "_")
                        // Replace newlines with double spaces.
                        .replace(/\n/g, "  ")
                )
                .join(delimiter)
        )
        .join("\n");
};

const retrieveSampleFile = ({ sampleId, filename }) => (dispatch, getState) => {
    const state = getState();

    const cleanSampleId = sampleId.replace(/^nlx-/, "");

    // Audio resource location.
    const location =
        state.CONFIG.NLX__API_ENDPOINT__AUDIO_STREAMER +
        "/decrypt?id=" +
        cleanSampleId;
    const token = state.user.session.token;

    const fileDescription = {
        location,
        sampleId,
        filename,
    };

    const makeRequest = () =>
        fetch(location, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                dispatch({
                    type: types.files.UPDATED,
                    payload: { ...fileDescription, response },
                });
            })
            .catch(error => {
                console.error(error);
            });

    dispatch({
        type: types.files.ADDED,
        payload: [{ ...fileDescription, request: makeRequest() }],
    });
};

const retrieveModelFile = ({ surveyId, filename }) => (dispatch, getState) => {
    const {
        user: {
            session: { token },
        },
        CONFIG: {
            NLX__API_ENDPOINT__REPORTING_DOWNLOAD: REPORTING_API,
            NLX__API_ENDPOINT__REPORTING_STATUS: REPORTING_STATUS_API,
        },
    } = getState();

    let jobId;
    const getFile = file => {
        const location = `${REPORTING_API}/${jobId}`;
        const fileDescription = {
            location,
            filename,
        };
        fetch(location, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            dispatch({
                type: types.files.UPDATED,
                payload: { ...fileDescription, response },
            });
        });
        dispatch({
            type: types.files.UPDATED,
            payload: [{ ...fileDescription }],
        });
    };

    const jobRequestLocation = `${REPORTING_API}/${surveyId}`;

    dispatch({
        type: types.files.ADDED,
        payload: [{ filename, jobRequestLocation }],
    });

    axios
        .post(jobRequestLocation, undefined, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            const {
                data: { _id },
            } = response;
            jobId = _id;
            const statusLocation = `${REPORTING_STATUS_API}/${jobId}`;
            const getProgressUpdate = () =>
                axios.get(statusLocation, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            const continueGettingProgress = response => {
                let lastResponse = response;
                dispatch({
                    type: types.files.UPDATED,
                    payload: [
                        {
                            filename,
                            progressResponse: response,
                            statusLocation,
                        },
                    ],
                });
                if (response.data.status === "RUNNING") {
                    getProgressUpdate().then(continueGettingProgress);
                } else {
                    getFile(response.data.file);
                }
                return lastResponse;
            };
            getProgressUpdate().then(continueGettingProgress);
        });
};

const mapSampleToResponse = samplesByResponseId => response => ({
    ...response,
    sample: samplesByResponseId[response.responseId]
        ? Object.values(samplesByResponseId[response.responseId])[0]
        : null,
});

const expandResponse = response => {
    switch (response.fragmentType) {
        case "CONSENT":
            return [];
        case "TEXT_SURVEYJS":
            return surveyJsResponseToTextResponses(response).map(
                ({ fragmentData, data, ...response }) => ({
                    ...response,
                    question: fragmentData.title,
                    text: data,
                })
            );
        case "AUDIO_STANDARD":
            // There is a response with no sample.
            const { sampleId = null, transcription = null, length = null } =
                response.sample || {};
            if (response.sample === undefined) return [];
            return {
                ...response,
                skipped: response.data.skipped,
                question: response.fragmentData.prompt,
                text: transcription,
                transcription: true,
                length,
                sampleId,
            };
        default:
            return response;
    }
};
const cleanResponse = ({
    _id,
    __v,
    index,
    data,
    parentFragmentId,
    collectionName,
    responses,
    surveyId,
    fragmentId,
    fragmentType,
    fragmentData,
    // TODO: We should probably strip the userIds further up the stream.
    userId,
    ...response
}) => ({
    ...response,
    type: fragmentType,
    questionId: fragmentId || parentFragmentId + "_" + index,
});

const buildResponses = ({
    surveyId,
    sampleIds,
    reponseIds,
    filename,
    format,
}) => (dispatch, getState) => {
    const { responsesBySurveyId, samplesByResponseId } = getState();
    const responses = Object.values(responsesBySurveyId[surveyId]);
    const responsesWithExpandedSurveyJS = responses
        .map(mapSampleToResponse(samplesByResponseId))
        .reduce(
            (expandedResponses, response) =>
                expandedResponses.concat(expandResponse(response)),
            []
        )
        .map(cleanResponse);
    dispatch({
        type: types.files.ADDED,
        payload: [
            {
                filename,
                response: new Response(
                    format === "json"
                        ? JSON.stringify(responsesWithExpandedSurveyJS, null, 2)
                        : format === "csv"
                        ? CSVFromCollection()(responsesWithExpandedSurveyJS)
                        : ""
                ),
            },
        ],
    });
};

const buildSubmissions = ({ surveyId, sessionIds, filename, format }) => (
    dispatch,
    getState
) => {
    const {
        responsesBySurveyId,
        sessionsById,
        samplesByResponseId,
    } = getState();
    const responses = Object.values(responsesBySurveyId[surveyId]);
    const cleanAndExpandedResponses = responses
        .map(mapSampleToResponse(samplesByResponseId))
        .reduce(
            (expandedResponses, response) =>
                expandedResponses.concat(expandResponse(response)),
            []
        )
        // Preamble the text.
        .map(response =>
            response.fragmentType === "AUDIO_STANDARD"
                ? {
                      ...response,
                      text: `(${response.sampleId} ${moment(0)
                          .utc(false)
                          .add(response.length, "seconds")
                          .format("HH:mm:ss.SS")}) ${response.text}`,
                  }
                : response
        )
        .map(cleanResponse);
    // Bin by SessionId
    const submissions = Object.values(
        groupBy(cleanAndExpandedResponses, "sessionId")
    ).reduce(
        (submissions, responses) => [
            ...submissions,
            responses.reduce(
                (submission, response) => ({
                    ...submission,
                    [response.question]: response.text,
                }),
                {
                    // sessionId: responses[0],
                    startTime: sessionsById[responses[0].sessionId].startTime,
                }
            ),
        ],
        []
    );

    dispatch({
        type: types.files.ADDED,
        payload: [
            {
                filename,
                response: new Response(
                    format === "json"
                        ? JSON.stringify(submissions, null, 2)
                        : format === "csv"
                        ? // TODO: Configure the headers such that the questions are always in order.
                          CSVFromCollection()(submissions)
                        : ""
                ),
            },
        ],
    });
};

const retrieveSampleBySampleId = ({ sampleId, withFeatures = false }) => (
    dispatch,
    getState
) => {
    const {
        user: {
            session: { token },
        },
        CONFIG: { NLX__API_ENDPOINT__SAMPLE: SAMPLE_ENDPOINT },
    } = getState();

    return axios
        .get(SAMPLE_ENDPOINT + "/" + sampleId, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                withFeatures,
            },
        })
        .then(response => {
            dispatch({
                type: types.samples.LOADED,
                payload: [response.data],
            });
            return response;
        });
};

const buildFeatures = ({ sampleId, filename, format }) => (
    dispatch,
    getState
) => {
    const featuresCSV = features => {
        // Columns are features.
        // Rows are features over time.
        const AUDIO_FEATURES = [
            "ZCR",
            "chroma_deviation",
            "energy",
            "entropy",
            "spectral_centroid",
            "spectral_entropy",
            "spectral_flux",
            "spectral_rolloff",
            "spectral_spread",
        ]
            .concat(new Array(12).fill(0).map((n, i) => `chroma_vector.${i}`))
            .concat(new Array(13).fill(0).map((n, i) => `MFCC.${i}`));
        const headers = AUDIO_FEATURES;
        const audioFeaturesByTimeCollection = [];
        const getFrame = i =>
            AUDIO_FEATURES.reduce((obj, feature) => {
                const featureTrail = feature.split(/\./g);
                const values = featureTrail.reduce(
                    (trail, crumb) =>
                        trail instanceof Object ? trail[crumb] : undefined,
                    features.audio
                );
                if (Array.isArray(values) && values[i] !== undefined) {
                    return { ...obj, [feature]: values[i] };
                } else {
                    return obj;
                }
            }, {});

        for (let i = 0; true; i++) {
            const frame = getFrame(i);
            if (Object.keys(frame).length === 0) {
                break;
            }
            audioFeaturesByTimeCollection.push(frame);
        }

        return CSVFromCollection(headers)(audioFeaturesByTimeCollection);
    };

    const featuresJSON = features => JSON.stringify(features, null, 2);

    dispatch({
        type: types.files.ADDED,
        payload: [{ filename, sampleId }],
    });

    dispatch(
        retrieveSampleBySampleId({
            sampleId,
            withFeatures: true,
        })
    )
        .then(response => {
            const { samplesById } = getState();
            const sampleFeatureArray = samplesById[sampleId].v1features;
            if (
                sampleFeatureArray === undefined ||
                sampleFeatureArray[0] === undefined
            )
                return false;
            const features = sampleFeatureArray[0].features;
            return features;
        })
        .then(format === "csv" ? featuresCSV : featuresJSON)
        .then(fileContent => {
            dispatch({
                type: types.files.UPDATED,
                payload: {
                    filename,
                    sampleId,
                    response: new Response(fileContent),
                },
            });
        });
};

export default ({ dispatch, getState }) => next => action => {
    switch (action.type) {
        case types.files.CLOSE:
            next(action);
            break;

        case types.requests.FILE_SURVEY_TEXT_SUMMARY: {
            next(action);
            const { filesByFilename = {} } = getState();
            const { filename, surveyId } = action.payload || {};
            // Just don't go for files that already exist...
            if (filesByFilename[filename]) {
                break;
            }

            if (surveyId) {
                dispatch(buildSubmissions(action.payload));
            }
            break;
        }

        case types.requests.FILE_SURVEY_MODELS: {
            next(action);
            const { filesByFilename = {} } = getState();
            const { filename, surveyId } = action.payload || {};
            // Just don't go for files that already exist...
            if (filesByFilename[filename]) {
                break;
            }

            if (surveyId) {
                dispatch(retrieveModelFile(action.payload));
            }
            break;
        }
        case types.requests.FILE_SURVEY_RESPONSES: {
            next(action);
            const { filesByFilename = {} } = getState();
            const { filename, surveyId } = action.payload || {};
            // Just don't go for files that already exist...
            if (filesByFilename[filename]) {
                break;
            }

            if (surveyId) {
                dispatch(buildResponses(action.payload));
            }
            break;
        }
        case types.requests.FILE_FEATURIZATION: {
            next(action);
            dispatch(buildFeatures(action.payload));
            break;
        }
        case types.requests.FILE_SAMPLE: {
            next(action);
            const { filesBySampleId = {} } = getState();
            const { sampleId } = action.payload || {};
            // Just don't go for files that already exist...
            if (filesBySampleId[sampleId]) {
                break;
            }
            if (sampleId) {
                dispatch(retrieveSampleFile(action.payload));
            }
            break;
        }
        default:
            next(action);
    }
};
