import * as Sentry from "@sentry/browser";
import types from "store/types";

// We want this to start as early as possible.
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: process.env.REACT_APP__SENTRYIO__DSN,
        environment: process.env.REACT_APP__META__BUILD_TARGET,
    });
}

export default store => next => action => {
    next(action);
    if (process.env.NODE_ENV === "production") {
        if (action.error && action.payload instanceof Error) {
            Sentry.captureException(action.payload);
        }
        switch (action.type) {
            case types.user.AUTHENTICATED:
            case types.user.LOADED_PROFILE:
                const {
                    user: { userId, profile: { email } = {} } = {},
                } = store.getState();
                Sentry.configureScope(scope => {
                    scope.setUser({ id: userId, email });
                });
                break;
            case types.configuration.SET:
                const { CONFIG = {} } = store.getState();
                Sentry.configureScope(scope => {
                    Object.entries(CONFIG).forEach(([key, value]) => {
                        if (key.startsWith("META__")) {
                            scope.setTag(key, value);
                        } else {
                            scope.setExtra(key, value);
                        }
                    });
                });
                break;
            default:
                break;
        }
    }
};
