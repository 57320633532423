import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { Typography, Popover } from "@mui/material";
import {
    ClipboardLink,
    SurveyResultResponses,
    SessionSimpleSummary,
    SurveyStatsCard,
    SurveyActivityChartCard,
    SurveyDropOffChartCard,
    DownloadButton,
    BreadCrumbsNavbar,
} from "components";
import UserAppLayout from "layouts/UserAppLayout";
import { sortBy } from "lodash";
import types from "store/types";
import "./ViewSurvey.css";

const SurveySessionPreviews = compose(
    connect(({ sessionsBySurveyId, responsesBySessionId }, { surveyId }) => ({
        sessionsById: sessionsBySurveyId[surveyId],
        responsesBySessionId,
    })),
    withProps(
        ({
            sessionsById = {},
            responsesBySessionId = {},
            sortBy: _sortBy = ({ startTime }) => -new Date(startTime),
            filter = ({ sessionId }) => responsesBySessionId[sessionId],
        }) => ({
            sessions: sortBy(
                Object.values(sessionsById).filter(filter),
                _sortBy
            ),
        })
    )
)(({ sessions = [], responsesBySessionId = {} }) => {
    return sessions.map(({ sessionId, metadata }) => (
        <React.Fragment key={sessionId}>
            <SessionSimpleSummary sessionId={sessionId} metadata={metadata}>
                <SurveyResultResponses
                    responses={Object.values(
                        responsesBySessionId[sessionId] || {}
                    )}
                />
            </SessionSimpleSummary>
        </React.Fragment>
    ));
});
const defaultPopover = { open: false, anchorEl: null };
const ViewSurvey = compose(
    connect(({ navigation: { match: { params: { surveyId} = {} } = {} } = {}}) => ({ surveyId})),
    connect(
        ({ surveys, sessionsBySurveyId, CONFIG, popover }, { surveyId }) => ({
            surveyEndpoint: CONFIG.APPLICATION__SURVEY_ENDPOINT,
            survey: surveys[surveyId],
            sessionsById: sessionsBySurveyId[surveyId],
            popover: popover || defaultPopover,
        })
    ),
    withProps(({ surveyId, sessionsById = {}, surveyEndpoint }) => ({
        totalSessions: Object.values(sessionsById).length,
        endpoint: `${surveyEndpoint}/${surveyId}`,
    })),
    withProps(({ dispatch }) => ({
        onClose: () => {
            dispatch({
                type: types.popover.CLOSE_DEVICE_INFO_POPOVER,
                payload: {
                    open: false,
                    anchorEl: null,
                },
            });
        },
    }))
)(
    ({
        dispatch,
        endpoint,
        totalSessions,
        survey,
        surveyId,
        onClose,
        popover,
    }) => {
        dispatch({
            type: types.requests.SURVEY,
            payload: { survey: { surveyId } },
        });
        dispatch({ type: types.requests.SESSIONS, payload: { surveyId } });
        dispatch({ type: types.requests.RESPONSES, payload: { surveyId } });
        if (survey) {
            return (
                <UserAppLayout>
                    <div className="container ViewSurvey">
                        <div className="view-survey__header">
                            <Typography
                                className="view-survey__header-title"
                                variant="h5"
                            >
                                {survey.title || "Untitled Survey"}
                            </Typography>
                            <DownloadButton
                                disabled={totalSessions === 0}
                                surveyId={surveyId}
                                color="primary"
                                variant="contained"
                                className="view-survey__download-results"
                            >
                                Download Results
                            </DownloadButton>
                        </div>
                        <div className="view-survey__header-breadcrumbs">
                            <BreadCrumbsNavbar
                                trail={[
                                    {
                                        title: "My Surveys",
                                        location: {
                                            view: "home",
                                        },
                                    },
                                    {
                                        title: "Survey Overview",
                                    },
                                ]}
                            />
                        </div>
                        {totalSessions === 0 ? (
                            <div className="view-survey__header">
                                <div className="view-survey__no-responses-text">
                                    <Typography variant="h5">
                                        Share this link with survey
                                        participants.
                                    </Typography>
                                </div>
                            </div>
                        ) : null}
                        <div className="view-survey__header">
                            <ClipboardLink className="view-survey__header-center">
                                {endpoint}
                            </ClipboardLink>
                        </div>
                        <div className="view-survey-overview__container">
                            <div className="view-survey-overview-chart view-survey-overview-chart__engagement">
                                <SurveyActivityChartCard
                                    period="day"
                                    length={7}
                                    title="Daily Activity"
                                    surveyId={surveyId}
                                />
                            </div>
                            <div className="view-survey-overview-chart view-survey-overview-chart__completion-stats">
                                <SurveyStatsCard surveyId={surveyId} />
                            </div>
                            <div className="view-survey-overview-chart view-survey-overview-chart__dropoff-rate">
                                <SurveyDropOffChartCard
                                    title="Question Completions"
                                    surveyId={surveyId}
                                />
                            </div>
                        </div>
                        <div className="view-survey-responses__container">
                            <div className="view-survey-responses__header">
                                <Typography variant="h6">
                                    Survey Sessions
                                </Typography>
                            </div>
                            <div className="view-survey-responses__header">
                                {/* <div className="view-survey-responses__search-bar-container">
                                <SearchBar placeholder="Search Responses" />
                            </div> */}
                                {/*
                                TODO: Sort by different parameters.
                                <div>Sort by Date &darr;</div>
                             */}
                            </div>
                            {totalSessions === 0 ? (
                                <div className="view-survey-responses__header">
                                    <div className="view-survey__no-responses-text">
                                        <Typography variant="h5">
                                            Results will appear within a minute
                                            of being collected.
                                        </Typography>
                                    </div>
                                </div>
                            ) : null}
                            <div className="view-survey-responses__sessions">
                                <SurveySessionPreviews surveyId={surveyId} />
                                <Popover
                                    id={"simple-session"}
                                    open={popover.open}
                                    anchorEl={popover.anchorEl}
                                    onClose={onClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                >
                                    {popover.userAgent ? (
                                        <div className="session-summary__user-agent">
                                            <div className="session-summary__user-agent-browser">
                                                <Typography>
                                                    <b>Browser Name: </b>
                                                    {
                                                        popover.userAgent
                                                            .browser.name
                                                    }
                                                    <br />
                                                    <b> Version: </b>
                                                    {
                                                        popover.userAgent
                                                            .browser.version
                                                    }
                                                </Typography>
                                            </div>
                                            <div className="session-summary__user-agent-os">
                                                <Typography>
                                                    <b>OS Name: </b>
                                                    {popover.userAgent.os.name}
                                                    <br />
                                                    <b> Version: </b>
                                                    {
                                                        popover.userAgent.os
                                                            .version
                                                    }
                                                </Typography>
                                            </div>
                                            <div className="session-summary__user-agent">
                                                <Typography>
                                                    <b>User Agent String:</b>
                                                    <br />
                                                    <i>
                                                        {popover.userAgent.ua}
                                                    </i>
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="session-summary__user-agent">
                                            <Typography>
                                                No device data was captured for
                                                this user. This might be because
                                                this sample was collected before
                                                device data was stored. If it is
                                                a recent sample, please report
                                                this issue.
                                            </Typography>
                                        </div>
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </div>
                </UserAppLayout>
            );
        } else {
            return null;
        }
    }
);

ViewSurvey.defaultProps = {};

export default ViewSurvey;
