import { combineReducers } from "redux";
import reduceReducers from "reduce-reducers";

// TODO: Use a set of simpler reducers to make this huge reducer more manageable, and make it easy to change how a specific action works over field.
const loadableFieldReducer = (key, defaultValue) => ({ LOADED }) => (
    state = defaultValue,
    action = {}
) => {
    switch (action.type) {
        case LOADED:
            if (!(action.payload instanceof Object)) {
                return state;
            }
            return action.payload[key] || defaultValue;
        default:
            return state;
    }
};

const updateableFieldReducer = (key, defaultValue) => ({ UPDATED }) => (
    state = defaultValue,
    action = {}
) => {
    switch (action.type) {
        case UPDATED:
            if (!(action.payload instanceof Object)) {
                return state;
            }
            return action.payload[key] || state;
        default:
            return state;
    }
};

const creatableFieldReducer = (key, defaultValue) => ({ CREATED }) => (
    state = defaultValue,
    action = {}
) => {
    switch (action.type) {
        case CREATED:
            if (!(action.payload instanceof Object)) {
                return state;
            }
            return action.payload[key] || state;
        default:
            return state;
    }
};

export const questionReducer = ({ CREATED, UPDATED, LOADED }) =>
    combineReducers({
        // question: reduceReducers(
        //   creatableFieldReducer('question', '')({ CREATED }),
        //   updateableFieldReducer('question', '')({ UPDATED }),
        //   loadableFieldReducer('question', '')({ LOADED }),
        // )
        question(state = "", action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.question || state;
                default:
                    return state;
            }
        },
        answers(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.answers || state;
                default:
                    return state;
            }
        },
    });

export const fragmentsReducer = ({ CREATED, UPDATED, DELETED, MOVED }) => (
    state = [],
    action = {}
) => {
    switch (action.type) {
        case CREATED: {
            if (!(action.payload instanceof Object)) {
                return state;
            }
            const { id, fragmentId, type, data = {} } = action.payload;
            const fragment = state[id];
            // Allow creation at an index.
            if (fragment) {
                const updated = state.slice();
                updated.splice(state.indexOf(fragment), 0, { type, data });
                return updated;
            }
            return state.concat({ fragmentId, type, data });
        }
        case UPDATED: {
            if (!(action.payload instanceof Object)) {
                return state;
            }
            const { id, fragmentId, type, data = {} } = action.payload;
            const fragment = state[id];
            if (fragment) {
                const updated = state.slice();
                updated.splice(state.indexOf(fragment), 1, {
                    fragmentId: fragmentId || fragment.fragmentId,
                    type: type === null ? type : type || fragment.type,
                    data: { ...fragment.data, ...data },
                });
                return updated;
            }
            return state;
        }
        case MOVED: {
            if (!(action.payload instanceof Object)) {
                return state;
            }
            const { id, movement } = action.payload;
            const fragment = state[id];
            if (fragment) {
                const updated = state.slice();
                const location = state.indexOf(fragment);
                updated.splice(location, 1);
                updated.splice(location + movement, 0, fragment);
                return updated;
            }
            return state;
        }
        case DELETED: {
            const index = action.payload;
            return state
                .slice(0, index)
                .concat(state.slice(index + 1, state.length));
        }
        default:
            return state;
    }
};

export const voiceQuestionPromptReducer = ({ CREATED, UPDATED, LOADED }) =>
    combineReducers({
        prompt(state = "", action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.prompt || state;
                default:
                    return state;
            }
        },
        lengthInSeconds(state = 30, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.lengthInSeconds || state;
                default:
                    return state;
            }
        },
    });

const surveyReducer = ({
    CREATED,
    PUBLISHED,
    UPDATED,
    RECORD_VIEW,
    ARCHIVED,
    LOADED,
    CREATED_FRAGMENT,
    UPDATED_FRAGMENT,
    DELETED_FRAGMENT,
    MOVED_FRAGMENT,
}) =>
    combineReducers({
        title(state = "", action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                case UPDATED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return typeof action.payload.title === "string"
                        ? action.payload.title
                        : state;
                default:
                    return state;
            }
        },
        logoUrl(state = "", action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                case UPDATED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return typeof action.payload.logoUrl === "string"
                        ? action.payload.logoUrl
                        : state;
                default:
                    return state;
            }
        },
        archived(state = false, action = {}) {
            switch (action.type) {
                case CREATED:
                    return false;
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.archived !== undefined
                        ? action.payload.archived
                        : state;
                case ARCHIVED:
                    return true;
                default:
                    return state;
            }
        },
        _id(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload._id === undefined
                        ? state
                        : action.payload._id;
                default:
                    return state;
            }
        },
        __v(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload._v === undefined
                        ? state
                        : action.payload._v;
                default:
                    return state;
            }
        },
        id(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.id === undefined
                        ? state
                        : action.payload.id;
                default:
                    return state;
            }
        },
        surveyId(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.surveyId === undefined
                        ? state
                        : action.payload.surveyId;
                default:
                    return state;
            }
        },
        // TODO: It's unclear at this point whether users will use custom grouping, or whether these automatic groups are going to be used.
        group(state = "Group", action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.published
                        ? "Published Surveys"
                        : "Unpublished Surveys";
                case PUBLISHED:
                    return "Published Surveys";
                default:
                    return state;
            }
        },
        creator(state = null, action = {}) {
            switch (action.type) {
                case CREATED: // TODO: Add userId to the meta?
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.creator === undefined
                        ? state
                        : action.payload.creator;
                default:
                    return state;
            }
        },
        organization(state = null, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.organization === undefined
                        ? state
                        : action.payload.organization;
                default:
                    return state;
            }
        },
        surveyLink(state = null, action = {}) {
            switch (action.type) {
                case PUBLISHED:
                case LOADED:
                case UPDATED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.surveyLink === undefined
                        ? state
                        : action.payload.surveyLink;
                default:
                    return state;
            }
        },
        surveyType(state = "voice", action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.surveyType === undefined
                        ? state
                        : action.payload.surveyType;
                default:
                    return state;
            }
        },
        sessions(state = [], action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.sessions === undefined
                        ? state
                        : action.payload.sessions;
                default:
                    return state;
            }
        },
        audioResponseSamples(state = [], action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.audioResponseSamples === undefined
                        ? state
                        : action.payload.audioResponseSamples;
                default:
                    return state;
            }
        },
        voiceQuestionPrompts(
            state = [{ prompt: "", lengthInSeconds: 30 }],
            action = {}
        ) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.voiceQuestionPrompts === undefined
                        ? state
                        : action.payload.voiceQuestionPrompts;
                default:
                    return state;
            }
        },
        textQuestionsResponses(state = [], action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.testQuestionsResponses === undefined
                        ? state
                        : action.payload.testQuestionsResponses;
                default:
                    return state;
            }
        },
        textQuestions(state = [], action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.textQuestions === undefined
                        ? state
                        : action.payload.textQuestions;
                default:
                    return state;
            }
        },
        viewNum(state = 0, action = {}) {
            switch (action.type) {
                case RECORD_VIEW:
                    return state + 1;
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.viewNum === undefined
                        ? state
                        : action.payload.viewNum;
                default:
                    return state;
            }
        },
        createdDate(state = 0, action = {}) {
            switch (action.type) {
                case CREATED:
                    if (!(action.meta instanceof Object)) {
                        return state;
                    }
                    return action.meta.timestamp || state;
                case UPDATED:
                case LOADED:
                case PUBLISHED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.createdDate === undefined
                        ? state
                        : action.payload.createdDate;
                default:
                    return state;
            }
        },
        startDate(state = 0, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.startDate === undefined
                        ? state
                        : action.payload.startDate;
                default:
                    return state;
            }
        },
        endDate(state = 0, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.endDate === undefined
                        ? state
                        : action.payload.endDate;
                default:
                    return state;
            }
        },
        published(state = false, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.published === undefined
                        ? state
                        : action.payload.published;
                case PUBLISHED:
                    return true;
                default:
                    return state;
            }
        },
        previewSurvey(state = 0, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                case PUBLISHED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.previewSurvey === undefined
                        ? state
                        : action.payload.previewSurvey;
                default:
                    return state;
            }
        },
        fragments: reduceReducers(
            fragmentsReducer({
                CREATED: CREATED_FRAGMENT,
                UPDATED: UPDATED_FRAGMENT,
                DELETED: DELETED_FRAGMENT,
                MOVED: MOVED_FRAGMENT,
            }),
            creatableFieldReducer("fragments", [])({ CREATED }),
            updateableFieldReducer("fragments", [])({ UPDATED }),
            loadableFieldReducer("fragments", [])({ LOADED })
        ),
        interfaceSize(state = "Standard", action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                case PUBLISHED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.interfaceSize === undefined
                        ? state
                        : action.payload.interfaceSize;
                default:
                    return state;
            }
        },
        disabledAudio(state = false, action = {}) {
            switch (action.type) {
                case CREATED:
                case UPDATED:
                case LOADED:
                case PUBLISHED:
                    if (!(action.payload instanceof Object)) {
                        return state;
                    }
                    return action.payload.disabledAudio === undefined
                        ? state
                        : action.payload.disabledAudio;
                default:
                    return state;

            }
        }
    });

export default surveyReducer;
