import React from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import types from "store/types"
import "./create-survey.css";

const CreateSurveyButton = ({ dispatch }) => (
  <div className="create-survey__container">
    <Button variant="contained" color="primary" className="create-survey__button" onClick={
      () => dispatch({ type: types.requests.NAVIGATION, payload: { view: 'create-survey' }})
      }>
      Create New Survey
    </Button>
  </div>
);

export default connect()(CreateSurveyButton);
