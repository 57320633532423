import {
    intoObjectKey,
    type,
    initialState,
    toAction,
    payload,
} from "redux-composable-reducers";
import types from "store/types";
import intoObject from "redux-composable-reducers/lib/intoObject";
import reduce from "redux-composable-reducers/lib/reduce";

export const addRange = (ranges = [], [start, end]) => {
    const { included: startIncluded, index: startIndex } = searchRanges(
        ranges,
        start
    );
    const { included: endIncluded, index: endIndex } = searchRanges(
        ranges,
        end
    );
    const out = ranges.slice();
    out.splice(startIndex, endIndex - startIndex + (endIncluded ? 1 : 0), [
        startIncluded ? ranges[startIndex][0] : start,
        endIncluded ? ranges[endIndex][1] : end,
    ]);
    return out;
};

export const subtractRange = (ranges = [], [start, end]) => {
    const { included: startIncluded, index: startIndex } = searchRanges(
        ranges,
        start
    );
    const { included: endIncluded, index: endIndex } = searchRanges(
        ranges,
        end
    );
    const replacementRanges = [];
    if (startIncluded && ranges[startIndex][0] < start)
        replacementRanges.push([ranges[startIndex][0], start]);
    if (endIncluded && ranges[endIndex][1] > end)
        replacementRanges.push([end, ranges[endIndex][1]]);
    const out = ranges.slice();
    out.splice(
        startIndex,
        endIndex - startIndex + (endIncluded ? 1 : 0),
        ...replacementRanges
    );
    return out;
};

export const searchRanges = (
    ranges = [],
    time,
    from = 0,
    to = ranges.length
) => {
    if (from === to) return { included: false, index: from };
    const pivot = (from + to) >> 1;
    if (ranges[pivot][0] > time) return searchRanges(ranges, time, from, pivot);
    if (ranges[pivot][1] < time)
        return searchRanges(ranges, time, pivot + 1, to);
    return { included: true, index: pivot };
};

export const clipRanges = (ranges = [], range) =>
    ranges.reduce(subtractRange, addRange(ranges, range));

// const hasPageRange = (s, { payload: { fromDate, toDate } } = {}) =>
//     fromDate instanceof Date && toDate instanceof Date;
// const hasRequestId = (s, { payload: { requestId } }) => requestId !== undefined;

const actionToRange = (s, { payload: { fromDate, toDate } }) => {
    const start = fromDate < toDate ? fromDate : toDate;
    const end = fromDate > toDate ? fromDate : toDate;
    return [start, end];
};

/**
 * { payload: { fromDate, toDate, requestId, resource, [relatedResourceId:surveyId] }}
 */
export const pageRange = initialState({
    rangeRequests: {},
    requestedRanges: [],
    retrievedRanges: [],
})(
    type({
        [types.pageRange.REQUESTED]: intoObjectKey({
            rangeRequests: intoObjectKey(payload("requestId"))(actionToRange),
            requestedRanges: toAction(actionToRange)(addRange),
        }),
        [types.pageRange.RETRIEVED]: reduce(
            intoObject({
                requestedRanges: (
                    { retrievedRanges, rangeRequests, requestedRanges },
                    { payload: { requestId } = {} }
                ) =>
                    retrievedRanges.reduce(
                        addRange,
                        subtractRange(requestedRanges, rangeRequests[requestId])
                    ),
            })((state) => state),
            intoObjectKey({
                // Add to the retrieved ranges.
                rangeRequests: intoObjectKey(
                    payload("requestId"),
                    null
                )(() => null),
                retrievedRanges: toAction(actionToRange)(addRange),
                requestedRanges: toAction(actionToRange)(addRange),
            })
        ),
    })
);

export default intoObjectKey(payload("endpoint"))(pageRange);
