import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import "./ConsentForm.css";

/**
 * A simple form for getting consent.
 */
const ConsentForm = ({
    checked,
    setChecked,
    prompt,
    confirm,
    confirmText,
    reject,
    rejectText,
    disabled,
    children,
}) => (
    <div className="consent-form__container">
        <div className="consent-form__language">{children}</div>
        <div className="consent-form__prompt">
            <div className="consent-form__check">
                <Checkbox
                    onChange={
                        typeof setChecked === "function"
                            ? (e) => setChecked(e.target.checked)
                            : undefined
                    }
                    checked={checked}
                    disabled={disabled}
                />
            </div>
            <div className="consent-form__prompt-text">{prompt}</div>
        </div>
        <div className="consent-form__actions">
            {/* <Button className="consent-form__reject-action" onClick={reject}>
          {rejectText}
        </Button> */}
            <span className="expanding-gap"></span>
            <Button
                className="consent-form__confirm-action"
                variant="contained"
                color="primary"
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                disabled={!checked}
                onClick={confirm}
            >
                {confirmText}
            </Button>
        </div>
    </div>
);

ConsentForm.propTypes = {
    /** Form language near checkbox. */
    prompt: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** Whether the form is checked by default. */
    optInOut: PropTypes.oneOf(["in", "out"]),
    /** Consent provided callback. */
    confirm: PropTypes.func,
    /** Text on button to confirm consent */
    confirmText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** Consent rejected callback. */
    reject: PropTypes.func,
    /** Text on button when not giving consent. */
    rejectText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

ConsentForm.defaultProps = {
    prompt: (
        <div>
            I understand the details of study as explained previously and I am
            willing to participate. I have read and agreed to the{" "}
            <a href="http://uploads.neurolexlabs.com/docs/Neurolex_InformationSheetConsent.pdf">
                Consent Document.
            </a>
        </div>
    ),
    optInOut: "in",
    reject: () => window.history.back(),
    confirmText: "Begin Survey",
    rejectText: "Back",
};

export default ConsentForm;
