import types from "store/types";
import { v1 as UUID } from "uuid";
import axios from "axios";
import {
    intoObjectKey,
    intoArray,
    payload,
    pass,
} from "store/reducers/helpers";
import retryWithBackoff from "util/retryWithBackoff";
import { retrievePageRange } from "store/middlewares/pageRange";
import { memoize } from "lodash";
import debounce from "util/debounce";

const sessionIsValid = (session) => {
    // The session is not an object.
    if (!(session instanceof Object)) return false;
    // The session doesn't have the right fields.
    if (!session.surveyId || !session.sessionId || !session.startTime)
        return false;
    // The session has expired.
    // TODO? It would probably be better to invalidate the session when the last response was more than a day ago, and that should be checked from the server. i.e. validUntil.
    const expirationTime = 24 * 60 * 60 * 1000;
    if (+new Date() - new Date(session.startTime) > expirationTime)
        return false;
    return true;
};

const retrieveSessionsBySurveyId =
    (
        surveyId,
        { fromDate = new Date(), toDate = new Date("2015"), limit = 1000 } = {}
    ) =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            user: {
                session: { token },
            },
            CONFIG: { NLX__API_ENDPOINT__OBJECT },
            pageRange,
        } = state;
        const endpoint = `${NLX__API_ENDPOINT__OBJECT}/surveys/${surveyId}/sessions`;

        const getSessionsBySurveyId = async ({ fromDate, toDate }) => {
            if (fromDate === toDate) return [];
            const { data } = await axios.get(endpoint, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    fromDate: fromDate.toISOString(),
                    toDate: toDate.toISOString(),
                    limit,
                },
            });
            return data;
        };

        const retrieveSessionsBySurveyIdPageRange = retrievePageRange(
            pageRange[endpoint],
            getSessionsBySurveyId,
            (sessions) => {
                // Assume that we retrieved all the responses in the range if the server returned less than our limit.
                if (sessions.length < limit) return toDate;

                // Session doesn't have a createdDate, and instead uses the _id.
                const lastDoc = sessions[sessions.length - 1];
                const hexTime = lastDoc._id.toString().substring(0, 8);
                return new Date(parseInt(hexTime, 16) * 1000);
            }
        );

        const sessions = await dispatch(
            retrieveSessionsBySurveyIdPageRange({ fromDate, toDate, endpoint })
        );

        if (!sessions) return;

        return Promise.all([
            dispatch({
                type: types.session.LOADED,
                payload: sessions,
            }),
            dispatch({
                type: types.responses.LOADED,
                payload: sessions.reduce(
                    (a, { responses }) => a.concat(responses),
                    []
                ),
            }),
            dispatch({
                type: types.samples.LOADED,
                payload: sessions.reduce(
                    (a, { samples }) => a.concat(samples),
                    []
                ),
            }),
        ]);
    };

const retrieveSession = memoize((sessionId) =>
    debounce((dispatch, getState) => {
        const {
            sessionsById: { [sessionId]: session },
        } = getState();
        if (session) return session;

        const {
            CONFIG: { NLX__API_ENDPOINT__OBJECT },
            user: {
                session: { token },
            },
        } = getState();
        const endpoint = `${NLX__API_ENDPOINT__OBJECT}/sessions/${sessionId}`;
        return axios
            .get(endpoint, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(async ({ data: session }) => {
                await dispatch({
                    type: types.session.LOADED,
                    payload: [session],
                });
                return session;
            });
    }, 10 * 60 * 1000)
);

const uploadLatestSession = (dispatch, getState) => async () => {
    // Always try to upload the most recent session.
    const {
        session,
        CONFIG: { NLX__API_ENDPOINT__SESSION: SESSION_ENDPOINT },
    } = getState();
    // TODO: If we already successfully uploaded the last session, don't try again.
    dispatch({ type: types.session.SENT, payload: session });
    await axios.post(SESSION_ENDPOINT, session);
    return dispatch({ type: types.session.DELIVERED, payload: session });
};

// A session consists of the action of a single user taking a single survey.
export default ({ getState, dispatch }) =>
    (next) =>
    async (action) => {
        switch (action.type) {
            case types.requests.SESSIONS:
                const { surveyId, sessionId } = action.payload;
                if (sessionId) {
                    await dispatch(retrieveSession(sessionId));
                }
                if (surveyId) {
                    await dispatch(retrieveSessionsBySurveyId(surveyId));
                }
                return next(action);
                break;
            case types.requests.SESSION:
                {
                    let { session } = getState();
                    const {
                        surveyId,
                        force = false,
                        metadata,
                    } = action.payload;

                    const payload = {
                        surveyId,
                        sessionId: UUID(),
                        startTime: new Date(),
                        metadata: {
                            query: getState().entryQuery,
                            userAgent: getState().userAgent,
                            ...metadata,
                        },
                    };

                    if (force) {
                        return dispatch({
                            type: types.session.INITIALIZED,
                            payload: payload,
                        }).then(() => {
                            return next(action);
                        });
                    }
                    // Return if session is already valid and in the state.
                    if (sessionIsValid(session)) {
                        return next(action);
                        // return true;
                    }
                    // Attempt to get the session from localStorage.
                    try {
                        session = JSON.parse(
                            localStorage.getItem(`survey-session-${surveyId}`)
                        );
                        if (metadata) {
                            session.metadata = {
                                ...metadata,
                                ...session.metadata,
                            };
                        }
                    } catch (e) {}
                    // If the session is not valid, create a new session.
                    if (!sessionIsValid(session)) {
                        session = payload;
                    }
                    // Dispatch the session to the reducer.
                    return dispatch({
                        type: types.session.INITIALIZED,
                        payload: session,
                    }).then(() => {
                        return next(action);
                    });
                }
                break;
            case types.session.INITIALIZED:
            case types.session.ADDED_RESPONSE:
            case types.session.ADDED_SAMPLE:
            case types.session.UPDATED:
                {
                    next(action);
                    const { session } = getState();
                    // Update the session in localstorage when it changes.
                    localStorage.setItem(
                        `survey-session-${session.surveyId}`,
                        JSON.stringify(session)
                    );
                    // Update the session on the server when it changes.
                    retryWithBackoff(uploadLatestSession(dispatch, getState));
                }
                break;
            case types.session.ARCHIVED:
                {
                    const { sessionId } = action.payload;
                    const token = getState().user.session.token;
                    const { sessionsById: { [sessionId]: session = {} } = {} } =
                        getState();
                    next({ ...action, payload: session });
                    if (sessionId) {
                        const {
                            CONFIG: {
                                NLX__API_ENDPOINT__SESSION: SESSION_ENDPOINT,
                            },
                        } = getState();
                        const archiveSurveyEndpoint = `${SESSION_ENDPOINT}/archive/${sessionId}`;
                        axios
                            .post(archiveSurveyEndpoint, undefined, {
                                headers: { Authorization: `Bearer ${token}` },
                            })
                            .catch((err) => {
                                if (session) {
                                    dispatch({
                                        type: types.sessions.LOADED,
                                        payload: [session],
                                    });
                                }
                                // TODO: Universalize this method under it's own error analytics kind of type.
                                dispatch({
                                    type: types.requests.ANALYTICS_EVENT,
                                    payload: {
                                        category: "Error",
                                        action: `${action.type}: ${err.name}`,
                                        label: err.message,
                                    },
                                });
                            });
                    }
                }
                break;
            case types.recorder.FAILED:
                next(action);
                dispatch({
                    type: types.session.UPDATED,
                    payload: intoObjectKey({
                        session: {
                            metadata: {
                                errorLog: intoArray.append(
                                    payload({ name: pass })
                                ),
                            },
                        },
                    })(pass)(getState(), action),
                });
                break;
            default:
                next(action);
        }
    };
