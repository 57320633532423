import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import {
    ResponseNumber,
    TextResponsesPieChart,
    TextResponsesScatterChart,
    TextResponsesBarChart,
} from "components";
import { Typography } from "@mui/material";
import { SpeakerNotesOff, TextFields } from "@mui/icons-material";
import "./TextResponseSummary.css";
const TextResponseText = ({ text }) => (
    <span
        className={[
            "text-response-summary__text",
            `text-response-summary__text--${
                text.length < 30 ? "short" : "long"
            }`,
        ].join(" ")}
    >
        {text}
    </span>
);

TextResponseText.propTypes = {
    text: PropTypes.string,
};

const TextResponseSummary = compose(
    withProps(({ response = {} }) => ({
        prompt: response.fragmentData.title,
        responseData: response.data,
        responseType: response.responseType,
        isFreeResponse: response.fragmentData.type === "text",
    }))
)(
    ({
        prompt,
        responseData = null,
        response = {},
        responseType,
        responses = [],
    }) => {
        // The "Label" for ratings is hidden.
        if (response.fragmentData.rateValues) {
            const index = response.fragmentData.rateValues.findIndex(
                rateValue =>
                    typeof rateValue === "object"
                        ? rateValue.value + "" === responseData + ""
                        : rateValue + "" === responseData + ""
            );
            responseData =
                "" +
                (typeof response.fragmentData.rateValues[index] === "object"
                    ? response.fragmentData.rateValues[index].text
                    : response.fragmentData.rateValues[index]);
        }
        return (
            <div className="text-response-summary">
                <div className="text-response-summary__response-header">
                    <Typography
                        variant="h6"
                        className="text-response-summary__question-number"
                    >
                        <ResponseNumber {...response} />
                    </Typography>
                    <div className="text-response-summary__icon">
                        <TextFields fontSize="small" />
                    </div>
                    <Typography
                        variant="subtitle1"
                        className="text-response-summary__prompt"
                    >
                        {prompt}
                    </Typography>
                </div>
                <div className="text-response-summary__response">
                    {responseData ? (
                        <Typography
                            variant="body1"
                            className="text-response-summary__response-text"
                        >
                            {Array.isArray(responseData) ? (
                                responseData.map((text, i) => (
                                    <TextResponseText key={i} text={text} />
                                ))
                            ) : (
                                <TextResponseText text={responseData} />
                            )}
                        </Typography>
                    ) : (
                        <Typography
                            variant="body1"
                            className="text-response-summary__response-text"
                        >
                            <SpeakerNotesOff />
                            <div style={{ whiteSpace: "nowrap" }}>
                                No Response
                            </div>
                        </Typography>
                    )}
                    <div className="text-response-summary__responses-visualization">
                        {/* // Note: ChartJS tends to take the height and width as suggestions, and then resizes to the container. */}
                        <div style={{ height: 224, width: 224 }}>
                            <Chart
                                height={224}
                                width={224}
                                {...{ responseType, response, responses }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export const ChartTypes = {
    SINGLE_CHOICE: "RADIOGROUP",
    FREE_RESPONSE: "TEXT",
    MULTIPLE_CHOICE: "CHECKBOX",
    RATING: "RATING",
    YES_NO: "YESNO",
    NPS: "NPS",
    CSAT: "CSAT",
};

const Chart = ({ responseType, response, responses, height, width }) => {
    if (response.fragmentData.referenceType) {
        switch (response.fragmentData.referenceType) {
            case ChartTypes.NPS:
            case ChartTypes.CSAT:
                return (
                    <TextResponsesScatterChart
                        height={height}
                        width={width}
                        response={response}
                        responses={responses}
                    />
                );
            case ChartTypes.YES_NO:
                return (
                    <TextResponsesPieChart
                        height={height}
                        width={width}
                        response={response}
                        responses={responses}
                    />
                );
            default:
        }
    }
    switch (responseType) {
        case ChartTypes.SINGLE_CHOICE:
        case ChartTypes.MULTIPLE_CHOICE:
            return (
                <TextResponsesBarChart
                    height={height}
                    width={width}
                    response={response}
                    responses={responses}
                />
            );
        case ChartTypes.RATING:
            return (
                <TextResponsesScatterChart
                    height={height}
                    width={width}
                    response={response}
                    responses={responses}
                />
            );
        // case ChartTypes.FREE_RESPONSE:
        //     return (
        //         <TextResponsesWordCloud
        //             height={height}
        //             width={width}
        //             response={response}
        //             responses={responses}
        //         />
        //     );
        default:
            return null;
    }
};

export default TextResponseSummary;
