import React from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import { ResponseNumber, ModelSummary, SurveyResultResponse } from "components";
import { Typography } from "@mui/material";
import { KeyboardVoice } from "@mui/icons-material";
import "./AudioResponseSummary.css";

const AudioResponseSummary = compose(
    withProps(
        ({
            response: { fragmentData = {}, responseId } = {},
            responses = [],
        }) => ({
            responseId,
            prompt: fragmentData.prompt,
        })
    ),
    connect(({ samplesByResponseId = {} }, { responseId }) => ({
        //samplesById: samplesByResponseId[responseId],
        sample: Object.values(samplesByResponseId[responseId] || {})[0],
    })),
    withProps(({ sample = {} }) => ({
        sampleId: sample.sampleId,
        transcription: sample.transcription,
        models: sample.model,
    }))
)(
    ({
        response,
        sampleId,
        prompt,
        transcription,
        models = {},
    }) => {
        return (
            <div className="audio-response-summary">
                <div className="audio-response-summary__response">
                    <div className="audio-response-summary__response-header">
                        <Typography
                            variant="h6"
                            className="audio-response-summary__question-number"
                        >
                            <ResponseNumber {...response} />
                        </Typography>
                        <div className="audio-response-summary__icon">
                            <KeyboardVoice fontSize="small" />
                        </div>
                        <Typography
                            variant="subtitle1"
                            className="audio-response-summary__prompt"
                        >
                            {prompt}
                        </Typography>
                    </div>
                    {sampleId ? (
                        <SurveyResultResponse
                            sampleId={sampleId}
                            transcription={transcription}
                            fullView={true}
                        />
                    ) : null}
                </div>
                {Object.entries(models).length ? (
                    <div className="audio-response-summary__models">
                        <div className="audio-response-summary__models-info" />
                        {Object.entries(models)
                            .sort(([a], [b]) => (a === b ? 0 : a < b ? -1 : 1))
                            .filter(([model]) =>
                                [
                                    "accent",
                                    "age",
                                    // "audioquality",
                                    "fatigue",
                                    "gender",
                                    // "sentiment",
                                    // "stress"
                                ].includes(model)
                            )
                            .map(([model, value], key) => (
                                <div className="audio-response-summary__model" key={key}>
                                    <ModelSummary model={model} value={value} />
                                </div>
                            ))}
                    </div>
                ) : null}
            </div>
        );
    }
);

export default AudioResponseSummary;
