import React from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import "./Screens.css";

const Screens = compose(
    connect(({ surveys }, { surveyId }) => ({ survey: surveys[surveyId] })),
    withProps(({ survey = { fragments: [] } }) => ({
        screens: survey.fragments.length,
    }))
)(({ screens }) => (
    <React.Fragment>
        {screens} {screens === 1 ? "Screen" : "Screens"}
    </React.Fragment>
));

export default Screens;
