import React from 'react'
import './PlayButton.css'

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';
import PauseIcon from '@mui/icons-material/PauseCircleFilled';
import StopIcon from '@mui/icons-material/Stop';
import RecordIcon from '@mui/icons-material/FiberManualRecord';
import ReplayIcon from '@mui/icons-material/Autorenew';

import filterDOMProps from 'util/filterDOMProps';

const iconOnState = (icon, text) => (
  <div className="play-button__container">
    <div className="play-button__icon">
      {icon}
    </div>
    <div className="play-button__help-text">
      <Typography variant="button" style={{ color: 'white' }}>{text}</Typography>
    </div>
  </div>
)

const buttonOnState = (state, size) => {
  switch (state) {
    case 'play':
      return iconOnState(<PlayIcon />, "Play Audio");
    case 'pause':
      return iconOnState(<PauseIcon />, "Pause Audio");
    case 'stop':
      return iconOnState(<StopIcon />, "Stop Audio");
    case 'replay':
      return iconOnState(<ReplayIcon />, "Replay Audio");
    case 'record':
      return iconOnState(<RecordIcon />, "Record Audio");
    case 'live':
      return iconOnState(<RecordIcon style={{fill: 'red'}} />, "Live Audio");
    default:
      return iconOnState(<PlayIcon />, "Play Audio");
  }
}

const PlayButton = ({ state, size, children, ...props }) => (
  <IconButton
    className={`playbutton ${state}`}
    aria-label="Play"
    {...filterDOMProps(props)}
  >
    {buttonOnState(state, size)}
    {children}
  </IconButton>
)

export default PlayButton
