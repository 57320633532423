import React from 'react'
import PropTypes from 'prop-types'
import { withState } from 'recompose'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"

const withAnswer = withState('answer', 'selectAnswer', undefined)

/**
 * A question and answer group.
 */
// bsStyles are: "" (grey), "info" (dark blue), "success" (light green), "info" (light blue), "warning" (yellow), "danger" (red)
const Question = ({
  children,
  type,
  name,
  choices,
  answer,
  selectAnswer,
  onAnswer,
  hidden
}) => (
    <div>
      {children}
      <List>
        {type === 'singleChoiceRadio'
          ? choices.map((choice, i) => (
            <ListItem key={choice} onClick={() => {
              if (selectAnswer) selectAnswer(choice)
              if (onAnswer) onAnswer(choice)
            }}>
              <Checkbox
                tabIndex={i}
                checked={answer === choice} disableRipple />
              <ListItemText primary={choice} />
            </ListItem>
          ))
          : null}
      </List>
    </div>
  )

Question.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  type: PropTypes.string,
  choices: PropTypes.array,
  hidden: PropTypes.bool,
  onAnswer: PropTypes.func
}

Question.defaultProps = {
  hidden: false
}

export default withAnswer(Question)
