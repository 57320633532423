import React from "react";
import { connect } from "react-redux";
import {
    MenuItem,
    Card,
    CardContent,
    Button,
    Typography,
} from "@mui/material";
import {
    CardMenu,
    CompletionRate,
    SurveyActivityChart,
    Screens,
    DuplicateSurveyMenuItem,
    EditSurveyMenuItem,
} from "components";
import defaultChartOptions from "util/chartJsOptions";
import mergeBranch from "util/mergeBranch";
import types from "store/types";
import "./SurveyResultCard.css";

const chartOptions = mergeBranch(defaultChartOptions)({
    animation: {
        duration: 0,
    },
    layout: {
        padding: {
            top: 16,
        },
    },
    scales: {
        y: {
            grid: { drawBorder: false },
            ticks: { display: false },
        },
    },
});

const SurveyResultCard = ({
    children,
    archived,
    surveyId,
    index,
    title,
    onPrimary,
    onSecondary,
}) => {
    return (
        <Card
            className={[
                "survey-result-card",
                archived ? "survey-result-card--archived" : "",
            ].join(" ")}
        >
            <CardContent>
                <div className="survey-result-card__title-container">
                    <div className="survey-result-card__title">
                        <Typography align="left" variant="h6" gutterBottom>
                            {title || "Untitled Survey"}
                        </Typography>
                    </div>
                    <div className="survey-result-card__card-menu">
                        <CardMenu menuAnchor={React.createRef()}>
                            <MenuItem
                                onClick={onPrimary.bind(this, surveyId, index)}
                            >
                                View Results
                            </MenuItem>
                            <EditSurveyMenuItem surveyId={surveyId}>
                                Edit
                            </EditSurveyMenuItem>
                            <DuplicateSurveyMenuItem surveyId={surveyId}>
                                Duplicate
                            </DuplicateSurveyMenuItem>
                            <MenuItem
                                onClick={onSecondary.bind(
                                    this,
                                    surveyId,
                                    index
                                )}
                            >
                                Archive
                            </MenuItem>
                        </CardMenu>
                    </div>
                </div>
                <Typography align="left" variant="subtitle2">
                    <Screens surveyId={surveyId} />
                </Typography>
                <div className="survey-result-card__chart">
                    <SurveyActivityChart
                        surveyId={surveyId}
                        height={210}
                        width={220}
                        period="week"
                        length={4}
                        options={chartOptions}
                    />
                </div>
                <Typography variant="caption">
                    <CompletionRate surveyId={surveyId} />
                </Typography>
                {children}
                <div className="survey-result-card__action">
                    <Button
                        size="small"
                        onClick={onPrimary.bind(this, surveyId, index)}
                        className="left"
                        variant="outlined"
                        color="primary"
                    >
                        View Results
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

SurveyResultCard.propTypes = {};

SurveyResultCard.defaultProps = {
    onPrimary() {},
    onSecondary() {},
};

export default connect(
    ({ samplesBySurveyId }, { surveyId }) => ({
        samples: samplesBySurveyId[surveyId],
    }),
    undefined,
    // Unify sampleIds with samples in the state.
    (
        { samples = {}, surveyId },
        { dispatch },
        { audioResponseSamples = [], ...props }
    ) => {
        // TODO: Change this if the audioResponseSamples ever come in with the createdDate, or if we can do this in bulk in the future.
        dispatch({
            type: types.requests.SURVEY,
            payload: { survey: { surveyId } },
        });
        return {
            ...props,
            audioResponseSamples: Object.values(samples),
        };
    }
)(SurveyResultCard);
