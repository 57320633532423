import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { ClipboardLink } from "components";
import {
    compose,
    setDisplayName,
    setPropTypes,
    withProps,
    defaultProps,
    mapProps,
} from "recompose";
import { connect } from "react-redux";
import types from "store/types";
import "./AudioErrorDialog.css";

const SurveyLink = compose(
    connect(
        ({
            surveyTaker: { surveyId },
            CONFIG: { APPLICATION__SURVEY_ENDPOINT },
        }) => ({ surveyId, endpoint: APPLICATION__SURVEY_ENDPOINT })
    ),
    withProps(({ surveyId, endpoint }) => ({
        children: `${endpoint}/${surveyId}`,
    }))
)(ClipboardLink);

const AudioErrorDialog = compose(
    setDisplayName("AudioErrorDialog"),
    setPropTypes({
        error: PropTypes.object,
        type: PropTypes.string,
        onRetry: PropTypes.func,
    }),
    defaultProps({
        open: true,
        fullWidth: true,
    }),
    connect(({ recorder: { error } }) => ({ error })),
    withProps(({ error = {}, type }) => ({ type: type ? type : error.name })),
    withProps(({ dispatch, onRetry }) => ({
        onRetry: () => {
            if (onRetry instanceof Function) onRetry();
            dispatch({
                type: types.requests.RECORDER,
            });
        },
    })),
    mapProps(({ error, type, onRetry, ...props }) => ({
        ...props,
        children: (
            <React.Fragment>
                <DialogTitle>Uh-oh! Voice input needs fixing.</DialogTitle>
                <DialogContent>
                    {(type => {
                        switch (type) {
                            case "NotAllowedError":
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            Please change your browser's
                                            microphone permissions in order to
                                            answer this question.
                                        </Typography>
                                        <img
                                            className="audio-error__permissions-directions"
                                            src="/img/microphone_permissions.gif"
                                            alt="Permissions Change GIF"
                                        />
                                        <Typography variant="body1">
                                            If you already changed permissions,
                                            you may need to refresh the page.
                                        </Typography>
                                    </div>
                                );
                            case "HTTPSError":
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            The browser is unable to access your
                                            microphone unless you are on a
                                            secure endpoint.
                                        </Typography>
                                        <Typography variant="body1">
                                            Make sure you are accessing this
                                            using "https:" at the beginning of
                                            the URL.
                                        </Typography>
                                    </div>
                                );
                            case "BrowserSpecificError":
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            {error.message}
                                        </Typography>
                                        <Typography variant="body1">
                                            Try using the Copy Link button below
                                            and pasting the survey link in your
                                            default mobile web browser (such as Safari) or on a
                                            different device.
                                        </Typography>
                                        <SurveyLink />
                                    </div>
                                );
                            case "iOSUpgradeError":
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            Looks like you're using an older version of iOS that doesn't support microphone access in the browser. Please upgrade your iOS to version 11.0 or greater or switch to a different device.
                                        </Typography>
                                        <SurveyLink />
                                    </div>
                                );
                            case "iPhoneWebViewError":
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            Looks like you're using an 'in-app' browser such as Facebook Messenger. These browsers have limited functions and won't allow access to your microphone.
                                        </Typography>
                                        <Typography variant="body1">
                                            Try using the Copy Link button below
                                            and pasting the survey link in your
                                            default mobile web browser (such as Safari) or on a
                                            different device.
                                        </Typography>
                                        <SurveyLink />
                                    </div>
                                );
                            default:
                                return (
                                    <div className="audio-error__content">
                                        <Typography variant="body1">
                                            This survey contains voice questions
                                            and there is a problem accessing
                                            your audio device. This might be
                                            because you are trying to open this
                                            survey in a non-traditional mobile
                                            browser such as Facebook Messenger,
                                            Slack, or an email client.
                                        </Typography>
                                        <Typography variant="body1">
                                            Try using the Copy Link button below
                                            and pasting the survey link in your
                                            default mobile web browser (such as Safari) or on a
                                            different device.
                                        </Typography>
                                        <SurveyLink />
                                    </div>
                                );
                        }
                    })(type)}
                </DialogContent>
                {type === "NotAllowedError" ? (
                    <DialogActions>
                        <Button onClick={onRetry}>Retry</Button>
                    </DialogActions>
                ) : null}
            </React.Fragment>
        ),
    }))
)(Dialog);

export default AudioErrorDialog;
