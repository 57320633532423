import { lifecycle } from 'recompose';
/**
 * withGlobalEventHandlers adds a layer of global event handlers to a component.
 * Takes care of removing handlers when the component is unmounted.
 * You can create handlers in anb array format, or with a convenient chained format.
 * You can also reload the handlers when props change.
 * Handlers are not reloaded by default.
 *
 * @property on
 * @property handlersShouldReload
 *
 * @param handlers [Array]
 * @param handlersShouldReload [Function]
 *
 * @return HOC
 *
 * withGlobalEventHandlers
 * .on('click', ({ modalRef, closeModal }) => event => {
 *   if (!modalRef.current.contains(event.target)) {
 *     closeModal()
 *   }
 * })(({modalRef, closeModal, isOpen}) =>
 *   <div style={{display: isOpen ? 'block' : 'none'}} ref={modalRef}>
 *     I'm a Modal <button onClick={closeModal} />
 *   </div>)
 */
export default function withGlobalEvents(handlers, handlersShouldReload) {
    const activeHandlers = [];

    function addGlobalEventListeners(props) {
        const inactiveHandlers = handlers.map(([a, b, ...c]) => [
            a,
            b(props),
            ...c
        ]);
        inactiveHandlers.forEach(eventHandlerArgs => {
            global.addEventListener(...eventHandlerArgs);
            activeHandlers.push(eventHandlerArgs);
        });
    }

    function removeGlobalEventListeners() {
        while (activeHandlers.length) {
            const deactiveHandlerArgs = activeHandlers.pop();
            global.removeEventListener(...deactiveHandlerArgs);
        }
    }

    const hoc = lifecycle({
        componentDidMount() {
            addGlobalEventListeners(this.props);
        },
        componentDidUpdate(prevProps) {
            if (
                handlersShouldReload instanceof Function &&
                handlersShouldReload(prevProps, this.props)
            ) {
                removeGlobalEventListeners();
                addGlobalEventListeners(this.props);
            }
        },
        componentWillUnmount() {
            removeGlobalEventListeners();
        }
    });

    hoc.on = (...args) =>
        withGlobalEvents(handlers.concat([args]), handlersShouldReload);
    hoc.handlersShouldReload = func => withGlobalEvents(handlers, func);
    return hoc;
}

withGlobalEvents.on = (...args) => withGlobalEvents([args]);
withGlobalEvents.handlersShouldReload = func =>
    withGlobalEvents([], func);
