import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import './SurveyUnpublishedCard.css';

const SurveyUnpublishedCard = ({ children, surveyId, index, fragments, title, onPrimary, onSecondary }) => (
  <Card className="surveyunpublishedcard">
    <CardContent>
      <Typography align="left" variant="h6" gutterBottom>
        {title || "Untitled Survey"}
      </Typography>
      <Typography align="left" variant="body1" gutterBottom>
        {fragments.length} Screen(s)
      </Typography>
      {children}
    </CardContent>
    <CardActions className="surveyactions">
      <Button size="small" onClick={onPrimary.bind(this, surveyId, index)} className="left">Edit Survey</Button>
      <Button size="small" onClick={onSecondary.bind(this, surveyId, index)} className="right">Delete Survey</Button>
    </CardActions>
  </Card>
);

SurveyUnpublishedCard.propTypes = {
  voiceQuestionPrompts: PropTypes.array.isRequired
}

SurveyUnpublishedCard.defaultProps = {
  voiceQuestionPrompts: [],
  onPrimary(){},
  onSecondary(){},
}

export default SurveyUnpublishedCard;
