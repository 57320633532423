import React from "react";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CheckIcon from "@mui/icons-material/Check";
import "./AudioFragmentFlowChart.css";

const AudioFragmentFlowChart = ({
    autoStart = false,
    earlyStop = true,
    autoStop = true,
    confirmComplete = true,
}) => (
    <React.Fragment>
        {!autoStart ? <MicIcon /> : null}
        {earlyStop ? <StopIcon /> : null}
        {autoStop ? <ScheduleIcon /> : null}
        {confirmComplete ? <CheckIcon /> : <NavigateNextIcon />}
    </React.Fragment>
);

export default AudioFragmentFlowChart;
