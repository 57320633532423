import { once } from "lodash";
import { intoObjectKey } from "redux-composable-reducers";

/**
 * **What are configurations?**
 *  - Constants for the current application.
 *  - Information about external services.
 *    - API Endpoints
 *    - API Tokens
 *    - API Constants
 *  - The current version of the application.
 *  - The current git hash of the application.
 *
 * **What kind of things should the application expect from a configuration?**
 *  - It should be adequate for the application.
 *    - The application should provide a configuration mismatch check.
 *    - The application should know when a configuration is missing.
 *  - It should be as flat as possible.
 *  - It should be baked in on build.
 *  - It should be replacable while the application is running.
 */

export default ({ REQUESTED, SET, UNSET, CLEARED }) =>
    ({ dispatch, getState }) => {
        const init = once(() => {
            dispatch({
                type: SET,
                payload: Object.entries(process.env).reduce(
                    intoObjectKey((s, [key]) => key.replace(/^REACT_APP__?/, ""))(
                        (s, [key, value]) =>
                            typeof value === "string"
                                ? value.replace(/^"|"$/g, "")
                                : value
                    ),
                    {}
                ),
            })
        });
        return (next) => (action) => {
            // Make sure we init the default configurations before anything else gets dispatched.
            init(dispatch);
            switch (action.type) {
                case REQUESTED:
                    next(action);
                    break;
                default:
                    next(action);
            }
        };
    };
