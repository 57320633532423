import "./TextResponsesScatterChart.css";
import { withProps } from "recompose";
import { Line as Chart } from "react-chartjs-2";
import chartOptions from "util/chartJsOptions";
import "./TextResponsesScatterChart.css";
import { flowRight } from "lodash";
import {
    intoObjectKey,
    intoArray,
    pass,
    reduceKey,
    dependency,
    branch,
    initialState,
} from "store/reducers/helpers";
import mergeBranch from "util/mergeBranch";

const TextResponsesScatterChart = withProps(({ response, responses }) => {
    const responseToRows = (_response) => {
        const { data, responseId, fragmentData, fragmentId } = _response;
        return (Array.isArray(data) ? data : [data]).map((value) => {
            value = typeof value === "string" ? parseInt(value) : value;
            const index = fragmentData.rateValues.findIndex((rateValue) =>
                typeof rateValue === "object"
                    ? rateValue.value === value
                    : rateValue === value
            );
            return {
                responseId,
                fragmentId,
                // NOTE: The labels are interpretted as x-axis values if they're numbers (and sometimes if they're dates.)
                label:
                    "" +
                    (typeof fragmentData.rateValues[index] === "object"
                        ? fragmentData.rateValues[index].text
                        : fragmentData.rateValues[index]),
                index,
                value: parseInt(value),
                magnitude: 1,
                highlight: responseId === response.responseId,
            };
        });
    };

    const responseFragmentToRows = (response) => {
        const data = response.fragmentData.rateValues;
        return (Array.isArray(data) ? data : [data]).map((data, index) => ({
            label: "" + (typeof data === "object" ? data.text : data),
            index,
            value: typeof data === "object" ? data.value : data,
            magnitude: 0,
            highlight: false,
        }));
    };

    const pointValueReducer = (STATE) =>
        flowRight(
            intoArray((state, response) => response.index),
            reduceKey((state, response) => response.index),
            intoObjectKey({
                x: (state, response) => response.index,
                y: (state, response) =>
                    STATE.state.magnitudeByValue[response.value],
            })
        )(pass);

    const dataFromRatingsQuestion = flowRight(
        // Normalize the response into some friendlier data.
        branch((state, object) =>
            typeof object === "object" && object.type === "fragment"
                ? (reducer) => (state, fragment) =>
                      responseFragmentToRows(fragment).reduce(reducer, state)
                : (reducer) => (state, response) =>
                      responseToRows(response).reduce(reducer, state)
        ),
        dependency(({ state: { magnitudeByValue } = {} }) => magnitudeByValue)(
            (STATE) =>
                intoObjectKey({
                    labels: intoArray((state, response) => response.index)(
                        (state, response) => response.label
                    ),
                    datasets: flowRight(
                        intoArray(() => 0),
                        reduceKey(() => 0),
                        initialState({
                            yAxisId: "y-axis-1",
                            pointRadius: 4,
                            borderWidth: 4,
                            lineTension: 0.3,
                        }),
                        intoObjectKey({
                            data: pointValueReducer(STATE),
                            pointBackgroundColor: intoArray(
                                (state, response) => response.index
                            )((state, response) =>
                                response.highlight ? "#FFF" : "transparent"
                            ),
                            pointBorderColor: intoArray(
                                (state, response) => response.index
                            )((state, response) =>
                                response.highlight ? "#3F71F7" : "transparent"
                            ),
                        })
                    )(pass),
                })
        ),
        intoObjectKey({
            state: flowRight(
                intoObjectKey({
                    magnitudeByValue: intoObjectKey(
                        (state, response) => response.value
                    )((total = 0, response) => total + response.magnitude),
                    minValue: (min = 0, response) =>
                        Math.min(response.value, min),
                    maxValue: (max = 0, response) =>
                        Math.max(response.value, max),
                })
            )(pass),
        })
    )(pass);

    const data = [
        {
            type: "fragment",
            fragmentData: response.fragmentData,
            fragmentId: response.fragmentId,
        },
        response,
        ...responses,
    ].reduce(dataFromRatingsQuestion, {});

    return {
        data,
        options: mergeBranch(chartOptions)({
            plugins: {
                labels: false,
            },
            scales: {
                y: {
                    ticks: {
                        precision: 0,
                        beginAtZero: true,
                    },
                    type: "linear",
                    display: true,
                    position: "left",
                    id: "y-axis-1",
                    grid: {
                        display: false,
                    },
                    labels: {
                        show: true,
                    },
                },
            },
        }),
    };
})(Chart);

export default TextResponsesScatterChart;
