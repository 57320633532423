// TODO: Filter when this should update.
// TODO: Start writing connect calls with these, but the action payload is the current props.
// This is also how we can "fuse" all the HoCs together and make the world whole again.
// Doing the memoization this way is also better than the default connect because we can return even earlier and have a memoized state tree just for whether it should update.
// reducer => (state, action) => stateDependency(
//     flowRight(
//         reduceKey("responseBySessionIdByFragmentId"),
//         reduceKey(payload("sessionId")),
//         reduceKey(payload("fragmentId"))
//     )(pass)
// )(sessionCompletedReducer(state))(reducer);

export default state => (completed, action) => {
    const {
        payload: { surveyId, sessionId },
    } = action;
    const { surveys = {}, responseBySessionIdByFragmentId = {} } = state;
    return !!((surveys[surveyId] || {}).fragments || []).every(
        ({ fragmentId }) =>
            responseBySessionIdByFragmentId[sessionId] &&
            responseBySessionIdByFragmentId[sessionId][fragmentId]
    );
};