import React from "react";
import { compose, withProps } from "recompose";
import { Checkbox } from "@mui/material";
import { connect } from "react-redux";
import types from "store/types";
import "./DownloadBuilderFileExclusion.css";

const DownloadBuilderDefault = {
    stage: 0,
    selected: {},
    files: [],
    ignore: {},
};

const DownloadBuilderFileExclusion = compose(
    connect(({ downloadBuilderBySurveyId, filesByFilename }, { surveyId }) => ({
        downloadBuilder:
            downloadBuilderBySurveyId[surveyId] || DownloadBuilderDefault,
        filesByFilename,
    })),
    withProps(({ downloadBuilder: { files = [], ignore = {} } }) => ({
        previewFiles: files.map(file => ({
            ...file,
            included: !ignore[file.filename],
        })),
    })),
    withProps(({ previewFiles, filesByFilename = {} }) => ({
        selectedFiles: previewFiles
            .filter(({ included }) => included)
            .map(({ filename }) => filesByFilename[filename])
            .filter(file => file),
    })),
    withProps(({ dispatch, surveyId }) => ({
        setFileSelection: (filename, included) => {
            dispatch({
                type: included
                    ? types.downloadBuilder.SELECTED_FILE
                    : types.downloadBuilder.DESELECTED_FILE,
                payload: {
                    surveyId,
                    filename
                },
            });
        },
    }))
)(({ previewFiles, setFileSelection, children }) => (
    <div className="download-builder-preview__container">
        {previewFiles.map(({ filename, description, included }) => (
            <div
                key={filename}
                className="download-builder-preview__file-container"
                onClick={() => setFileSelection(filename, !included)}
            >
                <div className="download-builder-preview__included">
                    <Checkbox checked={included} />
                </div>
                <div className="download-builder-preview__filename">
                    {filename}
                </div>
                <div className="download-builder-preview__description">
                    {description}
                </div>
            </div>
        ))}
    </div>
));

export default DownloadBuilderFileExclusion;
