const requestStatusReducer = ({
    REQUESTED,
    ACKNOWLEDGED,
    FULFILLED,
    FAILED,
}) => (status = null, action) => {
    switch (action.type) {
        case REQUESTED:
            return "REQUESTED";
        case ACKNOWLEDGED:
        case FULFILLED:
        case FAILED:
            // FAILED and FULFILLED states are sticky and can only be reset with a new request.
            if (status === "FAILED" || status === "FULFILLED") {
                return status;
            }
            switch (action.type) {
                case ACKNOWLEDGED:
                    return "ACKNOWLEDGED";
                case FULFILLED:
                    return "FULFILLED";
                case FAILED:
                    return "FAILED";
                default: // Blame Linter
                    break;
            }
            break;
        default:
            return status;
    }
};

export default requestStatusReducer;
