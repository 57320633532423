import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Snackbar } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
    compose,
    withState,
    withPropsOnChange,
    withProps,
    setDisplayName,
    setPropTypes,
    mapProps,
} from "recompose";
import "./SnackBarDownload.css";

const WhiteCircularProgress = withStyles({
    root: {
        color: "#fff",
    },
})(CircularProgress);

const SnackBarDownloadMessage = ({ children, status, progress }) => (
    <div
        className={`snackbar-download snackbar-download--${status.toLowerCase()}`}
    >
        <div className="snackbar-download__message">{children}</div>
        <div className="snackbar-download__status">
            {// Message
            (() => {
                switch (status) {
                    case "REQUESTED":
                        return <span>Starting Download</span>;
                    case "ACKNOWLEDGED":
                        return <span>Downloading...</span>;
                    case "FULFILLED":
                        return <span>Done!</span>;
                    case "FAILED":
                        return <span>Download Interrupted</span>;
                    default:
                        return null;
                }
            })()}
        </div>
        <div className="snackbar-download__progress">
            {// Circular Progress
            (() => {
                switch (status) {
                    case "REQUESTED":
                    case "ACKNOWLEDGED":
                        // TODO: When the progress is known, update this with the download progress.
                        return (
                            <WhiteCircularProgress
                                variant="indeterminate"
                                size={14}
                                thickness={6}
                            />
                        );
                    case "FULFILLED":
                    case "FAILED":
                    default:
                        return null;
                }
            })()}
        </div>
    </div>
);

export const SnackBarDownload = compose(
    setDisplayName("SnackBarDownload"),
    setPropTypes({
        status: PropTypes.oneOf([
            "REQUESTED",
            "ACKNOWLEDGED",
            "FULFILLED",
            "FAILED",
        ]).isRequired,
        progress: PropTypes.shape({
            lengthComputable: PropTypes.bool,
            loaded: PropTypes.number,
            total: PropTypes.number,
        }),
    }),
    withState("open", "setOpen", props => {
        return (
            props.open ||
            props.status === "REQUESTED" ||
            props.status === "ACKNOWLEDGED"
        );
    }),
    withPropsOnChange(["status"], ({ status, setOpen, autoHideDuration }) => {
        if (status === "REQUESTED") {
            setOpen(true);
        }
        // Let's just roll our own autoHideDuration.
        if (status === "FULFILLED" || status === "FAILED") {
            setTimeout(() => setOpen(false), autoHideDuration);
        }
        return {};
    }),
    withProps(({ status, progress, message }) => ({
        message: (
            <SnackBarDownloadMessage status={status} progress={progress}>
                {message}
            </SnackBarDownloadMessage>
        ),
        className: "snackbar-download__container",
    })),
    mapProps(({ status, progress, setOpen, autoHideDuration, ...props }) => props)
)(Snackbar);

export default SnackBarDownload;
