import React from "react";
import { Loading } from "components";
import FullscreenLayout from "layouts/FullscreenLayout";
export default () => {
  return (
    <FullscreenLayout>
      <Loading />
    </FullscreenLayout>
  );
};
