const surveyJsResponseToTextResponses = response =>
    response.fragmentData.surveyjs.questions.map((question, i) => ({
        ...response,
        fragmentId: null,
        parentFragmentId: response.fragmentId,
        fragmentType: "TEXT_SURVEYJS_SINGLETON",
        fragmentData: question,
        responseType: (question.type || "TEXT").toUpperCase(),
        index: i,
        data: response.data
            ? response.data[question.name] || response.data[`question${i + 1}`]
            : null,
    }));

export default surveyJsResponseToTextResponses;
