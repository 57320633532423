import React from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import moment from "moment";
import "./Footer.css";

/**
 * A footer
 */
const Footer = connect(({ CONFIG }) => ({ version: CONFIG.META__VERSION, env: CONFIG.META__BUILD_TARGET === "production" ? '': CONFIG.META__BUILD_TARGET }))(props => (
    <div className="Footer">
        <div className="footer__app-version">{props.env} {props.version}</div>
        <div className="footer__links">
            <Typography variant="body1" className="footer__link privacy-policy">
                <a
                    href="https://drive.google.com/open?id=1ZRaWLcAJXLkUB_Otp8H1sefuQPIWId3B"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                >
                    {"Privacy Policy"}
                </a>
            </Typography>
            <Typography variant="body1" className="footer__link terms-of-use">
                <a
                    href="https://drive.google.com/open?id=1wjjZq857HnfjPzJ3m8yZWdgN2ConIEG7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                >
                    {"Terms of Use"}
                </a>
            </Typography>
            <Typography variant="body1" className="footer__link branding-policy">
                <a
                    href="https://drive.google.com/open?id=1OYkqh9mqisqvvYRMaz_0FjX-0KIvBKLO"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                >
                    {"Branding Policy"}
                </a>
            </Typography>
            <Typography variant="body1" className="footer__link contact">
                <a
                    href="https://www.surveylex.com/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                >
                    {"Contact"}
                </a>
            </Typography>
        </div>
        <div className="footer__social">
            <a
                href="https://twitter.com/survey_lex"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg
                    aria-labelledby="simpleicons-twitter-icon"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title id="simpleicons-twitter-icon">Twitter icon</title>
                    <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                </svg>
            </a>
            <a
                href="https://www.facebook.com/Surveylex-309266023234671/?modal=admin_todo_tour"
                target="_blank"
                rel="noopener noreferrer"
            >
                <svg
                    aria-labelledby="simpleicons-facebook-icon"
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title id="simpleicons-facebook-icon">Facebook icon</title>
                    <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                </svg>
            </a>
        </div>
        <div className="footer__copyright">
            <Typography variant="body1" className="footer__copyright--text">
                ©&nbsp;Copyright&nbsp;
                {moment().year()}
                &nbsp;SurveyLex - All&nbsp;Rights&nbsp;Reserved
            </Typography>
        </div>
    </div>
));

export default Footer;
