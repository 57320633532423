import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import UserAppLayout from "layouts/UserAppLayout";
import "./CreateSurvey.css";
import {
    EditorHeader,
    EditorAddScreen,
    EditorPane,
    EditorFragmentInfo,
    EditorFragmentConsent,
    EditorFragmentAudioStandard,
    EditorFragmentTextSurveyJS,
    EditorFragmentTemplate,
    DispatchGlobalEvent,
    NamedDivider,
} from "components";

import { compose, withProps, withHandlers } from "recompose";
import { connect } from "react-redux";
import types from "store/types";
import { CircularProgress } from "@mui/material";
import withPromise from "enhancers/withPromise";

const ComingSoon = () => <div>Coming Soon!</div>;
const fragmentTypeToComponent = {
    CONSENT: EditorFragmentConsent,
    INFO: EditorFragmentInfo,
    AUDIO_STANDARD: EditorFragmentAudioStandard,
    TEMPLATE: EditorFragmentTemplate,
    VIDEO_STANDARD: ComingSoon,
    TRANSITION_INSTRUCTOR_BOSTON_NAMING_TEST: ComingSoon,
    AUDIO_BOSTON_NAMING_TEST: ComingSoon,
    TEXT_SURVEYJS: EditorFragmentTextSurveyJS,
    UPLOAD: ComingSoon,
    DEANONYMIZATION: ComingSoon,
};

const RequestSurveyCreator = compose(
    connect(),
    withPromise("loaded", ({ dispatch }) =>
        dispatch({
            type: types.requests.SURVEY_CREATOR,
        }).then(() => true)
    )
)((loaded) => (
    <div className="create-survey__loader">
        {loaded ? null : <CircularProgress />}
    </div>
));

const publishSurveyCreatorSurvey = (dispatch, getState) => {
    const surveyId = getState().surveyCreator.survey.surveyId;
    // NOTE: We're setting the published state manually, here.
    dispatch({
        type: types.surveyCreator.UPDATED_SURVEY,
        payload: {
            surveyId,
            published: true,
        },
    });
    dispatch({
        type: types.surveyCreator.SAVED,
    }).then(() => {
        dispatch({
            type: types.requests.NAVIGATION,
            payload: { view: "view-survey", params: { surveyId } },
        });
    });
};

const surveyIsSavable = (survey) => true; /* !!(survey && survey.title); */

const surveyIsPublishable = (survey) =>
    surveyIsSavable(survey) &&
    !!(survey.fragments && survey.fragments.length > 0);

const SaveAndPublishButton = connect(
    ({ surveyCreator: { survey } }) => ({
        disabled: !surveyIsPublishable(survey),
    }),
    (dispatch) => ({
        onClick: () => {
            dispatch(publishSurveyCreatorSurvey);
        },
    })
)(Button);

const SaveButton = connect(
    ({ surveyCreator: { survey } }) => ({ disabled: !surveyIsSavable(survey) }),
    (dispatch) => ({
        onClick: () => {
            dispatch({
                type: types.surveyCreator.SAVED,
            }).then(() => {
                dispatch({
                    type: types.requests.NAVIGATION,
                    payload: { view: "home" },
                });
            });
        },
    })
)(Button);

const CreateSurveyFragment = connect(
    (
        {
            surveyCreator: {
                survey: { fragments = [] },
            },
        },
        { fragmentIndex }
    ) => {
        const { type } = fragments[fragmentIndex] || {};
        return {
            type,
        };
    }
)(({ type, fragmentIndex, length }) => {
    // TODO: Update this so that we don't need to re-render everything with each additional screen. (Perhaps let the EditorPane figure out isFirst and isLast on it's own.)
    const Component = fragmentTypeToComponent[type];
    return (
        <div className="editor-pane__container">
            {Component ? (
                <Component
                    fragmentIndex={fragmentIndex}
                    isFirst={fragmentIndex === 0}
                    isLast={fragmentIndex === length - 1}
                />
            ) : (
                <EditorPane
                    fragmentIndex={fragmentIndex}
                    isFirst={fragmentIndex === 0}
                    isLast={fragmentIndex === length - 1}
                >
                    <EditorAddScreen />
                </EditorPane>
            )}
        </div>
    );
});

const CreateSurveyFragments = connect(
    ({
        surveyCreator: {
            survey: { fragments = [] },
        },
    }) => ({
        length: fragments.length,
        fragments,
    }),
    {
        // Update when the length has changed or the fragment id at a fragment index has changed.
        areStatePropsEqual: (
            { fragments: _fragments = [] },
            { fragments = [] }
        ) =>
            fragments.length === _fragments.length &&
            fragments.every(({ fragmentId } = [], fragmentIndex) => {
                const { fragmentId: _fragmentId } =
                    _fragments[fragmentIndex] || {};
                return _fragmentId === fragmentId;
            }),
    }
)(({ fragments, length }) => (
    <div className="create-survey__fragments">
        {fragments.map(({ fragmentId }, fragmentIndex) => (
            <CreateSurveyFragment
                key={fragmentId}
                fragmentIndex={fragmentIndex}
                length={length}
            />
        ))}
    </div>
));

const SurveyCreatorSaveButtons = compose(
    connect(
        ({
            surveyCreator: {
                survey: { published },
            },
        }) => ({ published })
    )
)(({ published }) =>
    published ? (
        <SaveButton
            color="primary"
            variant="contained"
            className="create-survey__button create-survey__save-button"
        >
            Save And Exit
        </SaveButton>
    ) : (
        <React.Fragment>
            <SaveButton className="create-survey__button create-survey__save-button">
                Save For Later
            </SaveButton>
            <SaveAndPublishButton
                className="create-survey__button create-survey__publish-button"
                color="primary"
                variant="contained"
            >
                Publish
            </SaveAndPublishButton>
        </React.Fragment>
    )
);

const EditorSetDefaultSize = compose(
    connect(
        ({
            surveyCreator: {
                survey: { surveyId, interfaceSize },
            },
        }) => ({
            surveyId,
            interfaceSize,
        }),
        (dispatch) => ({
            onSelect: (selection) =>
                dispatch({
                    type: types.surveyCreator.UPDATED_SURVEY,
                    payload: { interfaceSize: selection },
                }),
        })
    ),
    withProps({ sizes: ["Standard", "Large", "Extra Large"] }),
    withProps(({ interfaceSize = "Standard", sizes }) => ({
        selected: interfaceSize,
        options: sizes,
    })),
    withHandlers({
        onSelect:
            ({ options, onSelect }) =>
            (selection) => {
                if (options.includes(selection)) {
                    onSelect(selection);
                    return { selected: selection };
                }
            },
    })
)(({ options, selected, onSelect }) => (
    <div className="editor-set-default-size">
        <Typography variant="overline">Default Interface Size</Typography>
        <ButtonGroup
            variant="outlined"
            color="primary"
            aria-label="Default interface size."
            className="multi-button-selection"
        >
            {options.map((option) => (
                <Button
                    key={option}
                    variant={option === selected ? "contained" : undefined}
                    onClick={() => onSelect(option)}
                    className={
                        option === selected
                            ? [
                                  "multi-button-selection__choice",
                                  "multi-button-selection__choice--selected",
                              ].join(" ")
                            : "multi-button-selection__choice"
                    }
                >
                    {option}
                </Button>
            ))}
        </ButtonGroup>
    </div>
));

const CreateSurvey = () => (
    <UserAppLayout className="create-survey__container">
        {/* <RequestSurveyCreator /> */}
        <div className="create-survey__inner-container">
            <DispatchGlobalEvent event="keydown" />
            <div className="create-survey__actions">
                <SurveyCreatorSaveButtons />
            </div>
            <div className="create-survey__main-content">
                <EditorHeader />
                <CreateSurveyFragments />
            </div>
            <div className="create-survey__sidebar-content editor-add-screen">
                <div className="editor-add-screen-header">
                    <NamedDivider location="center">
                        <Typography variant="h6">Add Screen</Typography>
                    </NamedDivider>
                </div>
                <EditorAddScreen />
                <EditorSetDefaultSize />
            </div>
        </div>
    </UserAppLayout>
);

export default CreateSurvey;
