import {
    useLocation,
    useNavigate,
    useParams,
    useMatch,
} from "react-router-dom";
import { connect } from "react-redux";
import types from "store/types";
import routes from "routes";

function DispatchNavigation({ dispatch }) {
    const location = useLocation();
    const route = routes.find(({ match }) => match.test(location.pathname)) || {};
    const match = useMatch({
        path: route.path,
        exact: true,
        strict: false,
    });
    setTimeout(() => dispatch({
        type: types.navigation.ARRIVED,
        payload: {
            route,
            location,
            match,
            params: match ? match.params : {}
        },
    }));
    return null;
}

export default connect()(DispatchNavigation);
