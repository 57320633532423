import React from 'react'
import PropTypes from 'prop-types'
import './SurveyLexNavLink.css'

/**
 * A SurveyLex link in the navbar
 */
const SurveyLexNavLink = ({ active, text, onClick }) => {
  return (
    <div className={`slx-nav-link ${active ? 'active' : ''}`}>
      <button onClick={onClick}>{text}</button>
      <svg
        width="124px"
        height="11px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <polygon id="triangle-bottom" points="0 0 124 0 62 10.6484375"></polygon>
      </svg>
    </div>
  )
}

SurveyLexNavLink.propType = {
  active: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

SurveyLexNavLink.defaultProps = {
  active: false,
  text: 'Nav Link',
  onClick: () => {}
}

export default SurveyLexNavLink
