import React from "react";
import { connect } from "react-redux";
import { SnackBarDownload } from "components";
import "./SnackBarContainer.css";

const SnackBarContainer = props => {
    const { downloads } = props;
    const downloadIds = Object.values(downloads);

    return (
        downloadIds
            .filter(({ status }) => status)
            .map(({ downloadId, status, progress }) => (
                <SnackBarDownload
                    key={downloadId}
                    status={status}
                    progress={progress}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    autoHideDuration={2000}
                />
            ))
    );
};

const mapStateToProps = state => {
    return {
        downloads: state.downloadRequestsByDownloadId,
    };
};

const ConnectedSnackBarContainer = connect(mapStateToProps)(SnackBarContainer);
export default ConnectedSnackBarContainer;
