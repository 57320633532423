import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

export default () => {
    return (
        <React.Fragment>
            <CssBaseline />
        </React.Fragment>
    );
};
