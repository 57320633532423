import axios from 'axios';

const {
    REACT_APP__NLX__API_ENDPOINT__OBJECT: OBJECT_ENDPOINT
} = process.env;

export default class FileUploadService {
    constructor() {
        this.baseApiUrl = OBJECT_ENDPOINT;
    }
    // Upload Image
    uploadImageToUser = (blob, token) => {
        const url = this.baseApiUrl + "/images";
        return axios.post(
            url,
            blob,
            { headers: { Authorization: `Bearer ${token}` }, }
        );
    };

    // Delete Image
    deleteImageFromUser = (imageUrl, userId, token) => {
        const url = this.baseApiUrl + "/images/delete";
        return axios.delete(
            url,
            {
                headers: { Authorization: `Bearer ${token}`, },
                data: {
                    userId: userId,
                    imageUrl: imageUrl
                }
            },
        );
    };
}
