import React from "react";
import { sanitize } from "dompurify";
import { Survey, Model } from "survey-react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import types from "store/types";
import showdown from "showdown";
import showdownOptions from "util/showdownOptions";
import rewrite from "util/customMarkdownHtmlRewrite";
import "./SurveyFragmentTextSurveyJS.css";

// Disable block quotes.
showdown.subParser("blockQuotes", function (text, options, globals) {
    text = globals.converter._dispatch(
        "blockQuotes.before",
        text,
        options,
        globals
    );
    text = globals.converter._dispatch(
        "blockQuotes.after",
        text,
        options,
        globals
    );
    return text;
});

const SurveyFragmentTextSurveyJS = compose(
    connect(),
    withProps(() => ({
        surveyRef: React.createRef(),
    }))
)(
    ({
        fragment,
        dispatch,
        surveyRef,
        surveyjs: _surveyjs = {},
        isLast,
        onComplete,
    }) => {
        // These configs and model options are SurveyJS settings for customization of the display
        const surveyjs = { ..._surveyjs };
        // surveyjs.questionTitleTemplate = "{title}";
        surveyjs.title = "";
        surveyjs.requiredText = "";
        surveyjs.showCompletedPage = false;

        const model = new Model(surveyjs);
        model.onUpdateQuestionCssClasses.add((survey, options) => {
            const classes = options.cssClasses;

            classes.root = "sq-root";
            classes.title = "sq-title";
            classes.item = "sq-item";
            classes.label = "sq-label";

            const classesByType = {
                checkbox: {
                    root: "sq-root-cb",
                },
                radiogroup: {
                    root: "sq-root-radiogroup",
                },
                rating: {
                    root: "sq-root-rating",
                },
                matrix: {
                    root: "sq-root-matrix",
                },
            };

            Object.entries(
                classesByType[options.question.getType()] || {}
            ).reduce((classes, [element, className]) => {
                classes[element] += Array.isArray(className)
                    ? className.join(" ")
                    : ` ${className}`;
                return classes;
            }, classes);
        });

        //Create showdown mardown converter
        var converter = new showdown.Converter({
            ...showdownOptions,
            headerLevelStart: 2,
        });
        model.onTextMarkdown.add(function (survey, options) {
            //convert the mardown text to html
            var str = sanitize(converter.makeHtml(options.text));
            //remove root paragraphs <p></p>
            str = str.substring(3);
            str = str.substring(0, str.length - 4);
            // enable custom markdown embeds
            str = rewrite(str);
            //set html
            options.html = str;
        });

        const ref = surveyRef.current;
        if (ref !== null) {
            ref.childNodes[0].onsubmit = (e) => e.preventDefault();
        }
        return (
            <div className="surveyContainer">
                <div className="surveyjs">
                    <Survey
                        ref={surveyRef}
                        model={model}
                        completeText={isLast ? "Complete" : "Continue"}
                        onComplete={(surveyjs, callbacks) => {
                            dispatch({
                                type: types.responses.COMPLETED,
                                payload: {
                                    fragment,
                                    submission: surveyjs.data,
                                },
                            });
                            onComplete();
                        }}
                        onSubmit={(e) => e.preventDefault()}
                    />
                </div>
            </div>
        );
    }
);

export default SurveyFragmentTextSurveyJS;
