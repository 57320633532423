import types from "store/types";
import Auth0Lock from "auth0-lock";
import auth0Options from "util/auth0Options";
import mergeBranch from "util/mergeBranch";
import { combine, promote } from "redux-intervention";

/**
 * @function isAuthenticated
 * @param {object} state
 * @returns {boolean}
 */
const isAuthenticated = ({
    user: { session: { expiresAt, token } = {}, profile: { email } = {} } = {},
}) => new Date() < expiresAt && token && email;

const clearAuthenticationFromLocalStorage = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user_profile");
};

const setAuth0LocalStorage = ({
    user: {
        session: { accessToken, token, expiresAt } = {},
        profile = {},
    } = {},
}) => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("id_token", token);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("user_profile", JSON.stringify(profile));
};

const retrieveAuthenticationFromLocalStorage = (dispatch) => {
    try {
        const expiresAt = new Date(localStorage.getItem("expires_at"));
        const accessToken = localStorage.getItem("access_token");
        const token = localStorage.getItem("id_token");
        const profile = {
            name: "SurveyLex User",
            ...JSON.parse(localStorage.getItem("user_profile")),
        };
        if (!profile.email) return null;
        if (!token) return null;
        if (!(new Date() < expiresAt)) return null;
        return dispatch({
            type: types.user.AUTHENTICATED,
            payload: {
                userId: profile.uuid,
                session: {
                    token,
                    accessToken,
                    expiresAt,
                },
                profile,
            },
        });
    } catch (e) {
        return null;
    }
};

const authenticateWithAuth0 = async (dispatch, getState) => {
    const {
        navigation: { location: { hash } = {} },
        view,
    } = getState();
    if (
        !(
            view === "callback" &&
            typeof hash === "string" &&
            /access_token|id_token|error/.test(hash)
        ) ||
        isAuthenticated(getState())
    )
        return;

    await dispatch({ type: types.requests.AUTH0LOCK });
    const { auth0lock } = getState();

    const {
        idToken: token,
        accessToken,
        idTokenPayload: { exp, iat, email, name },
        tokenType,
    } = await new Promise((resolve, reject) => {
        auth0lock.resumeAuth(hash, (error, authResult) => {
            if (error) return reject(error);
            resolve(authResult);
        });
    });

    return dispatch({
        type: types.user.AUTHENTICATED,
        payload: {
            lastLogin: new Date(iat * 1000),
            session: {
                token,
                accessToken,
                expiresAt: new Date(exp * 1000),
                tokenType,
            },
            profile: {
                email,
                name,
            },
        },
    });
};

// Creating the lock automatically parses the window.location.hash.
const createAuth0Lock = (dispatch, getState) => {
    const {
        CONFIG: { AUTH0__CALLBACKURL, AUTH0__DOMAIN, AUTH0__CLIENTID },
        auth0lock,
        entryQuery: { return_to },
        navigation: {
            location: { pathname },
        },
        view,
    } = getState();

    if (auth0lock) return null;
    if (!view) return null;

    const options = mergeBranch(auth0Options)({
        auth: {
            redirectUrl:
                AUTH0__CALLBACKURL +
                // Set a return_to query.
                // If we're on the login or callback page, pass the route along.
                (view === "login" || view === "callback"
                    ? return_to
                        ? `?return_to=${return_to}`
                        : ""
                    : pathname // If we're on another page, pass the page along.
                    ? `?return_to=${pathname}`
                    : ""),
        },
    });

    return dispatch({
        type: types.auth.CREATED_LOCK,
        payload: new Auth0Lock(AUTH0__CLIENTID, AUTH0__DOMAIN, options),
    });
};

const redirectFromCallbackViewWhenAuthenticated = promote(
    () => async (dispatch, getState) => {
        if (getState().view !== "callback") return;
        await dispatch({ type: types.requests.AUTHENTICATION });
        const state = getState();
        const {
            entryQuery: { return_to },
        } = state;
        if (isAuthenticated(state)) {
            return dispatch({
                type: types.requests.NAVIGATION,
                payload: return_to
                    ? {
                          location: return_to,
                      }
                    : { view: "home" },
            });
        } else {
            return dispatch({
                type: types.requests.NAVIGATION,
                payload: {
                    view: "login",
                },
            });
        }
    }
);

const requestAuthenticationFromLoginViewWhenUnauthorized = promote(
    () => (dispatch, getState) => {
        if (getState().view !== "login") return;
        const state = getState();
        if (!isAuthenticated(state)) {
            return dispatch({ type: types.requests.AUTHENTICATION });
        }
    }
);

const redirectFromLoginViewWhenAuthorized = promote(
    () => async (dispatch, getState) => {
        if (getState().view !== "login") return;
        await dispatch({ type: types.requests.AUTHENTICATION });
        const state = getState();
        // If you are logged in and on the login page, redirect to home.
        if (isAuthenticated(state)) {
            return dispatch({
                type: types.requests.NAVIGATION,
                payload: state.entryQuery.return_to
                    ? {
                          location: state.entryQuery.return_to,
                      }
                    : { view: "home" },
            });
        }
    }
);

const redirectUnauthorizedRoutes = promote(() => async (dispatch, getState) => {
    const { navigation } = getState();
    // If it's a public page, pass.
    if (navigation.route.isPublic) return;
    await dispatch({ type: types.requests.AUTHENTICATION });

    // If you aren't logged in on a non-public page, redirect.
    if (!isAuthenticated(getState())) {
        const {
            CONFIG: { APPLICATION__SPLASH_REDIRECT },
            navigation: {
                location: { pathname },
            },
        } = getState();
        return dispatch({
            type: types.requests.NAVIGATION,
            payload: {
                location: APPLICATION__SPLASH_REDIRECT,
                query: { return_to: pathname },
            },
        });
    }
});

const auth0AuthAndRouting = combine(
    [
        // Hide or show the login lock.
        promote(() => async (dispatch, getState) => {
            await dispatch({ type: types.requests.AUTH0LOCK });
            const { auth0lock } = getState();
            if (auth0lock)
                auth0lock[isAuthenticated(getState()) ? "hide" : "show"]();
        }),
        types.requests.AUTHENTICATION,
        types.user.AUTHENTICATED,
        types.user.EXITED,
    ],
    {
        // Initializing the Auth0 Lock
        [types.requests.AUTH0LOCK]: promote(
            () => async (dispatch, getState) => {
                // Note: AUTH0LOCK needs to wait for navigation to resolve to navigate to the right location.
                await dispatch(createAuth0Lock);
            }
        ),
        [types.requests.AUTHENTICATION]:
            ({ dispatch, getState }) =>
            (next) =>
            async (action) => {
                await dispatch(authenticateWithAuth0);
                return next(action);
            },
    },
    [
        redirectFromCallbackViewWhenAuthenticated,
        types.navigation.ARRIVED,
        types.user.AUTHENTICATED,
    ]
);

// LocalStorage
export const localStorageAuthCache = combine(
    [
        ({ dispatch, getState }) =>
            (next) =>
            async (action) => {
                if (isAuthenticated(getState())) return next(action);
                await dispatch(retrieveAuthenticationFromLocalStorage);
                return next(action);
            },
        types.requests.AUTHENTICATION,
        types.application.INITIALIZED,
    ],
    {
        [types.user.EXITED]:
            ({ dispatch }) =>
            (next) =>
            async (action) => {
                clearAuthenticationFromLocalStorage();
                dispatch({ type: types.auth.FLUSHED });
                return next(action);
            },
        [types.user.AUTHENTICATED]:
            ({ dispatch, getState }) =>
            (next) =>
            (action) => {
                next(action);
                if (isAuthenticated(getState())) {
                    setAuth0LocalStorage(getState());
                    return dispatch({ type: types.auth.SET });
                }
            },
    }
);

export const authRouting = combine(
    // Enforce Public vs. Authorized Routing
    [redirectUnauthorizedRoutes, types.navigation.ARRIVED, types.user.EXITED],
    [
        redirectFromLoginViewWhenAuthorized,
        types.navigation.ARRIVED,
        types.user.AUTHENTICATED,
        types.user.EXITED,
    ],
    [
        requestAuthenticationFromLoginViewWhenUnauthorized,
        types.navigation.ARRIVED,
        types.user.AUTHENTICATED,
        types.user.EXITED,
    ]
);

export default combine(auth0AuthAndRouting, localStorageAuthCache, authRouting);
