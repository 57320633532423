import React from "react";
// Create this as a component.
import { compose } from "recompose";
import { connect } from "react-redux";
import { CreateSurvey } from "views";
import { withUserSurveys } from "enhancers";
//import UserAppLayout from "layouts/UserAppLayout";

const EditSurvey = ({ surveyId, surveysById, ...props }) => (
    <CreateSurvey
        surveyId={surveyId}
        survey={surveysById[surveyId]}
        editing={true}
        {...props}
    />
);

export default compose(
    withUserSurveys,
    connect(
        ({
            navigation: { match: { params: { surveyId } = {} } = {} } = {},
        }) => ({ surveyId })
    )
)(EditSurvey);
