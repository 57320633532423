// Leading debounce that always returns the last value.
export default (func, timeout, options) => {
    let value, timestamp = 0;
    return (...args) => {
        if (timestamp + timeout < Date.now()) {
            timestamp = Date.now();
            value = func(...args);
        }
        return value
    };
}