const surveyJsFragmentToSingletons = fragment =>
    fragment.data.surveyjs.questions.map((question, i) => ({
        ...fragment,
        fragmentId: null,
        parentFragmentId: fragment.fragmentId,
        type: "TEXT_SURVEYJS_SINGLETON",
        index: i,
        data: question,
    }));

const getSurveyFragmentMarkdown = fragment => {
    const { type, data } = fragment;
    switch (type) {
        case "CONSENT":
            return [data.title, data.prompt, data.consentText];
        case "INFO":
            return [data.title, data.content];
        case "TEST_SURVEYJS":
            return [].concat(
                surveyJsFragmentToSingletons(fragment).map(getSurveyFragmentMarkdown)
            );
        case "TEST_SURVEYJS_SINGLETON":
            return [data.name];
        case "AUDIO_STANDARD":
            return [data.prompt];
        default:
            return [];
    }
};

export default getSurveyFragmentMarkdown;