import React from "react";
import PropTypes from "prop-types";

// It might be wise to use an <img /> replacement like https://github.com/mbrevda/react-image.
// For logos we generally want to give equal visual weight. https://medium.com/@lukejones/optical-adjustment-b55492a1165c
// TODO: That means we need to find the actual height and width and make small, medium, and large have equal volume --
//    while providing a wrapper that ensures stable height and width for the rest of our application.
// This improvement might do well as a toggleable style on an improved image component.

/**
 * A logo image.
 */
const Logo = ({
    className = "",
    onClick,
    source,
    companyName,
    size,
    styles = {},
}) => (
    <div
        onClick={onClick}
        style={styles.container}
        className={["logo", className].join(" ")}
    >
            {size === "auto" ?
                <div
                    style={{
                        backgroundImage: source ? "url(" + source + ")" : undefined,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        height: "100%",
                        width: "100%",
                    }}
                /> :
                <img alt={companyName ? `${companyName} Logo` : "Logo"} src={source} />
            }
    </div>
);

Logo.propTypes = {
    source: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
};

Logo.defaultProps = {
    size: "small",
    onClick() {},
};

Logo.Sizes = {
    xsmall: 35,
    small: 50,
    medium: 75,
    large: 120,
};

export default Logo;
