
const mergeBranch = tree => (branch, clip = value => value === null) => {
    // If the tree is not a tree, return the branch
    if (!(tree instanceof Object)) return branch;
    // If it's a leaf, return the leaf.
    if (!(branch instanceof Object)) return branch;
    // If it's a function, and we can't tell the difference above.
    if (branch instanceof Function) return branch;
    // Merge the branch.
    return Object.entries(branch).reduce((reduced, [key, value]) => {
        // If the value is a clip, just remove.
        if (clip(value)) {
            if (Array.isArray(reduced)) {
                reduced.splice(key, 1);
            } else {
                delete reduced[key];
            }
        } else {
            if (Array.isArray(reduced)) {
                reduced.splice(key, 1, mergeBranch(reduced[key])(value));
            } else {
                reduced[key] = mergeBranch(reduced[key])(value);
            }
        }
        return reduced;
    }, Array.isArray(tree) ? [...tree] : { ...tree });
};

export default mergeBranch;