import types from "store/types";
import { combine } from "redux-intervention";
import defaultDownloadNameFromSurvey from "util/defaultDownloadNameFromSurvey";

const initializeDownloadBuilder = ({ dispatch, getState }) => (next) => (
    action
) => {
    const { payload: { surveyId } = {} } = action;
    const {
        downloadBuilderBySurveyId: { [surveyId]: downloadBuilder },
    } = getState();
    next(action);
    // NOTE: If the downloadBuilder already exists in the state, don't attempt to initialize it.
    if (downloadBuilder instanceof Object) return;

    const {
        surveys: { [surveyId]: survey },
    } = getState();

    dispatch({
        type: types.downloadBuilder.SET_NAME,
        payload: {
            surveyId,
            downloadName: defaultDownloadNameFromSurvey(survey),
        },
    });

    dispatch({
        type: types.downloadBuilder.UPDATED_SELECTION,
        payload: {
            surveyId,
            selection: {
                "text-summary": {
                    format: "csv",
                    name: "text-summary",
                    selected: true,
                },
                "audio-samples": {
                    format: "wav",
                    name: "audio-samples",
                    selected: true,
                },
            },
        },
    });
};

export default combine({
    [types.downloadBuilder.INITIALIZED]: initializeDownloadBuilder,
    // [types.downloadBuilder.UPDATED_SELECTION]: setFilesFromSelection,
});
