export default {
    title: {
        display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
        mode: "index",
        intersect: false,
    },
    legend: {
        display: false,
    },
    layout: {
        padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
        },
    },
    elements: {
        pointStyle: "circle",
    },
    plugins: {
        // https://github.com/emn178/chartjs-plugin-labels
        labels: {
            // Don't render labels in the chart by default.
            render: () => "",
        },
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            ticks: {
                beginAtZero: true,
                padding: 8,
                fontColor: "#999",
                autoSkip: true,
                autoSkipPadding: 20,
                maxRotation: 0,
            },
            grid: {
                drawTicks: false,
                drawOnChartArea: false,
                color: "#ccc",
                lineWidth: 2,
            },
        },
        y: {
            ticks: {
                beginAtZero: true,
                padding: 8,
                fontColor: "#999",
                autoSkip: true,
                //mirror: true
            },
            grid: {
                drawTicks: false,
                drawOnChartArea: false,
                color: "#ccc",
                lineWidth: 2,
            },
        },
    },
};
