import authLogo from "assets/ico-surveylex-blue.svg";
export default {
    auth: {
        // redirectUrl: CALLBACKURL,
        responseType: "token id_token",
        params: {
            scope: "openid email profile",
        },
    },
    rememberLastLogin: false,
    theme: {
        logo: authLogo,
    },
    languageDictionary: {
        title: "SurveyLex",
    },
};
