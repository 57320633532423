import React from "react";
import { connect } from "react-redux";
import { compose, withProps, withHandlers } from "recompose";
import { Showdown } from "components";
import LinearProgress from "@mui/material/LinearProgress";
import { once } from "lodash";
import retryWithBackoff from "util/retryWithBackoff";
import types from "store/types";
import axios from "axios";

import "./SurveyFragmentBranch.css";

const DeterminateLinearProgress = ({ remaining = 0, total = 0 }) => (
    <LinearProgress
        variant="determinate"
        value={total === 0 ? 0 : (remaining / total) * 100}
    />
);

// We can request a state summary from the server to gaurantee the upload was successful.
// const requestSurveyCompletionStatus = (dispatch) =>
//     dispatch({
//         type: types.requests.SURVEY_COMPLETION,
//         payload: true,
//     });

/**
 * TODO: Improve the statuses for responses.
 * Some models for consideration.
 *
 * deliveryStatus: 'CREATED', 'DELIVERED', 'DELIVERY_ACKNOWLEDGED'
 * availability: 'LOCAL', 'SURVEYLEX', 'http://endpoint'
 *  We would look for availability of 'SURVEYLEX' to know it's been uploaded.
 * actionDeliveryStatus: 'SENDING', 'CONFIRMING_DELIVERY', 'DONE'
 *
 */

// TODO: Fill in the redirect with information from the current session and state where applicable. (i.e. rewrite with constants)
const redirectSurvey = (location) => (dispatch, getState) => {
    const {
        surveyTaker: {
            session: { metadata: { query = {} } = {} },
        },
    } = getState();
    return dispatch({
        type: types.requests.NAVIGATION,
        payload: { location, query: { ...query } },
    });
};

const resolveCondition_Remote = (condition) => (dispatch) =>
    retryWithBackoff(
        () =>
            dispatch((dispatch, getState) => {
                const {
                    surveyTaker: {
                        session: { sessionId, metadata: { query = {} } = {} },
                    },
                } = getState();
                return axios.get(condition.location, {
                    params: { sessionId, ...query },
                });
            }),
        2 * 1000,
        20 * 1000
    ).then((response) => {
        return response.data.pass;
    });

const SurveyFragmentBranch = compose(
    connect(),
    withHandlers({
        onBranch: ({ dispatch, redirect }) => () =>
            dispatch(redirectSurvey(redirect)),
        onContinue: ({ dispatch, onComplete, fragment }) => (...args) => {
            dispatch({
                type: types.responses.COMPLETED,
                payload: {
                    fragment,
                    submission: {},
                },
            });
            onComplete(...args);
        },
        resolveCondition: ({ condition = {}, dispatch }) => {
            const resolveRemoteCondition = once(() =>
                dispatch(resolveCondition_Remote(condition))
            );
            return () => {
                switch (condition.type) {
                    case "REMOTE":
                        return resolveRemoteCondition();
                    default:
                        return Promise.resolve(false);
                }
            };
        },
    }),
    connect(({ surveyTaker: { uploading = [] } }) => ({
        remaining: uploading.length,
        isReady: uploading.length === 0,
    })),
    withProps(({ isReady, resolveCondition, onContinue, onBranch }) => {
        if (isReady) {
            resolveCondition().then((pass) => {
                if (pass) return onContinue();
                return onBranch();
            });
        }
        return {};
    })
)(({ content = `Please wait...`, remaining, fragmentIndex }) => {
    return (
        <div className="survey-screen survey-fragment-branch__container">
            <div className="survey-fragment-branch__inner-container">
                <Showdown className="ssurvey-fragment-branch__content">
                    {content}
                </Showdown>
                {remaining > 0 ? (
                    <DeterminateLinearProgress
                        total={fragmentIndex}
                        remaining={remaining}
                    />
                ) : (
                    <LinearProgress variant="indeterminate" />
                )}
            </div>
        </div>
    );
});

export default SurveyFragmentBranch;
