import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import withMobileDialog from "enhancers/withMobileDialog";
import { mapProps, compose } from "recompose";

const ConfirmPromptDialog = compose(
    // Deprecated Props
    mapProps(
        ({
            handleConfirm,
            handleCancel,
            firstButtonText,
            secondButtonText,
            ...props
        }) => ({
            cancelText: firstButtonText,
            confirmText: secondButtonText,
            onDismiss: handleCancel,
            onCancel: handleCancel,
            onConfirm: handleConfirm,
            ...props,
        })
    )
)(
    ({
        closeModal,
        header,
        text,
        cancelText,
        confirmText,
        onDismiss,
        onCancel,
        onConfirm,
        ...dialogProps
    }) => (
        <Dialog
            onClose={() => {
                if (onDismiss instanceof Function) onDismiss();
                closeModal();
            }}
            aria-labelledby="responsive-dialog-title"
            {...dialogProps}
        >
            <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (onCancel instanceof Function) onCancel();
                        closeModal();
                    }}
                    color="primary"
                    //  autoFocus
                >
                    {cancelText}
                </Button>
                <Button
                    onClick={() => {
                        if (onConfirm instanceof Function) onConfirm();
                        closeModal();
                    }}
                    color="primary"
                    autoFocus
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
);

ConfirmPromptDialog.propTypes = {
    header: PropTypes.node,
    text: PropTypes.node,
    closeModal: PropTypes.func,
    onDismiss: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    handleConfirm: PropTypes.func,
    handleCancel: PropTypes.func,
    firstButtonText: PropTypes.string,
    secondButtonText: PropTypes.string,
    ...Dialog.propTypes,
};

ConfirmPromptDialog.defaultProps = {
    cancelText: "Cancel",
    confirmText: "OK",
};

export default withMobileDialog()(ConfirmPromptDialog);
