const sessionReducer = ({
    RECEIVED,
    LOADED,
    INITIALIZED,
    CLEARED,
    UPDATED,
    ADDED_SAMPLE,
    ADDED_RESPONSE,
    ARCHIVED
}) => (state = null, action = {}) => {
    switch (action.type) {
        case LOADED:
        case RECEIVED:
        case INITIALIZED:
            return action.payload;
        case CLEARED:
            return null;
        case ADDED_SAMPLE:
        case ADDED_RESPONSE:
            return state;
        case UPDATED:
            return { ...state, ...action.payload };
        case ARCHIVED:
            return {...state, archived: true};
        default:
            return state;
    }
};

export default sessionReducer;
