import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import { Card, CardContent } from "@mui/material";
import { TextResponseSummary, AudioResponseSummary } from "components";
import surveyJsResponseToTextResponses from "util/surveyJsResponseToTextResponses";
import "./ResponseSummary.css";

/**
 * Creates a response summary.
 *
 * Given a responseId, it provides a response and responses object and forks rendering on the responses `fragmentType`.
 */
const ResponseSummary = compose(
    connect(
        (
            { responsesById, responsesByFragmentId, sessions },
            { responseId }
        ) => {
            const response = responsesById[responseId] || {};
            return {
                response,
                responsesById: responsesByFragmentId[response.fragmentId],
            };
        }
    ),
    withProps(({ responsesById }) => ({
        responses: Object.values(responsesById),
    }))
)(({ responses, response }) => {
    const props = { responses, response };
    // TODO: Consolidate the fragment responses to somewhere useful and import them.
    // TODO? Further, would is be useful to group all the things that associated with a response fragment, like we do with routes? That would make fragmentType associated features all in a kind of configuration file. So instead of a switch here we would do something like fragments.find(({id}) => id === fragmentId).ResponseSummaryComponent
    return []
        .concat(
            (() => {
                switch (response.fragmentType) {
                    case "CONSENT":
                    case "INFO":
                        return [];
                    // return <ConsentResponseSummary {...props} />;
                    case "TEXT_SURVEYJS":
                        const responseList = surveyJsResponseToTextResponses(
                            response
                        );
                        const responsesList = responses.reduce(
                            (list, response) =>
                                list.concat(
                                    surveyJsResponseToTextResponses(response)
                                ),
                            []
                        );
                        return responseList.map((response, i) => (
                            <TextResponseSummary
                                response={response}
                                responses={responsesList.filter(
                                    ({ index, fragmentData: { title } = {} }) =>
                                        response.index === index &&
                                        response.fragmentData.title === title
                                )}
                            />
                        ));
                    case "AUDIO_STANDARD":
                        return <AudioResponseSummary {...props} />;
                    default:
                        return <div>{response.fragmentType}</div>;
                }
            })()
        )
        .map((summary, i) => (
            <div key={i}>
                <Card>
                    <CardContent>{summary}</CardContent>
                </Card>
            </div>
        ));
});

ResponseSummary.propTypes = {
    responseId: PropTypes.string,
};

export default ResponseSummary;
