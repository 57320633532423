import React from "react";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { Button } from "@mui/material";
import "./DownloadButton.css";
import { DownloadModal } from "components";
import types from "store/types";

const DownloadButton = compose(
    connect(),
    withState("open", "setOpen", false),
    withHandlers({
        onClose: ({ setOpen }) => () => setOpen(false),
        onClick: ({ setOpen, dispatch, surveyId }) => () => {
            dispatch({
                type: types.downloadBuilder.INITIALIZED,
                payload: { surveyId },
            });
            setOpen(true);
        },
    })
)(({ className, open, disabled, onClose, onClick, children, ...rest }) => (
    <React.Fragment>
        <Button
            disabled={disabled}
            className={className}
            variant="contained"
            color="primary"
            onClick={onClick}
        >
            {children}
        </Button>
        <DownloadModal open={open} onClose={onClose} {...rest} />
    </React.Fragment>
));

export default DownloadButton;
