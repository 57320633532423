const retryWithBackoff = (promiseFunc, backoff = 50, maxBackoff = 8000) => {
    // TODO: Check the state for something telling us to stop trying to upload.
    return new Promise(resolve =>
        setTimeout(
            () =>
                promiseFunc()
                    .catch(e => {
                        console.warn(e);
                        return retryWithBackoff(
                            promiseFunc,
                            Math.min(maxBackoff, backoff * 2),
                            maxBackoff
                        );
                    })
                    .then(resolve),
            backoff
        )
    );
};
export default retryWithBackoff;
