import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import {
    Typography,
    Card,
    CardContent,
    LinearProgress,
    Button,
    IconButton,
    Tooltip,
} from "@mui/material"

import DevicesIcon from "@mui/icons-material/Devices";
import ArchiveIcon from "@mui/icons-material/Archive";

import types from "store/types";

const ViewSessionDetailsButton = compose(
    connect(),
    mapProps(({ dispatch, sessionId, surveyId, ...rest }) => ({
        onClick: () => {
            if (rest.onClick) rest.onClick();
            dispatch({
                type: types.requests.NAVIGATION,
                payload: {
                    view: "view-survey-session",
                    params: {
                        sessionId,
                        surveyId,
                    },
                },
            });
        },
        ...rest,
    }))
)(Button);

const SessionSimpleSummary = compose(
    connect(
        (
            {
                sessionsById,
                responsesBySessionId,
                surveys,
                popover,
                completedBySessionId,
            },
            { sessionId, metadata }
        ) => ({
            session: sessionsById[sessionId],
            responsesById: responsesBySessionId[sessionId],
            survey: surveys[sessionsById[sessionId].surveyId],
            popover: popover,
            metadata: metadata,
            completed: completedBySessionId[sessionId],
        })
    ),
    withProps(({ survey }) => ({
        fragmentIds: survey
            ? survey.fragments.map(({ fragmentId }) => fragmentId)
            : [],
    })),
    withProps(({ responsesById, fragmentIds }) => ({
        completedFragmentIds: responsesById
            ? Array.from(
                  new Set(
                      Object.values(responsesById).map(
                          ({ fragmentId }) => fragmentId
                      )
                  )
              )
            : [],
    })),
    withProps(({ session }) => {
        const time = moment(session.startTime).fromNow();
        return {
            title: time.slice(0, 1).toUpperCase() + time.slice(1),
        };
    }),
    withProps(({ dispatch, metadata = {} }) => ({
        onDevicesClick: event => {
            const anchorEl = event.currentTarget;
            dispatch({
                type: types.popover.OPEN_DEVICE_INFO_POPOVER,
                payload: {
                    open: true,
                    // storing the anchorEl ref in state crashes browser so we use a function that gets it instead - MUI allows this
                    anchorEl: () => {
                        return anchorEl;
                    },
                    userAgent: metadata.userAgent,
                },
            });
        },
        onArchiveClick: sessionId => {
            dispatch({
                type: types.modals.REQUEST_CONFIRMATION,
                payload: {
                    modalProps: {
                        text:
                            "Are you sure you would like to archive this session? It will no longer show up in your Summary view, but the data will not be deleted.",
                        header: "Confirm Session Archiving",
                        handleConfirm: () => {
                            dispatch({
                                type: types.session.ARCHIVED,
                                payload: {
                                    sessionId,
                                },
                            });
                        },
                    },
                },
            });
        },
    }))
)(
    ({
        children = null,
        completed = false,
        title,
        fragmentIds = [],
        completedFragmentIds = [],
        session: { sessionId, surveyId },
        onDevicesClick,
        onArchiveClick,
    }) => {
        return (
            <div className="session-summary session-summary__container">
                <Card className="session-summary session-summary__card">
                    <CardContent>
                        <div className="session-summary session-summary__header">
                            <div className="session-summary session-summary__header-title">
                                <Typography variant="h6">{title}</Typography>
                                <div className="session-summary__device-info">
                                    <IconButton
                                        color="primary"
                                        aria-label="Device Info"
                                        onClick={onDevicesClick}
                                    >
                                        <DevicesIcon />
                                    </IconButton>
                                    <Tooltip title="Archive session to hide it from your summary.">
                                        <IconButton
                                            color="primary"
                                            aria-label="archive"
                                            onClick={e =>
                                                onArchiveClick(sessionId)
                                            }
                                        >
                                            <ArchiveIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>

                            <div
                                className={
                                    "session-summary session-summary__state session-summary__state--" +
                                    (completed ? "complete" : "incomplete")
                                }
                            >
                                {`(${completedFragmentIds.length}/${fragmentIds.length}) Completed`}
                            </div>
                        </div>
                        <div className="session-summary session-summary__progress">
                            {/* <Typography variant="caption">
                                Processing...
                            </Typography> */}
                            <LinearProgress
                                variant="determinate"
                                value={completed ? 100 : 0}
                            />
                        </div>
                    </CardContent>
                    <CardContent className="session-summary session-summary__content-container">
                        {children}
                    </CardContent>
                    <CardContent className="session-summmary session-summary__action-container">
                        <ViewSessionDetailsButton
                            sessionId={sessionId}
                            surveyId={surveyId}
                            className="session-summary session-summary__details-action"
                            variant="outlined"
                            color="primary"
                        >
                            View Details
                        </ViewSessionDetailsButton>
                    </CardContent>
                </Card>
            </div>
        );
    }
);

SessionSimpleSummary.propTypes = {
    sessionId: PropTypes.string,
};

export default SessionSimpleSummary;
