import React from "react";
import { connect } from "react-redux";
import {
    NamedDivider,
    SurveyResultCard,
    SurveyUnpublishedCard,
} from "components";
import "./SurveySummaries.css";

const groupByKey = key => (groups = {}, obj) => {
    const group = obj[key];
    groups[group] = Array.isArray(groups[group]) ? groups[group] : [];
    groups[group].push(obj);
    return groups;
};

const SurveySummaries = ({ surveys, onView, onArchive, onEdit, onDelete }) => {
    const surveysByGroups = surveys.reduce(groupByKey("group"), {});
    return (
        <div className="survey-summaries">
            {Object.keys(surveysByGroups)
                .sort()
                .map(groupKey => (
                    <div
                        key={groupKey}
                        className="survey-summaries__group-container"
                    >
                        <div className="survey-summaries__group-title">
                            <NamedDivider name={groupKey} />
                        </div>
                        <div className="survey-summaries__group">
                            {surveysByGroups[groupKey]
                                .map(survey => (
                                    <div
                                        key={survey._id}
                                        className="survey-summaries__survey-summary"
                                    >
                                        {survey.published ? (
                                            <SurveyResultCard
                                                onPrimary={onView}
                                                onSecondary={onArchive}
                                                {...survey}
                                            />
                                        ) : (
                                            <SurveyUnpublishedCard
                                                onPrimary={onEdit}
                                                onSecondary={onDelete}
                                                {...survey}
                                            />
                                        )}
                                    </div>
                                ))
                                /* NOTE: This is a hack to make flexbox work for arbitrary grids. This will allow for up to 13 elements to fill each row where the modulus of the total is 1. */
                                .concat(
                                    Array.from(Array(12).fill(null), (_, i) => (
                                        <div
                                            key={i + "_flexbox-lastline-fix"}
                                            className="survey-summaries__survey-summary flexbox-lastline-fix"
                                        />
                                    ))
                                )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

const withSurveys = connect(
    state => {
        return {
            surveys: Object.values(state.surveys).filter(
                ({ creator }) => creator === state.user.userId
            ),
            filterByTerms: state.surveySearch.terms,
        };
    },
    undefined,
    (stateProps, dispatchProps, props) => {
        const allSurveys = stateProps.surveys;
        const filterByTerms = stateProps.filterByTerms.map(
            term =>
                new RegExp(term.replace(/[.?*+()[\]^$~]/g, a => "\\" + a), "i")
        );
        const filteredSurveys = allSurveys.filter(
            survey =>
                // NOTE: `survey.title && ` was  hiding surveys with empty titles.
                !survey.title ||
                filterByTerms.every(term => survey.title.match(term))
        );
        return Object.assign({}, props, dispatchProps, {
            surveys: filteredSurveys,
        });
    }
);

export default withSurveys(SurveySummaries);
