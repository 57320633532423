import React from "react";
import PropTypes from "prop-types";
import { compose, setDisplayName, setPropTypes, withProps } from "recompose";
import { connect } from "react-redux";
import { Checkbox, Typography } from "@mui/material";
import types from "store/types";
import "./DownloadBuilderExportOptions.css";

const DownloadBuilderExportOptions = ({ surveyId }) => (
    <div className="export-options">
        <DownloadBuilderExportBooleanOption
            title="Only Include Completions"
            option="filterIncomplete"
            surveyId={surveyId}
        />
        <DownloadBuilderExportBooleanOption
            title="Organize By Session"
            option="folderBySession"
            surveyId={surveyId}
        />
        <DownloadBuilderExportBooleanOption
            title="Organize By Question"
            option="folderByQuestion"
            surveyId={surveyId}
        />
    </div>
);

export const DownloadBuilderExportBooleanOption = compose(
    setDisplayName("SelectableFileType"),
    setPropTypes({
        title: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
            .isRequired,
        option: PropTypes.string.isRequired,
        surveyId: PropTypes.string.isRequired,
    }),
    connect(({ downloadBuilderBySurveyId }, { surveyId }) => ({
        downloadBuilder: downloadBuilderBySurveyId[surveyId],
    })),
    withProps(
        ({
            dispatch,
            downloadBuilder: { options = {} } = {},
            title,
            option,
            surveyId,
        }) => ({
            title,
            checked: options[option] || false,
            onChange: ({ target: { checked: value } }) =>
                dispatch({
                    type: types.downloadBuilder.SET_OPTION,
                    payload: {
                        surveyId, // NOTE: Which download builder?
                        title,
                        option,
                        value,
                    },
                }),
        })
    )
)(({ title, onChange, checked, disabled }) => {
    return (
        <div key={title} className="export-option">
            <Checkbox
                className="export-option__checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <Typography
                variant="subtitle2"
                className="export-option__title"
                onClick={disabled ? onChange : () => {}}
            >
                {title}
            </Typography>
        </div>
    );
});

export default DownloadBuilderExportOptions;
