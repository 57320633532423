import React from "react";
import PropTypes from "prop-types";
import { compose, withProps, setPropTypes, setDisplayName } from "recompose";
import { connect } from "react-redux";
import {
    Checkbox,
    Typography,
    Select,
    FormControl,
    MenuItem,
} from "@mui/material";
import { keyBy } from "lodash";
import types from "store/types";
import "./DownloadBuilderFileInclusion.css";

// TODO:RENAME Maybe these are more like FileBuilderOptions?
// These are handled directly in a the downloadBuilder middleware and converted to files.
const FileBuilderTypes = [
    {
        name: "text-summary",
        title: "Submissions Summary",
        format: "csv",
        formats: ["csv", "json"],
        selected: false,
        disabled: false,
    },
    {
        name: "sessions-metadata",
        title: "Submissions Metadata",
        format: "csv",
        formats: ["csv", "json"],
        selected: false,
        disabled: false,
    },
    {
        name: "responses",
        title: "Responses Summary",
        format: "csv",
        formats: ["csv", "json"],
        selected: false,
        disabled: false,
    },
    {
        name: "audio-samples",
        title: "Audio Samples",
        format: "wav",
        formats: ["wav"],
        selected: false,
        disabled: false,
    },
    {
        name: "audio-models",
        title: "Audio Models Summary",
        format: "csv",
        formats: [
            "csv",
            // "json"
        ],
        selected: false,
        disabled: false,
    },
    {
        name: "audio-features",
        title: "Audio Features",
        format: "csv",
        formats: ["csv", "json"],
        selected: false,
        disabled: false,
    },
];

const FileBuilderTypeByType = keyBy(FileBuilderTypes, "name");

const DownloadBuilderDefault = {
    stage: 0,
    selected: {},
    files: [],
    ignore: {},
};

const SelectableFileType = compose(
    setDisplayName("SelectableFileType"),
    setPropTypes({
        surveyId: PropTypes.string,
        fileType: PropTypes.oneOf(FileBuilderTypes.map(({ name }) => name)),
    }),
    connect(({ downloadBuilderBySurveyId, surveys }, { surveyId }) => ({
        downloadBuilder:
            downloadBuilderBySurveyId[surveyId] || DownloadBuilderDefault,
        survey: surveys[surveyId],
    })),
    withProps(({ downloadBuilder, fileType }) => ({
        definition:
            // TODO: Change this state to a better name, i.e. downloadBuilder.fileTypeSelectionsByType
            {
                ...FileBuilderTypeByType[fileType],
                ...(downloadBuilder.selected[fileType] || {}),
            },
    })),
    withProps(
        ({ dispatch, surveyId, fileType, definition, downloadBuilder }) => {
            const updateSelection = func => {
                const current = {
                    ...definition,
                    ...downloadBuilder.selected[fileType],
                };
                const next = func(current);
                dispatch({
                    type: types.downloadBuilder.UPDATED_SELECTION,
                    payload: {
                        selection: {
                            ...downloadBuilder.selected,
                            [fileType]: {
                                ...current,
                                ...next,
                            },
                        },
                        surveyId,
                    },
                });
            };
            return {
                onToggleSelection: () =>
                    updateSelection(current => ({
                        selected: !current.selected,
                    })),
                onChangeFormat: format =>
                    updateSelection(current => ({
                        format,
                    })),
            };
        }
    )
)(
    ({
        definition: { title, format, formats, disabled, selected },
        onToggleSelection,
        onChangeFormat,
    }) => (
        <div key={title} className="available-download">
            <Checkbox
                className="available-download__included"
                checked={selected}
                onChange={onToggleSelection}
                disabled={disabled}
                color="primary"
            />
            <Typography
                variant="subtitle2"
                className="available-download__title"
                onClick={disabled ? onToggleSelection : () => {}}
            >
                {title}
            </Typography>
            <FormControl className="available-download__formats-container">
                {/* <InputLabel shrink htmlFor={`${title}-formats`}>Format</InputLabel> */}
                <Select
                    disabled={disabled}
                    className="available-download__formats"
                    value={format}
                    onChange={({
                        target: { value: format = formats[0] } = {},
                    }) => onChangeFormat(format)}
                    inputProps={{
                        className: "available-download__format",
                        name: "Format",
                        id: `${title}-formats`,
                    }}
                >
                    {formats.map(format => (
                        <MenuItem key={format} value={format}>
                            {format}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
);

const DownloadBuilderFileInclusion = ({ surveyId }) => (
    <div className="file-inclusion">
        {FileBuilderTypes.map(props => (
            <SelectableFileType
                key={props.name}
                surveyId={surveyId}
                fileType={props.name}
                {...props}
            />
        ))}
    </div>
);

export default DownloadBuilderFileInclusion;
