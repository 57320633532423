import React from "react";
import PropTypes from "prop-types";
import {
    compose,
    withProps,
    withStateHandlers,
    mapProps,
    setDisplayName,
    setPropTypes,
} from "recompose";
import { connect } from "react-redux";
import {
    Dialog,
    DialogContent,
    TextField,
    Button,
    IconButton,
    Typography,
} from "@mui/material";
import { withValidation } from "enhancers";
import {
    DownloadBuilderFileInclusion,
    DownloadProgress,
    ExpansionPanel,
} from "components";
import moment from "moment";
import types from "store/types";
import "./DownloadModal.css";
import { ArrowBack } from "@mui/icons-material";
import defaultDownloadNameFromSurvey from "util/defaultDownloadNameFromSurvey";

const ExportNameInput = compose(
    setDisplayName("ExportNameInput"),
    connect(({ downloadBuilderBySurveyId, surveys }, { surveyId }) => ({
        downloadBuilder: downloadBuilderBySurveyId[surveyId],
        survey: surveys[surveyId],
    })),
    withProps(
        ({
            dispatch,
            surveyId,
            downloadBuilder: { downloadName = "" } = {},
        }) => ({
            value: downloadName,
            onChange: (downloadName) =>
                dispatch({
                    type: types.downloadBuilder.SET_NAME,
                    payload: { downloadName, surveyId },
                }),
        })
    ),
    withValidation(
        (value) => typeof value === "string" && value.length > 0,
        {
            coerce: (value = "") =>
                value.length > 50 ? value.substr(0, 50) : value,
        },
        ({ survey }) => defaultDownloadNameFromSurvey(survey)
    ),
    withProps(({ onChange, valid }) => ({
        onChange: ({ target: { value } }) => onChange(value),
        helperText: valid ? undefined : "Please give the export a name.",
    })),
    withValidation.cleanup,
    mapProps(
        ({ dispatch, surveyId, downloadBuilder, survey, ...props }) => props
    )
)(TextField);

const PreviousExports = compose(
    setDisplayName("PreviousExports"),
    connect(({ downloadsBySurveyId }, { surveyId }) => ({
        downloadsById: downloadsBySurveyId[surveyId],
    })),
    withProps(({ downloadsById = {} }) => ({
        downloads: Object.values(downloadsById)
            .filter(({ downloadId }) => downloadId)
            .reverse(),
    }))
)(({ dispatch, surveyId, downloads = [], onSelectExport }) => {
    dispatch({ type: types.requests.DOWNLOADS, payload: { surveyId } });
    return (
        <div className="previous-exports">
            {downloads.length ? (
                downloads.map(
                    ({
                        downloadName = "Unnamed Export",
                        downloadId,
                        ...downloadRest
                    }) => (
                        <Button
                            key={downloadId}
                            variant="text"
                            fullWidth
                            onClick={() =>
                                onSelectExport({
                                    downloadId,
                                    downloadName,
                                    ...downloadRest,
                                })
                            }
                        >
                            {downloadName}
                        </Button>
                    )
                )
            ) : (
                <div>There are no exports of this survey's data available.</div>
            )}
        </div>
    );
});

const unixFromUUID = function (uuid_str) {
    var uuid_arr = uuid_str.split("-"),
        time_str = [uuid_arr[2].substring(1), uuid_arr[1], uuid_arr[0]].join(
            ""
        );
    return (parseInt(time_str, 16) - 122192928000000000) / 10000;
};

const Export = compose(
    setDisplayName("Export"),
    setPropTypes({
        downloadId: PropTypes.string,
        downloadName: PropTypes.string,
        downloadStatus: PropTypes.string,
    }),
    connect(({ downloadsById }, { downloadId }) => ({
        download: downloadsById[downloadId],
    })),
    withProps(({ dispatch, downloadId, downloadName, download }) => ({
        ...(download || {}),
        onDownload: () =>
            dispatch({
                type: types.requests.DOWNLOAD_ARCHIVE,
                payload: { downloadId, downloadName },
            }),
    }))
)(
    ({
        downloadId,
        downloadStatus,
        downloadName,
        downloadExpiration,
        isAvailable,
        includedFiles=0,
        expectedFiles=0,
        onDownload,
    }) => (
        <React.Fragment>
            <DownloadProgress downloadId={downloadId} />
            {downloadStatus && downloadStatus !== "COMPLETED" ? (
                <Typography variant="overline" className="export__status">
                    {downloadStatus}
                </Typography>
            ) : null}
            <div className="export__container">
                <div className="export__details-container">
                    <Typography variant="body2">
                        {includedFiles} Files
                    </Typography>
                    {/* <Typography variant="body2">
                        {expectedFiles} Estimated Files
                    </Typography> */}
                    {downloadId ? (
                        <Typography variant="body2">
                            Built on{" "}
                            {moment(unixFromUUID(downloadId)).format(
                                "MMM D, YYYY [at] h:mm A"
                            )}
                        </Typography>
                    ) : null}
                    {downloadExpiration ? (
                        <Typography variant="body2">
                            Expires{" "}
                            {moment(downloadExpiration).format(
                                "MMM D, YYYY [at] h:mm A"
                            )}
                        </Typography>
                    ) : null}
                </div>
                <div className="export__actions-container">
                    <Button
                        className="export__button export__button-download"
                        disabled={!isAvailable}
                        onClick={onDownload}
                        variant="contained"
                        color="primary"
                    >
                        Download
                    </Button>
                    {/* <Button
                        className="export__button export__button-rebuild"
                        disabled={!isAvailable}
                        variant="outlined"
                        color="primary"
                    >
                        Rebuild
                    </Button>
                    <Button
                        className="export__button export__button-delete"
                        disabled={!isAvailable}
                        variant="outlined"
                        color="primary"
                    >
                        Delete
                    </Button> */}
                </div>
            </div>
        </React.Fragment>
    )
);

const DownloadBuilderModal = compose(
    setDisplayName("DownloadBuilderModal"),
    connect(
        (
            { downloadBuilderBySurveyId, downloadsBySurveyId, surveys },
            { surveyId }
        ) => ({
            downloadBuilder: downloadBuilderBySurveyId[surveyId],
            archives: downloadsBySurveyId[surveyId],
            survey: surveys[surveyId],
        })
    ),
    withStateHandlers(
        ({
            report = null,
            expandedExportOptions = true,
            expandedPreviousExports = false,
        }) => ({ report, expandedExportOptions, expandedPreviousExports }),
        {
            onSelectReport: (state, props) => (report) => ({
                report,
                expandedExportOptions: false,
                expandedPreviousExports: false,
            }),
            onDeselectReport: (state, props) => () => ({
                report: null,
                expandedExportOptions: true,
                expandedPreviousExports: false,
            }),
            onExpandExportOptions: () => () => ({
                expandedExportOptions: true,
                expandedPreviousExports: false,
            }),
            onExpandPreviousExports: ({
                report,
                expandedPreviousExports,
            }) => () =>
                report
                    ? {
                          expandedPreviousExports: !expandedPreviousExports,
                      }
                    : {
                          expandedExportOptions: false,
                          expandedPreviousExports: true,
                      },
        }
    ),
    withProps(
        ({
            downloadBuilder: { files = [], downloadName = "" } = {},
            dispatch,
            surveyId,
            onSelectReport,
        }) => ({
            onBuild: () =>
                dispatch((dispatch, getState) => {
                    const {
                        downloadBuilderBySurveyId: {
                            [surveyId]: downloadBuilder,
                        },
                    } = getState();
                    dispatch({
                        type: types.requests.BUILD_DOWNLOAD,
                        payload: { ...downloadBuilder, surveyId },
                    }).then(() => {
                        const {
                            downloadBuilderBySurveyId: {
                                [surveyId]: { build },
                            },
                        } = getState();
                        onSelectReport(build);
                    });
                    const {
                        downloadBuilderBySurveyId: {
                            [surveyId]: { build },
                        },
                    } = getState();
                    onSelectReport(build);
                }),
        })
    )
)(
    ({
        surveyId,
        report,
        downloadBuilder: { files = [], downloadName = "" } = {},
        open,
        onClose,
        onBuild,
        onSelectReport,
        onDeselectReport,
        expandedPreviousExports,
        expandedExportOptions,
        onExpandExportOptions,
        onExpandPreviousExports,
    }) => {
        return (
            <Dialog
                className="download-builder-modal"
                open={open}
                onClose={onClose}
                onBackdropClick={onClose}
            >
                <DialogContent className="download-builder-modal__content-container">
                    <div className="download-builder-modal__header">
                        {report instanceof Object ? (
                            <React.Fragment>
                                <IconButton
                                    className="download-builder-modal__header-back"
                                    onClick={onDeselectReport}
                                >
                                    <ArrowBack />
                                </IconButton>
                                <div className="download-builder-modal__header-title">
                                    <Typography variant="overline">
                                        Export Details
                                    </Typography>
                                    <Typography variant="h6">
                                        {report.downloadName}
                                    </Typography>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <ExportNameInput
                                    label="Export Name"
                                    surveyId={surveyId}
                                    variant="outlined"
                                    className="download-builder-modal__name-input"
                                    fullWidth
                                />
                                <Button
                                    className="download-builder-modal__build-button"
                                    onClick={onBuild}
                                    variant="contained"
                                    color="primary"
                                    disabled={!downloadName}
                                >
                                    Export
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                    {report instanceof Object ? (
                        <React.Fragment>
                            <Export surveyId={surveyId} {...report} />
                            <ExpansionPanel
                                expanded={expandedPreviousExports}
                                onChange={onExpandPreviousExports}
                                title={
                                    <Typography
                                        variant="subtitle1"
                                        className="download-builder-modal__pane-title"
                                    >
                                        Previous Exports
                                    </Typography>
                                }
                                className={[
                                    "download-builder-modal__previous-exports-panel",
                                    "download-builder-modal__previous-exports-panel--report",
                                    `download-builder-modal__previous-exports-panel--${
                                        expandedPreviousExports
                                            ? "expanded"
                                            : "collapsed"
                                    }`,
                                ].join(" ")}
                            >
                                <PreviousExports
                                    onSelectExport={onSelectReport}
                                    surveyId={surveyId}
                                />
                            </ExpansionPanel>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <ExpansionPanel
                                expanded={expandedExportOptions}
                                onChange={onExpandExportOptions}
                                title={
                                    <Typography
                                        variant="subtitle1"
                                        className="download-builder-modal__pane-title"
                                    >
                                        Export Options
                                    </Typography>
                                }
                                className={[
                                    "download-builder-modal__export-options-panel",
                                    `download-builder-modal__export-options-panel--${
                                        expandedExportOptions
                                            ? "expanded"
                                            : "collapsed"
                                    }`,
                                ].join(" ")}
                            >
                                <div className="download-builder-modal__export-options">
                                    <Typography
                                        variant="overline"
                                        className="download-builder-modal__export-options-section-title"
                                    >
                                        Included Files
                                    </Typography>
                                    <DownloadBuilderFileInclusion
                                        surveyId={surveyId}
                                    />
                                    {/* <Typography
                                        variant="overline"
                                        className="download-builder-modal__export-options-section-title"
                                    >
                                        Organize and Filter
                                    </Typography>
                                    <DownloadBuilderExportOptions
                                        surveyId={surveyId}
                                    /> */}
                                </div>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expandedPreviousExports}
                                onChange={onExpandPreviousExports}
                                title={
                                    <Typography
                                        variant="subtitle1"
                                        className="download-builder-modal__pane-title"
                                    >
                                        Previous Exports
                                    </Typography>
                                }
                                className={[
                                    "download-builder-modal__previous-exports-panel",
                                    `download-builder-modal__previous-exports-panel--${
                                        expandedPreviousExports
                                            ? "expanded"
                                            : "collapsed"
                                    }`,
                                ].join(" ")}
                            >
                                <PreviousExports
                                    onSelectExport={onSelectReport}
                                    surveyId={surveyId}
                                />
                            </ExpansionPanel>
                        </React.Fragment>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
);

export default DownloadBuilderModal;
