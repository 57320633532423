import React from "react";
import PropTypes from "prop-types";
import { SurveyResultResponse } from "components";
import "./SurveyResultResponses.css";

const SurveyResultResponses = ({ responses, fullView }) => {
    return (
        <div className="surveyresultresponses">
            {responses.length
                ? responses
                      .filter(response => (response.data || {}).sampleId)
                      .map((response, key) => (
                          <SurveyResultResponse
                              key={response.responseId || key}
                              fullView={fullView}
                              sampleId={(response.data || {}).sampleId}
                          />
                      ))
                : null}
        </div>
    );
};

SurveyResultResponses.propTypes = {
    responses: PropTypes.arrayOf(
        PropTypes.shape({
            collectionName: PropTypes.string,
            createdDate: PropTypes.string,
            sampleId: PropTypes.string,
            sessionId: PropTypes.string,
            surveyId: PropTypes.string,
            transcription: PropTypes.string,
        })
    ),
    // This is a temporary way of denoting whether or not to display the full SurveyResponse or the simple version for home screen
    fullView: PropTypes.bool,
};

SurveyResultResponses.defaultProps = {
    responses: [],
    fullView: false,
};

export default SurveyResultResponses;
