import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import { Card, CardContent, Typography } from "@mui/material";
import { Bar as BarChart } from "react-chartjs-2";
import { sortedIndexBy } from "lodash";
import createTimeBins from "util/createTimeBins";
import chartOptions from "util/chartJsOptions";
import mergeBranch from "util/mergeBranch";
import moment from "moment";
import "./SurveyActivityChartCard.css";

const ChartCard = ({ className, title, chart }) => (
    <Card className={`chart-card ${className} flex-card`}>
        <CardContent className="chart-card__title">
            <Typography variant="overline">{title}</Typography>
        </CardContent>
        <CardContent className="chart-card__chart">{chart}</CardContent>
    </Card>
);

ChartCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    chart: PropTypes.node,
};

const SurveyActivityChartCard = withProps(
    ({ period = "day", length = 7, surveyId }) => ({
        className: "activity-chart-card",
        chart: (
            <SurveyActivityChart
                surveyId={surveyId}
                period={period}
                length={length}
            />
        ),
    })
)(ChartCard);

SurveyActivityChartCard.propTypes = {
    surveyId: PropTypes.string,
    period: PropTypes.string,
    length: PropTypes.number,
};

const SurveyActivityChart = compose(
    withProps(({ period, length }) => ({
        bins: createTimeBins(
            moment()
                .startOf(period)
                .add(-(length - 1), period),
            period,
            length
        ),
    })),
    connect(
        (
            {
                responsesBySessionId,
                sessionsBySurveyId,
                completedBySessionId,
            },
            { surveyId }
        ) => ({
            sessionsById: sessionsBySurveyId[surveyId],
            responsesBySessionId,
            completedBySessionId,
        })
    ),
    withProps(
        ({
            bins = [],
            sessionsById = {},
            responsesBySessionId = {},
            completedBySessionId,
        }) => ({
            sessionsByBin: Object.values(sessionsById).reduce(
                (sessionsByBin, { sessionId, startTime }) => {
                    // Which bin is it in?
                    const index = sortedIndexBy(
                        bins,
                        { start: new Date(startTime) },
                        ({ start }) => start
                    );
                    // If it's not in a bin, skip it.
                    if (
                        !(
                            index &&
                            bins[index - 1] &&
                            bins[index - 1].includes(startTime)
                        )
                    )
                        return sessionsByBin;
                    // Which category is it in?
                    // It's a session, so it's at least a view.
                    sessionsByBin[index - 1].view += 1;
                    // If there are some responses it is an engagement.
                    const responses = Object.values(
                        responsesBySessionId[sessionId] || {}
                    );
                    if (responses.length > 0) {
                        sessionsByBin[index - 1].engage += 1;
                    }
                    // If there is a response for every fragment, it's completed.
                    if (completedBySessionId[sessionId]) {
                        sessionsByBin[index - 1].complete += 1;
                    }
                    return sessionsByBin;
                },
                bins.map(() => ({ complete: 0, engage: 0, view: 0 }))
            ),
        })
    ),
    withProps(({ options }) => {
        return {
            options: mergeBranch(options)({
                scales: {
                    x: {  stacked: true },
                    y: {  stacked: true },
                },
            }),
        };
    }),
    withProps(({ sessionsByBin, bins, labelFormat = "M/D" }) => {
        return {
            data: {
                labels: bins.reduce(
                    (labels, bin) => labels.concat(bin.getLabel(labelFormat)),
                    []
                ),
                datasets: [
                    {
                        // stack: "Total Engagement",
                        label: "Completion",
                        backgroundColor: "#3F71F7", // Blue
                        data: sessionsByBin.map(({ complete }) => complete),
                    },
                    {
                        // stack: "Total Engagement",
                        label: "Engagement",
                        //backgroundColor: pattern.draw('diagonal-right-left', "#3F71F7")
                        backgroundColor: "#FFDC3D", // Yellow
                        data: sessionsByBin.map(({ engage }) => engage),
                    },
                    {
                        // stack: "Total Engagement",
                        label: "View",
                        //backgroundColor: "#6B6B6B", // Dark Grey
                        backgroundColor: "#eee", // Light Grey
                        data: sessionsByBin.map(({ view }) => view),
                    },
                ],
            },
        };
    })
)(({ data, options, height, width }) => (
    <BarChart height={height} width={width} data={data} options={options} />
));

SurveyActivityChart.propTypes = {
    surveyId: PropTypes.string,
    period: PropTypes.string,
    length: PropTypes.number,
    labelFormat: PropTypes.string,
};

SurveyActivityChart.defaultProps = {
    options: chartOptions,
};

export { SurveyActivityChart };
export default SurveyActivityChartCard;
