import React from "react";
import { Logo } from "components";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import AddAPhoto from "@mui/icons-material/AddAPhotoOutlined";
import AddPhotoAlternate from "@mui/icons-material/ColorLensOutlined";
import types from "store/types";

import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import "./EditorHeader.css";

const SurveyTitleInput = compose(
    connect(
        ({
            surveyCreator: {
                survey: { title },
            },
        }) => ({
            value: title || "",
        }),
        dispatch => ({
            onChange: ({ target: { value } }) =>
                dispatch({
                    type: types.surveyCreator.UPDATED_SURVEY,
                    payload: { title: value },
                }),
        })
    ),
    mapProps(({ dispatch, ...props }) => props)
)(TextField);

const SelectedLogo = connect(state => ({
    selectedLogo: state.surveyCreator.survey.logoUrl,
}))(({ selectedLogo, ...props }) => {
    if (selectedLogo) {
        return <Logo size="auto" source={selectedLogo} />;
    } else {
        return (
            <React.Fragment>
                <AddAPhoto className="editor-header__button-icon" />
                <Typography
                    className="editor-header__button-text"
                    variant="button"
                >
                    Choose Logo
                </Typography>
            </React.Fragment>
        );
    }
});

const SelectLogoButton = compose(
    connect(),
    withProps(({ dispatch }) => ({
        onClick: () =>
            dispatch({
                type: types.modals.SELECT_IMAGE,
                payload: {
                    modalProps: {
                        text: "Select an image or upload a new one.",
                        header: "Select an Image",
                    },
                },
            }),
    })),
    mapProps(({ dispatch, ...props }) => props)
)(Button);

const EditorHeader = () => (
    <div className="editor-header__container">
        <SurveyTitleInput
            className="editor-header__title-input"
            placeholder="Survey Title"
            margin="normal"
            fullWidth={true}
        />
        <div className="editor-header__actions">
            <SelectLogoButton
                className="editor-header__button editor-header__logo-input"
                variant="outlined"
            >
                <SelectedLogo />
            </SelectLogoButton>
            <Button
                disabled
                className="editor-header__button editor-header__background-input"
                variant="outlined"
            >
                <AddPhotoAlternate className="editor-header__button-icon" />
                <Typography
                    className="editor-header__button-text"
                    variant="button"
                >
                    Choose Color
                </Typography>
            </Button>
        </div>
    </div>
);

export default EditorHeader;
