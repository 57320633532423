import configuration from "./configurations";
import auth from "./auth";
import user from "./user";
import session from "./session";
import survey from "./survey";
import navigation from "./navigation";
import playback from "./playback";
import surveyCreator from "./surveyCreator";
import surveyCreatorUnsavedNavigation from "./surveyCreatorUnsavedNavigation";
import analytics from "./analytics";
import navigationPrompt from "./navigationPrompt";
import recorder from "./recorder";
import responses from "./responses";
import surveyTaker from "./surveyTaker";
import preloadFragment from "./preloadFragment";
import fileUpload from "./fileUpload";
import files from "./files";
import downloadBuilder from "./downloadBuilder";
import reporting from "./reporting";
import sentryio from "./sentryio";
import userAgent from "./user-agent";
import queryString from "./querystring";
import types from "store/types";

// Use this to slow down the stream.
// const slow = wait => () => next => action => setTimeout(() => next(action), wait)

export { default as configuration } from "./configurations";
export { default as auth } from "./auth";
export { default as user } from "./user";
export { default as session } from "./session";
export { default as survey } from "./survey";
export { default as navigation } from "./navigation";
export { default as playback } from "./playback";
export { default as surveyCreator } from "./surveyCreator";
export { default as surveyCreatorUnsavedNavigation } from "./surveyCreatorUnsavedNavigation";
export { default as analytics } from "./analytics";
export { default as navigationPrompt } from "./navigationPrompt";
export { default as recorder } from "./recorder";
export { default as responses } from "./responses";
export { default as surveyTaker } from "./surveyTaker";
export { default as preloadFragment } from "./preloadFragment";
export { default as fileUpload } from "./fileUpload";
export { default as files } from "./files";
export { default as downloadBuilder } from "./downloadBuilder";
export { default as reporting } from "./reporting";
export { default as sentryio } from "./sentryio";
export { default as userAgent } from "./user-agent";
export { default as queryString } from "./querystring";

export default [
    configuration(types.configuration),
    userAgent,
    queryString,
    auth,
    user,
    session,
    survey,
    responses,
    surveyCreator,
    surveyCreatorUnsavedNavigation,
    surveyTaker,
    preloadFragment,
    downloadBuilder,
    reporting,
    navigationPrompt,
    navigation,
    files,
    playback,
    recorder,
    fileUpload,
    analytics,
    sentryio,
];
