import {
    CRUDArrayReducer,
    actionTypesReducerReducer,
    intoObjectKey,
    payload,
    initialState,
    pass,
} from "./helpers";
import reduceReducers from "reduce-reducers";
import { flowRight } from "lodash";

const surveyTakerReducer = ({
    INITIALIZED,
    SET_SESSION,
    SET_FRAGMENT,
    SESSION_PROGRESS,
    COMPLETED_FRAGMENT,
    SENT_RESPONSE,
    DELIVERED_RESPONSE,
    LOADED,
}) => (state = {}, action = {}) => {
    switch (action.type) {
        case INITIALIZED: {
            const { surveyId, fragmentIndex = 0 } = action.payload;
            if (surveyId !== state.surveyId) {
                state = {
                    sessionId: state.sessionId,
                    surveyId,
                };
            }
            if (fragmentIndex !== state.fragmentIndex) {
                const survey = state.survey;
                const fragment = survey
                    ? survey.fragments[fragmentIndex]
                    : undefined;
                state = {
                    ...state,
                    fragmentIndex,
                    fragmentId: fragment ? fragment.fragmentId : undefined,
                };
            }
            return state;
        }
        case LOADED: {
            const survey = action.payload;
            const fragment = survey.fragments[state.fragmentIndex];
            return {
                ...state,
                survey,
                fragmentId: fragment ? fragment.fragmentId : undefined,
            };
        }
        case SET_SESSION: {
            const { sessionId, session } = action.payload;
            return {
                ...state,
                // Setting a new session clears out the completedFragments.
                // TODO: Instead set up the completed fragments by session id so we don't just lose all the information on this session if it was valueable.
                completedFragments:
                    sessionId !== state.sessionId
                        ? []
                        : state.completedFragments,
                session,
                sessionId,
            };
        }
        case SET_FRAGMENT: {
            const { fragmentId, fragmentIndex } = action.payload;
            return {
                ...state,
                fragmentId,
                fragmentIndex,
            };
        }
        case SESSION_PROGRESS: {
            const { session } = action.payload;
            return {
                ...state,
                session,
            };
        }
        case COMPLETED_FRAGMENT: {
            return {
                ...state,
                completedFragments: (state.completedFragments || []).concat([
                    action.payload,
                ]),
            };
        }
        case SENT_RESPONSE:
        case DELIVERED_RESPONSE: {
            return {
                ...state,
                uploading: CRUDArrayReducer(
                    { CREATED: SENT_RESPONSE, DELETED: DELIVERED_RESPONSE },
                    (state, { payload = {} } = {}) => payload.responseId,
                    (state, action) => state.indexOf(action.payload.responseId)
                )(state.uploading || [], action),
            };
        }
        default:
            return state;
    }
};

export default surveyTaker =>
    reduceReducers(
        surveyTakerReducer(surveyTaker),
        intoObjectKey({
            preloadByFragmentId: flowRight(
                initialState({}),
                actionTypesReducerReducer({
                    [surveyTaker.PRELOADED_FRAGMENT]: intoObjectKey(
                        payload("fragmentId")
                    )(payload("preload")),
                })
            )(pass),
        })(pass)
    );
