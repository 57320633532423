import types from "store/types";
import axios from "axios";
import { memoize } from "lodash";
import debounce from "util/debounce";
import { combine, promote } from "redux-intervention";


const retrieveSurveys = debounce(
    async (dispatch, getState) => {
        await dispatch({ type: types.requests.AUTHENTICATION });
        await dispatch({ type: types.requests.USER_PROFILE });
        const {
            CONFIG: { NLX__API_ENDPOINT__OBJECT },
            user: { userId, session: { token } = {} },
        } = getState();
        if (!token) return null;
        if (!userId) return null;
        const { data: surveys } = await axios.get(
            `${NLX__API_ENDPOINT__OBJECT}/surveys/u/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (surveys.length > 0) {
            await dispatch({ type: types.surveys.LOADED, payload: surveys });
        }
    },
    // Update at max once a minute.
    60 * 1000
);

const retrieveSurvey = memoize((surveyId) =>
    debounce(
        async (dispatch, getState) => {
            await dispatch({ type: types.requests.AUTHENTICATION });
            await dispatch({ type: types.requests.USER_PROFILE });
            const {
                CONFIG: { NLX__API_ENDPOINT__OBJECT },
                user: { session: { token } = {} },
            } = getState();
            if (!surveyId) {
                return Promise.reject("`surveyId` is required.");
            }
            if (!token) {
                return Promise.reject("Valid token was not present.");
            }
            const { data: survey } = await axios.get(
                `${NLX__API_ENDPOINT__OBJECT}/surveys/${surveyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (survey) {
                return dispatch({
                    type: types.surveys.LOADED,
                    payload: [survey],
                });
            }
        },
        // Update at max once a minute.
        60 * 1000,
        { leading: true }
    )
);

// Memoized to debounce independently per surveyId.
const retrieveSurveySamples = memoize((surveyId) =>
    debounce(
        async (dispatch, getState) => {
            const { user: { session: { token } = {} } = {} } = getState();
            if (!token) return null;
            if (typeof surveyId !== "string") {
                console.warn("Survey ID does not exist");
                return;
            }
            const { data: samples } = await axios.get(
                `${NLX__API_ENDPOINT__OBJECT}/samples/s/${surveyId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            await dispatch({
                type: types.samples.LOADED,
                payload: samples,
            });
            return next(action);
        },
        // Update at max once a minute.
        60 * 1000,
        { leading: true }
    )
);

// Redux Middleware ~ store => next => action => next(action)
export default combine({
    [types.requests.SURVEYS]:
        ({ dispatch, getState }) =>
        (next) =>
        async (action) => {
            await dispatch(retrieveSurveys);
            return next(action);
        },
    [types.requests.SURVEY]:
        ({ dispatch, getState }) =>
        (next) =>
        async (action) => {
            const { survey: { surveyId } = {} } = action.payload;
            if (surveyId) {
                const survey = getState().surveys[surveyId];
                if (!survey) {
                    await dispatch(retrieveSurvey(surveyId));
                }
                // if (!survey || survey.published) {
                //     dispatch(retrieveSurveySamples(surveyId));
                // }
            }
            return next(action);
        },
    [types.surveys.PUBLISHED]:
        ({ dispatch, getState }) =>
        (next) =>
        async (action) => {
            const survey = { ...action.payload, published: true };
            const {
                CONFIG: { NLX__API_ENDPOINT__OBJECT },
                user: { session: { token } = {} },
            } = getState();

            const {
                data: { result: publishedSurvey },
            } = await axios.post(
                `${NLX__API_ENDPOINT__OBJECT}/surveys/`,
                survey,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            await dispatch({
                type: types.surveys.LOADED,
                payload: [publishedSurvey],
            });
            return next(action);
        },
    [types.surveys.ARCHIVED]:
        ({ getState, dispatch }) =>
        (next) =>
        async (action) => {
            const {
                CONFIG: { NLX__API_ENDPOINT__OBJECT },
                user: { session: { token } = {} },
            } = getState();
            const surveyId = action.payload;
            if (surveyId) {
                const { data } = await axios.post(
                    `${NLX__API_ENDPOINT__OBJECT}/surveys/archive/${surveyId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                await dispatch({
                    type: types.modals.SURVEY_SUCCESS,
                    payload: {
                        modalProps: {
                            surveyId: surveyId,
                            text: "Your survey was archived.",
                            header: "Survey Archived",
                        },
                    },
                });
            }
            return next(action);
        },
    [types.surveys.DELETED]:
        ({ getState, dispatch }) =>
        (next) =>
        async (action) => {
            const {
                CONFIG: { NLX__API_ENDPOINT__OBJECT },
                user: { session: { token } = {} },
            } = getState();
            const surveyId = action.payload;
            if (surveyId) {
                const token = state.user.session.token;
                const { data } = await axios.delete(
                    `${NLX__API_ENDPOINT__OBJECT}/surveys/${surveyId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                await dispatch({
                    type: types.modals.SURVEY_SUCCESS,
                    payload: {
                        modalProps: {
                            surveyId,
                            text: "Your survey was deleted.",
                            header: "Survey Deleted",
                        },
                    },
                });
            }
            return next(action);
        },
});
