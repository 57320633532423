import React from "react";
import PropTypes from "prop-types";
import {
    compose,
    withState,
    withHandlers,
    branch,
    setPropTypes,
    setDisplayName,
    withProps,
    mapProps,
} from "recompose";
import {
    Stepper as MaterialStepper,
    Step as MaterialStep,
    StepLabel,
    StepContent,
    Button,
} from "@mui/material";
import "./Stepper.css";

export const Stepper = compose(
    setDisplayName("Stepper"),
    setPropTypes({
        setActiveStep: PropTypes.func,
        activeStep: PropTypes.number,
        steps: PropTypes.arrayOf(PropTypes.element),
    }),
    branch(
        // If we're not being controlled externally, apply an internal state.
        ({ setActiveStep }) => setActiveStep === undefined,
        withState("activeStep", "setActiveStep", 0)
    ),
    withHandlers({
        nextStep: ({ activeStep, steps = [], setActiveStep }) => () =>
            setActiveStep(Math.min(activeStep + 1, steps.length - 1)),
        previousStep: ({ activeStep, setActiveStep }) => () =>
            setActiveStep(Math.max(activeStep - 1, 0)),
    })
)(({ nextStep, previousStep, activeStep, steps, children }) => (
    <MaterialStepper activeStep={activeStep} orientation="vertical">
        {Array.isArray(steps)
            ? steps
                  .map(props => ({
                      ...props,
                      onNext: nextStep,
                      onBack: previousStep,
                      activeStep,
                  }))
                  .map(Step)
            : children}
    </MaterialStepper>
));

// NOTE: We need this to still be a MaterialStep component for compatibility with MaterialStepper.
// i.e. compose(...)(MaterialStep)
export const Step = compose(
    setDisplayName("Step"),
    setPropTypes({
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
            .isRequired,
        children: PropTypes.element,
        action: PropTypes.string,
        onNext: PropTypes.func,
        onBack: PropTypes.func,
        onClick: PropTypes.func,
        showBack: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    withProps(
        ({
            index,
            label,
            children,
            onClick,
            showBack = true,
            onNext,
            onBack,
            action,
            disabled,
        }) => ({
            key: index || label,
            label,
            children: [
                <StepLabel>{label}</StepLabel>,
                <StepContent>
                    {children ? children : null}
                    <div className="step__buttons-container">
                        {showBack ? (
                            <Button
                                className="step__back-button"
                                disabled={!(onBack instanceof Function)}
                                onClick={onBack}
                            >
                                Back
                            </Button>
                        ) : null}
                        <div className="expanding-gap" />
                        {onClick instanceof Function ||
                        onNext instanceof Function ? (
                            <Button
                                className="step__action-button"
                                variant="contained"
                                color="primary"
                                disabled={disabled}
                                onClick={() => {
                                    if (!disabled) {
                                        if (onClick instanceof Function)
                                            onClick();
                                        if (onNext instanceof Function)
                                            onNext();
                                    }
                                }}
                            >
                                {action}
                            </Button>
                        ) : null}
                    </div>
                </StepContent>,
            ],
        })
    ),
    // Dispose of our props so Material doesn't apply them to an HTML element.
    mapProps(
        ({ onNext, onBack, onClick, showBack, disabled, action, ...props }) =>
            props
    )
)(MaterialStep);

export default Stepper;
