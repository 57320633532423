import React from "react";
import { FormBuilder, EditorPane } from "components";
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import mergeBranch from "util/mergeBranch";
import types from "store/types";

import "./EditorFragmentTextSurveyJS.css";
import { intoObjectKey } from "store/reducers/helpers";

const ReferenceTypes = [
    {
        type: "radiogroup",
        referenceType: "YES_NO",
        choices: ["Yes", "No"],
    },
    {
        type: "rating",
        referenceType: "NPS",
        rateValues: new Array(11).fill(0).map((o, i) => ({
            value: i,
            text: "" + i,
        })),
    },
    {
        type: "rating",
        referenceType: "CSAT",
        rateValues: new Array(5).fill(0).map((o, i) => ({
            value: i + 1,
            text: [
                "Very unsatisfied",
                "Unsatisfied",
                "Neutral",
                "Satisfied",
                "Very satisfied",
            ][i],
        })),
    },
].reduce(
    intoObjectKey((state, { referenceType }) => referenceType)(
        (state, reference) => reference
    ),
    {}
);

const PromptFormBuilderElement = ({
    value,
    dataKey,
    isRequired = false,
    onChange,
    ...rest
}) => {
    return (
        <FormGroup className="editor-fragment-surveyjs__form-group">
            <TextField
                value={value}
                fullWidth
                variant="outlined"
                placeholder="Question Prompt"
                margin="dense"
                className="editor-fragment-surveyjs__question-prompt"
                multiline={true}
                onChange={e => {
                    onChange({
                        [dataKey]: e.target.value,
                    });
                }}
                {...rest}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        key={`required-${dataKey}`}
                        checked={isRequired}
                        onChange={e =>
                            onChange({
                                isRequired: !isRequired,
                            })
                        }
                    />
                }
                label="Required"
            />
        </FormGroup>
    );
};

const formBuilderValuesFromSurveyJS = ({ questions = [] } = {}) => {
    if (!Array.isArray(questions)) questions = [];
    return questions.map(({ type, rateValues, choices, ...rest }, i) => ({
        key: i, // Which question?
        type, // What type of question?
        data: rest, // What kind of data is associated with this question?
        value: Array.isArray(choices)
            ? {
                  data: {
                      type,
                      ...rest,
                  },
                  key: "choices",
                  type: "container",
                  values: choices.map((choice, i) => ({
                      key: i,
                      type: "choice",
                      data: {
                          type,
                          ...rest,
                      },
                      value:
                          choice instanceof Object
                              ? choice
                              : {
                                    value: choice,
                                    text: choice,
                                },
                  })),
              }
            : Array.isArray(rateValues)
            ? {
                  data: {
                      type,
                      ...rest,
                  },
                  key: "rateValues",
                  type: "container",
                  values: rateValues.map((rating, i) => ({
                      data: {
                          type,
                          ...rest,
                      },
                      key: i,
                      type: "value",
                      value:
                          rating instanceof Object
                              ? rating
                              : {
                                    value: i,
                                    text: rating,
                                },
                  })),
              }
            : undefined,
    }));
};

// connectFragmentDataToValue("surveyjs.questions[propName].type", "onChange", v => v),

const EditorFragmentTextSurveyJS = compose(
    connect(
        (
            {
                surveyCreator: {
                    survey: { fragments },
                },
            },
            { fragmentIndex }
        ) => {
            const { data: { surveyjs: value } = {} } =
                fragments[fragmentIndex] || {};
            return { value };
        }
    ),
    withProps(({ value, dispatch, fragmentIndex }) => ({
        onChange: value => {
            dispatch({
                type: types.surveyCreator.UPDATED_FRAGMENT,
                payload: {
                    id: fragmentIndex,
                    data: {
                        surveyjs: value,
                    },
                },
            });
        },
    })),
    withProps(({ value: surveyjs, onChange }) => ({
        onChange: branch => {
            const tree = mergeBranch(mergeBranch(surveyjs)({ questions: [] }))(
                branch
            );
            onChange(tree);
        },
    })),
    withProps(({ value: surveyjs, onChange }) => ({
        children: (
            <React.Fragment>
                <FormBuilder
                    manifest={{
                        key: "questions",
                        type: "surveyjs",
                        values: formBuilderValuesFromSurveyJS(surveyjs),
                    }}
                    onChange={onChange}
                    renderFormElement={({
                        data = {},
                        type,
                        value,
                        values,
                        dataKey,
                        onChange,
                        // onFormAction,
                        children,
                    }) => {
                        const responseType = data.referenceType;
                        switch (type) {
                            case "text":
                            case "rating":
                            case "radiogroup":
                            case "checkbox":
                            case "boolean":
                                return (
                                    <div className="editor-fragment-surveyjs__container">
                                        <div className="editor-fragment-surveyjs__prompt">
                                            <div className="editor-fragment-surveyjs__prompt-number">
                                                <Typography variant="h5">
                                                    {dataKey +
                                                        1 /* TODO: This should add the number of previous questions in the survey. */}
                                                    .{" "}
                                                </Typography>
                                            </div>
                                            <PromptFormBuilderElement
                                                data={data}
                                                value={data.title}
                                                dataKey="title"
                                                onChange={onChange}
                                                isRequired={data.isRequired}
                                            />
                                        </div>

                                        <div className="editor-fragment-surveyjs__content">
                                            {(() => {
                                                switch (type) {
                                                    case "text":
                                                        return (
                                                            <TextField
                                                                className="editor-fragment-surveyjs__response-area"
                                                                placeholder="Response Area"
                                                                // fullWidth
                                                                disabled
                                                            />
                                                        );
                                                    case "rating":
                                                    case "boolean":
                                                    case "radiogroup":
                                                    case "checkbox":
                                                        return children;
                                                    case "dropdown":
                                                        return (
                                                            <select>
                                                                {children.map(
                                                                    child => (
                                                                        <option>
                                                                            {
                                                                                child
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                        </div>

                                        <div className="editor-fragment-surveyjs__actions">
                                            {(() => {
                                                if (responseType) {
                                                    return null;
                                                }
                                                switch (type) {
                                                    case "rating":
                                                        return (
                                                            <div className="editor-fragment-surveyjs__add-rating-button">
                                                                <Button
                                                                    onClick={() =>
                                                                        onChange(
                                                                            {
                                                                                rateValues: {
                                                                                    [value
                                                                                        .values
                                                                                        .length]:
                                                                                        value
                                                                                            .values
                                                                                            .length +
                                                                                        1,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Add Rating
                                                                    Option
                                                                </Button>
                                                            </div>
                                                        );
                                                    case "radiogroup":
                                                    case "checkbox":
                                                        return (
                                                            <div className="editor-fragment-surveyjs__add-choice-button">
                                                                <Button
                                                                    onClick={() =>
                                                                        onChange(
                                                                            {
                                                                                choices: {
                                                                                    [value
                                                                                        .values
                                                                                        .length]:
                                                                                        "",
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    Add Choice
                                                                    Option
                                                                </Button>
                                                            </div>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                        </div>
                                    </div>
                                );
                            case "value":
                                return (
                                    <div className="editor-fragment-surveyjs__value">
                                        <TextField
                                            value={value.text}
                                            fullWidth
                                            onChange={e =>
                                                onChange({
                                                    value: dataKey,
                                                    text: e.target.value,
                                                })
                                            }
                                            disabled={!!responseType}
                                        />
                                        <IconButton
                                            className="editor-fragment-surveyjs__clear-value"
                                            onClick={() => {
                                                onChange(null);
                                            }}
                                            disabled={!!responseType}
                                        >
                                            <RemoveCircleIcon />
                                        </IconButton>
                                    </div>
                                );
                            case "choice":
                                return (
                                    <div className="editor-fragment-surveyjs__value">
                                        <TextField
                                            value={value.text}
                                            fullWidth
                                            onChange={e =>
                                                onChange(e.target.value)
                                            }
                                            disabled={!!responseType}
                                        />
                                        <IconButton
                                            className={`editor-fragment-surveyjs__clear-value editor-fragment-surveyjs__clear-value--${data.type}`}
                                            onClick={() => {
                                                onChange(null);
                                            }}
                                            disabled={!!responseType}
                                        >
                                            {data.type === "checkbox" ? (
                                                <RemoveIcon />
                                            ) : (
                                                <RemoveCircleIcon />
                                            )}
                                        </IconButton>
                                    </div>
                                );
                            case "container":
                                return (
                                    <React.Fragment>{children}</React.Fragment>
                                );
                            case "surveyjs":
                                return (
                                    <React.Fragment>
                                        <div className="editor-fragment-surveyjs__survey">
                                            {children.map((child, i) => (
                                                <div
                                                    key={i}
                                                    className="editor-fragment-surveyjs__question"
                                                >
                                                    {child}
                                                    <IconButton
                                                        className="editor-fragment-surveyjs__remove-question-button"
                                                        onClick={() => {
                                                            onChange({
                                                                [i]: null,
                                                            });
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="editor-fragment-surveyjs__add-question-label">
                                            <Typography variant="caption">
                                                Add a Question
                                            </Typography>
                                        </div>
                                        <div className="editor-fragment-surveyjs__add-question-actions">
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]: {
                                                            type: "text",
                                                        },
                                                    })
                                                }
                                                color="primary"
                                            >
                                                Short Response
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]: {
                                                            type: "rating",
                                                            rateValues: new Array(
                                                                5
                                                            )
                                                                .fill(0)
                                                                .map(
                                                                    (o, i) => ({
                                                                        value: i,
                                                                        text:
                                                                            i +
                                                                            1 +
                                                                            "",
                                                                    })
                                                                ),
                                                        },
                                                    })
                                                }
                                                color="primary"
                                            >
                                                Rating
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]: {
                                                            type: "radiogroup",
                                                            choices: [""],
                                                        },
                                                    })
                                                }
                                                color="primary"
                                            >
                                                Multiple Choice
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]: {
                                                            type: "checkbox",
                                                            choices: [""],
                                                        },
                                                    })
                                                }
                                                color="primary"
                                            >
                                                Checkboxes
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]:
                                                            ReferenceTypes.YES_NO,
                                                    })
                                                }
                                                color="primary"
                                            >
                                                Yes / No
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]:
                                                            ReferenceTypes.CSAT,
                                                    })
                                                }
                                                color="primary"
                                            >
                                                CSAT
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onChange({
                                                        [values.length]:
                                                            ReferenceTypes.NPS,
                                                    })
                                                }
                                                color="primary"
                                            >
                                                NPS
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                );
                            default:
                                return (
                                    <React.Fragment>
                                        <li>
                                            {dataKey}:{JSON.stringify(value)}{" "}
                                            {type}{" "}
                                            {JSON.stringify(data, null, 2)}
                                        </li>
                                        <ul>{children}</ul>
                                    </React.Fragment>
                                );
                        }
                    }}
                />
                <div className="editor-fragment-surveyjs__json-import">
                    <TextField
                        variant="outlined"
                        rows="4"
                        margin="normal"
                        label="Import JSON Survey"
                        multiline
                        fullWidth
                        onChange={({ target: { value } }) => {
                            try {
                                const json = JSON.parse(value);
                                const questions = (json.pages || [])
                                    .reduce(
                                        (questions, { elements }) =>
                                            questions.concat(elements),
                                        []
                                    )
                                    .concat(json.questions || []);
                                onChange({ questions });
                            } catch (error) {
                                console.log(error);
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        ),
    }))
)(EditorPane);

// EditorFragmentTextSurveyJS.defaultProps = {
//     surveyjs: {
//         questions: [
//             {
//                 type: "text",
//                 title: "",
//             },
//         ],
//     },
// };

export default EditorFragmentTextSurveyJS;
