import React from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import "./ResponseNumber.css";

const ResponseNumber = compose(
    connect(
        (
            { surveys, responsesById },
            { responseId, fragmentId, parentFragmentId }
        ) => {
            const isChild = !!(fragmentId === null && parentFragmentId);
            const response = responsesById[responseId];
            const survey = response ? surveys[response.surveyId] : undefined;
            return {
                response,
                survey,
                isChild,
            };
        }
    ),
    // Make number for primary responses.
    withProps(({ survey, response }) => {
        const fragments = survey ? survey.fragments : [];
        const index = response
            ? fragments.findIndex(
                  ({ fragmentId }) => fragmentId === response.fragmentId
              )
            : -1;
        const fragment = index + 1 ? fragments[index] : undefined;
        return { fragment, number: `#${index + 1}` };
    }),
    // Make number for secondary responses.
    withProps(({ index, number, isChild }) => {
        if (!isChild) return {};
        return { number: `${number}.${index + 1}` };
    })
)(({ number }) => <React.Fragment>{number}</React.Fragment>);

export default ResponseNumber;
