import axios from "axios";
import types from "store/types";
import { throttle, memoize } from "lodash";
import { combine, promote } from "redux-intervention";
import debounce from "util/debounce";

const retrieveDownload = (downloadId, downloadName = "download.zip") => async (
    dispatch,
    getState
) => {
    if (downloadId === undefined) {
        console.warn("Download was requested without a downloadId.");
        return;
    }
    const {
        CONFIG: { NLX__API_ENDPOINT__REPORTING: REPORTING_ENDPOINT },
        user: {
            session: { token },
        },
        downloadRequestsByDownloadId,
    } = getState();
    const { requestStatus: downloadStatus } =
        downloadRequestsByDownloadId[downloadId] || {};
    if (downloadStatus) {
        console.warn("Download already in progress.");
        return;
    }

    const { signedUrl } = (await axios
        .get(`${REPORTING_ENDPOINT}/zip/download/${downloadId}/link`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })).data;

    let anchor = document.createElement('a');
    anchor.target = '_blank'
    anchor.href = signedUrl
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

// TODO: Update this when there is a route for retrieving reports by surveyId.
const retrieveReports = debounce(
    (dispatch, getState) => {
        const {
            CONFIG: { NLX__API_ENDPOINT__REPORTING: REPORTING_API },
            user: {
                userId,
                session: { token },
            },
        } = getState();
        return axios
            .get(`${REPORTING_API}/zip/u/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                return dispatch({
                    type: types.reports.LOADED,
                    payload: response.data,
                });
            });
    },
    60 * 1000,
    { leading: true }
);

const retrieveBuildStatus = memoize((downloadId) =>
    throttle(
        (dispatch, getState) => {
            const {
                CONFIG: { NLX__API_ENDPOINT__REPORTING: REPORTING_API },
                user: {
                    session: { token },
                },
            } = getState();
            return axios
                .get(`${REPORTING_API}/zip/status/${downloadId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    return dispatch({
                        type: types.reports.LOADED,
                        payload: response.data,
                    });
                });
        },
        1 * 1000 // Once a second.
    )
);

const requestSurveyExport = ({ dispatch, getState }) => (next) => (action) => {
    const { downloadName, surveyId } = action.payload;
    const {
        CONFIG: { NLX__API_ENDPOINT__REPORTING: REPORTING_API },
        user: {
            userId,
            session: { token },
        },
        downloadBuilderBySurveyId: {
            [surveyId]: { selected },
        },
    } = getState();
    const mapSelectionToExportOptions = ({ name, selected, format }) =>
        !selected ? [] : { type: name, format };
    const data = {
        downloadName,
        userId,
        surveyId,
        exportOptions: Object.values(selected)
            .map(mapSelectionToExportOptions)
            .reduce((arr, el) => arr.concat(el), []),
    };
    dispatch({
        type: types.reports.INITIALIZED,
        payload: {
            downloadName,
            files: [],
            surveyId,
            userId,
            downloadStatus: "INITIALIZED",
        },
    });
    dispatch({
        type: types.downloadBuilder.QUEUING,
        payload: {
            downloadName,
            files: [],
            surveyId,
            userId,
            downloadStatus: "QUEUING",
        },
    });
    return axios
        .post(`${REPORTING_API}/surveys/${surveyId}/export`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            const { downloadId } = response.data;
            dispatch({
                type: types.reports.LOADED,
                payload: {
                    ...data,
                    downloadId,
                },
            });
            dispatch({
                type: types.downloadBuilder.QUEUED,
                payload: {
                    ...data,
                    downloadId,
                },
            });
            next(action);
        });
};

// Reporting Service
export default combine({
    [types.requests
        .DOWNLOAD_ARCHIVE]: promote(
        ({ payload: { downloadId, downloadName } }) =>
            retrieveDownload(downloadId, downloadName)
    ),
    [types.requests.BUILD_DOWNLOAD]: requestSurveyExport,
    [types.requests.DOWNLOADS]: promote(() => retrieveReports),
    [types.requests
        .BUILD_PROGRESS]: promote(({ payload: { downloadId } = {} }) =>
        downloadId ? retrieveBuildStatus(downloadId) : null
    ),
});
