import React from "react";
import types from "store/types";
import withGlobalEvents from "enhancers/withGlobalEvents";
import { compose } from "recompose";
import { connect } from "react-redux";

export default ({ event }) => {
    const DispatchGlobalEventComponent = compose(
        connect(),
        withGlobalEvents
        .on(event, ({ dispatch }) => eventInstance => {
            dispatch({
                type: types.browserEvent(event),
                payload: eventInstance,
            });
        })
    )(() => null);
    return <DispatchGlobalEventComponent />;
};
