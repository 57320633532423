import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import types from "store/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    IconButton,
} from "@mui/material";
import withMobileDialog from "enhancers/withMobileDialog";
import { AddAPhoto, Delete as DeleteIcon } from "@mui/icons-material";
import { Logo } from "components";

import "./ImageSelectorDialog.css";

const ClearLogo = compose(
    connect(({ surveyCreator: { survey: { logoUrl } } }) => ({ logoUrl })),
    withProps(({ dispatch, logoUrl }) => ({
        disabled: logoUrl,
        onClick: () => {
            dispatch({
                type: types.modals.CLOSE_MODAL,
            });
            dispatch({
                type: types.surveyCreator.UPDATED_SURVEY,
                payload: { logoUrl: "" },
            });
        },
    }))
)(({ disabled, onClick }) =>
    disabled ? (
        <Grid className="image-selector__grid-item" item>
            <Paper className="paper" onClick={onClick}>
                <Typography variant="button">Empty Logo</Typography>
            </Paper>
        </Grid>
    ) : null
);

const DeleteUserImage = compose(
    connect(),
    withProps(({ dispatch, imageUrl }) => ({
        onClick: () =>
            dispatch({
                type: types.fileUpload.DELETE_IMAGE_FROM_USER,
                payload: { imageUrl },
            }),
    }))
)(({ onClick }) => (
    <IconButton
        aria-label="Delete"
        className="image-selector__delete-button"
        onClick={onClick}
    >
        <DeleteIcon fontSize="small" />
    </IconButton>
));

DeleteUserImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
};

const UploadUserImage = compose(
    connect(),
    withProps(({ dispatch }) => ({
        onChange: event => {
            const formData = new FormData();
            const file = event.target.files[0];
            formData.append("image", file);
            // TODO: Add file to the files middleware so we can easily create an upload loading bar.
            // dispatch({
            //     type: types.files.ADDED,
            //     payload: { }
            // })
            dispatch({
                type: types.fileUpload.UPLOAD_IMAGE_TO_USER,
                payload: { blob: formData },
            });
        },
    }))
)(({ onChange, children = <Button component="span" variant="contained" color="primary">
            <AddAPhoto />
            Upload Logo
        </Button> }) => (
        <React.Fragment>
            <input
                accept="image/*"
                id="upload-image-file"
                type="file"
                onChange={onChange}
            />
            <label htmlFor="upload-image-file">{children}</label>
        </React.Fragment>
));

const UserImageSelectionGrid = compose(
    connect(({ user: { profile: { uploadedImages } } }) => ({
        uploadedImages,
    })),
    withProps(({ dispatch, onSelection }) => ({
        onSelectImage: imageUrl => () => {
            if (onSelection) onSelection(imageUrl);
            dispatch({
                type: types.surveyCreator.UPDATED_SURVEY,
                payload: { logoUrl: imageUrl },
            });
        },
    }))
)(({ uploadedImages, onSelectImage }) => {
    return (
        <Grid
            container
            className="image-selector__grid-items image-selector__image-grid"
            justify="center"
            spacing={16}
        >
            <ClearLogo />
            {uploadedImages.map(imageUrl => (
                <Grid item key={imageUrl} className="image-selector__grid-item">
                    <Paper className="paper" onClick={onSelectImage(imageUrl)}>
                        <Logo size="auto" source={imageUrl} />
                        {/* <img alt="logo" src={imageUrl} /> */}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
});

class ImageSelectorDialog extends React.Component {
    handleClose = () => {
        if (this.props.handleCancel) this.props.handleCancel();
        if (this.props.closeModal) this.props.closeModal();
    };

    render() {
        const {
            profile,
            header,
            text,
            firstButtonText,
            handleClose,
            handleCancel,
            closeModal,
            ...dialogProps
        } = this.props;

        return (
            <div className="image-selector__container">
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    {...dialogProps}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {header}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                        <UserImageSelectionGrid onSelection={closeModal} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                            autoFocus
                        >
                            {firstButtonText}
                        </Button>
                        <span className="expanding-gap" />
                        <UploadUserImage />
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ImageSelectorDialog.propTypes = {
    header: PropTypes.node,
    text: PropTypes.node,
    closeModal: PropTypes.func,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleCancel: PropTypes.func,
    firstButtonText: PropTypes.string,
    ...Dialog.propTypes,
};

ImageSelectorDialog.defaultProps = {
    firstButtonText: "Cancel",
};

export default compose(
    withMobileDialog(),
    connect()
)(ImageSelectorDialog);
