import { clipRanges } from "store/reducers/pageRange";
import types from "store/types";

const clipDates =
    (pageRange) =>
    ({ fromDate, toDate, minAge }) => {
        if (pageRange) {
            const ascending = fromDate < toDate;
            const range = ascending
                ? [fromDate, new Date(toDate - minAge)]
                : [toDate, new Date(fromDate - minAge)];
            const availableRanges = clipRanges(
                pageRange.requestedRanges,
                range
            );

            if (availableRanges.length === 0) return;

            [fromDate, toDate] = ascending
                ? availableRanges[0]
                : availableRanges[availableRanges.length - 1].reverse();
        }
        return { fromDate, toDate };
    };

export const retrievePageRange =
    (pageRange, makeRequest, getRetrievedExtent) =>
    ({
        fromDate = new Date(),
        toDate = new Date("2015"),
        minAge = 15 * /* minutes */ 60 * 1000,
        endpoint,
        ...rest
    }) =>
    async (dispatch, getState) => {
        const availableRange = clipDates(pageRange)({
            fromDate,
            toDate,
            minAge,
        });
        if (!availableRange) return;

        ({ fromDate, toDate } = availableRange);

        const request = makeRequest({
            fromDate,
            toDate,
            minAge,
            ...rest,
        });
        const requestId = endpoint + fromDate + toDate;

        await dispatch({
            type: types.pageRange.REQUESTED,
            payload: {
                ...rest,
                endpoint,
                requestId,
                fromDate,
                toDate,
            },
        });

        const emptyRange = {
            type: types.pageRange.RETRIEVED,
            payload: {
                ...rest,
                endpoint,
                requestId,
                fromDate,
                toDate: fromDate,
            },
        };

        let response;
        try {
            response = await request;
        } catch (e) {
            // We didn't retrieve anything.
            await dispatch(emptyRange);
            throw error;
        }
        if (!response) {
            // TODO: This is probably an error of some kind.
            await dispatch(emptyRange);
        } else {
            const toDate = getRetrievedExtent(response);
            await dispatch({
                type: types.pageRange.RETRIEVED,
                payload: {
                    ...rest,
                    endpoint,
                    requestId,
                    fromDate,
                    toDate,
                },
            });
        }
        return response;
    };
