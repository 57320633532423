import React from "react";
import { connect } from "react-redux";
import { compose, withProps, branch } from "recompose";
import filterDOMProps from "util/filterDOMProps";
import continuous from "enhancers/continuous";
import types from "store/types";
import "./AudioVisualization.css";

const FREQUENCY_VS_LEVEL = compose(
    // Fill the values with the analyser.
    withProps(({ analyser, values }) => {
        if (analyser) {
            analyser.getByteFrequencyData(values);
            return { values };
        } else {
            return {};
        }
    }),
    // Make sure the canvas fits it's container.
    withProps(({ canvas }) => {
        if (canvas.parentElement) {
            let {
                width,
                height,
            } = canvas.parentElement.getBoundingClientRect();
            // This assumes high DPI. We could add the actual DPI value in here.
            canvas.height = (height - 4) * 2;
            canvas.width = width * 2;
        }
        return {};
    }),
    // Draw something onto the canvas
    withProps(
        ({
            values = [],
            canvas,
            analyser,
            barWidth = 2,
            barSpacing = 6,
            numberOfBars = canvas.width / (barWidth + barSpacing),
        }) => {
            const context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = "#F6D565";
            context.lineCap = "round";
            const multiplier =
                (analyser ? analyser.frequencyBinCount : 0) / numberOfBars;

            // Draw rectangle for each frequency bin.
            for (let i = 6; i < numberOfBars; i++) {
                let x;
                let magnitude = 0;
                let offset = Math.floor(i * multiplier);

                // gotta sum/average the block, or we miss narrow-bandwidth spikes
                for (var j = 0; j < multiplier; j++) {
                    magnitude += values[offset + j];
                }

                // set magnitude / height
                magnitude = magnitude / multiplier;

                // have the bars move the correct direction
                x = canvas.width / 2 + (i - 7) * barSpacing;
                // fill the context
                context.fillStyle =
                    "hsl( " +
                    Math.round((i * 360) / numberOfBars) +
                    ", 100%, 50%)";
                context.fillRect(x, canvas.height, barWidth, -magnitude);

                // Mirror to the other side.
                x = canvas.width / 2 - (i - 7) * barSpacing;
                // fill the context
                context.fillStyle =
                    "hsl( " +
                    Math.round((i * 360) / numberOfBars) +
                    ", 100%, 50%)";
                context.fillRect(x, canvas.height, barWidth, -magnitude);
            }
            return {};
        }
    )
);

const animations = {
    FREQUENCY_VS_LEVEL
};

const AudioVisualization = compose(
    // We'll need a canvas down the line.
    withProps({
        canvas: (() => {
            const canvas = document.createElement("canvas");
            canvas.classList.add("analyser__canvas");
            return canvas;
        })(),
    }),
    connect(
        ({ recorder: { analyser } }) => ({
            analyser,
        }),
        dispatch => {
            dispatch({ type: types.requests.RECORDER });
            return { dispatch };
        }
    ),
    // We don't want to create these on every render.
    withProps(({ analyser }) => {
        return analyser
            ? {
                  values: new Uint8Array(analyser.frequencyBinCount),
              }
            : {};
    }),
    branch(
        ({ type }) => animations[type] instanceof Function,
        compose(
            // Set the canvas as a child.
            withProps(({ canvas, ...props }) => ({
                canvas,
                children: (
                    <div
                        {...filterDOMProps(props)}
                        className="analyser"
                        ref={ref => {
                            if (ref) {
                                try {
                                    ref.replaceChild(canvas, canvas);
                                } catch (e) {
                                    ref.appendChild(canvas);
                                }
                            }
                        }}
                    />
                ),
            })),
            // Everything after this will be rendered in an animation loop.
            continuous(),
            // TODO: Use the props to draw the canvas animation here.
            // TODO: We could generate the branches from the animations object, or maybe there's a better idea.
            FREQUENCY_VS_LEVEL
        ),
        // Render an empty child.
        withProps(({ ...props }) => ({
            children: <div {...filterDOMProps(props)} className="analyser" />
        }))
    )
)(({ children }) => children);

export default AudioVisualization;
