import { parse, create } from "js-video-url-parser";

const audioOptions = ["preload", "autoplay", "loop", "muted"];
const audioOptionsExpression = new RegExp(
    `^(?:(${audioOptions.join("|")})(?:,| |, )?)*$`,
    "i"
);
const anchorTagExpression = /<a[^>]*href="([^"]+)"[^>]*(?:\/>|>(.*?)(?=<\/a>)<\/a>)/g;

export default (html = "") =>
    html.replace(anchorTagExpression, (link, href, children = "") => {
        const videoInfo = parse(href);
        if (videoInfo) {
            const embedLink = create({
                videoInfo,
                format: "embed",
            });
            return `<div class="showdown-video-embed"><iframe width="100%" height="100%" frameborder="0" allowfullscreen src="${embedLink}" /></div>`;
        } else if (/\.(wav|mp3|ogg|m4a|flac|opus|aac|webm|aiff)$/i.test(href)) {
            const linkOptions = audioOptionsExpression.test(children)
                ? children.replace(/, ?/g, " ")
                : "";
            return `<audio preload ${linkOptions} controls controlsList="nodownload">
                 <source src="${href}" />
               </audio>`;
        } else {
            return link;
        }
    });
