import React from "react";
import PropTypes from "prop-types";
import filterDOMProps from "util/filterDOMProps";
import "./NamedDivider.css";

import Typography from "@mui/material/Typography";

const NamedDivider = ({
    name,
    location = "left",
    children,
    thickness,
    ...props
}) => (
    <div className="named-divider" {...filterDOMProps(props)}>
        {location === "center" || location === "right" ? (
            <hr style={{ borderTopWidth: thickness }} />
        ) : null}
        {name ? (
            <Typography className="named-divider__name">{name}</Typography>
        ) : children ? (
            children
        ) : null}
        {location === "center" || location === "left" ? (
            <hr style={{ borderTopWidth: thickness }} />
        ) : null}
    </div>
);

NamedDivider.propType = {
    name: PropTypes.string,
    children: PropTypes.element,
    thickness: PropTypes.number,
};

NamedDivider.defaultProps = {
    thickness: 2,
};

export default NamedDivider;
