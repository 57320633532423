import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import continuous from "enhancers/continuous";
import { compose, withProps } from "recompose";

import "./CountdownTimer.css";

const CountdownTimer = compose(
    withProps(({ startTime = null }) => ({ startTime: moment(startTime).toDate() })),
    continuous({
        limiter: 250, // We're only updating once a second, so let's shrink the window a little bit.
        //continue: ({ startTime, seconds }) => startTime + seconds < new Date(),
    }),
    withProps(({ startTime, seconds }) => ({
        time:
            seconds * 1000 -
            (Number.isNaN(+startTime) ? 0 : +new Date() - startTime),
    })),
    withProps(({ time }) => ({ isNegative: time < 0 }))
)(({ time, isNegative, format }) => (
    <React.Fragment>
        {isNegative ? moment(0).format(format) : moment(time).format(format)}
    </React.Fragment>
));

CountdownTimer.propTypes = {
    startTime: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
        PropTypes.number,
    ]),
    seconds: PropTypes.number,
};

CountdownTimer.defaultProps = {
    seconds: 0,
};

export default CountdownTimer;
