import React from "react";
import PropTypes from "prop-types";

/**
 * FormBuilder inverts the typical React render to render through objects.
 */
class FormBuilderElement extends React.PureComponent {
    render() {
        const {
            data,
            type,
            dataKey,
            value,
            values,
            renderFormElement,
            onChange,
            onFormAction,
        } = this.props;
        return (
            <React.Fragment>
                {renderFormElement({
                    data,
                    type,
                    dataKey,
                    value,
                    values,
                    onChange: value => onChange({ [dataKey]: value }),
                    onFormAction,
                    children: Array.isArray(values) ? (
                        values.map((value, i) => (
                            <FormBuilderElement
                                key={value.key || i}
                                dataKey={value.key || i}
                                {...value}
                                renderFormElement={renderFormElement}
                                onChange={value =>
                                    onChange({ [dataKey]: value })
                                }
                                onFormAction={onFormAction}
                            />
                        ))
                    ) : value instanceof Object ? (
                        <FormBuilderElement
                            key={value.key}
                            dataKey={value.key}
                            {...value}
                            renderFormElement={renderFormElement}
                            onChange={value => onChange({ [dataKey]: value })}
                            onFormAction={onFormAction}
                        />
                    ) : null,
                })}
            </React.Fragment>
        );
    }
}

FormBuilderElement.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    // We need to use dataKey because key is used by React.
    dataKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number, // TODO is this needed?
    ]),
    value: PropTypes.any,
    values: PropTypes.array,
    onChange: PropTypes.func,
    onFormAction: PropTypes.func,
    renderFormElement: PropTypes.func,
};

const FormBuilderRenderDebug = ({
    data,
    type,
    dataKey,
    value,
    values,
    onChange,
    onFormAction,
    children,
}) => (
    <React.Fragment>
        <li>
            {dataKey}:{value} {type} {JSON.stringify(data, null, 2)}
        </li>
        <ul>{children}</ul>
    </React.Fragment>
);

FormBuilderRenderDebug.propTypes = FormBuilderElement.propTypes;

const FormBuilder = ({
    manifest,
    onChange,
    onFormAction,
    renderFormElement,
}) => (
    <FormBuilderElement
        {...manifest}
        dataKey={manifest.key}
        onChange={onChange}
        onFormAction={onFormAction}
        renderFormElement={renderFormElement}
    />
);

FormBuilder.propTypes = {
    manifest: PropTypes.object,
    onChange: PropTypes.func,
    onFormAction: PropTypes.func,
    renderFormElement: PropTypes.func,
};

FormBuilder.defaultProps = {
    onChange: value => {
        console.log("CHANGE: ", value);
    },
    onFormAction: value => {
        console.log("FORM ACTION: ", value);
    },
    renderFormElement: FormBuilderRenderDebug,
    manifest: {},
};

export default FormBuilder;
export { FormBuilderElement, FormBuilderRenderDebug };
