import { combine, promiseNext } from "redux-intervention";
import types from "store/types";

const UNSAVED = "UNSAVED_SURVEY_CREATOR";

const preventUnsavedNavigation = ({ dispatch, getState }) => (next) => (
    action
) => {
    switch (action.type) {
        case types.requests.PREVENT_NAVIGATION:
        case types.requests.UNPREVENT_NAVIGATION:
            return next(action);
        default:
            const {
                history: {
                    surveyCreator: {
                        survey: { past: surveyHistory, present: survey },
                    },
                },
                surveyCreator: { saveSurvey },
                preventingNavigation,
            } = getState();

            if (
                // There have been changes.
                surveyHistory.length > 1 &&
                // And those changed have not been saved.
                survey !== saveSurvey
            ) {
                // Enable navigation prompt.
                if (!preventingNavigation.has(UNSAVED))
                    dispatch({
                        type: types.requests.PREVENT_NAVIGATION,
                        payload: UNSAVED,
                    });
            } else {
                // Remove navigation prompt.
                if (preventingNavigation.has(UNSAVED))
                    dispatch({
                        type: types.requests.UNPREVENT_NAVIGATION,
                        payload: UNSAVED,
                    });
            }
            return next(action);
    }
};

const confirmUnsavedInternalNavigation = ({ dispatch, getState }) => (next) => (
    action
) => {
    const { preventingNavigation } = getState();
    if (preventingNavigation.has(UNSAVED)) {
        dispatch({
            type: types.modals.REQUEST_CONFIRMATION,
            payload: {
                modalProps: {
                    text: "You've made unsaved changes to the survey.",
                    header: "Save Survey",
                    onDismiss: () => {
                        // We aren't letting the navigation action go through on close.
                    },
                    onCancel: () => {
                        dispatch({
                            type: types.surveyCreator.RESET_SURVEY,
                        });
                        next(action);
                    },
                    cancelText: "Discard",
                    onConfirm: () => {
                        dispatch({
                            type: types.surveyCreator.SAVED,
                        });
                        next(action);
                    },
                    confirmText: "Save Changes",
                },
            },
        });
    } else {
        next(action);
    }
};

// NOTE: This needs to come before navigation.
const surveyCreatorUnsavedNavigation = promiseNext(
    preventUnsavedNavigation,
    combine({
        [types.requests.NAVIGATION]: confirmUnsavedInternalNavigation,
    })
);

export default surveyCreatorUnsavedNavigation;
