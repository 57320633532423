const setOnBeforeUnload = (getState) => {
    window.onbeforeunload = function () {
        // Returning `undefined` will not show a dialog.
        const preventingNavigation = getState().preventingNavigation;
        return preventingNavigation.size === 0 ? undefined : true;
    };
};

export default ({ getState, dispatch }) => {
    setOnBeforeUnload(getState);
    // TODO: Create this as an enhancer instead of a middleware.
    return (next) => (action) => next(action);
};
