import React from "react";
import { compose, withProps } from "recompose";
import { connect } from "react-redux";
import "./DownloadBuilderFileSummary.css";

const DownloadBuilderDefault = {
    stage: 0,
    selected: {},
    files: [],
    ignore: {},
};

const DownloadBuilderFileSummary = compose(
    connect(({ downloadBuilderBySurveyId }, { surveyId }) => ({
        downloadBuilder:
            downloadBuilderBySurveyId[surveyId] || DownloadBuilderDefault,
    })),
    withProps(({ downloadBuilder: { files = [], ignore = {} } }) => ({
        includedFiles: files
            .map(file => ({
                ...file,
                included: !ignore[file.filename],
            }))
            .filter(({ included }) => included),
    })),
    withProps(({ includedFiles }) => ({
        includedFilesSummaries: Object.values(
            includedFiles.reduce(
                (summary, file) => ({
                    ...summary,
                    [file.description]: {
                        description: file.description,
                        total:
                            ((summary[file.description] || {}).total || 0) + 1,
                    },
                }),
                {}
            )
        ),
    }))
)(({ includedFilesSummaries }) => (
    <div className="download-builder-file-summary__container">
        <div className="download-builder-file-summary">
            {includedFilesSummaries.length === 0 ? (
                <div className="download-builder-preview__no-files">
                    No files...
                </div>
            ) : null}
            {includedFilesSummaries
                .sort(
                    // Sort by total, and then by description.
                    (
                        { total: a, description: c },
                        { total: b, description: d }
                    ) => (a === b ? (c < d ? -1 : 1) : a < b ? -1 : 1)
                )
                .map(({ description, total }) => (
                    <div
                        className="download-builder-file-summary__file-summary"
                        key={description}
                    >
                        <div className="download-builder-file-summary__file-summary-description-total">
                            {total}
                        </div>
                        <div className="download-builder-file-summary__file-summary-description">
                            {description}
                        </div>
                    </div>
                ))}
        </div>
    </div>
));

export default DownloadBuilderFileSummary;
