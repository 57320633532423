import React from "react";
import "./EditorFragmentTemplate.css";
import { connect } from "react-redux";
import { compose, withProps, withStateHandlers } from "recompose";
import { EditorTemplateList, EditorPane } from "components";
import {
    IconButton,
    TextField,
    FormControl,
    InputAdornment,
} from "@mui/material";
import {
    Add as AddIcon,
    InsertLink as InsertLinkIcon,
} from "@mui/icons-material";
import withValidation from "enhancers/withValidation";
import types from "store/types";

const surveyLexLinkExpression = /https:\/\/app\.surveylex\.com\/surveys\/(?<surveyId>\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/;

const AddTemplateFromLink = compose(
    connect(({ surveyCreator: { survey = {} } }) => ({ survey })),
    withStateHandlers(({ value = "" }) => ({ value }), {
        onChange: (state, { onChange }) => (value) => {
            if (onChange instanceof Function) onChange({ value });
            return { value };
        },
        onSubmitTemplateLink: (
            { value },
            { dispatch, survey, fragmentIndex }
        ) => (event) => {
            const {
                groups: { surveyId },
            } = value.match(surveyLexLinkExpression);
            const fragmentId = survey.fragments[fragmentIndex].fragmentId;
            dispatch({
                type: types.surveyCreator.APPLIED_TEMPLATE,
                payload: {
                    surveyUrl: `https://api.neurolex.ai/1.0/object/surveys/taker/${surveyId}`,
                },
            }).then(() => {
                const fragmentIndex = survey.fragments.findIndex(
                    ({ fragmentId: id }) => fragmentId === id
                );
                dispatch({
                    type: types.surveyCreator.DELETED_FRAGMENT,
                    payload: fragmentIndex,
                });
            });
        },
    }),
    withValidation(
        (value) =>
            surveyLexLinkExpression.test(value) ||
            "The URL must be a SurveyLex survey link."
    )
)(({ onSubmitTemplateLink, value, onChange, valid, errorMessage }) => (
    <FormControl
        error={!valid}
        fullWidth
        onSubmit={(e) => {
            e.preventDefault();
            if (valid) onSubmitTemplateLink();
        }}
        component="form"
        className="add-template-from-link"
    >
        <TextField
            fullWidth
            // error={!valid}
            // helperText={!valid ? value : ""}
            onChange={(event) => {
                onChange(event.target.value);
            }}
            variant="outlined"
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <InsertLinkIcon />
                    </InputAdornment>
                ),
            }}
            label="Use SurveyLex Link as Template"
        />
        <IconButton
            color="primary"
            variant="contained"
            onClick={valid ? onSubmitTemplateLink : () => {}}
            disabled={!valid}
        >
            <AddIcon />
        </IconButton>
    </FormControl>
));

const EditorFragmentTemplate = compose(
    connect(({ surveyCreator: { survey: { fragments = [] } = {} } }) => ({
        fragments,
    })),
    withProps(({ fragments, fragmentIndex }) => ({
        children: (
            <div className="editor-fragment-template">
                <div className="editor-fragment-template__top-section">
                    <AddTemplateFromLink fragmentIndex={fragmentIndex} />
                </div>
                <EditorTemplateList fragmentIndex={fragmentIndex} />
            </div>
        ),
    }))
)(EditorPane);

export default EditorFragmentTemplate;
