import React from "react";
import LogoWhite from "assets/logo-white.svg";
import "./Survey.css";
import FullscreenLayout from "layouts/FullscreenLayout";
import { Button } from "@mui/material";
import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import { Top, ModalContainer, Loading } from "components";
import cleanText from "util/cleanText";
import types from "store/types";
import {
    SurveyCompletion,
    SurveyFragmentInfo,
    SurveyFragmentAudioStandard,
    SurveyFragmentConsent,
    SurveyFragmentTextSurveyJS,
    SurveyFragmentBranch,
} from "components";

import AudioErrorDialog from "components/AudioErrorDialog/AudioErrorDialog";

const SkipQuestion = ({ type }) => <div>{type}</div>;
const defaultFragmentTypeToComponent = {
    CONSENT: SurveyFragmentConsent,
    INFO: SurveyFragmentInfo,
    AUDIO_STANDARD: SurveyFragmentAudioStandard,
    VIDEO_STANDARD: SkipQuestion,
    TRANSITION_INSTRUCTOR_BOSTON_NAMING_TEST: SkipQuestion,
    AUDIO_BOSTON_NAMING_TEST: SkipQuestion,
    TEXT_SURVEYJS: SurveyFragmentTextSurveyJS,
    UPLOAD: SkipQuestion,
    DEANONYMIZATION: SkipQuestion,
    BRANCH_REDIRECT: SurveyFragmentBranch,
    TEMPLATE: SkipQuestion
};

const SurveyNotSupported = compose(
    connect(
        ({
            surveyTaker: { survey },
            recorder: { unsupported, error },
            CONFIG,
        }) => ({
            survey,
            unsupported,
            surveyEndpoint: CONFIG.APPLICATION__SURVEY_ENDPOINT,
            isError: error instanceof Object,
        })
    ),
    withProps(({ survey: { fragments = [] } = {} }) => ({
        containsAudioFragment:
            fragments.filter(({ type }) => type === "AUDIO_STANDARD").length >
            0,
    })),
    withProps(({ survey: { surveyId = "" } = {}, surveyEndpoint }) => ({
        endpoint: `${surveyEndpoint}/${surveyId}`,
    })),
    withProps({
        browser: window.navigator.userAgent,
    })
)(({ unsupported, containsAudioFragment, browser, endpoint, isError }) =>
    (isError || unsupported) && containsAudioFragment ? (
        <AudioErrorDialog />
    ) : null
);

const HideWhileUploading = compose(
    connect(({ preventingNavigation }) => ({ preventingNavigation })),
    mapProps(({ preventingNavigation, children, dispatch, ...props }) => ({
        ...props,
        children: preventingNavigation.has("SURVEY_TAKER_UPLOAD")
            ? null
            : children,
    }))
)(React.Fragment);
const PoweredBy = compose(
    connect(),
    withProps(({ dispatch }) => ({
        onClick: () => {
            // TODO: Make sure we wait until the survey is completely submitted.
            dispatch({
                type: types.requests.NAVIGATION,
                payload: {
                    location: "https://www.surveylex.com",
                },
            });
        },
    }))
)(({ onClick }) => (
    <div className="powered-by-surveylex__container">
        <Button onClick={onClick} variant="contained" color="primary">
            Powered by
            <span className="powered-by-surveylex__typography">
                <img src={LogoWhite} alt="SurveyLex" height={15} />
            </span>
        </Button>
    </div>
));

const Survey = compose(
    connect(({ surveyTaker }, { surveyId }) => ({
        surveyId: surveyTaker.surveyId,
        survey: surveyTaker.survey,
        fragmentIndex: surveyTaker.fragmentIndex,
        fragmentId: surveyTaker.fragmentId,
    })),
    withProps(({ survey, fragmentIndex }) => ({
        surveyCompleted: survey && fragmentIndex === -1,
    })),
    withProps(({ survey, fragmentIndex }) => ({
        surveyFragment:
            survey && survey.fragments
                ? survey.fragments[fragmentIndex]
                : undefined,
    })),
    withProps(
        ({
            surveyFragment,
            fragmentTypeToComponent = defaultFragmentTypeToComponent,
        }) => ({
            SurveyFragment: surveyFragment
                ? fragmentTypeToComponent[surveyFragment.type]
                : undefined,
        })
    ),
    withProps(({ dispatch, surveyId, fragmentId, fragmentIndex }) => ({
        onRetake: () => {
            dispatch({
                type: types.requests.SURVEY_TAKER,
                payload: {
                    surveyId,
                    retake: true,
                },
            });
        },
        onCompleteFragment: () =>
            Promise.all([
                dispatch({
                    type: types.surveyTaker.COMPLETED_FRAGMENT,
                    payload: {
                        surveyId,
                        fragmentIndex,
                        fragmentId,
                    },
                }),
                dispatch({
                    type: types.surveyTaker.SET_FRAGMENT,
                }),
            ]),
    }))
)(
    ({
        surveyNotFound,
        surveyCompleted,
        survey,
        fragmentIndex,
        surveyFragment = {},
        SurveyFragment,
        onCompleteFragment,
        onRetake,
    }) => (
        <FullscreenLayout
            style={{
                fontSize: {
                    Standard: 18,
                    Large: 22,
                    "Extra Large": 28,
                }[(survey && survey.interfaceSize) || "Standard"],
            }}
        >
            {surveyCompleted ? (
                <React.Fragment>
                    <Top logoUrl={survey.logoUrl} title={survey.title} />
                    <div className="container survey-complete-screen">
                        <div className="description">
                            <SurveyCompletion>
                                <p>
                                    Please wait on this screen for a moment for
                                    your submission to finish uploading:
                                </p>
                            </SurveyCompletion>
                            <HideWhileUploading>
                                <div>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={onRetake}
                                    >
                                        Take Again
                                    </Button>
                                </div>
                                <Button
                                    href="https://www.surveylex.com"
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    className="survey-complete-screen__powered-by-button"
                                >
                                    <div className="survey-complete-screen__powered-by">
                                        <div className="survey-complete-screen__logo">
                                            <img
                                                src={LogoWhite}
                                                alt="SurveyLex"
                                                height={15}
                                            />
                                        </div>
                                        <div className="survey-complete-screen__call-to-action">
                                            Make your own voice surveys today!
                                        </div>
                                    </div>
                                </Button>
                            </HideWhileUploading>
                        </div>
                    </div>
                </React.Fragment>
            ) : surveyCompleted === false && surveyFragment ? (
                <React.Fragment>
                    <div
                        className={`fragment-${fragmentIndex} fragment-${surveyFragment.type.toLowerCase()}`}
                    >
                        <PoweredBy />
                        {/* <SurveyCompletion /> */}
                        <SurveyNotSupported />
                        <Top
                            logoUrl={survey.logoUrl}
                            title={survey.title}
                            context={
                                <React.Fragment>
                                    <div>
                                        {fragmentIndex + 1} of{" "}
                                        {survey.fragments.length}
                                    </div>
                                    {/* <Stepper alternativeStep activeStep={fragmentIndex}>
                                    {survey.fragments.map((o, i) => (
                                        <Step key={i}>
                                            <StepLabel  />
                                        </Step>
                                    ))}
                                </Stepper> */}
                                    <div className="survey-header__subtitle">
                                        {survey.fragments[fragmentIndex].data
                                            ? cleanText(
                                                  survey.fragments[
                                                      fragmentIndex
                                                  ].data.prompt
                                              )
                                            : "Text Questions"}
                                    </div>
                                </React.Fragment>
                            }
                        />
                        <SurveyFragment
                            key={surveyFragment.fragmentId} // Force updates.
                            fragment={surveyFragment}
                            {...surveyFragment.data}
                            isFirst={fragmentIndex === 0}
                            isLast={
                                fragmentIndex === survey.fragments.length - 1
                            }
                            onComplete={onCompleteFragment}
                        />
                    </div>
                    <ModalContainer />
                </React.Fragment>
            ) : surveyNotFound ? (
                <React.Fragment>
                    <PoweredBy />
                    <div className="container">
                        <div className="description">
                            This survey does not exist or is no longer able to
                            be taken. Please check your URL and try again.
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <PoweredBy />
                    <Loading />
                </React.Fragment>
            )}
        </FullscreenLayout>
    )
);
export default Survey;
