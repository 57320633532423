import React from "react";
import PropTypes from "prop-types";
import {
    CSSBaseline,
    ModalContainer,
    SnackBarContainer,
    SurveyLexHeaderBar,
    Footer,
} from "components";
import "./UserAppLayout.css";

// This is the standard layout of the user facing portion of the application.
const UserAppLayout = ({ className, children }) => (
    <div className={`app-container ${className}`}>
        <CSSBaseline />
        <SurveyLexHeaderBar />
        <div className="app-content">{children}</div>
        <Footer />
        <ModalContainer />
        <SnackBarContainer />
    </div>
);

UserAppLayout.defaultProps = {
    className: "",
};

UserAppLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default UserAppLayout;
