import { connect } from "react-redux";
import types from "store/types";

export default (
    field,
    event = "onChange",
    getValueFromEvent = ({ target: { value } }) => value,
    defaultValue = ""
) =>
    connect(
        (
            {
                surveyCreator: {
                    survey: { fragments },
                },
            },
            { fragmentIndex }
        ) => {
            const { data: { [field]: value = defaultValue } = {} } =
                fragments[fragmentIndex] || {};
            return { value };
        },
        undefined,
        ({ value }, { dispatch }, { fragmentIndex, ...props }) => ({
            value,
            [event]: event => {
                const value = getValueFromEvent(event);
                dispatch({
                    type: types.surveyCreator.UPDATED_FRAGMENT,
                    payload: {
                        id: fragmentIndex,
                        // fragmentId,
                        data: {
                            [field]: value,
                        },
                    },
                });
            },
            ...props,
        })
    );
