import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { Typography, Card, CardContent } from "@mui/material";
import { Line as LineChart } from "react-chartjs-2";
import { cloneDeep } from "lodash";
import chartOptions from "util/chartJsOptions";
import "./SurveyDropOffChartCard.css";

const SurveyDropOffChartCard = compose(
    connect(({ surveys, responsesByFragmentId }, { surveyId }) => ({
        fragments: surveys[surveyId] ? surveys[surveyId].fragments : [],
        responsesByFragmentId,
    })),
    withProps(({ fragments }) => ({
        fragmentIds: fragments.map(({ fragmentId }) => fragmentId),
    })),
    withProps(({ fragmentIds, responsesByFragmentId }) => {
        const bins = fragmentIds.map((zero, index) => index + 1);
        return {
            data: {
                labels: bins.reduce(
                    (labels, bin) => labels.concat("#" + bin),
                    []
                ),
                datasets: [
                    {
                        label: "Completed",
                        backgroundColor: "black",
                        fill: false,
                        pointBackgroundColor: "white",
                        pointRadius: 4,
                        pointBorderColor: "#3F71F7",
                        lineTension: 0,
                        borderColor: "#3F71F7",
                        borderWidth: 4,
                        data: fragmentIds.map(
                            (fragmentId) =>
                                Object.values(
                                    responsesByFragmentId[fragmentId] || {}
                                ).length
                        ),
                    },
                ],
            },
        };
    })
)(({ data, className, title }) => {
    const options = cloneDeep(chartOptions);
    options.scales.x.scaleLabel = {
            display: false,
            fontColor: "#666",
            labelString: "Question",
        } ;
    return (
        <Card className={`${className} flex-card`}>
            <CardContent className="chart-card__title">
                <Typography variant="overline">{title}</Typography>
            </CardContent>
            <CardContent className="chart-card__chart">
                <LineChart data={data} options={options} />
            </CardContent>
        </Card>
    );
});

export default SurveyDropOffChartCard;
