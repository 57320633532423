import types from "store/types";
import { keyBy, memoize, debounce, mergeWith, unionBy } from "lodash";
import axios from "axios";

const retrievePublicSurvey = memoize(surveyId =>
    debounce(
        (dispatch, getState) => {
            const {
                surveyTaker: { survey: currentSurvey },
                CONFIG: { NLX__API_ENDPOINT__OBJECT: OBJECT_ENDPOINT },
            } = getState();

            if (
                currentSurvey instanceof Object &&
                currentSurvey.surveyId === surveyId
            )
                return Promise.resolve();

            const endpoint = OBJECT_ENDPOINT + "/surveys/taker/" + surveyId;
            return axios.get(endpoint, { params: { rewrite: true } }).then(res => {
                if (res.data) {
                    dispatch({
                        type: types.surveys.LOADED,
                        payload: [res.data],
                    });
                    return dispatch({
                        type: types.surveyTaker.LOADED,
                        payload: res.data,
                    });
                }
            });
        },
        // Update at max once a minute.
        60 * 1000,
        { leading: true }
    )
);

const mergeSessions = (session, _session) =>
    mergeWith({ ...session }, _session, (o, _o) => {
        switch (true) {
            case Array.isArray(o) &&
                // Array.isArray(_o) &&
                o.length &&
                o[0] instanceof Object &&
                typeof o[0].responseId === "string":
                return unionBy(o, _o, "responseId");
            default:
                return undefined;
        }
    });

const retrieveSessionStatus = (dispatch, getState) => {
    const {
        surveyTaker: { sessionId },
        CONFIG: { NLX__API_ENDPOINT__OBJECT: OBJECT_ENDPOINT },
    } = getState();
    const endpoint = OBJECT_ENDPOINT + "/sessions/progress/" + sessionId;
    return axios
        .get(endpoint)
        .then(res => {
            if (res.data) {
                dispatch({
                    type: types.session.UPDATED,
                    payload: mergeSessions(getState().session, res.data),
                });
            }
            return res.data;
        })
        .catch(() => {
            // TODO: This surpresses the 404 error but we probably need to rewrite this to handle this error better
            console.warn("Session hasn't been created yet.");
        });
};

const retrieveSessionStatusMinutely = debounce(
    retrieveSessionStatus,
    // Update at max once a minute.
    60 * 1000,
    { leading: true }
);

const retrieveSessionStatusOtherSecondly = debounce(
    retrieveSessionStatus,
    // Update at max once a minute.
    2 * 1000,
    { leading: true }
);
const getNextFragmentId = (
    /* surveyTaker */ {
        survey = { fragments: [] },
        session = {},
        completedFragments = [],
    }
) => {
    const responsesAndCompletedFragments = []
        .concat(session.responses || [])
        .concat(completedFragments);
    const responsesByFragmentId = keyBy(
        responsesAndCompletedFragments,
        "fragmentId"
    );
    // Find the first fragment that doesn't have an associated response.
    return survey.fragments.findIndex(
        ({ fragmentId }) => !responsesByFragmentId[fragmentId]
    );
};

export default ({ getState, dispatch }) => next => action => {
    switch (action.type) {
        case types.session.INITIALIZED:
            {
                next(action);
                const { session } = getState();
                dispatch({
                    type: types.surveyTaker.SET_SESSION,
                    payload: {
                        // Merge the session into our local session.
                        session,
                        sessionId: session.sessionId,
                    },
                });
            }
            break;
        case types.session.ADDED_RESPONSE:
        case types.session.ADDED_SAMPLE:
        case types.session.UPDATED:
            {
                next(action);
                const { session } = getState();
                dispatch({
                    type: types.surveyTaker.SET_SESSION,
                    payload: {
                        // Merge the session into our local session.
                        session: mergeSessions(
                            getState().surveyTaker.session,
                            session
                        ),
                        sessionId: session.sessionId,
                    },
                });
            }
            break;
        case types.requests.SURVEY_TAKER: {
            const { surveyId, retake = false, metadata } = action.payload;
            const { surveyTaker } = getState();

            if (retake) {
                const { session: { sessionId } = {} } = getState();
                return dispatch({
                    type: types.requests.SESSION,
                    payload: {
                        surveyId,
                        force: true,
                        metadata: {
                            retake: true,
                            previousSession: sessionId,
                        },
                    },
                })
                    .then(() => {
                        return dispatch({
                            ...action,
                            type: types.surveyTaker.INITIALIZED,
                        });
                    })
                    .then(() => {
                        next(action);
                    });
            }

            if (!surveyId) {
                console.error("Survey Taker requires a surveyId.");
                next(action);
                return false;
            }

            // If the survey has already been loaded, and the surveyId is the same, return.
            if (
                surveyTaker.survey &&
                !(surveyId === surveyTaker.survey.surveyId)
            ) {
                next(action);
                return true;
            }

            return dispatch({
                ...action,
                type: types.surveyTaker.INITIALIZED,
            })
                .then(() =>
                    Promise.all([
                        // Get the single survey.
                        dispatch(retrievePublicSurvey(surveyId)),
                        // Get a session for taking this survey.
                        dispatch({
                            type: types.requests.SESSION,
                            payload: {
                                surveyId,
                                metadata,
                            },
                        }),
                    ])
                )
                .then(() => {
                    next(action);
                });
        }
        // MAGIC: Having no payload to set fragment, sets the fragment to the current next fragment.
        case types.surveyTaker.SET_FRAGMENT:
            if (action.payload === undefined) {
                const surveyTaker = getState().surveyTaker;
                const fragmentIndex = getNextFragmentId(surveyTaker);
                const fragment = surveyTaker.survey.fragments[fragmentIndex];
                const fragmentId = fragment ? fragment.fragmentId : undefined;

                action.payload = {
                    fragmentId,
                    fragmentIndex,
                };
            }
            // Scroll to the top when we encounter a new fragment.
            const { surveyTaker: { fragmentId: before } = {} } = getState();
            next(action);
            const { surveyTaker: { fragmentId: after } = {} } = getState();
            if (before !== after) window.scrollTo(0, 0);
            break;
        case types.navigation.ARRIVED:
            next(action);
            if (getState().view === "take-survey") {
                const { fragmentId, surveyId } = action.payload.match.params;
                const fragmentIndex = fragmentId ? parseInt(fragmentId, 10) : 0;
                // If the survey hasn't been loaded, see if the survey exists on the server, and take us to the proper location.
                // TODO: We might want to move these `.then()` statements into the `types.requests.SURVEY_TAKER` channel. I think this is mainly here for historical reasons.
                dispatch({
                    type: types.requests.SURVEY_TAKER,
                    payload: { surveyId, fragmentIndex },
                })
                    .then(() => dispatch(retrieveSessionStatus))
                    .then(() =>
                        dispatch((dispatch, getState) => {
                            const { surveyTaker } = getState();
                            // These could occur if the survey simply doesn't exist, or we have
                            if (!(surveyTaker.survey instanceof Object)) {
                                console.warn("The survey is missing.");
                                return false;
                            }
                            if (
                                // The survey contains fragments.
                                !(
                                    Array.isArray(
                                        surveyTaker.survey.fragments
                                    ) && surveyTaker.survey.fragments.length > 0
                                )
                            ) {
                                console.warn(
                                    "The survey doesn't contain any fragments."
                                );
                                return false;
                            }
                            const fragmentIndex = fragmentId
                                ? parseInt(fragmentId, 10)
                                : getNextFragmentId(surveyTaker);
                            if (
                                surveyTaker.survey &&
                                fragmentIndex !== surveyTaker.fragmentIndex
                            ) {
                                dispatch({
                                    type: types.surveyTaker.SET_FRAGMENT,
                                    payload: {
                                        surveyId,
                                        fragmentIndex,
                                        ...surveyTaker.survey.fragments[
                                            fragmentIndex
                                        ],
                                    },
                                });
                            }
                        })
                    );
            }
            break;
        case types.requests.SURVEY_COMPLETION:
            next(action);
            if (action.payload) {
                dispatch(retrieveSessionStatusOtherSecondly);
            } else {
                dispatch(retrieveSessionStatusMinutely);
            }
            break;
        default: {
            next(action);
        }
    }
};
