import { MenuItem } from "@mui/material";
import { compose, withProps, mapProps } from "recompose";
import { connect } from "react-redux";
import types from "store/types";
import "./DuplicateSurveyMenuItem.css";

const DuplicateSurveyMenuItem = compose(
    connect(),
    withProps(({ dispatch, surveyId }) => ({
        onClick: () => {
            dispatch({
                type: types.requests.NAVIGATION,
                payload: {
                    view: "duplicate-survey",
                    params: { surveyId },
                },
            });
        },
    })),
    mapProps(({ dispatch, userId, survey, surveyId, ...props }) => props)
)(MenuItem);

export default DuplicateSurveyMenuItem;
