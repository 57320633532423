import { lifecycle } from "recompose";

/**
 * @function getPromise
 * @param {object} props
 * @returns {Promise}
 */

/**
 * @function withPromise
 * @param {string} propKey - Prop to set.
 * @param {function} getPromise - Return a promise given the current props.
 * @returns
 */

const withPromise = (key, getPromise) =>
    lifecycle({
        componentDidMount() {
            const promise = getPromise(this.props);
            promise.then((value) => {
                this.setState({ [key]: value });
            });
        },
    });

export default withPromise;
