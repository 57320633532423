import React, { Component } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckBox from "@mui/icons-material/CheckBoxOutlined";
import Audiotrack from "@mui/icons-material/KeyboardVoiceOutlined";
import Textsms from "@mui/icons-material/TextsmsOutlined";
import Subject from "@mui/icons-material/InfoOutlined";
import FileCopy from "@mui/icons-material/FileCopy";
import { connect } from "react-redux";
import types from "store/types";
import "./EditorAddScreen.css";

const NewPaneButton = connect(
    undefined,
    (dispatch, { id, type, ...rest }) => ({
        ...rest,
        onClick: () => {
            if (id !== undefined) {
                dispatch({
                    type: types.surveyCreator.UPDATED_FRAGMENT,
                    payload: {
                        id,
                        type,
                    },
                });
            } else {
                dispatch({
                    type: types.surveyCreator.CREATED_FRAGMENT,
                    payload: {
                        type,
                    },
                });
            }
        },
    })
)(({ title, Icon, onClick, disabled = false }) => (
    <Button
        className="editor-add-screen__choice"
        variant="outlined"
        color="primary"
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
    >
        <Icon className="editor-add-screen__choice-icon" />
        <Typography
            className="editor-add-screen__choice-title"
            variant="overline"
        >
            {title}
        </Typography>
    </Button>
));

class EditorAddScreen extends Component {
    render() {
        return (
            <div className="editor-add-screen-content">
                <NewPaneButton
                    id={this.props.id}
                    Icon={CheckBox}
                    type="CONSENT"
                    title="Consent"
                />
                <NewPaneButton
                    id={this.props.id}
                    Icon={Audiotrack}
                    type="AUDIO_STANDARD"
                    title="Audio"
                />
                <NewPaneButton
                    id={this.props.id}
                    Icon={Textsms}
                    type="TEXT_SURVEYJS"
                    title="Text"
                />
                <NewPaneButton
                    id={this.props.id}
                    Icon={Subject}
                    type="INFO"
                    title="Info"
                    />
                <NewPaneButton
                    id={this.props.id}
                    Icon={FileCopy}
                    type="TEMPLATE"
                    title="Template"
                    />
            </div>
        );
    }
}

export default EditorAddScreen;
