import { mergeWith, flowRight, keyBy } from "lodash";
import reduceReducers from "reduce-reducers";

export const CRUDArrayReducer = (
    { CREATED, UPDATED, DELETED } = {},
    reducer,
    indexer
) => (state = [], action = {}) => {
    switch (action.type) {
        case CREATED:
            return [...state, reducer(undefined, action)];
        case UPDATED: {
            const index = indexer(state, action);
            return index + 1
                ? [...state, reducer(state[index], action)]
                : state;
        }
        case DELETED: {
            const index = indexer(state, action);
            return index + 1
                ? [
                      ...state.slice(0, index),
                      ...state.slice(index + 1, state.length),
                  ]
                : state;
        }
        default:
            return state;
    }
};

export const PastPresentFutureReducer = ({
    UNDO,
    REDO,
    RESET,
    CLEAR,
}) => reducer => {
    const initialState = {
        past: [],
        present: reducer(undefined, {}),
        future: [],
    };
    return (state = initialState, action = {}) => {
        const { past, present, future } = state;
        switch (action.type) {
            case UNDO:
                const previous = past[past.length - 1];
                const newPast = past.slice(0, past.length - 1);
                return previous
                    ? {
                          past: newPast,
                          present: previous,
                          future: [present, ...future],
                      }
                    : state;
            case REDO:
                const next = future[0];
                const newFuture = future.slice(1);
                return next
                    ? {
                          past: [...past, present],
                          present: next,
                          future: newFuture,
                      }
                    : state;
            case RESET:
                const start = past[0];
                const timeline = [...past.slice(1), present, ...future];
                return past.length
                    ? {
                          past: [],
                          present: start,
                          future: timeline,
                      }
                    : state;
            case CLEAR:
                return {
                    past: [],
                    present,
                    future: [],
                };
            default:
                const newPresent = reducer(present, action);
                if (present === newPresent) {
                    return state;
                }
                return {
                    past: [...past, present],
                    present: newPresent,
                    future: [],
                };
        }
    };
};

// If we ever start getting or using data directly from Kafka payloads.
export const payloadEntity = branch => action =>
    action.payload.entities[branch][action.payload.id];

export const simpleLoadingReducer = key => ({ LOADED }) => (
    state = {},
    action = {}
) => {
    if (action.error) {
        return state;
    }
    switch (action.type) {
        case LOADED: {
            return flowRight(
                initialState({}),
                actionsReducerReducer(),
                intoObjectKey(payload(key)),
                // intoObjectKey(payload(secondKey)),
                mergeReducerReducer()
            )(payload())(state, action);
        }
        default:
            return state;
    }
};

export const simpleUpdatingReducer = key => ({ UPDATED }) => (
    state = {},
    action = {}
) => {
    if (action.error) {
        return state;
    }
    switch (action.type) {
        case UPDATED: {
            return {
                ...state,
                [action.payload[key]]: {
                    ...state[action.payload[key]],
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
};

// Keys are replaced, but Array keys are unioned.
const defaultMerge = (state, nextState) =>
    mergeWith(state, nextState, (o, _o) => {
        switch (true) {
            case Array.isArray(o) && _o === undefined:
                return o;
            // return unionBy(o, _o, "fragmentId");
            default:
                return undefined;
        }
    });

export const mergeReducerReducer = (merger = defaultMerge) => reducer => (
    state = {},
    action = {}
) => {
    const nextState = reducer(state, action);
    if (nextState !== state) {
        // Make a copy of the state so it can't be modified in the mergeReducer.
        return merger({ ...state }, nextState);
    } else {
        return state;
    }
};

export const stringAsValueReducer = transducer => reducer =>
    transducer(() => reducer);

export const stringAsKeyReducer = transducer => (reducer, ...options) =>
    transducer(state => state[reducer], ...options);

export const objectAsKeysReducer = transducer => (reducer, ...options) => (
    _reducer = pass
) =>
    reduceReducers(
        ...Object.entries(reducer).map(([key, value]) => {
            // TODO: This is a little weird because it's very easy for loops to occur.
            const _value =
                value instanceof Function
                    ? value
                    : transducer(value, ...options);
            return transducer(() => key, ...options)(_value);
        }),
        _reducer
    );

// Set the value on a key in an object, and the reducer is passed the current value as state.
export const intoObjectKey = (keyReducer = pass, dropKey = null) => {
    if (typeof keyReducer === "string")
        return stringAsKeyReducer(intoObjectKey)(keyReducer, dropKey);
    if (typeof keyReducer === "object")
        return objectAsKeysReducer(intoObjectKey)(keyReducer, dropKey);
    if (!(keyReducer instanceof Function)) return () => pass;
    return (reducer = pass) =>
        intoObject(keyReducer, dropKey)(reduceKey(keyReducer)(reducer));
};

export const reduceKey = keyReducer => {
    if (typeof keyReducer === "string")
        return stringAsKeyReducer(reduceKey)(keyReducer);
    if (!(keyReducer instanceof Function)) return () => pass;
    return reducer => (state, action) => {
        // TODO: Warn when the state isn't an object.
        if (!(state instanceof Object)) return undefined;
        const key = keyReducer(state, action);
        // NOTE: Ignore the [undefined] key.
        if (key === undefined) return state;
        const keyState = state[key];
        return reducer(keyState, action);
    };
};

// Set the value on a key in an object, and the reducer is passed the current object as state.
export const intoObject = (keyReducer, dropKey = null) => {
    if (typeof keyReducer === "string")
        return stringAsKeyReducer(intoObject)(keyReducer, dropKey);
    if (typeof keyReducer === "object")
        return objectAsKeysReducer(intoObject)(keyReducer, dropKey);
    if (!(keyReducer instanceof Function)) return () => pass;
    return reducer => (state = {}, action) => {
        // TODO: Warn when the state isn't an object.
        if (!(state instanceof Object)) return state;
        const key = keyReducer(state, action);
        // NOTE: Ignore the [undefined] key.
        if (key === undefined) return state;
        const keyState = state[key];
        const nextKeyState = reducer(state, action);
        // No change.
        if (keyState === nextKeyState) return state;
        // The next value is the dropkey value. (default: null)
        if (nextKeyState === dropKey) {
            // There is no key to drop.
            if (keyState === undefined) return state;
            const nextState = { ...state };
            delete nextState[key];
            return nextState;
        } else {
            return { ...state, [key]: nextKeyState };
        }
    };
};

export const intoArray = (keyReducer, dropKey = null) => {
    if (!(keyReducer instanceof Function)) return () => pass;
    return reducer => (state = [], action) => {
        // TODO: Warn when the state isn't an array.
        if (!Array.isArray(state)) return state;
        const key = keyReducer(state, action);
        // NOTE: Ignore the [undefined] key.
        if (key === undefined) return state;
        const keyState = state[key];
        const nextKeyState = reducer(state, action);
        // No change.
        if (keyState === nextKeyState) return state;
        // The next value is the dropkey value. (default: null)
        if (nextKeyState === dropKey) {
            // There is no key to drop.
            if (keyState === undefined) return state;
            const nextState = [...state];
            nextState.splice(key, 1);
            return nextState;
        } else {
            const nextState = [...state];
            nextState[key] = nextKeyState;
            return nextState;
            // TODO: It's not sure clear in the array if we should insert or replace.
            // return [...state.slice(0, key), nextKeyState, ...state.slice(key + 1)];
        }
    };
};

intoArray.append = intoArray(arr => arr.length);

// ? Branch might literally be the idea of transducers, i.e. making a branch function is just treading water.
export const branch = reduceTransducer => reducer => (state, action) =>
    reduceTransducer(state, action)(reducer)(state, action);

export const filter = predicateReducer => reducer => (state, action) =>
    predicateReducer(state, action) === true ? reducer(state, action) : state;

export const accept = predicateReducer => reducer => (state, action) => {
    const nextState = reducer(state, action);
    return predicateReducer(nextState, action) ? nextState : state;
};

// export const replace = predicateReducer => reducer => (state, action) => filter((state, action))(accept())

export const acceptReducerReducer = (filter, acceptableReducer) => reducer => (
    state,
    action
) => {
    const nextState = reducer(state, action);
    if (filter(nextState)) {
        return nextState;
    } else {
        return acceptableReducer(state, action);
    }
};

export const initialState = undefinedValue => {
    if (undefinedValue === undefined) {
        // TODO: Should we throw a warning or an error instead?
        return initialState(null);
    }
    return reducer => (state, action) => {
        return state === undefined
            ? reducer(undefinedValue, action)
            : reducer(state, action);
    };
};

export const actionsReducerReducer = (
    actionsReducer = (state, action) =>
        Array.isArray(action.payload)
            ? action.payload.map(payload => ({ ...action, payload }))
            : action
) => reducer => (state, action = {}) => {
    // Build some actions out of the action.
    const actions = actionsReducer(state, action);
    // Reduce to the next state.
    const nextState = Array.isArray(actions)
        ? actions.reduce(reducer, state)
        : reducer(state, actions);
    return nextState;
};

export const actionTypesReducerReducer = (_actionTypes = {}) => {
    const actionTypes = Array.isArray(_actionTypes)
        ? keyBy(_actionTypes)
        : _actionTypes;
    return reducer => (state, action) =>
        actionTypes[action.type] === undefined
            ? state
            : actionTypes[action.type] instanceof Function
            ? reducer(actionTypes[action.type](state, action), action)
            : reducer(state, action);
};

export const toState = reducer => next => (state, action) =>
    next(reducer(state, action), action);

export const toAction = reducer => next => (state, action) =>
    next(state, reducer(state, action));

export const pass = state => state;


// actionType reducer is used as a predicate to filter.
export const actionTypeFilter = (reducer = pass) => filter(type(reducer));

export const type = (reducer = pass) => {
    if (typeof reducer === "string") {
        return type(state => state === reducer);
    }
    if (Array.isArray(reducer)) {
        return type(state => reducer.includes(state));
    }
    if (typeof reducer === "object") {
        return (state, action) => {
            const value = type(state => reducer[state]);
            // We assume that function values are reducers.
            return value instanceof Function ? value(state, action) : value;
        };
    }
    return (state, action) => reducer(action.type, action);
};

export const payload = (reducer = pass) => {
    if (typeof reducer === "string") {
        // TODO: Warn when the state is not an object.
        return payload((state, action) =>
            state instanceof Object ? state[reducer] : undefined
        );
    }
    if (typeof reducer === "object") {
        return payload(intoObjectKey(reducer)(pass));
    }
    return (state, action) => reducer(action.payload, action);
};

export const meta = (reducer = pass) => {
    if (typeof reducer === "string") {
        // TODO: Warn when the state is not an object.
        return meta((state, action) =>
            state instanceof Object ? state[reducer] : undefined
        );
    }
    if (typeof reducer === "object") {
        return meta(intoObjectKey(reducer)(pass));
    }
    return (state, action) => reducer(action.meta, action);
};

export const simpleTwoKeyLoadingReducer = (firstKey, secondKey) => ({
    LOADED,
}) => (state = [], action = {}) => {
    switch (action.type) {
        case LOADED:
            return flowRight(
                initialState({}),
                actionsReducerReducer(),
                intoObjectKey(payload(firstKey)),
                intoObjectKey(payload(secondKey)),
                mergeReducerReducer()
            )(payload())(state, action);
        default:
            return state;
    }
};

export const dependency = dependantReducer => stateReducer => reducer => (
    state,
    action
) => {
    const nextState = reducer(state, action);
    const nextStateDependency = dependantReducer(nextState, action);
    const stateDependency = dependantReducer(state, action);
    if (stateDependency === nextStateDependency) {
        return nextState;
    }
    return stateReducer(nextState, action)(pass)(nextState, action);
};

export const peek = (titleReducer = () => "Peek!") => {
    if (typeof titleReducer === "string") {
        return peek(() => titleReducer);
    }
    return reducer => (state, action) => {
        const nextState = reducer(state, action);
        const title = titleReducer(state, action);
        console.group(title);
        console.info(state);
        console.info(action);
        console.info(nextState);
        console.info(state === nextState ? "Equal" : "Not Equal");
        console.groupEnd(title);
        return nextState;
    };
};

export const roll = (...transducers) => flowRight(...transducers)(pass);

export const forwardHistory = reducer =>
    flowRight(
        intoObject({
            past: initialState([])(state => state.past.concat(state.present)),
        }),
        intoObject({
            present: reducer,
            future: () => [],
        })
    );

export const pushHistory = reducer =>
    flowRight(
        intoObject({
            past: initialState([])(state => state.past.concat(state.present)),
        }),
        intoObject({
            present: reducer,
            future: () => [],
        })
    );

export const backwardHistory = flowRight(
    intoObject({
        present: state => state.past[state.past.length - 1],
    }),
    intoObject({
        past: initialState([])(state => state.past.slice(0, -1)),
        future: initialState([])(state => state.future.concat(state.present)),
    })
);
