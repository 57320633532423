import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { compose, setDisplayName, setPropTypes, withProps } from "recompose";
import { connect } from "react-redux";
import "./SkipAudioDialog.css";

const SkipAudioDialog = compose(
    setDisplayName("SkipAudioDialog"),
    setPropTypes({
        fragment: PropTypes.object,
        onSkip: PropTypes.func,
        onCancel: PropTypes.func,
    }),
    connect(),
    withProps(({ error = {}, type }) => ({ type: type ? type : error.name }))
)(({ onCancel, onSkip, onSchedule }) => {
    return (
        <Dialog open fullWidth>
            <DialogTitle>Skip Confirmation</DialogTitle>
            <DialogContent>
                <div className="audio-skip__content">
                    <Typography variant="body1">
                        Are you sure you want to skip this question?
                    </Typography>
                    <Typography variant="subheading">
                        Not a good time?
                    </Typography>
                    <Typography variant="body1">
                        Anwser this question on a different device or at a later time.
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Back</Button>
                <Button onClick={onSkip}>Skip</Button>
                <Button onClick={onSchedule}>Remind Me Later</Button>
            </DialogActions>
        </Dialog>
    );
});

export default SkipAudioDialog;
