import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import './SearchBar.css'

const SearchBar = ({ search, hideIcon, placeholder, onSearch }) => (
  <div className="search-text-field__container">
    { hideIcon ? null :
      <SearchIcon className="search-text-field__icon" />
    }
    <input
      className="search-text-field__input"
      type="text"
      placeholder={placeholder}
      value={search}
      onChange={onSearch && (event => onSearch(event.target.value))}
    />
  </div>
)

SearchBar.defaultProps = {
  search: '',
  hideIcon: false
}

SearchBar.propTypes = {
  search: PropTypes.string,
  hideIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func
}

export default SearchBar
