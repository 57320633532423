import React from "react";
import { Showdown } from "components";
import { Button } from "@mui/material";
import "./SurveyFragmentInfo.css";

import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import types from "store/types";

const SurveyFragmentInfo = compose(
    connect(),
    withProps(({ dispatch, onComplete, fragment }) => ({
        onComplete: (...args) => {
            dispatch({
                type: types.responses.COMPLETED,
                payload: {
                    fragment,
                    submission: {
                        // TODO: Maybe there are some metrics here that we could capture at some point in the future?
                        // What data is relevant in the SurveyFragment?
                    },
                },
            });
            onComplete(...args);
        },
    }))
)(({ title, content, continueText = "Continue", onComplete }) => {
    return (
        <div className="survey-screen survey-fragment-info">
            <div className="survey-fragment-info__inner-container">
                {
                    // Optionally hide the title.
                    !(typeof title === "string") ? null : (
                    <div className="survey-fragment-info__header">
                        <Showdown>{title}</Showdown>
                    </div>
                )}
                <div className="survey-fragment-info__content">
                    <Showdown>{content}</Showdown>
                </div>
                <div className="survey-fragment-info__actions">
                    <span className="expanding-gap" />
                    <Button
                        className="survey-fragment-info__next-action"
                        variant="contained"
                        color="primary"
                        onClick={onComplete}
                    >
                        {continueText}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default SurveyFragmentInfo;
