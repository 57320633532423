import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UserAppLayout from "layouts/UserAppLayout";
import "./About.css";

const About = props => {
  return (
    <UserAppLayout>
      <Card className="About">
        <Grid container>
          <Grid item xs={6} className="item">
            <Typography variant="h6" gutterBottom>
              {"About"}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {
                "At SurveyLex, we are innovating the survey experience. Users speak, as opposed to type or click, their responses. Using machine learning, we can auto-label responses with emotions (e.g. happy/sad), age, gender, accents, and even race. In this way we can dramatically reduce the number of questions on surveys up to 500% and decrease dropout rates by up to 50%. In doing so, we create a better experience for the survey maker and the survey taker."
              }
            </Typography>
            <Typography variant="body1" gutterBottom>
              {
                "If you have any questions on our product, feel free to reach out to us and we’ll definitely get back to you!"
              }
            </Typography>
            <div className="contact">
              <Typography variant="body1">{"Jim Schwoebel"}</Typography>
              <Typography variant="body1">{"(412)-613-0772"}</Typography>
              <Typography variant="body1">{"js@neurolex.co"}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} className="item">
            <iframe
              title="Welcome to SurveyLex"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/6BoCSHzBUiE"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </Grid>
        </Grid>
      </Card>
    </UserAppLayout>
  );
};

export default About;
