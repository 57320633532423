import types from "store/types";
import { once } from "lodash";

// Global audio element.
// https://tinyurl.com/ya6wsaj2 (Mozilla Docs)
const audio = document.createElement("audio");
audio.crossOrigin = "anonymous";

const setupDispatchAudioCallbacks = once(dispatch => {
    audio.addEventListener("ended", () => {
        dispatch({ type: types.responsePlayback.END_OF_PLAYBACK });
    });
    /*
  audio.addEventListener('timeupdate', () => {
      dispatch({ type: types.responsePlayback.PLAYING})
  })
  audio.addEventListener('waiting', () => {
      dispatch({ type: types.responsePlayback.BUFFERING})
  })
  audio.addEventListener('suspend', () => {
  audio.addEventListener('abort', () => {
  audio.addEventListener('error', () => {
  audio.addEventListener('empty', () => {
  audio.addEventListener('stalled', () => {
      dispatch({ type: types.responsePlayback.BUFFERING})
  })
  */
});

const playback = ({ dispatch, getState }) => next => action => {
    setupDispatchAudioCallbacks(dispatch);
    next(action);
    const state = getState();
    switch (action.type) {
        case types.responsePlayback.REQUESTED_PLAYBACK:
            audio.pause();
            const sampleId = state.responsePlayback.playback.id.replace(
                /^nlx-/,
                ""
            );
            // Audio resource location.
            const location =
                state.CONFIG.NLX__API_ENDPOINT__AUDIO_STREAMER +
                "/decrypt?id=" +
                sampleId;
            const token = state.user.session.token;
            // Cache the requested audio file.
            // NOTE: We need to do this in order to get the resouces ever though it requires an authorization header.
            // https://stackoverflow.com/questions/4285042
            // https://security.stackexchange.com/questions/175695
            return fetch(location, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                cache: "force-cache", // Let's give the server a break.
            })
                .then(response => response.blob())
                .then(blob => {
                    const source = URL.createObjectURL(blob);
                    dispatch({
                        type: types.responsePlayback.LOADED,
                        payload: {
                            source,
                            playbackId: state.responsePlayback.playback.id,
                        },
                    });
                    return source;
                })
                .catch(error => {
                    console.error(error);
                });
        case types.responsePlayback.LOADED:
            if (
                action.payload.playbackId === state.responsePlayback.playback.id
            ) {
                audio.setAttribute("type", "audio/wav");
                audio.setAttribute("src", state.responsePlayback.source);
                switch (state.responsePlayback.state) {
                    case "playing":
                        audio.play();
                        break;
                    default:
                        break;
                }
            }
            break;
        case types.responsePlayback.PLAYED:
            if (state.responsePlayback.source) {
                audio.play();
            }
            break;
        case types.responsePlayback.PAUSED:
            if (state.responsePlayback.source) {
                audio.pause();
            }
            break;
        default:
            break;
    }
};

export default playback;
