import React from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { keyBy } from "lodash";
import types from "store/types";
import { LinearProgress } from "@mui/material";
import "./SurveyCompletion.css";

const SurveyCompletion = compose(
    connect(({ surveyTaker: { survey, session, uploading } }) => ({ survey, session, uploading })),
    withProps(({ survey = {}, session = {}, uploading = [] }) => ({
        fragments: survey.fragments,
        responses: session.responses,
        uploading: uploading.length
    })),
    withProps(({ responses = [], samples = [] }) => ({
        responsesByFragmentId: keyBy(responses, "fragmentId"),
        samplesByResponseId: keyBy(samples, "responseId"),
    })),
    withProps(({ fragments = [], responsesByFragmentId = {} }) => ({
        completedFragments: fragments.filter(
            ({ fragmentId }) => responsesByFragmentId[fragmentId]
        ),
        remainingFragments: fragments.filter(({ fragmentId }) => {
            const hasResponseForFragment =
                responsesByFragmentId[fragmentId] instanceof Object;
            return !hasResponseForFragment;
        }),
    })),
    withProps(
        ({
            fragments = [],
            completedFragments = [],
            remainingFragments = [],
        }) => ({
            completed: completedFragments.length,
            remaining: remainingFragments.length,
            total: fragments.length,
        })
    ),
    withProps(({ dispatch, remaining, uploading }) => {
        if (remaining + uploading > 0) {
            dispatch({
                type: types.requests.SURVEY_COMPLETION,
                payload: true,
            });
            dispatch({
                type: types.requests.PREVENT_NAVIGATION,
                payload: "SURVEY_TAKER_UPLOAD",
            });
        } else {
            dispatch({
                type: types.requests.UNPREVENT_NAVIGATION,
                payload: "SURVEY_TAKER_UPLOAD",
            });
        }
        return {};
    })
)(({ children, completed, remaining, uploading, total }) => (
    <div>
        <LinearProgress
            variant="determinate"
            value={((completed - uploading) / total) * 100}
        />
        {remaining + uploading > 0 ? (
            <div> {children}</div>
        ) : (
            <div>
                <p>Thank you for completing the survey!</p>
            </div>
        )}
    </div>
));

export default SurveyCompletion;
