import React, { Component } from 'react';
import loading from './loading.svg';

class Loading extends Component {
  render() {
    const style = {
      // TODO: These should be in a container.
      //position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      //height: '100vh',
      //width: '100vw',
      //top: 0,
      //bottom: 0,
      //left: 0,
      //right: 0,
      backgroundColor: 'white',
    }

    return (
      <div style={style}>
        <img src={loading} alt="loading"/>
      </div>
    );
  }
}

export default Loading;